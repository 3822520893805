import React, { createContext, useContext } from "react";
import useInvoices, { IInvoices } from "~/hooks/useInvoices";

export const InvoiceContext = createContext(null as IInvoices | null);

export const useInvoiceContext = () => {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw new Error("useInvoiceContext must be used within a InvoiceContextProvider");
  }
  return context;
};

const InvoiceContextProvider = ({ children }: any) => {
  return (
    <InvoiceContext.Provider
      value={{
        ...useInvoices(),
      }}
    >
      {children}
    </InvoiceContext.Provider>
  );
};

export default InvoiceContextProvider;
