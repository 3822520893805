import Axios from "axios";
import { API_URL } from "~/config";

export class OptionsService {
  static getGlobalOptions = async () => await Axios.get(`${API_URL}/options`);
  static getCountries = async () => await Axios.get(`${API_URL}/countries`);
  static getCities = async ({ country, search }: { country: string; search: string }) =>
    await Axios.get(`${API_URL}/cities?country=${country}&search=${search}&limit=50`);
  static getLanguages = async () => await Axios.get(`${API_URL}/languages`);
  static getUmbrellaCompanies = async () => await Axios.get(`${API_URL}/umbrellas`);
  static getPaymentCompanies = async () => await Axios.get(`${API_URL}/payment_companies`);
}
