import Axios from "axios";
import { FormikProps, FormikValues } from "formik";
import { Location } from "history";
import omit from "lodash/omit";
import moment, { Moment } from "moment";
import { DataTableSortOrderType } from "primereact/datatable";
import React, { isValidElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import * as Yup from "yup";
import { IColumn } from "./components/common/BriefsTabs/columns";
import { CONFIRM_LEAVE_PAGE, URL_REGEXP } from "./config";
import { ADDITIONAL_ROLES, CLIENT_ROLES_REG_EXP, IRole } from "./contexts/PermissionContext";
import { IOptionsNormalization } from "./hooks/useOptions";
import { BriefStatuses, IBrief, ICustomItem } from "./interfaces/brief";
import { formatValuesBeforeBriefSaving } from "./routes/Brief/helper";
import toasts from "./store/actions/toasts";
import { IBooking } from "./store/constants/bookings";
import { uniqBy } from "lodash";

const LOCATION_FLEXIBLE_CODE = 3;
const getIr35Value = (ir35_id: number, IR35_TYPES: any) => {
  let ir35Value;
  for (let value in IR35_TYPES) {
    if (ir35_id === IR35_TYPES[value]) {
      ir35Value = value;
    }
  }
  return ir35Value || "";
};

export function openHtmlLink(url: string, download = false) {
  const element = document.createElement("a");
  element.style.display = "none";
  element.setAttribute("href", url);
  element.setAttribute("target", "_blank");
  download && element.setAttribute("download", url.substring(url.lastIndexOf("/") + 1));
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const PASSWORD_CHECK_LENGTH = 8;
export const PASSWORD_CHECK_LOWERCASE = /[a-z]+/;
export const PASSWORD_CHECK_UPPERCASE = /[A-Z]+/;
export const PASSWORD_CHECK_NUMBER = /[0-9]+/;
export const PASSWORD_CHECK_SPECIAL = /[\!\@\#\$\%\^\&\*]+/;

export const setAuthToken = (token: string): void => {
  Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export function checkLength(password: string) {
  return password.length >= PASSWORD_CHECK_LENGTH;
}

export function checkLowercase(password: string) {
  return PASSWORD_CHECK_LOWERCASE.test(password);
}

export function checkCapital(password: string) {
  return PASSWORD_CHECK_UPPERCASE.test(password);
}
export function checkNumber(password: string) {
  return PASSWORD_CHECK_NUMBER.test(password);
}

export function checkSpecial(password: string) {
  return PASSWORD_CHECK_SPECIAL.test(password);
}

export function checkConfirm(password: string, confirm_password: string) {
  return password && password === confirm_password;
}

export function validatePassword(password: string, confirm_password: string) {
  return (
    checkLength(password) &&
    checkLowercase(password) &&
    checkCapital(password) &&
    checkNumber(password) &&
    checkSpecial(password) &&
    checkConfirm(password, confirm_password)
  );
}

export function isLocalStorageAvailable(): boolean {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function getParameterByName(name: string, url: string) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const escapeRegExp = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const divideEntries = (form: any) => {
  let entries = {};
  const divide = (type: string, values: any) => ({
    [type]: values.filter((el: any) => !!el.id),
    [`custom_${type}`]: values.filter((el: any) => !el.id).map((entry: any) => entry.name),
  });
  if (form.skills && !!form.skills.length) {
    entries = { ...entries, ...divide("skills", form.skills) };
  }
  if (form.sectors && !!form.sectors.length) {
    entries = { ...entries, ...divide("sectors", form.sectors) };
  }
  if (form.platforms && !!form.platforms.length) {
    entries = { ...entries, ...divide("platforms", form.platforms) };
  }
  return entries;
};

export const processBriefForm = async (
  form: any,
  saveOrConfirm: (values: IBrief) => void,
  getOptionsNormalization: () => IOptionsNormalization,
  briefStatus: { status: string },
  dispatch: Dispatch<any>,
  validateBrief: (data: IBrief, type: number, IR35_TYPES: any) => Promise<{ valid: boolean; error_fields: string[] }>,
  reviewIR35: () => void,
  resetWrongInputs: (error_fields: string[]) => void
) => {
  const formValues = {
    ...form,
    ...divideEntries(form),
    ...briefStatus,
  };
  const { CONTRACT_TYPES, IR35_TYPES, DURATION_ID } = getOptionsNormalization();
  const values = formatValuesBeforeBriefSaving(form, formValues, {
    CONTRACT_TYPES,
    IR35_TYPES,
    DURATION_ID,
    LOCATION_FLEXIBLE_CODE,
  });

  let submitBriefFunction;
  if (!form.ir35_compliant && (form.ir35_id === IR35_TYPES.INSIDE || form.ir35_id === IR35_TYPES.OUTSIDE)) {
    try {
      const { valid, error_fields } = await validateBrief(values, form.ir35_id, IR35_TYPES);
      const ir35Value = getIr35Value(form.ir35_id, IR35_TYPES);

      const content: string = valid
        ? `Great news, if you work this way through your booking you are working ${ir35Value} IR35.`
        : form.ir35_id === IR35_TYPES.INSIDE
        ? `HOLD UP! THIS IS PROBABLY NOT ${ir35Value}! Please review your answers or change IR35 type for this brief.`
        : `HOLD UP! To continue with this brief as OUTSIDE IR35 you must agree to this statement. Please update your answer or change the IR35 type of this brief`;

      submitBriefFunction = () => {
        dispatch(
          toasts.setPopup({
            content,
            buttons: [
              ...(!valid ? [{ text: "Review answers", callback: () => resetWrongInputs(error_fields) }] : []),
              {
                text: valid ? "Continue" : "Change IR35 type",
                callback: () => (valid ? saveOrConfirm(values) : reviewIR35()),
              },
            ],
          })
        );
      };
    } catch (err) {}
  } else {
    submitBriefFunction = () => saveOrConfirm(values);
  }

  submitBriefFunction && submitBriefFunction();
};
export const processDraftBriefForm = async (
  form: any,
  saveOrUpdate: (values: IBrief) => void,
  history: RouteComponentProps["history"],
  getOptionsNormalization: () => IOptionsNormalization
) => {
  const formValues = {
    ...form,
    ...divideEntries(form),
    status: BriefStatuses.DRAFT,
  };
  const { CONTRACT_TYPES, IR35_TYPES, DURATION_ID } = getOptionsNormalization();
  const values = formatValuesBeforeBriefSaving(form, formValues, {
    CONTRACT_TYPES,
    IR35_TYPES,
    DURATION_ID,
    LOCATION_FLEXIBLE_CODE,
  });
  await saveOrUpdate(values);
  return history.push({
    pathname: "/briefs",
    state: { briefsCategoryIndex: 4 },
  });
};

export const formValuesExempt = (values: IBrief) =>
  omit(values, [
    "freelancer_amend",
    "freelancer_arrange_space",
    "freelancer_control",
    "freelancer_credits",
    "freelancer_defines_location",
    "freelancer_equipment",
    "freelancer_flexibility",
    "freelancer_introducing",
    "freelancer_managerial",
    "freelancer_other_projects",
    "freelancer_staff",
    "freelancer_substitute",
    "freelancer_type",
  ]);

export const resetIR35Type = (ir35_id: number, formik: FormikProps<FormikValues>) => {
  formik.setValues({
    ...formik.values,
    ir35_id,
    freelancer_amend: "",
    freelancer_control: "",
    freelancer_other_projects: "",
    freelancer_substitute: "",
    freelancer_defines_location: null,
    freelancer_arrange_space: null,
    freelancer_flexibility: null,
    location_id: null,
  });
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const reactNodeToString = (reactNode: React.ReactNode): string => {
  let string = "";
  if (typeof reactNode === "string") {
    string = reactNode;
  } else if (typeof reactNode === "number") {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach((child) => {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return string;
};

export const splitVocabulary = (
  vocabulary: ICustomItem[],
  selected: ICustomItem[],
  setItems: Function,
  setSelectedItems: Function
) => {
  const selectedAllItems = selected.reduce(
    (acc: { selectedIds: number[]; selectedCustomItems: ICustomItem[] }, selectedItem: ICustomItem) => {
      if (selectedItem.id) {
        acc.selectedIds.push(selectedItem.id);
      } else {
        acc.selectedCustomItems.push(selectedItem);
      }

      return acc;
    },
    { selectedIds: [], selectedCustomItems: [] }
  );
  const { selectedIds, selectedCustomItems } = selectedAllItems;

  setItems(vocabulary.filter((availableItem: ICustomItem) => !selectedIds.includes(availableItem.id)));
  if (selected.length) {
    const selectedItems = vocabulary
      .filter((availableItem: ICustomItem) => selectedIds.includes(availableItem.id))
      .map((item: ICustomItem) => ({
        ...item,
        order_id: (selected.find((s: ICustomItem) => s.id === item.id) as ICustomItem).order_id,
      }))
      .sort((a: any, b: any) => (a.order_id === b.order_id ? 0 : a.order_id > b.order_id ? 1 : -1));
    setSelectedItems([...selectedItems, ...selectedCustomItems]);
  }
};

export const networkErrorMessage = "Oh no, dodgy network going on, pls try again";
export const requiredFieldMessage = "Please, fill in required field";
export const invalidNumberMessage = "Please, enter a valid number";
export const minSymbolsMessage = "Please, add at least ${min} symbols";
export const maxSymbolsMessage = "Please, make it shorter than ${max} symbols";
export const minPhoneMessage = "Phone number should be at least ${min} characters";
export const maxPhoneMessage = "Phone number shouldn't be longer ${max} characters";

export const buildPaginatedQuery = (
  page: number,
  sort?: { field: string; order: DataTableSortOrderType },
  other?: { [key: string]: any }
) => {
  const query = page ? [`page=${page}&per_page=${other?.per_page || 15}`] : [];
  sort?.field && query.push(`order_by=${sort.field}&direction=${sort.order === 1 ? "asc" : "desc"}`);

  other?.discipline && query.push(`discipline_code=${other.discipline}`) && delete other.discipline;

  other?.search && query.push(`name=${other.search}`) && delete other.search;
  other?.search_brief && query.push(`search=${other.search_brief}`) && delete other.search_brief;

  if (other && !!Object.keys(other).length) {
    for (const key in other) {
      switch (typeof other[key]) {
        case "object":
          other[key].forEach((val: string) => query.push(`${key}[]=${val}`));
          break;
        default:
          query.push([key, other[key]].join("="));
      }
    }
  }

  return query.length ? "?" + query.join("&") : "";
};

export const formatPercent = (value: number) => Math.round((value + Number.EPSILON) * 1000) / 10;

export const formatCurrency = (amount: number | string = 0, maximumFractionDigits = 2) =>
  (typeof amount === "string" ? parseFloat(amount) : amount).toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits,
  });

export const isBriefFormTouched = (
  values: { [key: string]: any },
  initial: { [key: string]: any },
  isClientAdmin: boolean
): boolean => {
  if (isClientAdmin) {
    ["client_id", "author_id", "umbrella", "exempt"].forEach((field) => {
      delete values[field];
      delete initial[field];
    });
  }

  return JSON.stringify(values) !== JSON.stringify(initial);
};

export const isBriefFormComplited = (location: Location, isTouched: boolean): string | true =>
  /\/briefs\/\d+/.test(location.pathname) || !isTouched ? true : CONFIRM_LEAVE_PAGE;

export const getDiffBetweenDays = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }) =>
  endDate.diff(startDate, "days");

export const parseUrlPrefix = (url: string) => {
  const checkProtocol = new RegExp("^(http|https)://", "i");
  return checkProtocol.test(url) ? url : `https://${url}`;
};

export const calculateDates = {
  newBrief: (formik: FormikProps<FormikValues>, isExactWorkingDates: boolean) => {
    const isDatesInFormik = !!formik?.values?.dates?.length;

    if (isExactWorkingDates && formik.values.start_date && isDatesInFormik) {
      return formik?.values?.dates?.map((item: { date: Date }) => new Date(item.date));
    }

    return formik.values.start_date
      ? [new Date(formik.values.start_date as Date), new Date(formik.values.end_date)]
      : [];
  },
  currentBrief: (brief: IBrief | undefined, isExactWorkingDates: boolean) => {
    const isDatesInBrief = !!brief?.dates?.length;

    if (isExactWorkingDates && brief?.start_date && isDatesInBrief) {
      return brief?.dates?.map(({ date }: { date: any }) => new Date(date));
    }
    return brief?.start_date ? [new Date(brief?.start_date), new Date(brief?.end_date)] : [];
  },
  sort: (dates: Date[]) => dates.sort((a: any, b: any) => moment(a).valueOf() - moment(b).valueOf()),
  formatAsObjectArr: (dates: Date[]) => dates.map((date) => ({ date: moment(date).format("YYYY-MM-DD") })) ?? [],
};

export const isActiveBooking = (booking: IBooking) => ["ACCEPTED", "EXTENDED"].includes(booking?.status);
export const isBriefActiveBookings = (brief: IBrief) =>
  brief?.matches?.some((match) => match?.bookings?.some(isActiveBooking));

export const extractFileName = (url: string) => url.replace(/^.*[\\\/]/, "");

export const validateFile = (file: File, opts: { size?: number; types?: string[] }) => {
  const errors: string[] = [];

  opts.size && file.size > opts.size && errors.push(`Maximum upload size is ${opts.size / 1000000}Mb`);

  if (opts.types) {
    const fileExt = file.name.split(".").pop() as string;

    !opts.types.includes(fileExt) && errors.push(`Incorrect file type`);
  }

  return errors;
};

export const transformArrayToMap = (items: { code: string; name: string }[]) =>
  (items || []).reduce(
    (acc: { [key: string]: string }, { code, name }: { code: string; name: string }) => ({ ...acc, [code]: name }),
    {}
  );

export const prepareCustomColumns = (columns: IColumn[]) =>
  (columns || []).filter(({ showStatement = true }) => showStatement).map(({ header, field }) => ({ field, header }));

export const transformMatchingData = (props: any) => ({
  ...props,
  talent_description: props.talent?.work_profile?.description,
  talent_image_url: props.talent?.work_profile?.image_url,
  talent_id: props.talent?.id,
  talent_portfolio_url: props.talent?.work_profile?.portfolio_url,
  cv_url: props.talent?.work_profile?.cv_url,
});

export const padWithZero = (num: number, targetLength: number) => String(num).padStart(targetLength, "0");

export const processingRoles = (roles: IRole[]) => {
  const rolesOptions = roles
    ?.filter(({ name }: { name: string }) => CLIENT_ROLES_REG_EXP.test(name))
    .map(({ id, name, description, display_name }) => ({
      id,
      code: name,
      name: display_name,
      description,
    }));

  return {
    main: rolesOptions.filter(({ code }) => !ADDITIONAL_ROLES.includes(code)),
    additional: rolesOptions.filter(({ code }) => ADDITIONAL_ROLES.includes(code)),
  };
};

export const processingUserRoles = (userRoles: IRole[]) => {
  return userRoles.reduce(
    (rolesObject: { main: string; additional: string[] }, role: IRole) => {
      if (CLIENT_ROLES_REG_EXP.test(role.name)) {
        ADDITIONAL_ROLES.includes(role.name) ? rolesObject.additional.push(role.name) : (rolesObject.main = role.name);
      }
      return rolesObject;
    },
    { main: "", additional: [] }
  );
};

export const yupString = (
  required: boolean = false,
  min: number | null = null,
  max: number | null = null
): Yup.StringSchema<any, object> => {
  let field: Yup.StringSchema<any, object> = Yup.string();
  if (required) {
    field = field.required("Please, fill in required field");
  }
  if (min) {
    field = field.min(min, `Please, add at least ${min} symbols`);
  }
  if (max) {
    field = field.max(max, `Please, make it shorter than ${max} symbols`);
  }
  return field;
};

export const transformStatusTxt = (rowData: any) => {
  return rowData?.status?.split("_").join(" ") || "";
};

export const isValidUrl = (url: string) => !!new RegExp(URL_REGEXP).test(url);

export const arrayUnion = (arr1: any[], arr2: any[], identifier: string) => {
  const array = [...arr1, ...arr2];

  return uniqBy(array, identifier);
};

export const generateHEXContrastColor = (hexColor: string) => {
  const color = hexColor.replace("#", "");
  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#ffffff";
};
