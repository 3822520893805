import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import { Calendar } from "primereact/calendar";
import React from "react";
import moment from "moment";

interface ICalendar {
  dateFormat?: string;
  formik?: FormikProps<FormikValues>;
  hourFormat?: string;
  id: string;
  inline?: boolean;
  label?: string;
  noField?: boolean;
  readOnlyInput?: boolean;
  selectionMode?: string;
  showTime?: boolean;
  showIcon?: boolean;
  stepMinute?: number;
  useRawValue?: boolean;
  valueFormat?: string;
}

export default function ApCalendar(props: ICalendar) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta && field ? (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <Calendar
        dateFormat="dd/mm/yy"
        {...field}
        {...props}
        value={moment(field.value, props.valueFormat || "YYYY-MM-DD").toDate()}
        onChange={(e) => {
          props.formik?.setFieldValue(
            props.id,
            props.useRawValue
              ? moment(e.value).seconds(0).toDate()
              : moment(e.value).format(props.valueFormat || "YYYY-MM-DD")
          );
        }}
      />
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.id}>
      {({ field, form, meta }: FieldProps) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <Calendar
            dateFormat="dd/mm/yy"
            {...field}
            {...props}
            value={moment(field.value, props.valueFormat || "YYYY-MM-DD").toDate()}
            onChange={(e) => {
              let value: Date = e.value;
              if (props.stepMinute) {
                value.setMinutes(
                  ((((value.getMinutes() + props.stepMinute / 2) / props.stepMinute) | 0) * props.stepMinute) % 60
                );
              }
              form.setFieldValue(
                props.id,
                props.useRawValue
                  ? moment(value).seconds(0).toDate()
                  : moment(value).format(props.valueFormat || "YYYY-MM-DD")
              );
            }}
          />
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
}
