import React from "react";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import { Button } from "primereact/button";
import { Formik, Form } from "formik";
import "./CreatePopupModal.scss";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import { InputSwitch } from "primereact/inputswitch";
import ApRichText from "~/components/common/ApRichText/ApRichText";
import { CreatePopupSchema } from "~/schemas/CreatePopupSchema";
import { IUserPopup, POPUP_VARIANTS } from "~/interfaces/popups";
import useUserPopup from "~/hooks/useUserPopup";
import PopupIcon from "~/components/Toaster/PopupIcon";

export default function CreatePopupModal() {
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { isFetching } = useUserPopup();

  function onSubmit(popup: IUserPopup) {
    if (modalProps?.onSubmit) {
      modalProps.onSubmit(popup);
    }
  }

  return (
    <Formik
      initialValues={{
        name: modalProps?.popup?.name || "",
        variant: modalProps?.popup?.variant || "COMMON",
        is_available: modalProps?.popup?.is_available || false,
        order_id: modalProps?.popup?.order_id || 1,
        header: modalProps?.popup?.header || "",
        body: modalProps?.popup?.body || "",
        primary_button_text: modalProps?.popup?.primary_button_text || "",
        primary_button_action: modalProps?.popup?.primary_button_action || "",
        secondary_button_text: modalProps?.popup?.secondary_button_text || "",
        secondary_button_action: modalProps?.popup?.secondary_button_action || "",
      }}
      validationSchema={CreatePopupSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {(formik) => (
        <Form>
          <Dialog
            className="CreatePopupModal"
            header={`${modalProps?.popup ? "Update" : "Create"} pop-up`}
            footer={
              <footer>
                <Button
                  className="p-button-secondary"
                  label="Cancel"
                  type="button"
                  onClick={() => modalProps.onClose()}
                />
                <Button
                  disabled={isFetching}
                  loadingIcon
                  label="Save"
                  type="submit"
                  onClick={() => formik.submitForm()}
                />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            <div className="grid">
              <div className="col-12 field field-required">
                <label htmlFor="name">
                  Name <PopupIcon content="Visible only for Super Admins." />
                </label>{" "}
                <br />
                <ApInputText id="name" required />
              </div>

              <div className="col-6 field field-required">
                <label htmlFor="variant">
                  Type{" "}
                  <PopupIcon
                    content={(() => (
                      <>
                        <strong>Talent</strong>: a pop-up will be displayed only for the talent users.
                        <br />
                        <strong>Client</strong>: a pop-up will be displayed only for the client users.
                        <br />
                        <strong>Common</strong>: a pop-up will be displayed both for the talent and client users. Be
                        sure that only common variables (like a user name) are used in the Body field.
                      </>
                    ))()}
                  />
                </label>{" "}
                <br />
                <ApDropdown id="variant" options={POPUP_VARIANTS} />
              </div>

              <div className="col-3 mr-3 field field-required">
                <label htmlFor="order_id">
                  Order{" "}
                  <PopupIcon content="Defines the queue of the pop-ups. Order 1 is the highest possible. If 2 pop-ups have the same order, the one with the most recent creation date will be displayed the first." />
                </label>{" "}
                <br />
                <ApInputNumber id="order_id" required />
              </div>

              <div className="col-2 field field field-availability">
                <label htmlFor="is_available">
                  Available{" "}
                  <PopupIcon content="Unavailable pop-ups will not be displayed at all. Can be changed at any time. If the user has already seen the pop-up, switching the availability won’t trigger the pop-up to be displayed again." />
                </label>{" "}
                <br />
                <InputSwitch checked={formik.values.is_available} id="is_available" onChange={formik.handleChange} />
              </div>

              <div className="col-12 field field-required">
                <label htmlFor="header">Header</label> <br />
                <ApInputText id="header" required />
              </div>

              <div className="col-12 field field-required">
                <label htmlFor="body">Body</label> <br />
                <ApRichText
                  value={formik.values.body}
                  onChange={(content: string, _delta, _source, editor) => {
                    const isEmptyField = !editor.getText().trim().length;

                    isEmptyField ? formik.setFieldValue("body", null) : formik.setFieldValue("body", content);
                  }}
                  onBlur={(_previousRange, _source, editor) => {
                    const isEmptyField = !editor.getText().trim().length;

                    isEmptyField && formik.setFieldValue("body", null);
                    formik.setTouched({ ...formik.touched, body: true });
                  }}
                  style={{ height: "200px" }}
                />
                {formik.errors?.body && formik.touched.body && (
                  <div className="ap-error col-12">{formik.errors.body}</div>
                )}
              </div>

              <div className="col-6 field">
                <label htmlFor="primary_button_text">Action button text</label> <br />
                <ApInputText id="primary_button_text" />
              </div>

              <div className="col-6 field">
                <label htmlFor="primary_button_action">Action button URL</label> <br />
                <ApInputText id="primary_button_action" />
              </div>

              <div className="col-6 field">
                <label htmlFor="secondary_button_text"> Secondary button text</label> <br />
                <ApInputText id="secondary_button_text" />
              </div>

              <div className="col-6 field">
                <label htmlFor="secondary_button_action">Secondary button URL</label> <br />
                <ApInputText id="secondary_button_action" />
              </div>
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
