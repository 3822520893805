import client from "./client";
import location from "./location";
import modal from "./modal";
import talent from "./talent";
import toasts from "./toasts";
import user from "./user";
import bookings from "./bookings";

export default {
  client,
  location,
  modal,
  talent,
  toasts,
  user,
  bookings,
};
