import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useSelector } from "react-redux";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import * as Yup from "yup";
import { IState } from "~/store/reducers/index";
import "./InterviewNotes.scss";
import { IMatching } from "~/interfaces/brief";
import { useInterviewContext } from "~/contexts/InterviewContext";

export default function InterviewNotes() {
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { isLoading } = useInterviewContext();

  function onSubmit(matching: IMatching) {
    if (modalProps?.onSubmit) {
      modalProps.onSubmit(matching);
    }
  }

  return (
    <Formik
      initialValues={{
        notes: modalProps.matching?.notes || "",
      }}
      validationSchema={Yup.object().shape({
        notes: Yup.string().max(250, "Please, make it shorter than 250 symbols"),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Dialog
            className="InterviewNotes"
            header="Interview Notes"
            footer={
              <footer>
                {modalProps.readOnly ? (
                  <Button label="Close" onClick={modalProps.onClose} />
                ) : (
                  <>
                    <Button
                      className="p-button-secondary"
                      label="Cancel"
                      onClick={modalProps.onClose}
                      disabled={isLoading}
                    />
                    <Button label="Save" type="submit" onClick={formik.submitForm} disabled={isLoading} />
                  </>
                )}
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            <div className="field">
              <label>Notes</label> <br />
              {modalProps.readOnly ? (
                formik.values.notes || "-"
              ) : (
                <ApInputTextarea
                  id="notes"
                  placeholder="Add any notes about the talent here. These are private and will not be shared with the talent"
                  maxChars={250}
                />
              )}
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
