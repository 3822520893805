import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toasts from "../../store/actions/toasts";
import { IState } from "../../store/reducers/index";
import "./Toaster.scss";

export default function Popup() {
  const dispatch = useDispatch();
  const popup = useSelector((state: IState) => state.toasts.popup);
  const [values, setValues] = useState({});
  const [isValid, setIsValid] = useState(true);

  const buttons = (popup && popup.buttons) ?? [
    {
      text: "Got It",
    },
  ];

  useEffect(() => {
    const closePopup = ({ key }: { key: string }) => {
      if (key === "Escape") {
        document.removeEventListener("keyup", closePopup, false);
        onClose();
      }
    };

    if (popup) {
      document.addEventListener("keyup", closePopup, false);
    } else {
      document.removeEventListener("keyup", closePopup, false);
    }
  }, [popup]);

  const onClose = () => {
    dispatch(toasts.setPopup());
    setValues({});
    setIsValid(true);
  };

  return popup ? (
    <div className="popup-container">
      <div className="container">
        <div className="popup">
          <div className="content">{popup.content}</div>
          {popup.form && React.createElement(popup.form, { values, setValues, isValid, setIsValid })}
          <footer>
            {buttons.length > 0 && (
              <div className="buttons">
                {buttons.map(({ text, callback, active }, key: number) => (
                  <button
                    key={key}
                    onClick={() => {
                      onClose();
                      callback && callback(values);
                    }}
                    className={classNames({
                      two: popup.buttons ? popup.buttons.length > 1 : false,
                      "p-disabled": !isValid,
                      active: active,
                    })}
                  >
                    {text}
                  </button>
                ))}
              </div>
            )}
          </footer>
        </div>
      </div>
    </div>
  ) : null;
}
