import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { IState } from "../store/reducers/index";
import { InvoicesService } from "~/API/InvoicesService";
import { usePermissionContext } from "~/contexts/PermissionContext";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { IInvoice, IInvoiceDetails } from "~/interfaces/invoices";
import { get } from "lodash";

export interface IInvoices {
  invoices: null | any;
  invoiceDetails: null | any;
  getInvoices: (params: { [key: string]: any }) => void;
  getNewInvoices: (params: { [key: string]: any }) => void;
  getInvoiceDetails: (id: string) => void;
  resetInvoices: () => void;
  resetInvoiceDetails: () => void;
  putInvoice: (invoice: any) => Promise<number>;
  isFetching: boolean;
  total: number;
  newInvoicesTotal: number;
  disputedInvoicesTotal: number;
  pendingInvoicesTotal: number;
  approveInvoice: (invoiceId: number, poNumber?: string) => void;
  rejectInvoice: (invoiceId: number, reason: string) => void;
  uploadAttachments: (invoiceId: number, failes: File[]) => void;
  getInvoicePDF: (invoiceId: number) => void;
  fetchedInvoiceId: number | undefined;
  search: string;
  setSearch: (search: string) => void;
}

const useInvoices = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const [isFetching, setIsFetching] = useState(false);
  const [invoices, setInvoices] = useState<null | IInvoice[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [newInvoicesTotal, setNewInvoicesTotal] = useState<number | undefined>(0);
  const [disputedInvoicesTotal, setDisputedInvoicesTotal] = useState<number | undefined>(0);
  const [pendingInvoicesTotal, setPendingInvoicesTotal] = useState<number | undefined>(0);
  const [fetchedInvoiceId, setFetchedInvoiceId] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<string>("");

  const refDisputed = useRef<number | undefined>(0);

  const initialDetails = {
    currency_code: 0,
    subtotal: 0,
    total_tax: 0,
    total: 0,
    online_url: "",
    line_amount_types: "",
    line_items: [],
  };
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceDetails>(initialDetails);
  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = checkIsAdmin();
  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };

  useEffect(() => {
    if (authenticatedUser) {
      setPendingInvoicesTotal(authenticatedUser.pending_invoices + authenticatedUser.disputed_invoices);
      setNewInvoicesTotal(authenticatedUser?.pending_invoices);
      setDisputedInvoicesTotal(authenticatedUser?.disputed_invoices);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    refDisputed.current = disputedInvoicesTotal;
  }, [disputedInvoicesTotal]);

  const getInvoices = async (params: { [key: string]: any }) => {
    try {
      createController();
      setIsFetching(true);
      const { status, data } = await InvoicesService.getInvoices(params, controller);
      status === 200 && setInvoices(data);
      setIsFetching(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        setIsFetching(false);
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    }
  };

  const getNewInvoices = async (params: { [key: string]: any }) => {
    try {
      createController();
      setIsFetching(true);
      const res = await InvoicesService.getNewInvoices(params, isAdmin, controller);
      const { data, total } = res.data;

      if (res.status === 200) {
        setInvoices(data);
        setTotal(total);
        if (params.talent === "" && params.status === "SUBMITTED") {
          setNewInvoicesTotal(total);
          setPendingInvoicesTotal(total + refDisputed.current);
        }
        setIsFetching(false);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        setIsFetching(false);
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    }
  };

  const getInvoiceDetails = async (invoiceId: string) => {
    try {
      setIsFetching(true);
      const { status, data } = await InvoicesService.getInvoiceDetails(invoiceId);

      status === 200 && setInvoiceDetails(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetching(false);
    }
  };
  const approveInvoice = async (invoiceId: number, poNumber?: string) => {
    try {
      setIsFetching(true);
      const payload = {
        status: "AUTHORISED",
        ...(poNumber ? { po_number: poNumber } : {}),
      };
      const { status } = await InvoicesService.changeInvoiceStatus(invoiceId, payload);
      status === 200 &&
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      console.error(err);
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };
  const rejectInvoice = async (invoiceId: number, reason: string) => {
    try {
      setIsFetching(true);
      const payload = {
        status: "DISPUTED",
        dispute_description: reason,
      };

      const { status } = await InvoicesService.changeInvoiceStatus(invoiceId, payload);
      if (status === 200) {
        setDisputedInvoicesTotal((total) => total + 1);
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      console.error(err);
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const putInvoice = async (invoice: any): Promise<number> => {
    try {
      setIsFetching(true);
      const res = await InvoicesService.putInvoice(invoice);
      if (res.status === 200) {
        setInvoices((invoices || []).map((invoice) => (invoice.id === res.data.id ? res.data : invoice)));
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
      return res.status;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      if (axios.isAxiosError(err)) return err.response?.status as number;
    } finally {
      setIsFetching(false);
    }
    return 400;
  };

  const uploadAttachments = async (id: number, files: File[]) => {
    try {
      setIsFetching(true);
      return await Promise.allSettled(
        files.map(async (file: File) => {
          try {
            const { status, data } = await InvoicesService.uploadAttachment(id, file);

            status === 200 &&
              (setInvoices((invoices || []).map((invoice) => (invoice.id === data.id ? data : invoice))),
              dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }])));

            Promise.resolve();
          } catch (err) {
            const message = get(err, "response.data.message", false);
            dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: message || TOAST_ERROR_MESSAGE }]));
            Promise.reject(message);
          }
        })
      );
    } catch (err) {
      Promise.reject();
    } finally {
      setIsFetching(false);
    }
  };

  const deleteAttachment = async (invoiceId: number, fileId: number) => {
    try {
      setIsFetching(true);
      const { status, data } = await InvoicesService.deleteAttachment(invoiceId, fileId);

      status === 200 &&
        (setInvoices((invoices || []).map((invoice) => (invoice.id === data.id ? data : invoice))),
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }])));
      return status;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      if (axios.isAxiosError(err)) return err.response?.status as number;
    } finally {
      setIsFetching(false);
    }

    return 400;
  };

  const getInvoicePDF = async (invoiceId: number) => {
    try {
      setFetchedInvoiceId(invoiceId);
      const res = await InvoicesService.getInvoicePDF(invoiceId);
      res.status === 200 && window.open(window.URL.createObjectURL(res.data));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setFetchedInvoiceId(undefined);
    }
  };

  const resetInvoices = () => {
    setInvoices([]);
    controller && controller.abort();
  };
  const resetInvoiceDetails = () => setInvoiceDetails(initialDetails);

  return {
    isFetching,
    invoices,
    invoiceDetails,
    getInvoices,
    getNewInvoices,
    getInvoiceDetails,
    resetInvoices,
    resetInvoiceDetails,
    putInvoice,
    total,
    newInvoicesTotal,
    pendingInvoicesTotal,
    approveInvoice,
    rejectInvoice,
    disputedInvoicesTotal,
    uploadAttachments,
    deleteAttachment,
    getInvoicePDF,
    fetchedInvoiceId,
    search,
    setSearch,
  } as IInvoices;
};
export default useInvoices;
