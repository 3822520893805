export interface IUserPopup {
  id: number;
  name: string;
  variant: "TALENT" | "CLIENT" | "COMMON";
  header: string;
  body: string;
  primary_button_text: string;
  primary_button_action: string;
  secondary_button_text?: string;
  secondary_button_action?: string;
  location: string;
  is_available: boolean;
}

export interface IUserPopupList {
  count: number;
  current_page: number;
  per_page: number;
  total: number;
  data: IUserPopup[];
}

export const POPUP_VARIANTS = ["TALENT", "CLIENT", "COMMON"];
