@import "../../../mixins.scss";

.categorySelector.p-dropdown {
  width: 155px;
  margin: 0 auto 2em;
  text-transform: uppercase;
  line-height: 38px;
  padding-left: 13px;
  border-radius: 5px;
  color: #848484;

  &.optionWidth {
    width: 300px;
  }
}

.briefTabsTools {
  display: flex;
  justify-content: space-between;
  .p-multiselect-label-container {
    height: 51px;
    display: flex;
    align-items: center;
  }
}

.brief-actions {
  text-align: right;
}

@include for-size(phone-only) {
  .filteringSelector.p-dropdown {
    width: 160px;
  }
  .p-dropdown-label.p-inputtext {
    padding: 10px 10px;
  }
}
