import React from "react";
import { FormikContextType } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import "../../CreateBriefForm/CreateBriefForm.scss";

interface IDescription {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}

const placeholder = "Add any other relevant information you want to share with talent here about the position.";

const multilinePlaceholderSafari = <div>{placeholder}</div>;

const WorkDescriptionPopupContent = <span>{placeholder}</span>;

export default function WorkDescription(props: IDescription) {
  const { formik, isViewOnly = false } = props;

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon popup={{ content: WorkDescriptionPopupContent, buttons: [{ text: "Got It 🦾" }] }} />
    </>
  );

  return (
    <div className="p-fluid field work-description">
      <label htmlFor="work_description">
        Additional information
        <InfoPopup />
      </label>
      <ApInputTextarea
        formik={formik}
        id="work_description"
        noField
        readOnly={isViewOnly}
        placeholder={placeholder}
        maxChars={1200}
        multilinePlaceholderSafari={multilinePlaceholderSafari}
      />
    </div>
  );
}
