import React, { useMemo } from "react";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import ApInputTextarea from "../../components/common/ApInputTextarea/ApInputTextarea";
import { genieDetailsMaxInputLength } from "~/schemas/common";
import ImageCropper from "../ImageCropper/ImageCropper";
import PopupIcon from "../../components/Toaster/PopupIcon";
import RateRadioGroup from "~/forms/ClientGenieDetailsForm/components/RateRadioGroup";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import "./ClientGenieDetailsForm.scss";
import CompanyBenefits from "../BriefViewDetailsForm/BriefFields/CompanyBenefits";
import { useFormikContext } from "formik";
import ApMultiCheckbox from "~/components/common/ApMultiCheckbox/ApMultiCheckbox";
import { useGlobalContext } from "~/contexts/GlobalContext";

const exampleTitle = "Here's an Example:";
const exampleBody =
  "At Genie we are stubbornly driven by a keystone mantra of talent first and have distilled that magic into the tech bottle that is Genie. Our ambition is to unashamedly be the ‘talent operating system of choice’ for the new creative economy.";

const companyVisionPopupContent = (
  <span>
    {exampleTitle}
    <br />
    <br />
    <em>{exampleBody}</em>
  </span>
);

const placeholder = `What are your organizations’ dreams and ambitions for the next 5-10 years. How do you plan to reach these new heights?

${exampleTitle}

${exampleBody}`;

export default function ClientGenieDetailsForm() {
  const { userAccess } = usePermissionContext();
  const formik = useFormikContext();
  const isAdmin = useMemo(() => userAccess(permissionType.accessAdmin), []);
  const {
    global: { globalOptions },
  } = useGlobalContext();

  const disciplines = useMemo(
    () =>
      globalOptions?.disciplines.map(({ name: label, id }: { name: string; id: number }) => ({
        id,
        name: label,
      })),
    [globalOptions?.disciplines]
  );

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon popup={{ content: companyVisionPopupContent, buttons: [{ text: "Got It 🦾" }] }} />
    </>
  );

  return (
    <div className="ClientGenieDetailsForm">
      <div className="p-fluid field">
        <ImageCropper />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="headline">Headline</label>
        <ApInputText id="headline" maxChars={genieDetailsMaxInputLength.headline} />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="subhead">Subhead</label>
        <ApInputText id="subhead" maxChars={genieDetailsMaxInputLength.subhead} />
      </div>
      <div className="p-fluid field">
        <label htmlFor="vision_statement">
          Company Vision Statement <InfoPopup />
        </label>
        <ApInputTextarea
          id="vision_statement"
          maxChars={genieDetailsMaxInputLength.vision_statement}
          style={{ minHeight: "140px" }}
          placeholder={placeholder}
        />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="description">
          {"Description "}
          <PopupIcon
            content={
              <>
                Talent see this if they click 'About the company' -<br />
                please include a link to your website.
              </>
            }
          />
        </label>
        <ApInputTextarea id="description" maxChars={genieDetailsMaxInputLength.description} />
      </div>
      {isAdmin && (
        <div className="p-fluid field">
          <ApInputTextarea id="genie_take" label="Genie's Opinion" maxChars={genieDetailsMaxInputLength.genie_take} />
        </div>
      )}
      <CompanyBenefits formik={formik} isCreationBriefProcess />
      <div className="p-fluid field">
        <RateRadioGroup isAdmin={isAdmin} />
      </div>
      {isAdmin && (
        <div className="p-fluid field field-required disciplines">
          <ApMultiCheckbox
            id="disciplines"
            options={disciplines}
            formik={formik}
            label="Configure Client Disciplines"
          />
        </div>
      )}
    </div>
  );
}
