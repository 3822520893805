import { FormikProps, FormikValues, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { IState } from "~/store/reducers/index";
import * as permissionType from "~/constants";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import actions from "~/store/actions";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { Link } from "react-router-dom";

interface Props {
    isViewOnly?: boolean;
    formik?: FormikProps<FormikValues>;
}
const popupContent = "To exclude any specific previously booked or VIP talent from being matched to this brief please select them from the list below.";

export default function ExcludedTalents(props: Props) {
    const dispatch = useDispatch();
    const formik = props.formik || useFormikContext();
    const isViewOnly = props?.isViewOnly || false;
    const { userAccess } = usePermissionContext();
    const isAdmin = useMemo(() => userAccess(permissionType.accessAdmin), []);
    const isClientAdmin = useMemo(() => userAccess(permissionType.accessClientAdmin), []);
    const talentsRebook = useSelector((state: IState) => state.talent?.rebook);

    useEffect(() => {
        formik.values.author_id && dispatch(actions.talent.getTalentRebook(formik.values.author_id, isAdmin, isClientAdmin));
    }, [formik.values.author_id]);

    const InfoPopup = () => (
        <>
            {" "}
            <PopupIcon popup={{ content: popupContent, buttons: [{ text: "Got It 🦾" }] }} />
        </>
    );

    if (isViewOnly && !formik.values.excluded_talents.length) {
        return null;
    }
    return (
        <>
            {isViewOnly ? (
                <div className="p-fluid field">
                    <label>Excluded Specific Talent from Matches:</label>
                    <div className="p-inputtext p-component">
                        {formik.values.excluded_talents.map(({ name, id }: { name: string, id: number }, i: number, arr: []) => (
                            <span key={id} className="mr-2">
                                <Link to={`/talents/${id}`}>{name}</Link>{arr.length - 1 === i ? '' : ','}
                            </span>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="p-fluid field">
                    <label htmlFor="excluded_talents">Exclude Specific Talent from Matches <InfoPopup /></label>
                    <ApMultiselect
                        id="excluded_talents"
                        disabled={!talentsRebook}
                        options={talentsRebook}
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Select Talent"
                        selectedItemsLabel="{0} talent selected"
                        filter
                        formik={formik}
                        noField
                    />
                </div >
            )}
        </>

    )
}