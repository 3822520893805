import * as Yup from "yup";
import { genieDetailsMaxInputLength } from "./common";
import { UserManagementSchema } from "./user";
import { requiredFieldMessage } from "../utils";

const clientCompanyDetails = {
  legal_name: Yup.string().max(50, "Please, make it shorter than 50 symbols"),
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  country: Yup.string().required("Please, choose the country from dropdown list"),
  city: Yup.string().min(2, "Please, add at least 2 symbols").max(50, "Please, make it shorter than 50 symbols"),
  postcode: Yup.string().min(3, "Please, add at least 3 symbols").max(20, "Please, make it shorter than 20 symbols"),
  notes: Yup.string().max(400, "Please, make it shorter than 400 symbols"),
  xero_contact_id: Yup.string().max(256, "Must be at most 256 symbols"),
};

const {
  description: descriptionMax,
  headline: headlineMax,
  subhead: subheadMax,
  vision_statement: visionStatementMax,
  genie_take: genieTakeMax,
} = genieDetailsMaxInputLength;

const clientGenieDetails = {
  description: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(descriptionMax, `Please, make it shorter than ${descriptionMax} symbols`)
    .required(requiredFieldMessage),
  headline: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(headlineMax, `Please, make it shorter than ${headlineMax} symbols`)
    .required(requiredFieldMessage),
  subhead: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(subheadMax, `Please, make it shorter than ${subheadMax} symbols`)
    .required(requiredFieldMessage),
  vision_statement: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(visionStatementMax, `Please, make it shorter than ${visionStatementMax} symbols`),
  // profile_image: imageSchema,
  genie_take: Yup.string().max(genieTakeMax, `Please, make it shorter than ${genieTakeMax} symbols`),
  disciplines: Yup.array().min(1, "Please, select at least 1 option"),
};

const clientComplience = {
  umbrella_only: Yup.boolean(),
  umbrella_companies: Yup.array().when("umbrella_only", {
    is: true,
    then: (schema) => schema.min(1).of(Yup.mixed()).required(requiredFieldMessage),
  }),
  ir35_compliant: Yup.boolean(),
  payment_company_name: Yup.string().when("ir35_compliant", {
    is: true,
    then: (schema) => schema.required(requiredFieldMessage),
  }),
};

const clientInvoices = {
  custom_attendees: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email(({ value }) => `${value}: is not a valid email;`),
    })
  ),
};

const clientUsers = {
  users: Yup.array()
    .of(UserManagementSchema)
    .min(1, "At least one user should be assigned")
    .required(requiredFieldMessage),
};

export const ClientSchema = Yup.object().shape({
  ...clientCompanyDetails,
  ...clientGenieDetails,
  ...clientUsers,
});

export const clientCompanyDetailsSchema = Yup.object().shape(clientCompanyDetails);
export const clientGenieDetailsSchema = Yup.object().shape(clientGenieDetails);
export const clientUsersSchema = Yup.object().shape(clientUsers);
export const clientComplienceSchema = Yup.object().shape(clientComplience);
export const clientInvoicesSchema = Yup.object().shape(clientInvoices);
