// Admin
export const accessAdmin = "accessAdmin";
export const getAnyRole = "getAnyRole";
export const postAnyRole = "postAnyRole";
export const editAnyRole = "editAnyRole";
export const getAnyConfig = "getAnyConfig";
export const postAnyConfig = "postAnyConfig";
export const editAnyConfig = "editAnyConfig";
export const postAnySetting = "postAnySetting";
export const editAnySetting = "editAnySetting";
export const getAnyUser = "getAnyUser";
export const postAnyUser = "postAnyUser";
export const editAnyUser = "editAnyUser";
export const getClientAdminList = "getClientAdminList";
export const getClientList = "getClientList";
export const getAnyClient = "getAnyClient";
export const postAnyClient = "postAnyClient";
export const editAnyClient = "editAnyClient";
export const getTalentList = "getTalentList";
export const getAnyTalent = "getAnyTalent";
export const postAnyTalent = "postAnyTalent";
export const editAnyTalent = "editAnyTalent";
export const getBriefList = "getBriefList";
export const getAnyBrief = "getAnyBrief";
export const postAnyBrief = "postAnyBrief";
export const editAnyBrief = "editAnyBrief";
export const getAnyMatch = "getAnyMatch";
export const postAnyMatch = "postAnyMatch";
export const editAnyMatch = "editAnyMatch";
export const getAnyBooking = "getAnyBooking";
export const postAnyBooking = "postAnyBooking";
export const editAnyBooking = "editAnyBooking";
export const getAnyGoto = "getAnyGoto";
export const getAnyDashboard = "getAnyDashboard";
export const postAnyMessage = "postAnyMessage";
export const getAnyNotification = "getAnyNotification";
export const postAnyNotification = "postAnyNotification";
export const editAnyNotification = "editAnyNotification";
export const postAnySkill = "postAnySkill";
export const editAnySkill = "editAnySkill";
export const getAnyReferralCode = "getAnyReferralCode";
export const postAnyReferralCode = "postAnyReferralCode";
export const editAnyReferralCode = "editAnyReferralCode";
export const getAnyClientPaymentProfile = "getAnyClientPaymentProfile";
export const editAnyClientPaymentProfile = "editAnyClientPaymentProfile";

// clientAdmin
export const accessClientAdmin = "accessClientAdmin";
export const getCurrentUser = "getCurrentUser";
export const getOwnClientUser = "getOwnClientUser";
export const postOwnClientUser = "postOwnClientUser";
export const postOwnClientBooking = "postOwnClientBooking";
export const editCurrentUser = "editCurrentUser";
export const getOwnClient = "getOwnClient";
export const getOwnClientInfo = "getOwnClientInfo";
export const editOwnClient = "editOwnClient";
export const getOwnClientBrief = "getOwnClientBrief";
export const postOwnClientBrief = "postOwnClientBrief";
export const editOwnClientBrief = "editOwnClientBrief";
export const getOwnClientMatch = "getOwnClientMatch";
export const editOwnClientMatch = "editOwnClientMatch";
export const postOwnClientNotification = "postOwnClientNotification";
export const getOwnClientTalents = "getOwnClientTalents";
export const editOwnClientGotoTalents = "editOwnClientGotoTalents";
export const getClientAdminDashboard = "getClientAdminDashboard";
export const getOwnClientBooking = "getOwnClientBooking";
export const postDirectTalentMatch = "postDirectTalentMatch";
export const editOwnClientNotes = "editOwnClientNotes";
export const editOwnClientUser = "editOwnClientUser";
export const getOwnRoles = "getOwnRoles";
export const triggerPortfolioOpenedEvent = "triggerPortfolioOpenedEvent";
export const getOwnClientPaymentProfile = "getOwnClientPaymentProfile";
export const editOwnClientPaymentProfile = "editOwnClientPaymentProfile";

export const editOwnUserMatch = "editOwnUserMatch";
export const getOwnClientPaymentData = "getOwnClientPaymentData";

// Invoices
export const getOwnInvoice = "getOwnInvoice";
