@import "../../../mixins.scss";

.async-select {
  > div {
    font-size: 14px;
    line-height: 19px;
    background: #f1f5f5;
    border-radius: 5px;
    border: 0;

    > div:first-child {
      padding: 11px 20px;
    }
  }

  svg {
    fill: $brandColor;
  }

  &.disabled svg {
    opacity: 0.5;
  }
}
