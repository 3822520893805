import React from "react";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import { FormikContextType } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { useGlobalContext } from "~/contexts/GlobalContext";

interface ILevels {
  formik: FormikContextType<any>;
  disciplines: boolean;
  isViewOnly?: boolean;
  canShowInfoPopup?: boolean;
}
export default function ProductionSize(props: ILevels) {
  const { formik, disciplines, isViewOnly = false, canShowInfoPopup } = props;
  const {
    global: { globalOptions },
  } = useGlobalContext();
  const productionSizes = globalOptions?.production_sizes;
  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon content="This is just to give a freelancer an indication of the opportunity and workload. It’s not something I use for my matching." />{" "}
    </>
  );

  return (
    <div className="p-fluid field">
      <label htmlFor="production_id">
        Production size
        {canShowInfoPopup && <InfoPopup />}
      </label>
      <ApDropdown
        disabled={!disciplines || isViewOnly}
        formik={formik}
        id="production_id"
        noField
        options={productionSizes}
        optionLabel="name"
        optionValue="id"
        placeholder="Select production size"
      />
    </div>
  );
}
