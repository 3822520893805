import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Formik, Form, FormikProps } from "formik";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import { ReferenceSchema } from "~/schemas/talent";
import isUndefined from "lodash/isUndefined";

interface IReferenceModal {
  form: { full_name: string; email: string };
  index: number;
  onSubmit: ({ full_name, email }: { full_name: string; email: string }) => void;
  onUpdate: (index: number, field: string, value: any) => void;
  onDelete: (index: number, field: string) => void;
  onClose: () => void;
}

export default function ReferenceModal(props: IReferenceModal) {
  const { form, index, onSubmit, onUpdate, onDelete, onClose } = props;

  function getModalButtons(formik: FormikProps<{ full_name: string; email: string }>) {
    let modalButtons = [
      {
        disabled: !formik.dirty || !formik.isValid,
        onClick: () => {
          formik.validateForm().then((res) => {
            formik.handleSubmit();
            if (!Object.keys(res).length) {
              if (isUndefined(index) && onUpdate) {
                onUpdate(index, "work_profile.references", formik.values);
              } else {
                onSubmit(formik.values);
              }
            }
          });
        },
        text: `${form ? "Edit" : "Add"} Reference`,
      },
    ];
    if (form) {
      modalButtons.unshift({
        disabled: false,
        onClick: () => {
          if (!isUndefined(index) && onDelete) {
            onDelete(index, "work_profile.references");
          }
        },
        text: "Delete",
      });
    }
    return modalButtons;
  }

  return (
    <Formik
      initialValues={{
        full_name: form?.full_name || "",
        email: form?.email || "",
      }}
      validationSchema={ReferenceSchema}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Form>
          <Dialog
            header={`${form ? "Edit" : "Add"} Reference`}
            footer={
              <footer>
                {[...getModalButtons(formik)].map((button: any, i: number) => (
                  <Button disabled={!!button.disabled} type="button" key={i} onClick={button.onClick}>
                    {button.text}
                  </Button>
                ))}
              </footer>
            }
            visible={true}
            modal={true}
            onHide={onClose}
          >
            <div className="p-fluid field field-required">
              <ApInputText id="full_name" label="Full name" placeholder="Full Name" />
            </div>
            <div className="p-fluid field field-required">
              <ApInputText id="email" label="Email" placeholder="Email" />
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
