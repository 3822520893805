@import '../../../mixins.scss';

.clipboardText {
    cursor: pointer;
    .in-clipboard {
        animation: fadeIn 5s;
        color: $brandColor;
        margin-left: 15px;
        position: absolute;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
