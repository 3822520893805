import React, { useEffect, useMemo, useState } from "react";
import { FormikContextType } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import { BriefService } from "~/API/BriefService";
import { useGlobalContext } from "~/contexts/GlobalContext";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { budgetOptions } from "../constants";

interface IBudget {
  formik: FormikContextType<any>;
  levels: any;
  selectedSpecialism: any;
  selectedDiscipline: any;
}

export default function Budget(props: IBudget) {
  const { formik, levels, selectedSpecialism, selectedDiscipline } = props;
  const [averageSalary, setAverageSalary] = useState(0 as number);
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = useMemo(checkIsAdmin, []);
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;

  useEffect(() => {
    setAverageSalary(0);
    if (
      formik.values.contract_id === CONTRACT_TYPES?.FREELANCE &&
      selectedDiscipline &&
      selectedSpecialism &&
      formik.values.level_id
    ) {
      (async () => {
        const payload = {
          discipline_code: selectedDiscipline.code,
          specialism_code: selectedSpecialism.code,
          level_code: levels?.find((el: any) => el.id === formik.values.level_id).code,
        };
        const { data, status } = await BriefService.getIndicativeSalary(isAdmin, payload);
        status === 200 && setAverageSalary(data);
      })();
    }
  }, [formik.values.discipline_id, formik.values.specialism_id, formik.values.level_id]);

  const PopupContent = () => (
    <>
      <strong style={{ color: "#d4272b" }}>WARNING</strong>
      <br />
      <br />
      Lowballing market rates vs level will negatively impact your chances of attracting the superstars you deserve.
      <br />
      <br />
      If you have a ballpark budget, put in a mid of range figure and I will take that into account for matching, while
      also considering the superstars a little over (or under) your budget.
      <br />
      <br />
      If you’re lucky enough not to have a budget, leave blank and I’ll simply use your chosen level as an indicator 😎
    </>
  );

  const AverageSalary = () => (
    <p className="average-salary">
      <b>
        Based on your selections so far the average day rate charged by Genie talent is apx £{averageSalary} per day.
      </b>
    </p>
  );
  return (
    <div className="block-fields block-budget">
      <div className="p-fluid field">
        <label htmlFor="budget">
          Do you have a ballpark budget you’d like me to work towards?
          {formik.values.contract_id === CONTRACT_TYPES?.FREELANCE
            ? " (as a day rate pls) "
            : " (You can choose to enter a pro rata yearly salary amount or a total for the entire brief duration) "}
          {formik.values.contract_id === CONTRACT_TYPES?.FREELANCE && <PopupIcon content={<PopupContent />} />}
          {averageSalary > 0 && <AverageSalary />}
        </label>
        <ApInputText formik={formik} id="budget" noField keyfilter={/[0-9]+/} maxLength={10} price />
      </div>
      {formik.values.contract_id === CONTRACT_TYPES?.FIXEDTERM && !!formik.values.budget && (
        <div className="p-fluid field col-4">
          <label htmlFor="budget_type">Ballpark budget type</label>
          <ApRadioGroup formik={formik} name="budget_type" noField options={budgetOptions} />
        </div>
      )}
    </div>
  );
}
