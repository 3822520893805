import cn from "classnames";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientUsersService } from "~/API/ClientUsersService";
import Loader from "~/components/Loader/Loader";
import * as permissionType from "~/constants";
import { usePermissionContext } from "~/contexts/PermissionContext";
import useClientUsers from "~/hooks/useClientUsers";
import Toasts, { TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { IState } from "~/store/reducers/index";
import ApSendMessage from "~/components/common/ApMessage/ApSendMessage";
import actions from "~/store/actions";
import { IClientUser } from "~/store/constants/client";
import { IUserForm } from "~/store/constants/user";
import "./UsersForm.scss";

interface Props {
  users: IClientUser[] | IUserForm[];
  selected?: number;
  onSelect?: () => void;
  type?: "client" | "talent";
  isPublished: boolean;
  updateUserList?: () => void;
  changeTab?: (activeTab: number) => void;
  isNewUser?: boolean;
}

interface IUser extends IClientUser {
  isFetching: boolean;
}

export default function UsersForm(props: Props) {
  const { isPublished, updateUserList, isNewUser, changeTab } = props;
  const { detachClientUserRole, attachClientUserRole } = useClientUsers();

  const [users, setUsers] = useState<IUser[]>([]);
  useEffect(() => {
    !!props.users.length &&
      setUsers(
        [...props?.users].map((item: IClientUser) => {
          return { ...item, isFetching: false };
        })
      );
  }, [props.users]);
  const dispatch = useDispatch();
  const { userAccess } = usePermissionContext();
  const { authenticatedUser } = useSelector((state: IState) => state.user);

  const openRoleDialog = async (user: IClientUser | IUserForm) => {
    dispatch(
      actions.modal.openModal("CHANGE_ROLE_MODAL", {
        onAttachRole: async (roleId: string) => {
          await attachClientUserRole(user.id, roleId);
          updateUserList && (await updateUserList());
        },
        onDetachRole: async (roleId: string) => {
          await detachClientUserRole(user.id, roleId);
          updateUserList && (await updateUserList());
        },
        user,
      })
    );
  };
  const setIsFetching = (curentUser: IUser) => {
    setUsers((users: any) => {
      return users.map((user) => {
        return {
          ...user,
          isFetching: user.id === curentUser.id,
        };
      });
    });
  };
  const canChangeTab = (user: IUser) => {
    if (user.published) {
      const isLastActiveUser = users.filter((user) => user.published).length === 1;
      isLastActiveUser && changeTab && changeTab(1);
    } else {
      const isLastInActiveUser = users.filter((user) => !user.published).length === 1;
      isLastInActiveUser && changeTab && changeTab(0);
    }
  };
  const togglePublish = async (e: React.MouseEvent, user: IUser) => {
    e.stopPropagation();
    setIsFetching(user);
    const { status, data } = await ClientUsersService.updateUserStatus(
      userAccess(permissionType.accessAdmin),
      user.id,
      !user.published
    );
    !isNewUser && canChangeTab(user);

    status === 200 && updateUserList && (await updateUserList());
    dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
  };
  const renderUsers = (user: IClientUser | IUserForm, i: any) => {
    const userClassName = cn("UsersListForm__user", {
      active: props.selected === user.id,
      ["talent-list"]: props.type === "talent",
      ["client-list"]: props.type === "client",
    });
    return (
      <div
        className={userClassName}
        key={i}
        onClick={() => props.onSelect && props.onSelect(props.selected === user.id ? 0 : user.id)}
      >
        {user.id && userAccess(permissionType.accessAdmin) && (
          <ApSendMessage
            opts={{
              user_id: user.id as number,
              ...(user.client_id && { client_id: user.client_id as number }),
              ...(user.talent_id && { talent_id: user.talent_id as number }),
            }}
          />
        )}
        {props.type === "client" && (
          <div className="client-admin-switch">
            <label className="label">
              {user?.roles?.find((role) => /^client_/.test(role.name))?.display_name || "Client"}
            </label>
            {!props?.isNewUser && userAccess(permissionType.editOwnClientUser, permissionType.editAnyUser) && (
              <Button
                disabled={authenticatedUser?.id === user.id}
                label="Change role"
                onClick={() => openRoleDialog(user)}
                type="button"
              />
            )}
          </div>
        )}
        {userAccess(permissionType.editAnyUser, permissionType.editOwnClientUser) && !props?.isNewUser && (
          <Button
            onClick={(e) => togglePublish(e, user)}
            type="button"
            className="toggle-publish-button"
            disabled={authenticatedUser?.id === user.id}
          >
            {user && user?.isFetching ? (
              <Loader className="centered" />
            ) : user.published ? (
              "Deactivate user"
            ) : (
              "Reactivate user"
            )}
          </Button>
        )}
        <h3>{user.full_name || `${(user as IUserForm).first_name?.trim()} ${user.last_name?.trim()}`}</h3>
        <div>
          <i className="pi pi-mobile"></i>
          <span>{user.telephone}</span>
        </div>
        <div>
          <i className="pi pi-envelope"></i>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </div>
        {!!(user?.disciplines || []).length && (
          <div>
            <i className="pi pi-sitemap"></i>
            <span>{user.disciplines.map(({ name }: { name: string }) => name).join(", ")}</span>
          </div>
        )}
        {user?.client_personal_profile?.role && (
          <div>
            <i className="pi pi-briefcase"></i>
            <span>{user.client_personal_profile.role}</span>
          </div>
        )}
      </div>
    );
  };

  return !!users.length && isNewUser
    ? users.map(renderUsers)
    : users.filter((user: IClientUser | IUserForm) => user.published === isPublished).map(renderUsers);
}
