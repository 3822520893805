import { Message } from "primereact/messages";
import { Popup } from "../reducers/toasts";

// ------------------------------------
// Constants
// ------------------------------------
export const SET_ALERTS = "SET_ALERTS";
export const SET_TOASTS = "SET_TOASTS";
export const SET_POPUP = "SET_POPUP";

export const TOAST_SUCCESS_MESSAGE = 'That’s all updated for you now 👌🏾';
export const TOAST_ERROR_MESSAGE = 'It\'s not you, it\'s me..... can you try that again pls?';

export const TOAST_IMAGE_SUCCESS = 'Image updated for you now 👌🏾';


export type MessageWithContent = Message & {content: JSX.Element};
// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  setAlerts: (alerts: MessageWithContent | MessageWithContent[]) => ({
    type: SET_ALERTS,
    payload: alerts,
  }),
  setToasts: (toasts: Message | Message[]) => ({
    type: SET_TOASTS,
    payload: toasts,
  }),
  setPopup: (popup?: Popup) => ({
    type: SET_POPUP,
    payload: popup,
  }),
};
