import Axios from "axios";
import { API_URL } from "~/config";
import { IInterview } from "~/interfaces/Interview";

export class InterviewService {
  static async getInterviews(matchId: number, isAdmin: boolean) {
    return await Axios.get(`${API_URL}${isAdmin ? "" : "/client_admin"}/match/${matchId}/interviews`);
  }
  static async updateInterview(interviewId: number, data: IInterview, isAdmin: boolean) {
    return await Axios.put(`${API_URL}${isAdmin ? "" : "/client_admin"}/interview/${interviewId}`, data);
  }
  static async addInterview(matchId: number, data: IInterview, isAdmin: boolean) {
    return await Axios.post(`${API_URL}${isAdmin ? "" : "/client_admin"}/match/${matchId}/interview`, data);
  }
  static async sendInterviewNotification(interviewId: number, isAdmin: boolean) {
    return await Axios.post(`${API_URL}${isAdmin ? "" : "/client_admin"}/interview/${interviewId}/send`);
  }
  static async addInterviewAttachment(interviewId: number, isAdmin: boolean, attachment: File) {
    const data = new FormData();
    data.append("attachment", attachment);
    return await Axios.post(`${API_URL}${isAdmin ? "" : "/client_admin"}/interview/${interviewId}/attachment`, data);
  }
  static async deleteInterviewAttachment(interviewId: number, isAdmin: boolean, attachmentId: number) {
    return await Axios.delete(
      `${API_URL}${isAdmin ? "" : "/client_admin"}/interview/${interviewId}/attachment/${attachmentId}`
    );
  }
}
