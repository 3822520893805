import Axios from "axios";
import { push } from "react-router-redux";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import Client, { IClient } from "../constants/client";
import { IState } from "../reducers/index";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_IMAGE_SUCCESS, TOAST_SUCCESS_MESSAGE } from "../constants/toasts";
import User from "../actions/user";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  createClient: (client: IClient) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Client.createClientRequest());
          const res = await Axios.post(`${API_URL}/client`, client);
          dispatch(Client.createClientSuccess(res));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          dispatch(push(`/clients/${res.data.id}`));
          resolve(res);
        } catch (err) {
          dispatch(Client.createClientFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
  getClient: (clientId: number, isAdmin: boolean) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Client.getClientRequest());
          const client = isAdmin ? clientId : "";
          const res = await Axios.get(`${API_URL}/${isAdmin ? "client" : "client_admin"}/${client}`);
          dispatch(Client.getClientSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(Client.getClientFailure(err));
          reject(err);
        }
      }
    });
  },
  listClients: () => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Client.listClientsRequest());
          const res = await Axios.get(`${API_URL}/clients`);
          dispatch(Client.listClientsSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(Client.listClientsFailure(err));
          reject(err);
        }
      }
    });
  },
  getClientIR35: (userId: number) => (dispatch: Dispatch, getState: () => any) => {
    dispatch(Client.getClientIR35Request());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      try {
        const res = await Axios.get(`${API_URL}/user/${userId}/client_payment_profile`);
        dispatch(Client.getClientIR35Success(res));
        resolve(res);
      } catch (err) {
        dispatch(Client.getClientIR35Failure(err));
        reject(err);
      }
    });
  },
  getClientPersonalProfile: (userId: number) => (dispatch: Dispatch, getState: () => any) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      try {
        const res = await Axios.get(`${API_URL}/user/${userId}/client_personal_profile`);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    });
  },
  updateClientPersonalProfile: (userId: number, data: object) => (dispatch: Dispatch, getState: () => any) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      try {
        const res = await Axios.put(`${API_URL}/user/${userId}/client_personal_profile`, data);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    });
  },
  updateClientIR35: (userId: number, form: any) => (dispatch: Dispatch, getState: () => any) => {
    dispatch(Client.updateClientIR35Request());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      try {
        const res = await Axios.put(`${API_URL}/user/${userId}/client_payment_profile`, form);
        dispatch(Client.updateClientIR35Success(res));
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        // user/current is re-fetched to get updated exempt and umbrella_only properties
        dispatch(User.verifyToken());
        resolve(res);
      } catch (err) {
        dispatch(Client.updateClientIR35Failure(err));
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        reject(err);
      }
    });
  },
  updateClient:
    (client: IClient | { id: number; published: boolean }, isAdmin: boolean) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          try {
            dispatch(Client.updateClientRequest());
            const clientId = isAdmin ? client.id : "";
            const res = await Axios.put(`${API_URL}/${isAdmin ? "client" : "client_admin"}/${clientId}`, client);
            dispatch(Client.updateClientSuccess(res));
            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
            resolve(res);
          } catch (err) {
            dispatch(Client.updateClientFailure(err));
            dispatch(
              Toasts.setToasts([
                { severity: "error", summary: "", detail: err.response?.data?.message || TOAST_ERROR_MESSAGE },
              ])
            );
            reject(err);
          }
        }
      });
    },
  saveProfileImage: (id: string, blob: Blob, isAdmin: boolean) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        const data: FormData = new FormData();
        data.append("image_url", blob);
        data.append("_method", "PUT");
        dispatch(Client.saveClientFileRequest());
        const clientId = isAdmin ? id : "";
        Axios.post(`${API_URL}/${isAdmin ? "client" : "client_admin"}/${clientId}`, data)
          .then((res) => {
            dispatch(Client.saveClientFileSuccess(res));
            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_IMAGE_SUCCESS }]));
            resolve(res);
          })
          .catch((err) => {
            dispatch(Client.saveClientFileFailure(err));
            dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
            reject(err);
          });
      } else {
        reject();
      }
    });
  },
};
