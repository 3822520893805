import { LOCATION_CHANGE } from "../constants/location";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  locationChange(location = "/") {
    return {
      type: LOCATION_CHANGE,
      payload: location,
    };
  }
};
