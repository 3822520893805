import React, { useEffect, useMemo } from "react";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import ApInputTextarea from "../../components/common/ApInputTextarea/ApInputTextarea";
import ApDropdown from "../../components/common/ApDropdown/ApDropdown";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";

export default function CompanyDetailsForm() {
  const {
    global: { getCountries, countries: countryOptions },
  } = useGlobalContext();
  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = useMemo(checkIsAdmin, []);

  useEffect(() => {
    !countryOptions.length && getCountries();
  }, []);

  return (
    <div className="CompanyDetailsForm">
      <div className="p-fluid field">
        <label htmlFor="legal_name">Legal name</label>
        <ApInputText id="legal_name" />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="name">Trading name</label>
        <ApInputText id="name" />
      </div>
      <div className="p-fluid field">
        <label htmlFor="address_1">Address line 1</label>
        <ApInputText id="address_1" />
      </div>
      <div className="p-fluid field">
        <label htmlFor="address_2">Address line 2</label>
        <ApInputText id="address_2" />
      </div>
      <div className="p-fluid field">
        <label htmlFor="city">Town / City</label>
        <ApInputText id="city" />
      </div>
      {countryOptions.length > 0 && (
        <div className="p-fluid field field-required">
          <label htmlFor="country">Country</label>
          <ApDropdown id="country" options={countryOptions} placeholder="Select a country" filter />
        </div>
      )}
      <div className="p-fluid field">
        <label htmlFor="postcode">Postcode</label>
        <ApInputText id="postcode" />
      </div>
      {isAdmin && (
        <div className="p-fluid field">
          <label htmlFor="notes">Notes</label>
          <ApInputTextarea id="notes" />
        </div>
      )}
    </div>
  );
}
