@import "../../../mixins.scss";

.ApCard {
  cursor: pointer;

  &.p-component {
    font-family: $baseFont;
  }

  &.p-card {
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    max-width: 365px;
    height: 480px;

    svg {
      color: $brandColor;
      vertical-align: middle;
      margin-right: 3px;
    }

    .wrp-img {
      display: block;
      height: 180px;
      transition: height 300ms ease-out;
      overflow: hidden;
    }

    .p-card-header {
      position: relative;
    }

    .p-card-body {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #ffffff;
      font-family: $baseFont;
      padding: 0;

      .p-card-content {
        padding-bottom: 0;

        .p-card-title {
          font-size: 16px;
          color: #333333;
          text-decoration: none;
          position: relative;
          margin: 0;

          &:hover {
            text-decoration: underline;
          }

          @for $i from 1 through 4 {
            &.bc-#{$i} {
              padding-right: calc(40px * $i + 5px) !important;
            }
          }

          .brief-actions {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .p-card-text {
          padding: 0 20px;

          .col-12.talent-rate_hidden {
            opacity: 0;
          }

          .invalid-talent {
            color: $brandColor;
          }
        }

        .brief-actions {
          text-align: right;
        }

        .p-accordion {
          margin-bottom: 25px;

          .p-accordion-tab {
            a {
              color: $primaryColor;
              text-transform: uppercase;

              &:focus,
              &:active {
                outline: none;
                box-shadow: none;
              }
            }

            .p-accordion-header-link {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              text-transform: uppercase;
              border: none;
              border-bottom: 1px solid #f3f3f3;
              color: #d4272b;
              background: #fff;
              border-radius: 0;
              padding: 1rem 20px;
            }

            .p-accordion-toggle-icon {
              order: 1;
              margin-right: 0;
              font-size: 0.6rem;
            }

            .wrp-content {
              .textWrapper {
                max-height: 100px;
                overflow: auto;
                font-size: 0.8rem;
                > p {
                  margin-top: 0;
                }
              }
            }
          }

          .p-accordion-content {
            padding: 0 20px;
            border: none;
          }
        }

        .matching-status {
          padding: 6px 0;
          max-width: inherit;
          display: block;
        }

        label {
          font-weight: 700;
        }

        .card-actions {
          min-height: 51px;
          &__link {
            background-color: #fff;
          }
          a,
          button {
            border-radius: 5px;
            border: 1px solid #f1f5f5;
            padding: 5px 8px;
            margin-right: 7px;

            &:hover {
              border: 1px solid $brandColor;
            }
          }

          > div:last-child {
            text-align: right;

            button {
              margin-right: 0;
            }
          }
        }
      }
    }

    .talent-type {
      margin: 5px 0;

      svg {
        color: $brandColor;
        vertical-align: middle;
      }

      a {
        display: inline-block;
        color: $brandColor;
        font-weight: 700;
        padding-left: 10px;
      }
    }
  }
}
