import React, { createContext, useContext } from "react";
import useDashboard from "~/hooks/useDashboard";
import * as Dashboard from "../store/constants/dashboard";

export interface IDashboardState {
  isFetching: boolean;
  data: Dashboard.IDashboard | Dashboard.IClientDashboard | undefined;
  loadDashboard: () => void;
  loadClientDashboard: () => void;
  resetDashboard: () => void;
}

export const DashboardContext = createContext(null as { dashboard: IDashboardState } | null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error("useDashboardContext must be used within a DashboardContextProvider");
  }
  return context;
};

const DashboardContextProvider = ({ children }: any) => {
  return (
    <DashboardContext.Provider
      value={{
        dashboard: { ...useDashboard() },
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
