import Axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import Bookings, { IBooking } from "../constants/bookings";
import { IState } from "../reducers/index";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  listClientBookings: () => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Bookings.listClientBookingsRequest());
          const res = await Axios.get(`${API_URL}/client_admin/bookings`);
          dispatch(Bookings.listClientBookingsSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(Bookings.listClientBookingsFailure(err));
          reject(err);
        }
      }
    });
  },
  putClientBooking:
    ({ id, start_date, end_date }: { id: number; start_date: string; end_date: string }) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          try {
            dispatch(Bookings.putClientBookingRequest());
            const res = await Axios.put(`${API_URL}/booking/${id}`, { start_date, end_date });
            dispatch(Bookings.putClientBookingSuccess(res));
            resolve(res);
          } catch (err) {
            dispatch(Bookings.putClientBookingFailure(err));
            reject(err);
          }
        }
      });
    },
  amendClientBooking:
    (booking: IBooking, canPostOwnClientBooking: boolean, isClientAdmin: boolean, isAdmin: boolean) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        const bookingUrl = `${API_URL}/${canPostOwnClientBooking && isClientAdmin ? "client_admin/" : ""}brief/${
          booking.brief_id || booking.brief.id
        }/booking`;

        if (token) {
          try {
            dispatch(Bookings.putClientBookingRequest());
            const old = await Axios.put(`${API_URL}/${isClientAdmin ? "client_admin/" : ""}booking/${booking.id}`, {
              status: "CANCELLED_BY_SYSTEM",
            });
            dispatch(Bookings.putClientBookingSuccess(old));
            dispatch(Bookings.postClientBookingRequest());
            const res = await Axios.post(bookingUrl, booking);
            dispatch(Bookings.postClientBookingSuccess(res));
            resolve(res);
          } catch (err) {
            dispatch(Bookings.putClientBookingFailure(err));
            dispatch(Bookings.postClientBookingFailure(err));
            reject(err);
          }
        }
      });
    },
};
