import classnames from "classnames";
import { Field } from "formik";
import { FileUpload, FileUploadProps } from "primereact/fileupload";
import { Button } from "primereact/button";
import React, { ReactNode, useRef } from "react";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { extractFileName } from "~/utils";
import "./ApFileUpload.scss";
import { IAttachment } from "~/interfaces/Interview";
import { isEmpty } from "lodash";

interface IUploadProps extends FileUploadProps {
  id: string;
  noField?: boolean;
  formik?: any;
  label?: string;
  className?: string;
  info?: string | ReactNode;
  onChange: (files: any) => void;
  onDelete?: (file: File | IAttachment, id: number) => void;
}

export default function ApFileUpload({
  noField,
  formik,
  id,
  label,
  className,
  info,
  onChange,
  onDelete,
  ...rest
}: IUploadProps) {
  const uploaderRef = useRef(null);

  let meta, field;
  if (noField && formik) {
    meta = formik.getFieldMeta(id);
    field = formik.getFieldProps(id);
  }

  const renderedLabel = label && (
    <label htmlFor={id}>
      {label} {info && <PopupIcon content={info} />}
    </label>
  );

  const renderedError = meta?.error && <div className="ap-error">{meta?.error}</div>;
  const description = rest.accept ? <div className="description">Supported file types: {rest.accept}</div> : null;

  const props = {
    ...rest,
    id,
    customUpload: !!onChange,
    uploadHandler: ({ files }: { files: File[] }) => {
      uploaderRef?.current?.clear();
      !!onChange && onChange(files);
    },
  };

  const { value } = formik.getFieldProps(id);

  const renderFileName = (file: IAttachment | File | string, index = 0) => {
    if (file instanceof File) {
      return (
        <div className="preview" key={index}>
          <span>{file.name}</span>
          {!!onDelete && <Button icon="pi pi-trash" disabled={props.disabled} onClick={() => onDelete(file, index)} />}
        </div>
      );
    } else {
      const fileUrl = file?.url ? file?.url : file;
      return (
        <div className="preview" key={index}>
          <a href={fileUrl} target="_blank">
            {extractFileName(fileUrl)}
          </a>
          {!!onDelete && <Button icon="pi pi-trash" disabled={props.disabled} onClick={() => onDelete(file, index)} />}
        </div>
      );
    }
  };

  return !!noField && !!meta ? (
    <div
      className={classnames("ApFileUpload", className, {
        error: meta.touched && meta.error,
      })}
    >
      {renderedLabel}
      {(value instanceof File || !isEmpty(value)) &&
        (props.multiple ? (
          <div className="preview-list">
            {value.map((file: IAttachment | File, i: number) => renderFileName(file, i))}
          </div>
        ) : (
          renderFileName(value)
        ))}
      <FileUpload ref={uploaderRef} {...field} {...props} />
      {description}
      {renderedError}
    </div>
  ) : (
    <Field name={id}>
      {({ field }: any) => (
        <>
          {renderedLabel}
          <FileUpload {...field} {...props} />
          {description}
          {renderedError}
        </>
      )}
    </Field>
  );
}
