import React from "react";
import { Notebook, Prohibit } from "phosphor-react";
import { ViewModes } from "~/components/common/ViewToggler/ViewToggler";

export enum TalentTypes {
  TYPE_GOTO = "TYPE_GOTO",
  TYPE_BOOKED = "TYPE_BOOKED",
  TYPE_INVITED = "TYPE_INVITED",
  TYPE_GENIE = "TYPE_GENIE",
}

export default function TalentTypeIcon({
  type,
  isBlocked,
  viewMode,
}: {
  type: string | null;
  isBlocked?: boolean;
  viewMode?: ViewModes;
}) {
  const isGrid = viewMode === ViewModes.GRID;
  const renderTalentType = () => {
    if (isBlocked) {
      return (
        <>
          <Prohibit size={18} />
          {isGrid ? " Blocked talent" : ""}
        </>
      );
    }
    switch (type) {
      case TalentTypes.TYPE_INVITED:
        return (
          <>
            <i className="icon-paper-plane" />
            {isGrid ? " Invited" : ""}
          </>
        );
      case TalentTypes.TYPE_GOTO:
        return (
          <>
            <i className="icon-bookmark" />
            {isGrid ? " Bookmarked" : ""}
          </>
        );
      case TalentTypes.TYPE_BOOKED:
        return (
          <>
            <Notebook size={18} /> {isGrid ? " Previously booked" : ""}
          </>
        );
      case TalentTypes.TYPE_GENIE:
        return (
          <>
            <i className="icon-genie" />
            {isGrid ? " Genie talent" : ""}
          </>
        );
      default:
        return "Talent";
    }
  };
  return <div className="talent-type">{renderTalentType()}</div>;
}
