import React from "react";
import { FormikContextType } from "formik";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import "../../CreateBriefForm/CreateBriefForm.scss";

interface IRightRequirements {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}
export default function RightRequirements(props: IRightRequirements) {
  const { formik, isViewOnly = false } = props;

  const rightRequirementOptions = [
    {
      id: 0,
      code: "RESIDENCE_PERMIT",
      name: "Must have Right to Work in Job Location",
    },
    {
      id: 1,
      code: "OPEN_PERMIT",
      name: "Open to Applicants that require a work permit/visa",
    },
  ];

  return (
    <div className="p-fluid field field-required right-requirements">
      <label htmlFor="right_requirements">Right to Work Requirements</label>
      <ApRadioGroup
        formik={formik}
        name="right_requirements"
        noField
        options={rightRequirementOptions || []}
        disabled={isViewOnly}
      />
    </div>
  );
}
