import React from "react";
import { Button } from "primereact/button";
import "./ViewToggler.scss";
import cn from "classnames";
import { SquaresFour, Table } from "phosphor-react";

export enum ViewModes {
  GRID,
  TABLE,
}

export default function ViewToggler({
  viewMode,
  setViewMode,
}: {
  viewMode: number;
  setViewMode: (mode: number) => void;
}) {
  return (
    <div className="ViewToggler">
      <label>View:</label>
      <Button
        className={cn("with-icon", { active: viewMode === ViewModes.TABLE })}
        onClick={() => setViewMode(ViewModes.TABLE)}
        tooltip="Switch to Table view"
        tooltipOptions={{ position: "top" }}
      >
        <Table size={25} />
      </Button>
      <Button
        className={cn("with-icon", { active: viewMode === ViewModes.GRID })}
        onClick={() => setViewMode(ViewModes.GRID)}
        tooltip="Switch to Grid view"
        tooltipOptions={{ position: "top" }}
      >
        <SquaresFour size={25} />
      </Button>
    </div>
  );
}
