import React, { useMemo } from "react";
import { FormikContextType } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import "../../CreateBriefForm/CreateBriefForm.scss";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { checkBriefForPermanent } from "~/routes/Brief/helper";

interface IDescription {
  formik: FormikContextType<any>;
  canShowInfoPopup?: boolean;
}
export const sellPopupContent = (
  <span>
    Don’t think about what you are looking for in terms of a person but what you want them to deliver. The best talent
    are always busy so you gotta sell it a bit too. I know, it’s almost like you’re the creative too 😂
    <br />
    <br />
    Here’s an example brief.
    <br />
    <br />
    <em>
      Launching a cool new wellness product. This digital service is all about the coolness and calmness of woodworking
      and sewing. The twist? Making people use their hands and minds at the same time allows them to refocus their minds
      on something else. The work needs a big idea that can stretch through a variety of media, especially social and
      PR. You'll be presenting to clients, so helping mock-up decks and the gift of the gab are a bonus. Please
      highlight any wellbeing experience, social campaigns or other examples that make you spot on for this.
    </em>
  </span>
);
const exampleTitle = "Here's an Example:";
const exampleBody =
  "Our creative ambition is to be the most influential company in the UK. To do that we need a new business strategy and to create a new content engine. We’re thinking big. We hope you can too.";

const opportunityPopupContent = (
  <span>
    {exampleTitle}
    <br />
    <br />
    <em>{exampleBody}</em>
  </span>
);

const placeholder = `Genie only works with the very best creative minds. Like all superstars, they’ll have options. And they may be happy right where they are. So you’ll need to ruffle a few feathers to get them interested.

${exampleTitle}

${exampleBody}`;

const multilinePlaceholderSafari = (
  <div>
    Genie only works with the very best creative minds. Like all superstars, they’ll have options. And they may be happy
    right where they are. So you’ll need to ruffle a few feathers to get them interested.
    <br />
    <br />
    {exampleTitle}
    <br />
    <br />
    {exampleBody}
  </div>
);

export default function Description(props: IDescription) {
  const { formik, canShowInfoPopup } = props;
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  const isPermanentHiringBrief = useMemo(
    () => checkBriefForPermanent(formik.values, CONTRACT_TYPES),
    [formik.values.contract_id, CONTRACT_TYPES]
  );
  const isFixedTermBriefType = useMemo(
    () => formik.values.contract_id === CONTRACT_TYPES?.FREELANCE,
    [formik.values.contract_id, CONTRACT_TYPES]
  );
  const isShowPopup = (isPermanentHiringBrief || isFixedTermBriefType) && canShowInfoPopup;

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon
        popup={{
          content: isPermanentHiringBrief ? opportunityPopupContent : sellPopupContent,
          buttons: [{ text: "Got It 🦾" }],
        }}
      />
    </>
  );

  return (
    <div className="p-fluid field field-required role-description">
      <label htmlFor="description">
        {isPermanentHiringBrief ? "The Opportunity" : "The Sell"}
        {isShowPopup && <InfoPopup />}
      </label>
      <ApInputTextarea
        formik={formik}
        id="description"
        noField
        placeholder={isPermanentHiringBrief ? placeholder : ""}
        maxChars={isPermanentHiringBrief ? 1200 : 1000}
        multilinePlaceholderSafari={multilinePlaceholderSafari}
      />
    </div>
  );
}
