import { Dispatch } from "redux";
import Modal from "../constants/modal";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  openModal: (modalType: string, modalProps = {}) => (dispatch: Dispatch) => {
    dispatch(Modal.modalShow(modalType, modalProps));
  },
  closeModal: () => (dispatch: Dispatch) => {
    dispatch(Modal.modalHide());
  },
};
