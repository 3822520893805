import React, { Dispatch, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";
import { useGlobalContext } from "~/contexts/GlobalContext";
import moment from "moment";
import { calculateDates, getDiffBetweenDays } from "~/utils";
import { Slider } from "primereact/slider";
import { FormikContextType } from "formik";
import CalendarFooterTemplate from "~/forms/BriefViewDetailsForm/Calendar/CalendarFooterTemplate";
import { InputNumber } from "primereact/inputnumber";
import "./CalendarBlock.scss";
interface ICalendarBlock {
  formik: FormikContextType<any>;
  dates: Date[] | undefined;
  minDate?: Date | undefined;
  setDates: Dispatch<any>;
  setDurationDays: Dispatch<any>;
  durationDays: number;
  maxDurationDays: number;
  setMaxDurationDays: Dispatch<any>;
  bookingStatus?: string | undefined;
  hideFlexibleDatesCheckbox?: boolean;
}

export default function CalendarBlock(props: ICalendarBlock) {
  const {
    formik,
    dates,
    minDate,
    setDates,
    setDurationDays,
    durationDays,
    maxDurationDays,
    setMaxDurationDays,
    bookingStatus,
    hideFlexibleDatesCheckbox,
  } = props;
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const isDurationTypeConsec = formik?.values.duration_id === DURATION_ID?.CONSEC;
  const isShowDurationSlider = formik?.values.duration_id === DURATION_ID?.NUMBER;
  const isExactWorkingDates = formik?.values.duration_id === DURATION_ID?.EXACT;
  const calendarType = isExactWorkingDates ? "multiple" : "range";
  const calendarTitle = isExactWorkingDates ? "Brief days" : "Brief dates";

  const isActiveBooking = bookingStatus && ["ACCEPTED", "EXTENDED", "EXTENSION_ACCEPTED"].includes(bookingStatus);

  const handleChangeDates = ({ value }: { value: Date | Date[] | undefined }) => {
    if (Array.isArray(value)) {
      isShowDurationSlider && changeDurationDays(value);

      if (isExactWorkingDates) {
        calculateDates.sort(value);
        formik.setValues({
          ...formik.values,
          start_date: value.length ? moment(value[0]).format("YYYY-MM-DD") : "",
          end_date: value.length ? moment(value[value.length - 1]).format("YYYY-MM-DD") : "",
          dates: calculateDates.formatAsObjectArr(value),
        });
      } else {
        const days = calculateDurationDays(value);
        formik.setValues({
          ...formik.values,
          start_date: moment(value[0]).format("YYYY-MM-DD"),
          end_date: moment(value[1] ?? value[0]).format("YYYY-MM-DD"),
          ...(isShowDurationSlider && { duration_days: days + 1 }),
        });
      }
      setDates(value);
    }
  };

  const resetDates = () => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
    });
  };

  const calculateDurationDays = (dates: Date[]) => {
    const endDate = dates[1] ? moment(dates[1]) : moment(dates[0]);
    const startDate = moment(dates[0]);
    return getDiffBetweenDays({ startDate, endDate });
  };

  const changeDurationDays = (dates: Date[], day: number = 0) => {
    const days = calculateDurationDays(dates);
    setMaxDurationDays(days + 1 || null);
    day === 0 ? setDurationDays(days + 1 || null) : setDurationDays(day);
  };

  const handleSetDurationDays = (day: number) => {
    setDurationDays(day);
    formik.setFieldValue("duration_days", day);
  };

  useEffect(() => {
    changeDurationDays([formik.values.start_date, formik.values.end_date], formik?.values?.duration_days);
  }, []);

  return (
    <div className="calendar-block">
      <div className="p-fluid p-formgrid">
        <div className="field field-required">
          <label htmlFor="start_date">{calendarTitle}</label>
          <Calendar
            dateFormat="dd/mm/yy"
            inline={isExactWorkingDates}
            onChange={handleChangeDates}
            showIcon={!isExactWorkingDates}
            selectionMode={calendarType}
            value={dates}
            minDate={minDate}
            disabledDays={!formik?.values.include_weekends && isDurationTypeConsec ? [0, 6] : []}
            footerTemplate={
              isExactWorkingDates ? () => <CalendarFooterTemplate dates={dates} resetDates={resetDates} /> : undefined
            }
          />
          {formik.touched?.start_date && formik.errors?.start_date && (
            <div className="ap-error">{formik.errors?.start_date}</div>
          )}
        </div>
      </div>
      {isShowDurationSlider && maxDurationDays && (
        <div className="card p-fluid field">
          <label htmlFor="start_date">Duration Days: {durationDays}</label>
          <div className="slider-block">
            <InputNumber
              inputId="duration_days"
              value={durationDays}
              onValueChange={(e) => handleSetDurationDays(e.value as number)}
              showButtons
              buttonLayout="horizontal"
              mode="decimal"
              min={1}
              max={maxDurationDays}
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-danger"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
            <Slider
              max={maxDurationDays}
              min={1}
              value={durationDays}
              onChange={(e) => handleSetDurationDays(e.value as number)}
            />
          </div>
        </div>
      )}
      <div className="p-fluid field">
        {isDurationTypeConsec && (
          <ApCheckbox formik={formik} noField name="include_weekends" label="Include weekend days" />
        )}
        {!isActiveBooking && !hideFlexibleDatesCheckbox ? (
          <ApCheckbox formik={formik} noField name="dates_flexible" label="These dates are flexible" />
        ) : null}
      </div>
    </div>
  );
}
