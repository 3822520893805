import { AnyAction } from "redux";
import * as User from "../constants/user";
import isUndefined from "lodash/isUndefined";
import { ITag } from "~/components/common/ApTag/ApTag";

// ------------------------------------
// Interface
// ------------------------------------
export interface IUserState {
  authenticatedUser: IAuthenticatedUser | null;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isFetchingPaymentProfile: boolean;
  isFetchingPersonalProfile: boolean;
  isFetchingBankProfile: boolean;
  errors: any[];
  paymentProfile: object;
  personalProfile: object;
  bankProfile: object;
  isFetchingTags: boolean;
}

export type IAuthenticatedUser = {
  id: number;
  email: string;
  full_name: string;
  ge_psid: string;
  must_change_password: boolean;
  token: string;
  client_id?: number;
  client?: {
    id: number;
    name?: string;
    work_profile: {
      image_url: string;
    };
  };
  client_personal_profile?: any;
  pending_invoices: number;
  disputed_invoices: number;
  tags?: ITag[];
};

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IUserState = {
  authenticatedUser: null,
  isAuthenticated: false,
  isAuthenticating: false,
  isFetchingPaymentProfile: false,
  isFetchingPersonalProfile: false,
  isFetchingBankProfile: false,
  isFetchingTags: false,
  errors: [],
  paymentProfile: {},
  personalProfile: {},
  bankProfile: {},
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IUserState => {
  switch (action.type) {
    case User.USERS_LOGIN_FAILURE:
    case User.USERS_VERIFY_TOKEN_FAILURE:
    case User.AUTH_RESET_REQUEST_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case User.USERS_LOGIN_REQUEST:
    case User.USERS_VERIFY_TOKEN_REQUEST:
    case User.AUTH_RESET_REQUEST_PASSWORD_REQUEST:
    case User.USERS_SET_PASSWORD_REQUEST:
    case User.AUTH_RESET_CHECK_PASSWORD_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };
    case User.AUTH_RESET_REQUEST_PASSWORD_FAILURE:
    case User.USERS_SET_PASSWORD_FAILURE:
    case User.AUTH_RESET_CHECK_PASSWORD_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        errors:
          typeof action.payload === "object" && !isUndefined(action.payload.length) ? action.payload : [action.payload],
      };
    case User.AUTH_RESET_CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        authenticatedUser: {
          ...state.authenticatedUser,
          ...action.payload.user,
          token: action.payload.token,
        },
      };
    case User.USERS_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        authenticatedUser: {
          ...state.authenticatedUser,
          token: action.payload.token,
          must_change_password: false,
        },
      };
    case User.USERS_LOGIN_SUCCESS:
      const { permissions, ...user } = action.payload;
      return {
        ...state,
        authenticatedUser: user,
        isAuthenticated: true,
        isAuthenticating: false,
      };
    case User.USERS_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        authenticatedUser: action.payload,
        isAuthenticated: true,
        isAuthenticating: false,
      };

    case User.USERS_GET_PAYMENT_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingPaymentProfile: true,
      };
    case User.USERS_GET_PAYMENT_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingPaymentProfile: false,
        paymentProfile: action.payload,
      };
    case User.USERS_GET_PAYMENT_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingPersonalProfile: false,
        errors: action.payload,
      };

    case User.USERS_GET_PERSONAL_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingPersonalProfile: true,
      };
    case User.USERS_GET_PERSONAL_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingPersonalProfile: false,
        personalProfile: action.payload,
      };
    case User.USERS_GET_PERSONAL_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingPersonalProfile: false,
        errors: action.payload,
      };
    case User.USERS_GET_BANK_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingBankProfile: true,
      };
    case User.USERS_GET_BANK_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingBankProfile: false,
        bankProfile: action.payload,
      };
    case User.USERS_GET_BANK_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingBankProfile: false,
        errors: action.payload,
      };
    case User.USERS_SET_NOT_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
      };
    case User.UPDATE_USER_TOKEN:
      return {
        ...state,
        authenticatedUser: action.payload.user,
        isAuthenticated: true,
      };

    case User.USERS_UPDATE_PAYMENT_PROFILE_REQUEST:
      return { ...state, isFetchingPaymentProfile: true };
    case User.USERS_UPDATE_PAYMENT_PROFILE_SUCCESS:
      return { ...state, isFetchingPaymentProfile: false, paymentProfile: action.payload };
    case User.USERS_UPDATE_PAYMENT_PROFILE_FAILURE:
      return { ...state, isFetchingPaymentProfile: false, errors: action.payload };

    case User.USERS_UPDATE_PROFILE_REQUEST:
      return { ...state, isFetchingPersonalProfile: true, isFetchingPaymentProfile: true };

    case User.USERS_UPDATE_PROFILE_FAILURE:
      return { ...state, isFetchingPersonalProfile: false, isFetchingPaymentProfile: false, errors: action.payload };

    case User.USERS_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingPersonalProfile: false,
        isFetchingPaymentProfile: false,
        personalProfile: action.payload.personal_profile,
        paymentProfile: action.payload.payment_profile,
      };

    case User.USERS_LOGOUT:
      return {
        ...initialState,
        authenticatedUser: null,
        isAuthenticated: false,
        isAuthenticating: false,
      };
    case User.UPDATE_TAG_REQUEST:
    case User.GET_TAGS_REQUEST:
    case User.DELETE_TAG_REQUEST:
      return {
        ...state,
        isFetchingTags: true,
      };
    case User.GET_TAGS_SUCCESS:
      return {
        ...state,
        isFetchingTags: false,
        authenticatedUser: {
          ...state.authenticatedUser,
          tags: action.payload,
        },
      };
    case User.GET_TAGS_FAILURE:
      return {
        ...state,
        isFetchingTags: false,
        errors: action.payload,
      };
    case User.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        isFetchingTags: false,
        authenticatedUser: {
          ...state.authenticatedUser,
          tags: state.authenticatedUser?.tags?.map((tag: ITag) => {
            if (tag.id === action.payload.id) {
              return action.payload;
            }
            return tag;
          }),
        },
      };
    case User.UPDATE_TAG_FAILURE:
    case User.DELETE_TAG_SUCCESS:
      return {
        ...state,
        isFetchingTags: false,
        authenticatedUser: {
          ...state.authenticatedUser,
          tags: state.authenticatedUser?.tags?.filter((tag: ITag) => tag.id !== action.payload),
        },
      };
    case User.DELETE_TAG_FAILURE:
      return {
        ...state,
        isFetchingTags: false,
      };
    case User.ADD_NEW_TAG:
      return {
        ...state,
        authenticatedUser: {
          ...state.authenticatedUser,
          tags: [...(state.authenticatedUser?.tags || []), action.payload],
        },
      };
    default:
      return state;
  }
};
