import React, { createContext, useContext } from "react";
import useConversation from "~/hooks/useConversation";
import { IConversationState } from "~/interfaces/conversation";

export const ConversationContext = createContext(null as IConversationState | null);

export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error("useConversationContext must be used within a ConversationContext");
  }
  return context;
};

const ConversationContextProvider = ({ children }: any) => {
  return (
    <ConversationContext.Provider
      value={{
        ...useConversation(),
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export default ConversationContextProvider;
