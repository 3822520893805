@import "../../../../mixins.scss";

@mixin collapceSign($sign) {
  td:first-child {
    &:after {
      content: $sign;
    }
  }
}

.filterDropdown {
  position: relative;
  display: inline-block;
  .p-dropdown {
    padding-right: 25px;
  }
  &Reset {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    color: $brandColor;
    font-size: 14px;
  }
}

.InvoicesList {
  font-family: $baseFont;
  padding: 40px;
  overflow: auto;
  padding: 0;

  h1 {
    padding: 0;
    margin: 0 0 40px 0;
  }

  .p-datatable {
    .p-datatable-thead > tr {
      > th {
        background-color: transparent;
        font-weight: 700;
      }
    }

    .p-datatable-tbody > tr {
      > td {
        font-size: 14px;
      }

      td:first-child {
        &:after {
          display: inline-block;
          background-color: #dce7e7;
          border-radius: 3px;
          width: 17px;
          text-align: center;
        }
      }

      &.collapsed,
      &.expanded {
        cursor: pointer;
      }

      &.collapsed {
        @include collapceSign("+");
      }

      &.expanded {
        @include collapceSign("-");

        td {
          border-bottom: none;
        }
      }
    }

    .p-datatable-tbody,
    .p-datatable-thead {
      > tr {
        > th,
        > td {
          padding: 0.625rem 0.625rem;
          font-size: 14px;
        }
      }
    }
  }

  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page {
        &.p-highlight {
          background-color: $lightRedColor;
          border-color: $lightRedColor;
        }
      }
    }
  }

  .p-component-overlay {
    background-color: transparent;
  }
  .matching-status {
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    padding: 5px 10px;
    border-radius: 4px;
    width: auto;
    height: auto;
    display: inline-block;
    margin: 0;

    &.circle {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-width: 3px;
      border-style: solid;
      border-radius: 50%;
      padding: 0;
      background-color: transparent;
      margin: 0 10px 0 15px;
    }

    &.yellow {
      color: #ffba01;
      border-color: #ffba01;
      background-color: rgb(100%, 73%, 0%, 0.1);
    }

    &.gray {
      color: #8e8e8e;
      border-color: #8e8e8e;
      background-color: rgba(142, 142, 142, 0.1);
    }

    &.green {
      color: #4cbd30;
      border-color: #4cbd30;
      background-color: rgba(76, 189, 48, 0.1);
    }

    &.red {
      color: #d32f2f;
      border-color: #d32f2f;
      background-color: rgb(83%, 18%, 18%, 0.1);
    }
  }

  .invoice-item {
    background-color: $toggleBg;
    padding: 15px 20px 15px 0;
    margin-bottom: 10px;
    border-radius: 10px;

    header {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;

      .status {
        display: flex;
        flex: 2;
        align-items: center;
      }

      .total {
        flex: 1;
        color: $primaryColor;
        text-align: right;
      }
    }

    article {
      margin: 7px 20px 0 45px;

      .invoice_number {
        margin-bottom: 15px;
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        flex: 1;
        font-size: 13px;
        line-height: 1.5em;

        label {
          display: block;
          color: #8e8e8e;
        }
      }
    }
  }
}
