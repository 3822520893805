import React, { createContext, useContext } from "react";
import useLoadingAnimation, { ILoadingAnimationState } from "~/hooks/useLoadingAnimation";

export const LoadingAnimationContext = createContext(null as ILoadingAnimationState | null);

export const useLoadingAnimationContext = () => {
  const context = useContext(LoadingAnimationContext);
  if (!context) {
    throw new Error("useLoadingAnimationContext must be used within a LoadingAnimationContextProvider");
  }
  return context;
};

const LoadingAnimationContextProvider = ({ children }: any) => {
  return (
    <LoadingAnimationContext.Provider
      value={{
        ...useLoadingAnimation(),
      }}
    >
      {children}
    </LoadingAnimationContext.Provider>
  );
};

export default LoadingAnimationContextProvider;
