import { FormikProps, FormikValues, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalContext } from "~/contexts/GlobalContext";
import ApDropdown from "../../components/common/ApDropdown/ApDropdown";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import ApRadioGroup from "../../components/common/ApRadioGroup/ApRadioGroup";
import Loader from "../../components/Loader/Loader";
import PopupIcon from "../../components/Toaster/PopupIcon";
import actions from "../../store/actions";
import toasts from "../../store/actions/toasts";
import { IState } from "../../store/reducers/index";
import { IUserState } from "../../store/reducers/user";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import { resetIR35Type } from "~/utils";
import { checkBriefForDraft } from "~/routes/Brief/helper";
import isUndefined from "lodash/isUndefined";
import ExcludedTalents from "./ExcludedTalents";
import ApMultiCheckbox from "~/components/common/ApMultiCheckbox/ApMultiCheckbox";

const gotoPopupIconText = (
  <>
    <p>
      'All Talent' is selected by default and includes talent from your company's 'My Talent Network' + New Talent (from
      Genie's Curated Network). By choosing a different option you will only be matched to talent in that specific
      segment.
    </p>
    <p>
      <strong>Pro tip:</strong>
    </p>
    <p>
      If you spot someone that you'd like to keep on your radar, simply hit the bookmark icon, and they'll automatically
      drop into your company's 'My Talent Network' area. When the time is right, you can easily book them for your next
      brief.
    </p>
  </>
);

export const IR35PopupContent = (
  <>
    PLEASE NOTE: When choosing to work with talent via an umbrella company, the employer's NI tax will be deducted from
    the agreed day rate by HMRC. The talent is therefore likely to increase their day rate to accommodate this. Please
    bear this in mind when viewing your matches.
    <br />
    <br />
    For any additional questions, please email <a href="mailto: support@meetgenie.co">support@meetgenie.co</a>.
  </>
);

interface Props {
  editMode?: boolean;
  validate?: FormikProps<FormikValues>;
  formik?: FormikProps<FormikValues>;
}

export default function BriefNewOverviewForm(props: Props) {
  const {
    global: { globalOptions, optionsNormalization },
  } = useGlobalContext();
  const { userAccess } = usePermissionContext();
  const dispatch = useDispatch();
  const formik = props.formik || useFormikContext();
  const isBriefDraft = checkBriefForDraft(formik.values);
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);
  const clients = userAccess(permissionType.accessAdmin)
    ? useSelector((state: IState) => state.client.clients)
    : currentUser
    ? [currentUser.client]
    : undefined;

  const contractTypes = globalOptions?.contract_types;
  const regions = globalOptions?.regions;

  const TALENT_GOTO_TYPES = useMemo(() => {
    return globalOptions?.goto_types ?? [];
  }, [globalOptions]);

  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  const ir35Types = useMemo(
    () =>
      contractTypes?.find((contractType: { id: number }) => contractType.id === CONTRACT_TYPES.FREELANCE)?.ir35_types,
    [contractTypes]
  );
  const contractOptions = useMemo(
    () => contractTypes?.map(({ id: code, name }: { id: number; name: string }) => ({ code, name })),
    [contractTypes]
  );
  const briefRegions = useMemo(
    () =>
      regions?.map(({ id: code, name, code: shortCodeName }: { id: string | null; name: string; code: string }) => ({
        code,
        name: shortCodeName !== "NA" && shortCodeName !== "FLEXIBLE" ? shortCodeName : name,
        fullname: name,
        shortCodeName,
      })),
    [regions]
  );

  const isClientUsersFetching = useSelector((state: IState) => state.client.isFetching);
  const currentClient = useSelector((state: IState) => state.client?.client);
  const allClientUsers = currentClient?.users;
  const clientUsers = useMemo(() => (allClientUsers || []).filter(({ published }) => published), [allClientUsers]);
  const [isUmbrella, setIsUmbrella] = useState(false);
  const [isExempt, setIsExempt] = useState(false);

  const mainClientDependencies = [formik.values.client_id, formik.values.author_id];
  const clientProfileDependencies =
    isBriefDraft && userAccess(permissionType.accessAdmin)
      ? [...mainClientDependencies, clients]
      : mainClientDependencies;

  useEffect(() => {
    if (clients && clients.length && formik.values.client_id && formik.values.author_id) {
      (async () => {
        const { umbrella_only, exempt, ir35_compliant } = (
          (await dispatch(actions.client.getClientIR35(formik.values.author_id))) as any
        )?.data;
        setUserIR35Settings(umbrella_only, exempt, ir35_compliant);
      })();
    }
  }, clientProfileDependencies);

  useEffect(() => {
    if (isUndefined(clients) && userAccess(permissionType.accessAdmin)) {
      dispatch(actions.client.listClients());
    }
  }, []);

  useEffect(() => {
    if (clients && clients.length === 1 && formik.values.client_id === 0) {
      formik.setFieldValue("client_id", clients[0]?.id);
    }
  }, [clients]);

  useEffect(() => {
    if (!!regions && !formik.values.regions.length) {
      formik.setFieldValue(
        "regions",
        regions.map(({ id }: { id: number }) => id)
      );
    }
  }, [regions]);

  useEffect(() => {
    if (!props.formik && formik.values.client_id) {
      dispatch(actions.client.getClient(formik.values.client_id, userAccess(permissionType.accessAdmin)));
    }
  }, [formik.values.client_id]);

  useEffect(() => {
    if (clientUsers?.length) {
      formik.setFieldValue("exempt", isExempt);
      formik.setFieldValue("umbrella", isUmbrella);
    }
  }, [clientUsers, formik.values?.contract_id]);

  useEffect(() => {
    if (formik.values.goto_types.length === 0 && !!TALENT_GOTO_TYPES) {
      formik.setFieldValue(
        "goto_types",
        TALENT_GOTO_TYPES.map(({ id }) => id)
      );
    }
  }, [TALENT_GOTO_TYPES]);

  useEffect(() => {
    if (currentUser?.id && currentUser?.client_id && userAccess(permissionType.accessClientAdmin)) {
      formik.setFieldValue("client_id", currentUser?.client_id);
      formik.setFieldValue("author_id", currentUser?.id);
    }
  }, [currentUser]);

  async function setUserIR35Settings(umbrella_only: boolean, exempt: boolean, ir35_compliant: boolean) {
    await Promise.all([setIsUmbrella(umbrella_only), setIsExempt(exempt)]);
    formik.setFieldValue("umbrella", umbrella_only, false);
    formik.setFieldValue("exempt", exempt, false);
    formik.setFieldValue("ir35_compliant", ir35_compliant, false);
    formik.setFieldValue("sds_type", `${ir35_compliant ? "CLIENT_SEND" : "GENIE_CREATE"}`, false);
  }

  const onIR35Change = (id: number, formik: any) => {
    resetIR35Type(id, formik);

    if (id === optionsNormalization?.IR35_TYPES?.UMBRELLA) {
      dispatch(
        toasts.setPopup({
          content: IR35PopupContent,
          buttons: [{ text: "Dismiss" }],
        })
      );
    }
  };

  return (
    <div className="BriefOverviewForm">
      <div className="p-fluid">
        <div className="field field-required">
          <label htmlFor="name">
            Brief name <PopupIcon content="For your records, talent won’t see this before you book them." />
          </label>
          <ApInputText formik={formik} id="name" noField />
        </div>
      </div>
      {userAccess(permissionType.accessAdmin) && (
        <div className="p-fluid field field-required">
          <label htmlFor="client_id">Client name</label>
          <ApDropdown
            id="client_id"
            dataKey="id"
            disabled={!clients || props.editMode || !userAccess(permissionType.accessAdmin)}
            formik={formik}
            filter={true}
            filterBy="name, legal_name"
            noField
            options={clients && clients.sort((a, b) => a.name.localeCompare(b.name))}
            optionLabel="name"
            optionValue="id"
            placeholder="Select Client"
          />
        </div>
      )}
      <div className="p-fluid field field-required">
        <label htmlFor="author_id">
          {userAccess(permissionType.accessAdmin) ? "Client user responsible" : "Client setting this brief"}
        </label>
        {isClientUsersFetching ? (
          <div>
            <Loader />
          </div>
        ) : (
          <ApDropdown
            id="author_id"
            dataKey="id"
            disabled={!clientUsers || props.editMode || !userAccess(permissionType.accessAdmin)}
            formik={formik}
            noField
            options={clientUsers}
            optionLabel="full_name"
            optionValue="id"
            placeholder="Select User"
            filter={false}
          />
        )}
      </div>
      {!!contractOptions?.length && (
        <div className="p-fluid field field-required">
          <label htmlFor="contract_id">
            Brief Type{" "}
            <PopupIcon
              content={
                <>
                  Day rate gig - for shorter bookings, most common freelancer type.
                  <br />
                  <br />
                  Fixed term - for longer bookings, pay talent via your company employment system (PAYE in the UK).
                  <br />
                  <br />
                  Permanent Hiring - for full-time, permanent positions in your company
                </>
              }
            />
          </label>
          <ApRadioGroup formik={formik} name="contract_id" noField options={contractOptions} />
        </div>
      )}

      {formik.values.contract_id === CONTRACT_TYPES?.FREELANCE &&
        formik.values.ir35_compliant &&
        currentClient?.payment_profile && (
          <div className="p-fluid field disclaimer">
            Compliance & Payment Handling: {currentClient.payment_profile.payment_company_name}
          </div>
        )}

      {!!ir35Types?.length && !isExempt && !isUmbrella && formik.values.contract_id === CONTRACT_TYPES?.FREELANCE && (
        <div className="p-fluid field field-required">
          <label htmlFor="ir35_id">IR35 type</label>
          <ApDropdown
            disabled={!clientUsers || props.editMode}
            formik={formik}
            id="ir35_id"
            scrollHeight="350px"
            optionLabel="name"
            optionValue="id"
            noField
            options={ir35Types}
            placeholder="Select IR35 type"
            onChange={(e) => onIR35Change(e.value, formik)}
          />
        </div>
      )}
      {formik.values.contract_id === CONTRACT_TYPES?.PERMANENT && (
        <div className="p-fluid">
          <div className="field field-required">
            <label htmlFor="job_title">Job Title</label>
            <ApInputText formik={formik} id="job_title" noField />
          </div>
        </div>
      )}
      <div className="p-fluid field">
        <label htmlFor="goto_types">
          Talent Pools to Match from <PopupIcon content={gotoPopupIconText} />
        </label>
        <ApMultiCheckbox
          formik={formik}
          id="goto_types"
          noField
          allItemsLabel="All Talent"
          options={TALENT_GOTO_TYPES}
        />
      </div>
      <ExcludedTalents formik={formik} />
      {!!briefRegions?.length && (
        <div className="p-fluid field">
          <label htmlFor="goto">
            Preferred talent location{" "}
            <PopupIcon
              content={
                <>
                  <p>
                    I’ll try to find talent in the part of the world you’d prefer them to be in. If I can’t find any
                    then I’ll go to the next best match.
                  </p>
                  {briefRegions
                    .filter(({ shortCodeName }: { shortCodeName: string }) => shortCodeName !== "FLEXIBLE")
                    .map(({ fullname, code }: { fullname: string; code: number }) => (
                      <p key={code}>{fullname}</p>
                    ))}
                </>
              }
            />
          </label>
          <ApMultiCheckbox
            formik={formik}
            id="regions"
            noField
            allItemsLabel="All"
            options={briefRegions}
            selectedProp="code"
          />
        </div>
      )}
    </div>
  );
}
