import { useState } from "react";
import { useDispatch } from "react-redux";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { IUserForm } from "~/store/constants/user";
import { ClientUsersService } from "~/API/ClientUsersService";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import { AxiosError } from "axios";
import { IClientUser } from "~/store/constants/client";

const useClientUsers = () => {
  const [isUsersFetching, setIsUsersFetching] = useState(false);
  const [isRoleChangeFetching, setIsRoleChangeFetching] = useState(false);
  const [isUserDataFetching, setIsUserDataFetching] = useState(false);
  const [clientUsers, setClientUsers] = useState<IUserForm[] | null>(null);
  const [userData, setUserData] = useState<IClientUser | null>(null);
  const dispatch = useDispatch();
  const { userAccess } = usePermissionContext();
  const isAdmin = userAccess(permissionType.accessAdmin);

  const getClientUsers = async (clientId: number) => {
    try {
      setIsUsersFetching(true);
      const { status, data } = await ClientUsersService.getClientUsers(clientId, isAdmin);
      status === 200 && setClientUsers(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsUsersFetching(false);
    }
  };
  const attachClientUserRole = async (userId: number, roleId: string) => {
    try {
      const isAdmin = userAccess(permissionType.accessAdmin);
      setIsRoleChangeFetching(true);
      await ClientUsersService.attachUserRole(userId, roleId, isAdmin);
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsRoleChangeFetching(false);
    }
  };

  const detachClientUserRole = async (userId: number | string, roleId: string) => {
    try {
      const isAdmin = userAccess(permissionType.accessAdmin);
      setIsRoleChangeFetching(true);
      await ClientUsersService.detachUserRole(userId, roleId, isAdmin);
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsRoleChangeFetching(false);
    }
  };

  const getUserById = async (userId: number) => {
    try {
      setIsUserDataFetching(true);
      const { status, data } = await ClientUsersService.getUserById(userId);
      status === 200 && setUserData(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsUserDataFetching(false);
    }
  };

  const updateUserById = async (userId: number, formData: IClientUser) => {
    try {
      setIsUserDataFetching(true);
      const { status, data } = await ClientUsersService.updateUserById(isAdmin, userId, formData);
      if (status === 200) {
        setUserData(data as IClientUser);
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsUserDataFetching(false);
    }
  };

  const validateEmail = async (email: string): Promise<string | AxiosError> => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsUserDataFetching(true);
        await ClientUsersService.validateEmail(email);
        resolve();
      } catch (err) {
        resolve(err.response.data.email);
      } finally {
        setIsUserDataFetching(false);
      }
    });
  };

  return {
    isUsersFetching,
    isUserDataFetching,
    clientUsers,
    userData,
    getClientUsers,
    getUserById,
    updateUserById,
    validateEmail,
    setClientUsers,
    attachClientUserRole,
    detachClientUserRole,
    isRoleChangeFetching,
  };
};
export default useClientUsers;
