import React, { ChangeEvent, SyntheticEvent, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import actions from "../../store/actions";
import toasts from "~/store/constants/toasts";

import "./ImageCropper.scss";
import * as permissionType from "~/constants";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { Button } from "primereact/button";
import { isUndefined, isNull } from "lodash";

export default function ImageCropper() {
  const { userAccess } = usePermissionContext();
  const defaultCrop: ReactCrop.Crop = { aspect: 780 / 410 };
  const inputEl = useRef<HTMLInputElement>(document.createElement("input"));

  const [crop, setCrop] = useState(defaultCrop);
  const [original, setOriginal] = useState("");

  const dispatch = useDispatch();
  const { talentId, clientId }: { talentId: string; clientId: string } = useParams();

  const saveImage = async (e: SyntheticEvent) => {
    e.preventDefault();
    const img: Blob | void = await getCroppedImg(original, crop);
    if (talentId) dispatch(actions.talent.saveProfileImage(talentId, img));
    if (clientId) dispatch(actions.client.saveProfileImage(clientId, img, userAccess(permissionType.accessAdmin)));
  };

  const selectImage = (event: ChangeEvent): void => {
    const { files } = event.target as HTMLInputElement;
    if (files && files.length) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        setOriginal(reader.result as string);
        dispatch(
          toasts.setAlerts([
            {
              closable: false,
              content: renderAskModal(closeAskModal),
              severity: "warn",
              sticky: true,
            },
          ])
        );
      };
    }
  };

  const closeAskModal = () => {
    dispatch(toasts.setAlerts([]));
  };

  const renderAskModal = (onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
    return (
      <div className="flex flex-column" style={{ flex: "1" }}>
        <div className="text-center">
          <i className="pi pi-exclamation-triangle" style={{ fontSize: "3rem" }}></i>
          <p>
            Nice 🙌
            <br />
            Next, pls use your cursor to click and drag an area in the image, so we can crop the image to fit the
            correct profile dimensions 👍🏿
          </p>
        </div>
        <footer>
          <Button className="p-button-success" label="Got It" onClick={onClose} type="button" />
        </footer>
      </div>
    );
  };

  const onLoad = (img) => {
    const aspect = 780 / 410;
    const width = img.width / aspect < img.height * aspect ? 100 : ((img.height * aspect) / img.width) * 100;
    const height = img.width / aspect > img.height * aspect ? 100 : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;
    setCrop({
      unit: "px",
      width: (img.width * width) / 100,
      height: (img.height * height) / 100,
      x: (img.width * x) / 100,
      y: (img.height * y) / 100,
      aspect,
    });

    return false; // Return false if you set crop state in here.
  };

  const getCroppedImg = (image: string, crop: ReactCrop.Crop): Promise<Blob> => {
    const canvas = document.createElement("canvas");
    return new Promise((resolve, reject) => {
      if (crop.width && crop.height) {
        const { x: cx, y: cy, width: cw, height: ch } = crop;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        if (!isNull(ctx)) {
          const img = document.getElementsByClassName("ReactCrop__image")[0] as HTMLImageElement;
          img.onload = async () => {
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            await ctx.drawImage(
              img,
              (cx as number) * scaleX,
              (cy as number) * scaleY,
              cw * scaleX,
              ch * scaleY,
              0,
              0,
              cw,
              ch
            );
            canvas.toBlob((blob: Blob | null) => {
              if (!isNull(blob)) {
                resolve(blob);
              } else {
                reject();
              }
            }, "image/jpeg");
          };
          img.src = image;
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  };

  const cropSetted: boolean =
    !isUndefined(crop.width) && !isUndefined(crop.height) ? crop.width * crop.height > 0 : false;

  return talentId || clientId ? (
    <div className="ImageCropContainer">
      <label htmlFor="profile_image">Image</label>
      {original !== "" && (
        <div className="wide">
          <ReactCrop src={original} crop={crop} onChange={(n) => setCrop(n)} onImageLoaded={onLoad} />
        </div>
      )}
      <div className="p-formgrid grid">
        <div className="field col">
          <button className="p-button p-button-text-icon-left" onClick={() => inputEl.current.click()} type="button">
            {original === "" ? "Select image" : "Use Other Image"}
          </button>
        </div>
        <div className="field col">
          {cropSetted && (
            <button onClick={saveImage} className="p-button p-button-text-icon-left">
              Update image
            </button>
          )}
        </div>
      </div>
      <input className="hidden" ref={inputEl} type="file" onChange={selectImage} />
    </div>
  ) : null;
}
