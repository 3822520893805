import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import "./UserManagementForm.scss";
import { usePermissionContext } from "~/contexts/PermissionContext";

import Loader from "~/components/Loader/Loader";
import cn from "classnames";
import useClientUsers from "~/hooks/useClientUsers";
import ApRadioGroup, { IRadioOption } from "~/components/common/ApRadioGroup/ApRadioGroup";
import ApMultiCheckbox, { IOption } from "~/components/common/ApMultiCheckbox/ApMultiCheckbox";
import { processingRoles } from "~/utils";

interface Props {
  edit?: boolean;
  type?: "client" | "talent";
}

function UserManagementForm(props: Props) {
  const { roles: allRoles, getRoles, isFetchingRoles } = usePermissionContext();
  const [filteredRoles, setFilteredRoles] = useState<{ main: IRadioOption[]; additional: IOption[] }>({
    main: [],
    additional: [],
  });

  const { validateEmail } = useClientUsers();

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const { main, additional } = processingRoles(allRoles);

    setFilteredRoles({ main, additional });
  }, [allRoles]);

  return (
    <div
      className={cn("UserManagementForm", { UserManagementForm_client: props.type === "client" && !isFetchingRoles })}
    >
      <div className="UserManagementForm__col">
        <div className="p-fluid field field-required">
          <label htmlFor="first_name">First name</label>
          <ApInputText id="first_name" type="text" />
        </div>
        <div className="p-fluid field field-required">
          <label htmlFor="last_name">Last name</label>
          <ApInputText id="last_name" type="text" />
        </div>
        <div className="p-fluid field field-required">
          <label htmlFor="email">E-mail</label>
          <ApInputText
            id="email"
            type="email"
            disabled={props.edit}
            validate={props.edit ? undefined : validateEmail}
          />
        </div>
        <div className="p-fluid field field-required">
          <label htmlFor="telephone">Telephone</label>
          <ApInputText id="telephone" />
        </div>
      </div>
      {props.type === "client" && (
        <div className="UserManagementForm__col">
          <div className="p-fluid field">
            {isFetchingRoles ? (
              <Loader size={2} />
            ) : (
              <>
                <label htmlFor="role">Main role</label>
                <ApRadioGroup name="role" options={filteredRoles.main} />
                <label htmlFor="additional_roles">Additional role</label>
                <ApMultiCheckbox id="additional_roles" options={filteredRoles.additional} selectedProp="code" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(UserManagementForm);
