import * as Yup from "yup";
import { maxPhoneMessage, maxSymbolsMessage, minPhoneMessage, minSymbolsMessage, requiredFieldMessage } from "../utils";

export const TalentPersonalYouSchema = Yup.object().shape({
  residence_address_1: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage),
  residence_address_2: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage),
  residence_city: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage),
  first_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  languages: Yup.string().max(100, maxSymbolsMessage),
  last_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  residence_postcode: Yup.string().min(3, minSymbolsMessage).max(16, maxSymbolsMessage),
  telephone: Yup.string().min(10, minPhoneMessage).max(15, maxPhoneMessage).required(requiredFieldMessage),
  uk_work_permit: Yup.boolean(),
  uk_work_verification: Yup.string().when("uk_work_permit", {
    is: true,
    then: Yup.string().oneOf(["PASSPORT", "VISA"]),
  }),
  uk_visa_code: Yup.string().when(["uk_work_permit", "uk_work_verification"], {
    is: (uk_work_permit, uk_work_verification) => uk_work_permit && uk_work_verification === "VISA",
    then: Yup.string().max(100, maxSymbolsMessage),
  }),
  uk_visa_expire_at: Yup.string()
    .nullable()
    .when(["uk_work_permit", "uk_work_verification"], {
      is: (uk_work_permit, uk_work_verification) => uk_work_permit && uk_work_verification === "VISA",
      then: Yup.string(),
    }),
  country_by_passport: Yup.string().when("uk_work_permit", {
    is: true,
    then: Yup.string(),
  }),
  country_of_residence: Yup.string(),
});

export const ClientPersonalYouSchema = Yup.object().shape({
  first_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  last_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  telephone: Yup.string().min(10, minPhoneMessage).max(15, maxPhoneMessage).required(requiredFieldMessage),
  department: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage),
  role: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage),
});
