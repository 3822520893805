import { useState } from "react";
import * as permissionType from "~/constants";
import { IPermissions } from "~/contexts/PermissionContext";
import { camelCase } from "lodash";
import { ClientUsersService } from "~/API/ClientUsersService";
import Toasts, { TOAST_ERROR_MESSAGE } from "~/store/constants/toasts";
import { useDispatch } from "react-redux";

const usePermission = () => {
  const [permissions, setPermissions] = useState<null | { [key: string]: string }>(null);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  const savePermissions = async (res: any) => {
    const permissions = Object.entries(permissionType).reduce(
      (acc: { [key: string]: string }, [key, val]: [string, string]) => {
        res.forEach((item: string) => {
          const itemFormated = camelCase(item);

          if (itemFormated === val) {
            acc[val] = key;
          }
        });
        return acc;
      },
      {}
    );

    setPermissions(permissions);
  };

  const userAccess = (...perms: string[]) => (permissions ? !!perms.some((perm) => permissions[perm]) : false);

  const resetPermissions = () => setPermissions(null);

  const canEditMatches = () =>
    userAccess(permissionType.editOwnClientMatch, permissionType.editAnyMatch, permissionType.editOwnUserMatch);

  const checkIsAdmin = () => userAccess(permissionType.accessAdmin);
  const checkIsClientAdmin = () => userAccess(permissionType.accessClientAdmin);
  const canOwnPaymentData = userAccess(permissionType.getOwnClientPaymentData);
  const canGetOwnInvoice = userAccess(permissionType.getOwnInvoice);

  const getRoles = async () => {
    setIsFetchingRoles(true);
    try {
      const { data: roles }: { data: [] } = await ClientUsersService.getRoles(userAccess(permissionType.accessAdmin));

      setRoles(roles);
      setIsFetchingRoles(false);
    } catch (e) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  return {
    permissions,
    roles,
    isFetchingRoles,
    getRoles,
    savePermissions,
    resetPermissions,
    userAccess,
    canEditMatches,
    checkIsAdmin,
    checkIsClientAdmin,
    canOwnPaymentData,
    canGetOwnInvoice,
  } as IPermissions;
};
export default usePermission;
