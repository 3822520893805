import cn from "classnames";
import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import React, { useEffect, useMemo } from "react";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import Loader from "~/components/Loader/Loader";
import { useGlobalContext } from "~/contexts/GlobalContext";
import useClientUsers from "~/hooks/useClientUsers";
import { ClientPersonalYouSchema } from "~/schemas/PersonalYouSchema";
import "./PersonalYouForm.scss";

interface Props {
  userId: number;
  isEditMode: boolean;
  updateUserList: () => void;
}

export default function ClientPersonalYouForm(props: Props) {
  const { userId, isEditMode } = props;
  const { isUserDataFetching: isFetching, userData, getUserById, updateUserById } = useClientUsers();
  const {
    global: { globalOptions },
  } = useGlobalContext();

  const disciplines = useMemo(
    () =>
      globalOptions?.disciplines.map(({ name: label, id }: { name: string; id: number }) => ({
        id,
        label,
      })),
    [globalOptions?.disciplines]
  );

  useEffect(() => {
    getUserById(userId);
  }, [userId]);

  const onSubmit = async (data: any) => {
    await updateUserById(userId, data);
    await props.updateUserList();
  };

  if (!userData) {
    return <Loader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name: userData?.first_name || "",
        last_name: userData?.last_name || "",
        telephone: userData?.telephone || "",
        disciplines: userData?.disciplines.map(({ id }) => id) || [],
        role: userData?.client_personal_profile?.role || "",
      }}
      onSubmit={onSubmit}
      validationSchema={ClientPersonalYouSchema}
    >
      {({ values }) => (
        <Form className={cn("PersonalYouForm p-fluid", { "readonly-form": !isEditMode })}>
          <div className="field grid field-required">
            <label className="col-12 md:col-4" htmlFor="first_name">
              First Name
            </label>
            <div className="col-12 md:col-8">
              {isEditMode ? (
                <ApInputText id="first_name" required />
              ) : (
                <div className="readonly-text">{values.first_name}</div>
              )}
            </div>
          </div>
          <div className="field grid field-required">
            <label className="col-12 md:col-4" htmlFor="last_name">
              Last Name
            </label>
            <div className="col-12 md:col-8">
              {isEditMode ? (
                <ApInputText id="last_name" required />
              ) : (
                <div className="readonly-text">{values.last_name}</div>
              )}
            </div>
          </div>
          <div className="field grid field-required">
            <label className="col-12 md:col-4" htmlFor="telephone">
              Email
            </label>
            <div className="col-12 md:col-8">
              {isEditMode ? (
                <ApInputText disabled id="email" type="email" required value={userData?.email} />
              ) : (
                <div className="readonly-text">{userData?.email}</div>
              )}
            </div>
          </div>
          <div className="field grid field-required">
            <label className="col-12 md:col-4" htmlFor="telephone">
              Telephone
            </label>
            <div className="col-12 md:col-8">
              {isEditMode ? (
                <ApInputText id="telephone" required />
              ) : (
                <div className="readonly-text">{values.telephone}</div>
              )}
            </div>
          </div>
          <div className="field grid">
            <label className="col-12 md:col-4" htmlFor="disciplines">
              Disciplines
            </label>
            <div className="col-12 md:col-8">
              {isEditMode ? (
                <ApMultiselect id="disciplines" options={disciplines} optionValue="id" />
              ) : (
                <div className="readonly-text">{userData?.disciplines.map(({ name }) => name).join(", ") || "-"}</div>
              )}
            </div>
          </div>
          <div className="field grid">
            <label className="col-12 md:col-4" htmlFor="role">
              Role
            </label>
            <div className="col-12 md:col-8">
              {isEditMode ? (
                <ApInputText id="role" required />
              ) : (
                <div className="readonly-text">{values.role || "-"}</div>
              )}
            </div>
          </div>
          {isEditMode && (
            <div className="p-field">
              <Button disabled={isFetching} loading={isFetching} type="submit" label="Save" />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
