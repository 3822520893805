import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import ModalRoot from "../../modals";
import routes from "../../routes";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import { isLocalStorageAvailable, setAuthToken } from "../../utils";
import PrivateRoute from "../common/PrivateRoute";
import LayoutHeader from "../LayoutHeader/LayoutHeader";
import Popup from "../Toaster/Popup";
import Toaster from "../Toaster/Toaster";
import { Props, StateProps } from "./App.d";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import "./theme-override.scss";
import "./App.scss";
import AppContext from "~/routes/AppContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import PageNotFound from "../common/PageNotFound/PageNotFound";
import Alerter from "../Alerter/Alerter";
import { IRoute } from "~/interfaces/routes";
import toasts from "~/store/actions/toasts";
import allSettled from "promise.allsettled";
import { AxiosError, AxiosResponse } from "axios";
import { LOGIN_URL } from "~/config";
import { useBriefContext } from "~/contexts/BriefContext";
import { useTalentContext } from "~/contexts/TalentContext";
import { useClientContext } from "~/contexts/ClientContext";
import LoadingAnimation from "../common/LoadingAnimation";
import { useLoadingAnimationContext } from "~/contexts/LoadingAnimationContext";
import UserPopup from "../UserPopup/UserPopup";

const mapDispatchToProps = {
  setNotAuthenticated: actions.user.setNotAuthenticated,
  verifyToken: actions.user.verifyToken,
};

const mapStateToProps = (state: IState): StateProps => ({
  location: state.location,
});

const App = (props: Props) => {
  const dispatch = useDispatch();
  const {
    global: { getGlobalOptions },
  } = useGlobalContext();
  const { resetFilterBriefsSettings } = useBriefContext();
  const { isShowStepperAnimation } = useLoadingAnimationContext();
  const { resetFilterTalentSettings } = useTalentContext();
  const { resetFilterClientSettings } = useClientContext();
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState(null as null | string);
  const [previousPath, setPreviousPath] = useState(null as null | string);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
  }, [location.pathname]);

  useLayoutEffect(() => {
    resetFilterBriefsSettings(currentPath);
    resetFilterTalentSettings(currentPath);
    resetFilterClientSettings(currentPath);
  }, [currentPath, previousPath]);

  const { userAccess, permissions: statePermissions, savePermissions } = usePermissionContext();
  const { authenticatedUser: user, isAuthenticating, isAuthenticated } = useSelector((state: IState) => state.user);
  const isUserReady = user && isAuthenticated && !isAuthenticating;
  const isAppReady = (!user && !localStorage.getItem("user")) || isUserReady;
  const isAdmin = userAccess(permissionType.accessAdmin);
  const isClientAdmin = userAccess(permissionType.accessClientAdmin);
  const { otp } = Object.fromEntries(new URLSearchParams(props.location.search)) as { otp: string };

  useEffect(() => {
    const permissions = localStorage.getItem("permissions");
    permissions && !statePermissions && savePermissions(permissions.split(","));
  }, [isUserReady]);

  useEffect(() => {
    const userNotificationPreference =
      user?.client_personal_profile?.preference === false || user?.client_personal_profile?.preference === null;

    const phBriefsFeature =
      user?.client_personal_profile?.ph_briefs_notified === false ||
      user?.client_personal_profile?.ph_briefs_notified === null;

    const hideModalNotificationCallback = () =>
      user?.id && dispatch(actions.client.updateClientPersonalProfile(user?.id, { preference: true }));

    const hideModalPhBriefsCallback = () =>
      user?.id && dispatch(actions.client.updateClientPersonalProfile(user?.id, { ph_briefs_notified: true }));

    if (location.pathname !== "/change-password" && isClientAdmin) {
      if (userNotificationPreference) {
        dispatch(
          toasts.setPopup({
            content: (
              <>
                <h2>Notification preferences</h2>
                You can now update your notification preferences from dropdown menu under your avatar! Click on Settings
                below to be redirected.
              </>
            ),
            buttons: [
              {
                text: "Dismiss",
                callback: hideModalNotificationCallback,
              },
              {
                text: "Settings",
                callback: () => {
                  hideModalNotificationCallback();
                  history.push("/notification-preferences");
                },
              },
            ],
          })
        );
      }

      if (phBriefsFeature && !userNotificationPreference) {
        dispatch(
          toasts.setPopup({
            content: (
              <>
                <h2>New feature</h2>
                You can now create <strong>Permanent Briefs</strong>.{" "}
                <a href="https://www.meetgenie.co/knowledge-base-2/setting-a-permanent-brief/" target={"_blank"}>
                  Click here
                </a>{" "}
                to learn about how to create the best permanent briefs or dive straight in by clicking Create New Brief
                below.
              </>
            ),
            buttons: [
              {
                text: "Dismiss",
                callback: hideModalPhBriefsCallback,
              },
              {
                text: "Create New Brief",
                callback: () => {
                  hideModalPhBriefsCallback();
                  history.push("/briefs/new");
                },
              },
            ],
          })
        );
      }
    }
  }, [isAppReady, isClientAdmin]);

  useEffect(() => {
    // Turn off any logging when not in debug mode
    const isDebug = Object.fromEntries(new URLSearchParams(props.location.search));
    if (isDebug.debug !== "true") {
      window.console.log = () => {};
      window.console.group = () => {};
      window.console.groupEnd = () => {};
      window.console.groupCollapsed = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};
    }

    // Take token from storage if exist
    if (isLocalStorageAvailable() && !otp) {
      const token = localStorage.getItem("user");
      if (token && localStorage.getItem("permissions")) {
        setAuthToken(token);
        // Verify existing token to retrieve the session
        props.verifyToken(token).catch((err) => {
          (err.response?.status === 401 || err.response.status === 403) && logoutUser();
        });
      } else {
        token && logoutUser();
      }
    }

    if (!Promise.allSettled) {
      Promise.allSettled = allSettled;
    }
  }, []);

  useEffect(() => {
    if (isUserReady) {
      getGlobalOptions();

      if (user?.id && isClientAdmin) {
        dispatch(actions.talent.getTalentRebook(user.id, isAdmin, isClientAdmin));
      }
    }
  }, [user?.id]);

  const savePermissionsToStorage = (res: { data: { permissions: string } }) => {
    const { permissions } = res.data;
    localStorage.setItem("permissions", permissions);
  };

  useEffect(() => {
    if (otp) {
      localStorage.removeItem("user");
      localStorage.removeItem("permissions");
      dispatch(actions.user.loginUserByOtp(otp))
        .then((res: AxiosResponse) => {
          if (res?.status === 200) {
            savePermissionsToStorage(res);
            props.history.push(localStorage.getItem("origin") || "/");
            localStorage.removeItem("origin");
            dispatch(actions.user.verifyToken());
          }
        })
        .catch((err: AxiosError) => {
          location.href = LOGIN_URL;
        });
    }
  }, []);

  function logoutUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    setAuthToken("");
    props.setNotAuthenticated();
    props.history.push("/login");
  }

  const canAccess = (route: IRoute): boolean =>
    route.accessPermissions ? route.accessPermissions.some((perm) => userAccess(perm)) : true;

  if (!!otp && isAppReady) {
    return null;
  }

  return (
    <main className="App">
      <LayoutHeader />
      {isAppReady && (
        <Switch location={props.location}>
          {routes().map((route, i: number) => {
            if (!canAccess(route)) {
              return null;
            }
            return route.protected ? (
              <PrivateRoute key={i} {...route} />
            ) : (
              <Route exact={route.exact} key={i} {...route} />
            );
          })}
          <Route component={PageNotFound} />
        </Switch>
      )}
      {isShowStepperAnimation && <LoadingAnimation />}
      {isUserReady && isClientAdmin && !isAdmin && <UserPopup />}
      <ModalRoot />
      <Toaster />
      <Alerter />
      <Popup />
    </main>
  );
};
const APP = (props: any) => {
  return (
    <AppContext>
      <App {...props} />
    </AppContext>
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(APP));
