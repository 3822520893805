import cn from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "~/components/Loader/Loader";
import actions from "~/store/actions";
import { IState } from "~/store/reducers/index";
import { IRole, usePermissionContext } from "~/contexts/PermissionContext";
import "./ChangeRoleModal.scss";
import { Formik, Form } from "formik";
import ApMultiCheckbox, { IOption } from "~/components/common/ApMultiCheckbox/ApMultiCheckbox";
import ApRadioGroup, { IRadioOption } from "~/components/common/ApRadioGroup/ApRadioGroup";
import { processingRoles, processingUserRoles } from "~/utils";

export default function SelectTalentModal() {
  const dispatch = useDispatch();
  const { roles, getRoles, isFetchingRoles } = usePermissionContext();
  const [filteredRoles, setFilteredRoles] = useState<{ main: IRadioOption[]; additional: IOption[] }>({
    main: [],
    additional: [],
  });
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { onAttachRole, onDetachRole, user } = modalProps || {};

  const activeRoles = useMemo(() => processingUserRoles(user?.roles ?? []), [user?.roles]);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const { main, additional } = processingRoles(roles);

    setFilteredRoles({ main, additional });
  }, [roles]);

  return (
    <Dialog
      header="Change role"
      className={cn("ChangeRoleModal", { loading: isFetchingRoles })}
      onHide={() => {
        dispatch(actions.modal.closeModal());
      }}
      footer={
        <Button
          label="Close"
          onClick={() => {
            dispatch(actions.modal.closeModal());
          }}
        />
      }
      modal
      visible
    >
      {isFetchingRoles ? (
        <Loader size={2} />
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            role: activeRoles.main,
            additional_roles: activeRoles.additional,
          }}
        >
          {(formik: any) => (
            <Form>
              <div className="grid">
                <div className="col-6">
                  <h3>Main roles</h3>
                  <ApRadioGroup
                    formik={formik}
                    name="role"
                    noField
                    options={filteredRoles.main}
                    onChange={async ({ value }) => {
                      formik.setFieldValue("role", value);
                      await onAttachRole(value);
                    }}
                  />
                </div>
                <div className="col-6">
                  <h3>Additional roles</h3>
                  <ApMultiCheckbox
                    id="additional_roles"
                    options={filteredRoles.additional}
                    formik={formik}
                    noField
                    selectedProp="code"
                    onUpdate={({ value, checked }) => (checked ? onAttachRole(value) : onDetachRole(value))}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
