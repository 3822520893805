@import "../../../mixins.scss";

.ViewToggler {
  display: inline-block;
  padding-bottom: 3px;
  line-height: 44px;

  label {
    font-weight: 700;
    margin-right: 15px;
  }

  .p-button {
    &.with-icon {
      padding: 9px !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &:hover {
        border-color: transparent !important;
      }

      svg {
        color: #8e8e8e;
      }

      &.active {
        background-color: #f1f5f5 !important;

        svg {
          color: #111;
        }
      }

      &.red {
        background-color: $brandColor !important;

        svg {
          color: #fff;
        }
      }
    }
  }
}
