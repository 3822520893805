import React, { createContext, useContext } from "react";
import useInterview from "~/hooks/useInterview";
import { IInterview } from "~/interfaces/Interview";

export interface IInterviewContext {
  isLoading: boolean;
  interview: IInterview | null;
  interviews: IInterview[] | [];
  attachmentsError: string[];
  setInterview: (interview: IInterview | null) => void;
  getInterviews: (matchId: number) => void;
  updateInterview: (interviewId: number, data: IInterview, cb?: Function) => void;
  addInterview: (matchId: number, data: IInterview, cb?: Function) => void;
  sendInterviewNotification: (interviewId: number) => void;
}

export const InterviewContext = createContext(null as IInterviewContext | null);

export const useInterviewContext = () => {
  const context = useContext(InterviewContext);
  if (!context) {
    throw new Error("useInterviewContext must be used within a InterviewContextProvider");
  }
  return context;
};

const InterviewContextProvider = ({ children }: any) => {
  return (
    <InterviewContext.Provider
      value={{
        ...useInterview(),
      }}
    >
      {children}
    </InterviewContext.Provider>
  );
};

export default InterviewContextProvider;
