@import "../../mixins";

.ir35_checker {
  li {
    .p-buttonset {
      .p-button {
        .p-button-label {
          font-size: medium;
          font-weight: 700;
        }
        border-radius: 10px !important;
        height: 40px;
        margin: 0 5px;
        background-color: $lightRedColor;
        color: $brandColor;
        &.p-highlight {
          background-color: $brandColor;
          color: #fff;
        }
      }
    }
  }
}
