import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ApCalendar from "~/components/common/ApCalendar/ApCalendar";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import { BriefMatchingInterviewSchema } from "../../schemas/BriefMatchingSchema";
import { IMatching } from "../../interfaces/brief";
import { IState } from "~/store/reducers/index";
import useClientUsers from "~/hooks/useClientUsers";
import "./InterviewModal.scss";
import { IUserForm } from "~/store/constants/user";
import _ from "lodash";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { extractFileName, validateFile } from "~/utils";
import ApFileUpload from "~/components/common/ApFileUpload/ApFileUpload";
import { ProgressBar } from "primereact/progressbar";
import { useInterviewContext } from "~/contexts/InterviewContext";
import { IAttachment } from "~/interfaces/Interview";
import Attendees, { IAttendee } from "~/components/common/Attendees/Attendees";
import ApInputText from "~/components/common/ApInputText/ApInputText";

export default function InterviewModal() {
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { clientUsers, getClientUsers } = useClientUsers();
  const [activeUsers, setActiveUsers] = useState<IUserForm[] | undefined>();
  const formikRef = useRef<FormikProps<any>>();
  const { attachmentsError, interview, isLoading } = useInterviewContext();
  const [clientAttendees, setClientAttendees] = useState<IAttendee[]>([]);

  useEffect(() => {
    getClientUsers(modalProps.clientId);
  }, []);

  useEffect(() => {
    interview?.attachments && formikRef?.current?.setFieldValue("attachments", [...interview.attachments]);
  }, [interview]);

  useEffect(() => {
    const filteredUsers = clientUsers?.filter((user) => !!user.published);
    setActiveUsers(filteredUsers);
  }, [clientUsers]);

  function onSubmit(matching: IMatching) {
    matching.attendees = [...matching?.custom_attendees, ...clientAttendees.filter(({ active }: IAttendee) => active)];

    if (modalProps?.onSubmit) {
      modalProps.onSubmit(matching);
    }

    matching.attachments_to_remove = [];
  }

  useEffect(() => {
    const activeUsers: IAttendee[] = clientUsers
      ?.filter((user) => !!user.published)
      .map(({ full_name: name, email, id }) => ({
        name,
        email,
        id,
        disabled: id === formikRef?.current?.values?.organizer_id,
      }));

    const clientAttendees =
      modalProps.matching?.attendees
        ?.filter((attendee: IAttendee) => attendee?.name) // get attendees which is clients
        .map((attendee: IAttendee) => ({ ...attendee, active: true })) || [];

    const userClientsAttendees = activeUsers?.map((attendee) => ({
      ...attendee,
      active: _.find(clientAttendees, { email: attendee.email }) || false, // if user client is selected as additional attendee
    }));

    setClientAttendees(
      _.unionBy(userClientsAttendees, clientAttendees, "email") // union of user clients and other clients in Genie
    );
  }, [clientUsers]);

  function onUploadAttachment(files: File[]) {
    let filesErrors: string[] = [];
    let validFiles: File[] = [];

    files.forEach((file: File) => {
      const errors = validateFile(file, {
        size: 10000000,
        types: ["doc", "docx", "pdf", "xls", "xlsx"],
      });

      let isAlreadyExists = formikRef.current?.values.attachments.find((attachment: IAttachment | File) => {
        if (attachment instanceof File) {
          return attachment.name === file.name;
        }
        return extractFileName(attachment.url) === file.name;
      });

      if (!!isAlreadyExists) {
        errors.push(`File already exists`);
      }

      if (errors.length) {
        filesErrors.push(`${file.name}: ${errors.join(", ")}`);
      } else {
        validFiles.push(file);
      }
    });

    formikRef.current?.setFieldValue("attachments", [...formikRef.current?.values.attachments, ...validFiles], false);

    filesErrors && formikRef.current?.setFieldError("attachments", filesErrors.join("; "));
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        date: modalProps.matching?.date || moment().add(1, "h").minutes(0).seconds(0).toDate(),
        medium: modalProps.matching?.medium || undefined,
        organizer_id: modalProps.matching?.organizer_id || "",
        attendees: modalProps.matching?.attendees || [],
        custom_attendees: modalProps.matching?.attendees?.filter((attendee: IAttendee) => !attendee.name) || [],
        meeting_url: modalProps.matching?.meeting_url || "",
        information: modalProps.matching?.information || "",
        attachments: modalProps.matching?.attachments || [],
        attachments_to_remove: [],
      }}
      validationSchema={BriefMatchingInterviewSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Dialog
            className="InterviewModal"
            header="Set an Interview"
            footer={
              <footer>
                {modalProps.user && (
                  <Button
                    className="p-button-secondary"
                    icon="pi pi-trash"
                    label="Remove"
                    onClick={modalProps.onRemove}
                    disabled={isLoading}
                  />
                )}
                <Button
                  label="Save &amp; Notify"
                  type="submit"
                  icon="pi pi-check"
                  onClick={() => formik.submitForm()}
                  disabled={isLoading}
                />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            <div className="grid">
              <div className="col col-12 md:col-6 col">
                <div className="field field-required">
                  <label htmlFor="organizer_id">Interview Organiser</label> <br />
                  <ApDropdown
                    appendTo="self"
                    disabled={!activeUsers}
                    scrollHeight="320px"
                    id="organizer_id"
                    options={activeUsers}
                    optionLabel="full_name"
                    optionValue="id"
                    placeholder="Choose Interview Organiser"
                    onChange={(e) => {
                      const organizerIdValue = e.value;
                      formik.setFieldValue("organizer_id", organizerIdValue);

                      setClientAttendees(
                        clientAttendees.map((attendee) => ({
                          ...attendee,
                          disabled: organizerIdValue === attendee.id,
                        }))
                      );
                    }}
                  />
                </div>
                <Attendees
                  clientAttendees={clientAttendees}
                  setClientAttendees={setClientAttendees}
                  formik={formik}
                  label="Additional Attendees"
                  placeholder="Type an email and press Enter or ,"
                />
                <div className="field field-required">
                  <label>Interview type</label> <br />
                  <ApRadioGroup
                    name="medium"
                    options={[
                      { name: "Video call", code: "TYPE_VIDEO_CALL" },
                      { name: "Phone call", code: "TYPE_PHONE_CALL" },
                      { name: "In person", code: "TYPE_IN_PERSON" },
                    ]}
                    onChange={({ value }) => {
                      value !== "TYPE_VIDEO_CALL" && formik.setFieldValue("meeting_url", "");
                      formik.setFieldValue("medium", value);
                    }}
                  />
                </div>
                {formik.values.medium === "TYPE_VIDEO_CALL" && (
                  <div className="field">
                    <label>Meeting Link/URL</label>
                    <ApInputText id="meeting_url" />
                  </div>
                )}
              </div>
              <div className="col col-12 md:col-6">
                <div className="field field-required">
                  <label>Date</label> <br />
                  <ApCalendar
                    appendTo="self"
                    dateFormat="dd/mm/yy"
                    minDate={moment().add(1, "h").minutes(0).seconds(0).toDate()}
                    hourFormat="24"
                    stepMinute={15}
                    id="date"
                    showTime
                    useRawValue
                    showIcon
                  />
                </div>
                <div className="field">
                  <label>Additional information</label>{" "}
                  <PopupIcon
                    content={
                      <>
                        Please add any relevant information you would like to share with the talent in preparation for
                        their interview. <br />
                        Topics for the interview, a case study, a piece of work you wish to discuss etc.
                      </>
                    }
                  />
                  <br />
                  <ApInputTextarea
                    id="information"
                    placeholder="Details about what talent can expect in the interview"
                    maxChars={600}
                  />
                </div>
                <div className="field">
                  <label>Attachments</label> <br />
                  {isLoading && formik.values?.attachments?.length > 0 && <ProgressBar mode="indeterminate" />}
                  <ApFileUpload
                    id="attachments"
                    name="attachments"
                    mode="basic"
                    auto
                    multiple
                    formik={formik}
                    noField
                    accept=".doc,.docx,.pdf,.xls,.xlsx"
                    disabled={isLoading}
                    onChange={(files) => {
                      onUploadAttachment(files);
                    }}
                    chooseLabel="Add attachment"
                    onDelete={(file: IAttachment | File, id: number) => {
                      if (_.isObject(file)) {
                        formik.setFieldValue("attachments_to_remove", [
                          ...formik.values.attachments_to_remove,
                          file.id,
                        ]);
                      }

                      formik.setFieldValue("attachments", [
                        ...formik.values.attachments.filter((_file: IAttachment | File, i: number) => i !== id),
                      ]);
                    }}
                  />
                  {attachmentsError &&
                    attachmentsError.map((err, index) => (
                      <div className="ap-error" key={index}>
                        {err}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
