@import "../../mixins.scss";

.UsersListForm {
  &__user {
    background: #fff;
    border: solid 2px transparent;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;
    padding: 1em;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }

    &.active {
      border-color: $brandColor;
    }

    h3 {
      margin: 0 0 1em;
    }

    .p-button {
      position: absolute;
      right: 1em;
      top: 1em;
    }

    &.talent-list,
    &.client-list {
      .toggle-publish-button.p-button {
        top: auto;
        bottom: 7px;
        right: 20px;
        min-width: 162px;

        .centered {
          margin: auto;
        }
      }
    }

    &.client-list {
      padding-bottom: 75px;
    }

    .send-message {
      float: right;
    }

    .client-admin-switch {
      display: flex;
      float: right;
      align-items: flex-end;
      flex-direction: column;
      margin-right: 6px;

      > .p-disabled {
        > .p-inputswitch-slider {
          background: $brandColor;
        }
      }

      .p-button {
        position: static;
      }
    }

    .p-button-raised + .client-admin-switch {
      margin-right: 13px;
    }

    .label {
      margin: 0 0 0.5em;
    }

    div > .pi {
      color: #848484;
      font-size: 0.8em;
      margin-right: 1em;
    }
  }

  &__addUser {
    border: dashed 2px #ccc;
    border-radius: 10px;
    height: 100%;
    margin-bottom: 1em;
    min-height: 100px;
    position: relative;
    text-align: center;
    margin-top: 10px;

    > .p-button {
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
