import React from "react";
import { Briefcase, FileArrowDown } from "phosphor-react";
import { Button } from "primereact/button";

export default function BottomLinkButtons({
  portfolio_url,
  cv_url,
}: {
  portfolio_url: string | null;
  cv_url: string | null;
}) {
  return (
    <>
      {!!portfolio_url && (
        <Button
          className="card-actions__link"
          onClick={() => window.open(portfolio_url, "_blank")}
          tooltip="View portfolio"
          icon={<Briefcase size={23} />}
        />
      )}
      {!!cv_url && (
        <Button
          className="card-actions__link"
          onClick={() => window.open(cv_url, "_blank")}
          tooltip="Download CV"
          icon={<FileArrowDown size={23} />}
        />
      )}
    </>
  );
}
