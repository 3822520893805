@import "../../../mixins.scss";

.tags-table {
  display: flex;
  flex-direction: column;
  &[disabled] {
    .p-checkbox {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .p-datatable-thead {
    display: none;
  }
  td {
    padding: 5px !important;
  }
  .p-tag {
    display: flex;
    text-align: center;
  }
  .tag-edit {
    width: 45px;
    text-align: right;
  }
  .p-datatable-table {
    table-layout: fixed !important;
    width: 100%;
  }
  .p-datatable-tbody {
    tr.p-highlight {
      background-color: initial !important;

      .p-button-icon .pi {
        color: $brandColor;
      }
    }
  }
  .p-datatable-footer {
    text-align: center;
    padding: 0.571rem 0;
    .p-button {
      margin: auto;
      width: 100%;
    }
  }
}
