import React, { createContext, useContext } from "react";
import useClientFilterSettings from "~/hooks/useClientFilterSettings";
import { IClientFilterSettings } from "~/store/reducers/client";

export const ClientContext = createContext(null as IClientFilterSettings | null);

export const useClientContext = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientContextProvider");
  }
  return context;
};

const ClientContextProvider = ({ children }: any) => {
  return (
    <ClientContext.Provider
      value={{
        ...useClientFilterSettings(),
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContextProvider;
