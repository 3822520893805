import React, { useEffect } from "react";
import { FormikContextType } from "formik";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import { useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import { genieDetailsMaxInputLength } from "~/schemas/common";
import { useBriefContext } from "~/contexts/BriefContext";
import "../../CreateBriefForm/CreateBriefForm.scss";
import { checkBriefForDraft } from "~/routes/Brief/helper";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { usePermissionContext } from "~/contexts/PermissionContext";

interface ICompanyBenefits {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
  isBenefitsFromBriefProfile?: boolean;
  isCreationBriefProcess?: boolean;
}
const exampleTitle = "Here's an Example:";

const popupContent = (
  <span>
    {exampleTitle}
    <br />
    <br />
    <em>
      Holidays & time-off,
      <br />
      Pension plans,
      <br />
      Examples include:
      <br />
      Tacos Tuesdays
      <br />
      Tech Talks
      <br />
      Quiz nights
      <br />
      Awards
      <br />
      Directors Showcase
      <br />
      And more
    </em>
  </span>
);

const placeholder = `Your basic company benefits are in your company profile settings. Remember to update this section with any specific benefits to this brief and to include the softer stuff. That can make a big difference

${exampleTitle}

Holidays & time-off,
Pension plans,
Examples include:
Tacos Tuesdays
Tech Talks
Quiz nights
Awards
Directors Showcase
And more`;

const multilinePlaceholderSafari = (
  <div>
    Your basic company benefits are in your company profile settings. Remember to update this section with any specific
    benefits to this brief and to include the softer stuff. That can make a big difference
    <br />
    <br />
    {exampleTitle}
    <br />
    <br />
    Holidays & time-off,
    <br />
    Pension plans,
    <br />
    Examples include:
    <br />
    Tacos Tuesdays
    <br />
    Tech Talks
    <br />
    Quiz nights
    <br />
    Awards
    <br />
    Directors Showcase
    <br />
    And more
  </div>
);

export default function CompanyBenefits(props: ICompanyBenefits) {
  const { formik, isViewOnly = false, isBenefitsFromBriefProfile = false, isCreationBriefProcess = false } = props;
  const { brief } = useBriefContext();
  const client = isBenefitsFromBriefProfile ? brief?.client : useSelector((state: IState) => state.client.client);
  const companyBenefits = formik.values.company_benefits;
  const companyBenefitsProfile = client?.work_profile?.company_benefits;
  const { checkIsClientAdmin } = usePermissionContext();
  const isClientAdmin = checkIsClientAdmin();

  const isBriefDraft = checkBriefForDraft(formik.values);
  useEffect(() => {
    if (!companyBenefits && !!companyBenefitsProfile && isCreationBriefProcess && !isBriefDraft && isClientAdmin) {
      formik.setFieldValue("company_benefits", companyBenefitsProfile);
    }
  }, []);
  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon popup={{ content: popupContent, buttons: [{ text: "Got It 🦾" }] }} />
    </>
  );

  return (
    <div className="p-fluid field">
      <label htmlFor="company_benefits">
        Company Benefits <InfoPopup />
      </label>
      <ApInputTextarea
        noField
        formik={formik}
        id="company_benefits"
        maxChars={genieDetailsMaxInputLength.company_benefits}
        readOnly={isViewOnly}
        placeholder={placeholder}
        multilinePlaceholderSafari={multilinePlaceholderSafari}
      />
    </div>
  );
}
