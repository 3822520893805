import * as Yup from "yup";
import { URL_REGEXP } from "~/config";
import { POPUP_VARIANTS } from "~/interfaces/popups";

export const CreatePopupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max")
    .required("Please, fill in required field"),
  variant: Yup.string().oneOf(POPUP_VARIANTS).required("Please, fill in required field"),
  is_available: Yup.boolean().required("Please, fill in required field"),
  order_id: Yup.number().nullable().positive().round("round").required("Please, fill in required field"),
  header: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max")
    .required("Please, fill in required field"),
  body: Yup.string().nullable().min(2, "Please, add at least 2 symbols").required("Please, fill in required field"),
  primary_button_text: Yup.string().nullable().min(2, "Please, add at least 2 symbols").max(255, "255 characters max"),
  primary_button_action: Yup.string()
    .nullable()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max")
    .matches(URL_REGEXP, "Please, enter URL in correct format"),
  secondary_button_text: Yup.string()
    .nullable()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max"),
  secondary_button_action: Yup.string()
    .nullable()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max")
    .matches(URL_REGEXP, "Please, enter URL in correct format"),
});
