import { routerReducer } from "react-router-redux";
import { combineReducers, Store } from "redux";
import reducers from "./reducers/index";

export const makeRootReducer = (asyncReducers?: {}) => {
  return combineReducers({
    ...reducers,
    router: routerReducer,
    ...asyncReducers,
  });
};

export const injectReducer = (
  store: Store & { [key: string]: any },
  { key, reducer }: { key: string; reducer: any }
) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
