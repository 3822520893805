import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import "./RemoveTalentModal.scss";

export default function RemoveTalentModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { onSubmit, talent } = modalProps || {};

  return (
    <Dialog
      header="Remove talent"
      className="RemoveTalentModal"
      onHide={() => {
        dispatch(actions.modal.closeModal());
      }}
      footer={
        <Button
          label="Confirm"
          onClick={() => {
            onSubmit(talent.id);
            dispatch(actions.modal.closeModal());
          }}
        />
      }
      modal
      visible
    >
      <p>Are you sure you want to remove {modalProps?.talent.name}?</p>
      <p>
        <strong>Warning! This action can not be reverted</strong>
      </p>
    </Dialog>
  );
}
