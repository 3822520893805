import React from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikValues } from "formik";
import "./ApDropdown.scss";

interface IDropdown {
  formik?: FormikProps<FormikValues>;
  noField?: boolean;
  id: string;
  dataKey?: string;
  disabled?: boolean;
  options: any;
  placeholder?: string;
  filter?: boolean;
  filterPlaceholder?: string;
  label?: string;
  className?: string;
  optionLabel?: string;
  optionValue?: string;
  onChange?: (e: DropdownChangeParams) => void;
  showClear?: boolean;
  filterBy?: string;
  scrollHeight?: string;
  dropdownIcon?: string;
}
export default function ApDropdown(props: IDropdown) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const { noField, ...rest } = props;

  const renderDropdown = (field: FieldInputProps<any>, meta: FieldMetaProps<any>) => (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <Dropdown {...field} {...rest} filterPlaceholder={props.filterPlaceholder || "Filter..."} inputId={props.id} />
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  );

  return noField && meta ? (
    renderDropdown(field as FieldInputProps<any>, meta)
  ) : (
    <Field name={props.id}>{({ field, meta }: any) => renderDropdown(field, meta)}</Field>
  );
}
