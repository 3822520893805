import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissionContext } from "~/contexts/PermissionContext";
import actions from "../../store/actions";
import toasts from "../../store/actions/toasts";
import { IState } from "../../store/reducers/index";
import * as permissionType from "~/constants";
import "./AmendBookingModal.scss";

export default function AmendBookingModal() {
  const dispatch = useDispatch();
  const { modalProps: booking } = useSelector((state: IState) => state.modal);
  const { id, start_date, end_date, talent_name } = booking;

  const [dates, setDates] = useState([new Date(start_date), new Date(end_date)]);
  const { userAccess } = usePermissionContext();
  const canPostOwnClientBooking = useMemo(() => userAccess(permissionType.postOwnClientBooking), []);

  function onSubmit() {
    const start_date = dates[0];
    const end_date = dates[1] ?? start_date;
    const bookingToAmend = {
      ...booking,
      related_booking_id: id,
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      status: "UPDATED",
    };

    if (!moment(booking.start_date).isSame(moment(start_date)) || !moment(booking.end_date).isSame(moment(end_date))) {
      dispatch(
        toasts.setPopup({
          content: (
            <>
              Ok, so to be clear you want the booking with&nbsp;
              <strong>{talent_name}</strong> <br />
              to now start on&nbsp;<strong>{moment(start_date).format("ddd DD MMM, YYYY")}</strong>&nbsp; and finish
              on&nbsp;<strong>{moment(end_date).format("ddd DD MMM, YYYY")}</strong>?
            </>
          ),
          buttons: [
            {
              text: "Go back",
            },
            {
              text: "Yes, go for it",
              callback: () => {
                dispatch(
                  actions.bookings.amendClientBooking(
                    bookingToAmend,
                    canPostOwnClientBooking,
                    userAccess(permissionType.accessClientAdmin),
                    userAccess(permissionType.accessAdmin)
                  )
                );
                dispatch(actions.modal.closeModal());
              },
            },
          ],
        })
      );
    } else {
      dispatch(actions.modal.closeModal());
    }
  }

  return (
    <Dialog
      className="AmendBookingModal"
      header={`Amend booking`}
      footer={
        <footer>
          <Button label="Save" type="submit" icon="pi pi-check" onClick={onSubmit} />
        </footer>
      }
      visible={true}
      modal={true}
      onHide={() => dispatch(actions.modal.closeModal())}
    >
      <Calendar inline selectionMode="range" onChange={(e) => setDates(e.value as Date[])} value={dates} />
    </Dialog>
  );
}
