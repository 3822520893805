import React from "react";
import { Link } from "react-router-dom";

interface IProps {
    opts: {
        user_id?: number;
        client_id?: number;
        talent_id?: number;
    }
}

export default function ApSendMessage(props: IProps) {
    return (
        <Link to={{
            pathname: "/contact",
            state: {
                user_id: props.opts.user_id,
                ...(props.opts.client_id && { client_id: props.opts.client_id }),
                ...(props.opts.talent_id && { talent_id: props.opts.talent_id }),
            }
        }} className='p-button-warning p-button-raised'><span className="pi pi-comment send-message" /></Link>
    );
};
