import { AxiosError, AxiosResponse } from "axios";
import { ITalent } from "./talent";
import { IBrief } from "~/interfaces/brief";

// ------------------------------------
// Constants
// ------------------------------------
export const CLIENT_BOOKINGS_LIST_FAILURE = "CLIENT_BOOKINGS_LIST_FAILURE";
export const CLIENT_BOOKINGS_LIST_REQUEST = "CLIENT_BOOKINGS_LIST_REQUEST";
export const CLIENT_BOOKINGS_LIST_SUCCESS = "CLIENT_BOOKINGS_LIST_SUCCESS";

export const CLIENT_BOOKINGS_POST_FAILURE = "CLIENT_BOOKINGS_POST_FAILURE";
export const CLIENT_BOOKINGS_POST_REQUEST = "CLIENT_BOOKINGS_POST_REQUEST";
export const CLIENT_BOOKINGS_POST_SUCCESS = "CLIENT_BOOKINGS_POST_SUCCESS";

export const CLIENT_BOOKINGS_PUT_FAILURE = "CLIENT_BOOKINGS_PUT_FAILURE";
export const CLIENT_BOOKINGS_PUT_REQUEST = "CLIENT_BOOKINGS_PUT_REQUEST";
export const CLIENT_BOOKINGS_PUT_SUCCESS = "CLIENT_BOOKINGS_PUT_SUCCESS";

// ------------------------------------
// Interfaces
// ------------------------------------
export interface IBooking {
  author: {
    id: number;
    email: string;
    name: string;
  };
  client_id: number;
  id: number;
  match_id: number;
  start_date: Date;
  end_date: Date;
  status: string;
  documents: IDocument[];
  talent: ITalent;
  brief: IBrief;
  brief_name?: string;
  brief_id?: number;
  talent_rate: number;
  talent_id?: number;
  type: string;
  contract_id: number;
  include_weekends: boolean;
  duration_days: number;
  duration_weekends: number;
  duration_id: number;
  duration_days_list?: string;
}

export type IDocument = {
  id: number;
  booking_id: number;
  type: string;
  url: string;
  created_at: Date;
  updated_at: Date;
};

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  listClientBookingsFailure: (err: AxiosError) => ({
    type: CLIENT_BOOKINGS_LIST_FAILURE,
    payload: err?.response?.data,
  }),
  listClientBookingsRequest: () => ({
    type: CLIENT_BOOKINGS_LIST_REQUEST,
  }),
  listClientBookingsSuccess: (res: AxiosResponse) => ({
    type: CLIENT_BOOKINGS_LIST_SUCCESS,
    payload: res.data,
  }),
  postClientBookingFailure: (err: AxiosError) => ({
    type: CLIENT_BOOKINGS_POST_FAILURE,
    payload: err?.response?.data,
  }),
  postClientBookingRequest: () => ({
    type: CLIENT_BOOKINGS_POST_REQUEST,
  }),
  postClientBookingSuccess: (res: AxiosResponse) => ({
    type: CLIENT_BOOKINGS_POST_SUCCESS,
    payload: res.data,
  }),
  putClientBookingFailure: (err: AxiosError) => ({
    type: CLIENT_BOOKINGS_PUT_FAILURE,
    payload: err?.response?.data,
  }),
  putClientBookingRequest: () => ({
    type: CLIENT_BOOKINGS_PUT_REQUEST,
  }),
  putClientBookingSuccess: (res: AxiosResponse) => ({
    type: CLIENT_BOOKINGS_PUT_SUCCESS,
    payload: res.data,
  }),
};
