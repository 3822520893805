import React, { Dispatch, MutableRefObject, useCallback, useEffect, useMemo } from "react";
import { FormikContextType, useFormikContext } from "formik";
import { usePermissionContext } from "~/contexts/PermissionContext";
import cn from "classnames";
import { ListBox } from "primereact/listbox";
import { PickList, PickListChangeParams } from "primereact/picklist";
import { getEntriesCounter } from "../helper";
import { ICustomSkill } from "~/interfaces/brief";
import { ISkill } from "~/store/constants/skills";
import { IMaxCount } from "~/forms/BriefNewDetailsForm/BriefNewDetailsForm";
import { PLATFORMS, SECTORS, SKILLS, LANGUAGES } from "../constants";

interface IEntry {
  formik?: FormikContextType<any>;
  isViewOnly?: boolean;
  selectedDiscipline?: any;
  setIsDisabledEntries: Dispatch<any>;
  selectedEntries: any;
  handleChangeEntries: (
    e: any,
    setEntries: Function,
    setSelectedEntries: Function,
    type: typeof SKILLS | typeof SECTORS | typeof PLATFORMS | typeof LANGUAGES,
    updatePickState?: boolean
  ) => void;
  typeOfEntries: typeof SKILLS | typeof SECTORS | typeof PLATFORMS | typeof LANGUAGES;
  label: JSX.Element | string;
  children?: JSX.Element | string;
  pickListRefs: MutableRefObject<any>;
  entries: any;
  sourceHeader: string;
  maxCount?: IMaxCount;
  className: string;
  pickListTypes: { [key: string]: any };
  disabledEntries: any;
}

export default function Entry(props: IEntry) {
  const {
    isViewOnly,
    selectedDiscipline,
    selectedEntries,
    handleChangeEntries,
    typeOfEntries,
    label,
    pickListRefs,
    entries,
    sourceHeader,
    maxCount,
    className,
    pickListTypes,
    disabledEntries,
    setIsDisabledEntries,
    children,
  } = props;
  const formik = props.formik || useFormikContext();
  const { checkIsAdmin } = usePermissionContext();
  const { setEntries, setSelectedEntries } = pickListTypes[typeOfEntries];
  const isAdmin = useMemo(checkIsAdmin, []);
  const isLanguagesType = typeOfEntries === LANGUAGES;
  const changeEntriesStatus = ({ type, isBlocked }: { type: string; isBlocked: boolean }) => {
    setIsDisabledEntries((entries) => ({
      ...entries,
      ...{ [type]: isBlocked },
    }));
  };

  useEffect(() => {
    if (!!selectedEntries.length) {
      Object.entries(maxCount).forEach(([type, { limit, selected }]) => {
        changeEntriesStatus({ type, isBlocked: selected.length >= limit });
      });
    }
  }, [selectedEntries]);

  const handleDoubleClickEntries = useCallback(
    (item, type) => {
      const { source: oldSource, selected: oldSelected } = pickListTypes[type];
      const isRemoveItem = oldSelected.some((el: ISkill) => el.name === item.name);
      const event = isRemoveItem
        ? {
            source: [...oldSource, item],
            target: oldSelected.filter((el: ISkill) => el.name !== item.name),
          }
        : {
            source: oldSource.filter((el: ISkill) => el.name !== item.name),
            target: [...oldSelected, item],
          };
      handleChangeEntries(event, setEntries, setSelectedEntries, type, true);
    },
    [entries]
  );

  const skillTemplate = (
    item: ICustomSkill,
    type: typeof SKILLS | typeof SECTORS | typeof PLATFORMS | typeof LANGUAGES
  ) => (
    <div className="skill-item" onDoubleClick={() => handleDoubleClickEntries(item, type)}>
      {item.name}
    </div>
  );
  return (
    <div className={cn(className, { ["disabled-source"]: disabledEntries[typeOfEntries] })}>
      <label htmlFor={typeOfEntries}>{label}</label>
      {!isLanguagesType && getEntriesCounter(typeOfEntries, maxCount, selectedEntries, selectedDiscipline)}
      {isViewOnly ? (
        <ListBox optionLabel="name" options={selectedEntries} disabled />
      ) : (
        <PickList
          ref={(el) => (pickListRefs.current[typeOfEntries] = el)}
          source={entries}
          target={selectedEntries}
          itemTemplate={(item) => skillTemplate(item, typeOfEntries)}
          onChange={(e: PickListChangeParams) => handleChangeEntries(e, setEntries, setSelectedEntries, typeOfEntries)}
          showSourceControls={false}
          sourceHeader={sourceHeader}
          targetHeader={isLanguagesType ? "Selected Languages" : "Now rank them"}
          showTargetControls={isLanguagesType ? false : selectedEntries.length > 0}
          className={cn("noselect", { "right-space": selectedEntries.length === 0 })}
        />
      )}
      {formik?.touched?.[typeOfEntries] && formik?.errors?.[typeOfEntries] && (
        <div className="ap-error">{formik.errors[typeOfEntries]}</div>
      )}
      {children}
    </div>
  );
}
