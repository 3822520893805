import React from "react";
import "./ResponseCard.scss";

interface IResponseCardButton {
  messenger_extensions: boolean;
  payload?: string;
  title: string;
  type: "postback" | "web_url";
  url?: string;
  webview_height_ratio: string;
  webview_share_button: string;
}

export interface IResponseCard {
  buttons?: IResponseCardButton[];
  id?: string;
  imageUrl: string;
  subtitle: string;
  title: string;
  ir35_code?: string;
}

export default (card: IResponseCard) => (
  <div id={card.id} className="ResponseCard">
    {card.imageUrl && <img src={card.imageUrl} />}
    <div>
      <header>
        <h2>{card.title}</h2>
        <p>{card.subtitle}</p>
      </header>
      {card.buttons && (!!card.buttons.length || !!Object.values(card.buttons).length) && (
        <ul>
          {(Array.isArray(card.buttons) ? card.buttons : Object.values(card.buttons).map((title) => ({ title }))).map(
            (button: any, i: number) => (
              <li key={i}>
                {button.type === "web_url" ? (
                  <a href={button.url} target="_blank" rel="nofollow">
                    {button.title}
                  </a>
                ) : (
                  <span>{button.title}</span>
                )}
              </li>
            )
          )}
        </ul>
      )}
    </div>
  </div>
);
