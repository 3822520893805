import React, { ReactNode, useState } from "react";
import { copyToClipboard, reactNodeToString } from "../../../utils";
import "./ClipboardText.scss";

type Props = {
  text?: string;
  children?: ReactNode;
  shouldRenderText?: boolean;
  icon?: string;
};

export default function ClipboardText({ text, shouldRenderText = true, icon, children }: Props) {
  if (!text && !children) return null;

  const [copied, setCopied] = useState(false);
  const textToCopy: string = text || reactNodeToString(children);

  function processClick() {
    copyToClipboard(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  }

  return (
    <span className="clipboardText" onClick={processClick} title="Copy link">
      {children ?? (shouldRenderText && text)} <i className={icon ?? "pi pi-copy"} />
      {copied && <span className="in-clipboard">Copied!</span>}
    </span>
  );
}
