import React, { useMemo } from "react";
import { Button } from "primereact/button";
import { Form, Formik } from "formik";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import toasts from "~/store/actions/toasts";
import actions from "~/store/actions";
import ApTag from "~/components/common/ApTag/ApTag";
import "./TagForm.scss";
import Loader from "~/components/Loader/Loader";
import { ITag } from "~/interfaces/common";
import { TagSchema } from "~/schemas/TagSchema";

export const colorPalette = [
  "#baf3db",
  "#f8e6a0",
  "#fedec8",
  "#ffd5d2",
  "#dfd8fd",
  "#4bce97",
  "#f5cd47",
  "#fea362",
  "#f87168",
  "#9f8fef",
  "#1f845a",
  "#946f00",
  "#c25100",
  "#d4272b",
  "#6e5dc6",
  "#cce0ff",
  "#c6edfb",
  "#d3f1a7",
  "#fdd0ec",
  "#dcdfe4",
  "#579dff",
  "#6cc3e0",
  "#94c748",
  "#e774bb",
  "#8590a2",
  "#0c66e4",
  "#227d9b",
  "#5b7f24",
  "#ae4787",
  "#626f86",
];

interface ITagFormProps {
  tag: ITag | undefined;
  tags: ITag[];
  handleUpdate: (data: any) => void;
  handleDelete: (data: any) => void;
}

export default function TagForm({ tag, tags, handleUpdate, handleDelete }: ITagFormProps) {
  const dispatch = useDispatch();
  const isFetchingTags = useSelector((state: any) => state.user.isFetchingTags);
  const isFetchingTalentTags = useSelector((state: any) => state.talent.isFetchingTags);
  const isFetching = useMemo(() => isFetchingTags || isFetchingTalentTags, [isFetchingTags, isFetchingTalentTags]);

  function handleSubmit(data: any) {
    const formattedData = {
      ...data,
      color: data.color.replace("#", ""),
    };

    handleUpdate(formattedData);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: tag?.id || null,
        name: tag?.name || "",
        color: (tag?.color && tag.color.padStart(7, "#")) || "#d4272b", // brand color
      }}
      validationSchema={() => TagSchema(tags)}
      onSubmit={handleSubmit}
      disabled={isFetching}
    >
      {(formik: any) => (
        <Form className="tag-form">
          <div className="tag-form-body">
            <div className="tag-preview">
              {isFetching && (
                <div className="tag-preview-loader">
                  <Loader size={3} />
                </div>
              )}
              <ApTag title={formik.values.name} fill={formik.values.color} />
            </div>
            <div className="field field-required">
              <label htmlFor="name">Title</label> <br />
              <ApInputText id="name" />
            </div>
            <div className="field field-required">
              <label htmlFor="color">Select a color</label>
              <div className="colorPanel">
                {colorPalette.map((color) => (
                  <ApTag
                    key={color}
                    className={cn({ selected: formik.values.color === color })}
                    onClick={() => formik.setFieldValue("color", color)}
                    fill={color}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="tag-buttons">
            {tag && (
              <Button
                label="Delete"
                type="button"
                className="p-button-secondary"
                disabled={isFetching}
                onClick={() => {
                  dispatch(
                    toasts.setPopup({
                      content: (
                        <>
                          Are you sure you want to delete <ApTag title={tag.name} fill={tag.color} /> tag? <br />
                          {isFetching && <Loader size={2} />}
                        </>
                      ),
                      buttons: [
                        {
                          text: "Go back",
                          callback: () => dispatch(actions.modal.closeModal()),
                        },
                        {
                          text: "Delete",
                          callback: () => {
                            handleDelete(tag);
                            dispatch(actions.modal.closeModal());
                          },
                          active: false,
                        },
                      ],
                    })
                  );
                }}
              />
            )}
            <Button label="Save" type="submit" onSubmit={formik.handleSubmit} disabled={isFetching} loadingIcon />
          </div>
        </Form>
      )}
    </Formik>
  );
}
