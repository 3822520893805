import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import ApRadioGroup from "../../components/common/ApRadioGroup/ApRadioGroup";
import { IState } from "../../store/reducers/index";
import "./RemoveInstanceModal.scss";
import * as Yup from "yup";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import { IBriefFreezeReason, IMatchRemovalReason } from "~/store/constants/global";
import { requiredFieldMessage } from "~/utils";

interface IProps {
  options: IMatchRemovalReason[] | IBriefFreezeReason[];
  content: ReactNode;
  type: "removeMatch" | "freezeBrief";
}

const OPTION_OWN_CODE = "REASON_OWN";

export const spacesMinValueTest = {
  name: "spaces-min-value",
  message: "Please, add at least 10 symbols",
  test: (value: any) => typeof value === "string" && value.trim().length > 9,
};

export default function RemoveInstanceModal() {
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { options, content, type }: IProps = modalProps || {};

  const isTypeFreezeBrief = type === "freezeBrief";
  const isTypeRemoveMatch = type === "removeMatch";
  const header = isTypeFreezeBrief ? "Cancel brief" : "Remove talent";
  const optionOwnId = isTypeFreezeBrief
    ? options?.find((option: IBriefFreezeReason) => option?.code === OPTION_OWN_CODE)?.id
    : undefined;

  function onSubmit(data: { option_id: number; description: string; freeze_description: string }) {
    modalProps.onSubmit && modalProps.onSubmit(data);
  }

  return (
    <Formik
      initialValues={{
        option_id: null,
        description: "",
        freeze_description: "",
      }}
      validationSchema={Yup.object().shape({
        option_id: Yup.number().required(requiredFieldMessage),
        description: Yup.string()
          .min(10, "Please, add at least 10 symbols")
          .max(650)
          .when("option_id", {
            is: (option_id) => isTypeFreezeBrief && option_id === optionOwnId,
            then: Yup.string().required(requiredFieldMessage).test(spacesMinValueTest),
          }),
        freeze_description: Yup.string().max(650, "Please, make it shorter than 650 symbols"),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Dialog
            className="RemoveInstanceModal"
            header={header}
            footer={
              <footer>
                <Button
                  label={header}
                  type="submit"
                  icon="pi pi-check"
                  disabled={!(formik.dirty && formik.isValid)}
                  onClick={formik.submitForm}
                />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            {content}
            {isTypeRemoveMatch && (
              <div className={"p-field"}>
                <ApInputTextarea
                  placeholder="Your feedback"
                  autoFocus
                  formik={formik}
                  id="freeze_description"
                  noField
                  maxChars={650}
                />
              </div>
            )}
            <ApRadioGroup selectedProp="id" formik={formik} name="option_id" noField options={options} />
            {isTypeFreezeBrief && formik.values.option_id === optionOwnId && (
              <div className={"p-field"}>
                <ApInputTextarea
                  placeholder="(Please be aware that I will share your reason for cancellation with the talent who responded positively to your brief)"
                  autoFocus
                  formik={formik}
                  id="description"
                  noField
                  maxChars={650}
                />
              </div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
