import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { ChangeEvent, useState } from "react";
import './CustomBriefElement.scss';

export default function CustomBriefElement(props: any) {
    const { label, placeholder, getElements, setItems } = props;

    const [value, setValue] = useState('');

    const addItem = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const items = getElements();
        if (items.map((el) => el.name).includes(value)) {
            return false;
        }
        setItems([{ name: value }, ...items]);
        setValue('');
    }
    const handleKeyPress = (e: any) => e.key === 'Enter' && addItem(e)

    const Btn = ({ iconClass, handleClick }) => (
        <Button
            disabled={!value.length}
            icon={iconClass}
            className="p-button-danger"
            onClick={handleClick}
        />
    )

    return (
        <div className="wrp-custom-element">
            <label htmlFor="type">{label}</label>
            <div className="p-inputgroup">
                <Btn iconClass="pi pi-check" handleClick={addItem} />
                <InputText
                    placeholder={placeholder}
                    value={value}
                    onKeyPress={handleKeyPress}
                    onChange={(event: ChangeEvent) => setValue(event.target.value)}
                />
                <Btn iconClass="pi pi-times" handleClick={() => setValue('')} />
            </div>
        </div>
    );
}