import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useBriefContext } from "~/contexts/BriefContext";
import { getSlides } from "./slides";
import { useLoadingAnimationContext } from "~/contexts/LoadingAnimationContext";
import cn from "classnames";
import "./LoadingAnimation.scss";

const MIN_LOAD_TIME = 4500; // 4.5 sec

const LoadingAnimation = () => {
  const [activeStep, setActiveStep] = useState(0);
  const completedTime = useRef(0) as MutableRefObject<any>;
  let timer = useRef({}) as MutableRefObject<any>;

  const { timeBriefCreation, setIsShowStepperAnimation, isErrorBriefCreation, callResult, setCallResult } =
    useLoadingAnimationContext();
  const { redirectAfterBriefCreated } = useBriefContext();

  const callbackFunc = () => {
    redirectAfterBriefCreated(callResult);
    setIsShowStepperAnimation(false);
  };

  const slides = useMemo(
    () => getSlides({ activeStep, completedTime, isErrorBriefCreation, callbackFunc }),
    [activeStep, callResult]
  );

  useEffect(() => {
    timer.current = setInterval(() => {
      setActiveStep((currentIndex: number) => currentIndex + 1);
    }, 1500);

    return () => {
      clearTimeout(timer.current);
      setCallResult(null);
    };
  }, []);

  useEffect(() => {
    window.location.pathname !== "/briefs/new" && setIsShowStepperAnimation(false);
  }, [window.location.pathname]);

  if (activeStep === 2 && !timeBriefCreation) {
    clearTimeout(timer.current);
  }
  if (activeStep === 3) {
    clearTimeout(timer.current);
  }

  if (timeBriefCreation && activeStep === 2) {
    const randomFractionalOfNumber = +`4.${Math.floor(Math.random() * 1000)}`;

    completedTime.current = timeBriefCreation < MIN_LOAD_TIME ? randomFractionalOfNumber : timeBriefCreation / 1000;
    setInterval(() => setActiveStep(3), 1500);
  }
  if (isErrorBriefCreation && activeStep === 2) {
    clearTimeout(timer.current);
    setActiveStep(3);
    setInterval(() => setIsShowStepperAnimation(false), 1000);
  }
  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(slides.length / 2);

  // Usd to determine the height/spacing of each item
  const itemHeight = 92;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex: number) => {
    // If these match, the item is active
    if (activeStep === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeStep > itemIndex - halfwayIndex) {
        return (itemIndex - activeStep) * itemHeight;
      } else {
        return -(slides.length + activeStep - itemIndex) * itemHeight;
      }
    }

    if (itemIndex > activeStep) {
      return (itemIndex - activeStep) * itemHeight;
    }

    if (itemIndex < activeStep) {
      if ((activeStep - itemIndex) * itemHeight >= shuffleThreshold) {
        return (slides.length - (activeStep - itemIndex)) * itemHeight;
      }
      return -(activeStep - itemIndex) * itemHeight;
    }
  };

  return (
    <div className="container LoadingAnimation">
      <section className={cn("outer-container", `bg-${activeStep + 1}`)}>
        <div className="carousel-wrapper">
          <div className="carousel">
            <div className="slides">
              <div className={cn("carousel-inner", { ["no-blur"]: activeStep === 3 })}>
                {slides.map((item: { key: number; content: any }, i: number) => (
                  <span
                    className={cn("carousel-item", {
                      active: activeStep === i,
                      futureStep: i > activeStep,
                      visible: Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
                    })}
                    key={item.key}
                    style={{
                      transform: `translateY(${determinePlacement(i)}px)`,
                    }}
                  >
                    {item.content}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoadingAnimation;
