import { useState } from "react";
import { ALL_DISCIPLINES } from "~/components/common/DisciplinesDropdown/DisciplinesDropdown";
import { ALL_BRIEF_TYPES } from "~/components/common/BriefTypesDropdown/BriefTypesDropdown";
import { IBriefFilterSettings } from "~/interfaces/brief";
import { DataTableSortMeta, DataTableSortOrderType } from "primereact/datatable";

const briefsCategories = [
  { label: "Open", code: "opened" },
  { label: "Resourced", code: "resourced" },
  { label: "Fulfilled", code: "fulfilled" },
  { label: "Cancelled", code: "cancelled" },
  { label: "Draft", code: "draft" },
  { label: "All", code: "all" },
];
const defaultSort: {
  field: string;
  order: DataTableSortOrderType;
} = {
  field: "id",
  order: -1,
};

const useBriefFilterSettings = () => {
  const [briefsCategory, setBriefsCategory] = useState(null as null | { label: string; code: string });
  const [discipline, setDiscipline] = useState(ALL_DISCIPLINES);
  const [briefType, setBriefType] = useState(ALL_BRIEF_TYPES);
  const [selectedColumns, setSelectedColumns] = useState<{ field: string; header: string }[]>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);

  const resetFilterBriefsSettings = (currentPath: string) => {
    const isExistBriefId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemBriefPath = currentPath?.includes("/briefs") && isExistBriefId;

    if (currentPath && currentPath !== "/briefs" && !isItemBriefPath) {
      setBriefsCategory(null);
      setDiscipline(ALL_DISCIPLINES);
      setSelectedColumns([]);
      setSearch("");
      setSort(defaultSort);
      setBriefType(ALL_BRIEF_TYPES);
    }
  };

  return {
    briefType,
    setBriefType,
    briefsCategories,
    briefsCategory,
    setBriefsCategory,
    discipline,
    setDiscipline,
    resetFilterBriefsSettings,
    selectedColumns,
    setSelectedColumns,
    search,
    setSearch,
    sort,
    setSort,
  } as IBriefFilterSettings;
};
export default useBriefFilterSettings;
