import React from "react";
import { FormikContextType } from "formik";
import ApFileUpload from "~/components/common/ApFileUpload/ApFileUpload";
import { extractFileName, validateFile } from "~/utils";
import { useBriefContext } from "~/contexts/BriefContext";
import PopupIcon from "~/components/Toaster/PopupIcon";

interface IJobDescription {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}
export default function JobDescription(props: IJobDescription) {
  const { formik, isViewOnly = false } = props;
  const { setJobUrlFormData } = useBriefContext();

  const deleteJobDescription = () => {
    setJobUrlFormData(null);
    formik.setFieldValue("job_url", "");
    formik.setFieldValue("job_url_name", "");
  };

  function onUploadJobDescription([file]: [File]) {
    deleteJobDescription();

    const errors = validateFile(file, {
      size: 10000000,
      types: ["doc", "docx", "pdf", "xls", "xlsx"],
    });

    if (errors.length) {
      formik.setFieldError("job_url", errors.join(", "));
    } else {
      const data = new FormData();
      data.append("job_url", file);
      setJobUrlFormData(data);
      formik.setFieldValue("job_url_name", file.name);
    }
  }

  if (isViewOnly) {
    return !!formik?.values?.job_url ? (
      <div className="p-fluid field job_description">
        <label htmlFor="job_url">Job Description</label> <br />
        {formik?.values?.job_url ? (
          <a href={formik.values.job_url} target="_blank">
            {extractFileName(formik.values.job_url)}
          </a>
        ) : (
          "—"
        )}
      </div>
    ) : null;
  }

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon
        popup={{
          content: <span>If you have a job spec please attach it here.</span>,
          buttons: [{ text: "Got It 🦾" }]
        }}
      />
    </>
  );

  return (
    <div className="p-fluid field job_description">
      <label htmlFor="job_url">Job Description  <InfoPopup /></label> <br />
      {!formik?.values?.job_url && formik?.values?.job_url_name && (
        <div className="preview_job_url">
          <span>{formik?.values?.job_url_name}</span>
          <button type="button" className="p-button p-component" onClick={deleteJobDescription}>
            <span className="p-button-label">
              <span className="pi pi-trash" />
            </span>
          </button>
        </div>
      )}
      <ApFileUpload
        formik={formik}
        id="job_url"
        mode="basic"
        auto
        noField
        accept=".doc,.docx,.pdf,.xls,.xlsx"
        onChange={onUploadJobDescription}
        chooseLabel={"Upload new"}
        onDelete={() => deleteJobDescription()}
      />
    </div>
  );
}
