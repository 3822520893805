import * as Yup from "yup";
import { ILineItemUnit } from "~/interfaces/invoices";
import { yupString } from "~/utils";

const LineItemSchema = Yup.object().shape({
  description: yupString(true, 10, 200),
  unit_type: Yup.string().oneOf(Object.keys(ILineItemUnit)),
  unit_amount: Yup.number().positive("Must be a positive number").typeError("Must be a positive number"),
  quantity: Yup.number().positive("Must be a positive number").typeError("Must be a positive number"),
  dates: Yup.array()
    .of(Yup.string())
    .nullable()
    .when("unit_type", {
      is: (unit_type: string) => unit_type !== ILineItemUnit.EXPENSE,
      then: Yup.array().min(2).required("Please, fill in required field"),
    }),
  total: Yup.number().min(1),
});

export const InvoiceSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  notes: yupString(),
  lineItems: Yup.array().of(LineItemSchema).min(1),
});
