import { Store } from "redux";
import actions from "../actions";

// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = "LOCATION_CHANGE";

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  updateLocation: (store: Store) => {
    return (nextLocation: any) => store.dispatch(actions.location.locationChange(nextLocation));
  },
};
