import React, { useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import cn from "classnames";
import { Button } from "primereact/button";
import actions from "~/store/actions";
import Loader from "~/components/Loader/Loader";
import ApFileUpload from "~/components/common/ApFileUpload/ApFileUpload";
import "./AddInvoiceAttachmentModal.scss";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { validateFile } from "~/utils";
import { IInvoiceFormValues } from "~/interfaces/invoices";

export default function AddInvoiceAttachmentModal() {
  const dispatch = useDispatch();
  const {
    modalProps,
    modalProps: { invoice },
  } = useSelector((state: IState) => state.modal);
  const { isFetching } = useInvoiceContext();
  const formikRef = useRef<FormikProps<IInvoiceFormValues> | null>(null);

  function onSubmit(data: { attachment: File }) {
    modalProps.onSubmit && modalProps.onSubmit(data.attachment, () => dispatch(actions.modal.closeModal()));
  }

  const onUploadAttachment = ([file]: [File]) => {
    const errors = validateFile(file, {
      size: 3000000,
    });

    if (errors.length) {
      formikRef.current?.setFieldError("attachment", errors.join(", "));
    } else {
      formikRef.current?.setFieldValue("attachment", file);
    }
  };

  const onDeleteFile = async () => {
    formikRef.current?.setFieldValue("attachment", null);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        attachment: null,
      }}
      validationSchema={Yup.object().shape({
        attachment: Yup.mixed(),
      })}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Dialog
            header="Add an atachment file"
            className={cn("AddInvoiceAttachmentModal", { loading: isFetching })}
            onHide={() => {
              dispatch(actions.modal.closeModal());
            }}
            footer={
              <div className="footer-btn">
                <Button label="Cancel" disabled={isFetching} onClick={() => dispatch(actions.modal.closeModal())} />
                <Button
                  label="Submit"
                  disabled={isFetching || !(formik.dirty && formik.isValid)}
                  onClick={formik.submitForm}
                />
              </div>
            }
            modal
            visible
          >
            {isFetching ? (
              <Loader size={2} />
            ) : (
              <div className="block-fields">
                <div className="p-fluid field">
                  <ApFileUpload
                    id="attachment"
                    mode="basic"
                    auto
                    onChange={onUploadAttachment}
                    chooseLabel={"Choose an attachment"}
                    onDelete={onDeleteFile}
                    formik={formik}
                    noField
                  />
                </div>
              </div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
