import React, { createContext, useContext } from "react";
import useFilters, { IFiltersState } from "~/hooks/useFilters";

export const FilterContext = createContext(null as IFiltersState | null);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a FilterContextProvider");
  }
  return context;
};

const FilterContextProvider = ({ children }: any) => {
  return (
    <FilterContext.Provider
      value={{
        ...useFilters(),
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;
