import React from "react";
import { FormikContextType } from "formik";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";

export default function Notes({ formik }: { formik: FormikContextType<any> }) {
  return (
    <div className="p-fluid field">
      <label htmlFor="notes">Notes</label>
      <ApInputTextarea formik={formik} id="notes" noField />
    </div>
  );
}
