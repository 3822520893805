import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import cn from "classnames";
import { Button } from "primereact/button";
import actions from "~/store/actions";
import Loader from "~/components/Loader/Loader";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import "./DisputeInvoiceModal.scss";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { requiredFieldMessage } from "~/utils";
import { spacesMinValueTest } from "../RemoveInstanceModal/RemoveInstanceModal";

export default function DisputeInvoiceModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { isFetching } = useInvoiceContext();

  function onSubmit(data: { reason: string }) {
    modalProps.onSubmit && modalProps.onSubmit(data.reason, () => dispatch(actions.modal.closeModal()));
  }

  return (
    <Formik
      initialValues={{
        reason: "",
      }}
      validationSchema={Yup.object().shape({
        reason: Yup.string()
          .min(10, "Please, add at least 10 symbols")
          .max(650, "Please, make it shorter than 650 symbols")
          .required(requiredFieldMessage)
          .test(spacesMinValueTest),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Dialog
            header="Dispute"
            className={cn("DisputeInvoiceModal", { loading: isFetching })}
            onHide={() => {
              dispatch(actions.modal.closeModal());
            }}
            footer={
              <div className="footer-btn">
                <Button label="Go back" disabled={isFetching} onClick={() => dispatch(actions.modal.closeModal())} />
                <Button
                  label="Submit"
                  disabled={isFetching || !(formik.dirty && formik.isValid)}
                  onClick={formik.submitForm}
                />
              </div>
            }
            modal
            visible
          >
            {isFetching ? (
              <Loader size={2} />
            ) : (
              <div className="block-fields">
                <div className="p-fluid field">
                  <label htmlFor="reason">Please, add the dispute reason</label>
                  <ApInputTextarea id="reason" formik={formik} autoFocus noField maxChars={650} />
                </div>
              </div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
