import Axios from "axios";
import { BACKEND_URL } from "~/config";
import { AxiosError, AxiosResponse } from "axios";

export class InvoicesService {
  static getInvoices = async (
    params = {},
    controller: { signal: { aborted?: boolean; onabort?: () => void | null } }
  ): Promise<AxiosResponse | AxiosError> => {
    try {
      return Axios.get(`${BACKEND_URL}/payment/xero/client_admin/invoices?${new URLSearchParams(params).toString()}`, {
        signal: controller?.signal,
      });
    } catch (err) {
      throw err;
    }
  };

  static getNewInvoices = async (
    params = {},
    isAdmin: boolean,
    controller: { signal: { aborted?: boolean; onabort?: () => void | null } }
  ): Promise<AxiosResponse | AxiosError> => {
    try {
      return await Axios.get(
        `${BACKEND_URL}/payment${isAdmin ? "" : "/client_admin"}/invoices/paginated?${new URLSearchParams(
          params
        ).toString()}`,
        {
          signal: controller?.signal,
        }
      );
    } catch (err) {
      throw err;
    }
  };

  static getInvoiceDetails = (invoiceId: string) => {
    return Axios.get(`${BACKEND_URL}/payment/xero/client_admin/invoice/${invoiceId}`);
  };

  static putInvoice = async (invoice: any) => await Axios.put(`${BACKEND_URL}/payment/invoice/${invoice.id}`, invoice);

  static changeInvoiceStatus = async (invoiceId: number, data: any) => {
    return await Axios.put(`${BACKEND_URL}/payment/client_admin/invoice/${invoiceId}`, data);
  };

  static uploadAttachment = async (id: number, attachment: File) => {
    const data = new FormData();
    data.append("attachment", attachment);
    return await Axios.post(`${BACKEND_URL}/payment/invoice/${id}/attachment`, data);
  };

  static deleteAttachment = async (invoiceId: number, fileId: number) =>
    await Axios.delete(`${BACKEND_URL}/payment/invoice/${invoiceId}/attachment/${fileId}`);

  static getInvoicePDF = async (invoiceId: number) =>
    await Axios.get(`${BACKEND_URL}/payment/invoice/${invoiceId}/pdf?type=ACCREC`, { responseType: "blob" });
}
