import { Tag } from "primereact/tag";
import React, { MouseEventHandler } from "react";
import { generateHEXContrastColor } from "~/utils";

interface IApTag {
  fill: string;
  title?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  className?: string;
}

export interface ITag {
  id: number;
  name: string;
  color: string;
}

const ApTag = (props: IApTag) => (
  <Tag
    value={props?.title || ""}
    style={{
      background: props.fill.padStart(7, "#"),
      color: generateHEXContrastColor(props.fill),
    }}
    onClick={props?.onClick}
    className={props?.className}
  />
);

export default ApTag;
