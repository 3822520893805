import * as Yup from "yup";

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const VALID_WIDTH = 780;
const VALID_HEIGHT = 410;

export const genieDetailsMaxInputLength = {
  headline: 70,
  subhead: 80,
  description: 1200,
  teamName: 100,
  vision_statement: 300,
  company_benefits: 1200,
  genie_take: 500,
};

export const talentNotesInputMaxLength = {
  notes: 640,
  type: 50,
};

export const imageSchema = Yup.lazy((value) => {
  if (!value) {
    return Yup.mixed().required("Please, select image");
  }
  if (typeof value === "string") {
    return Yup.string().required();
  }
  // @ts-ignore
  return Yup.mixed()
    .test(
      "file size",
      `Please, choose image less than ${MAX_FILE_SIZE / 1024 / 1024}Mb`,
      (value) => value && value.size <= MAX_FILE_SIZE
    )
    .test("fileFormat", "Please, choose PNG, JPG or GIF", (value) => value && SUPPORTED_FORMATS.includes(value.type))
    .test(
      "ratio",
      "The ratio of the image is incorrect and won't display properly. Please try again with an image of 780x410, or similar ratio.",
      (value) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = function () {
            const isValid = img.width / img.height === VALID_WIDTH / VALID_HEIGHT;
            resolve(isValid);
          };
          img.src = value.objectURL;
        });
      }
    );
});
