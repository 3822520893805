import { AnyAction } from "redux";
import * as Permissions from "../constants/permissions";

// ------------------------------------
// Interface
// ------------------------------------
export interface IPermissionsState {
  isFetching: boolean;
  items: Permissions.IGroupPermission[];
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IPermissionsState = {
  isFetching: false,
  items: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IPermissionsState => {
  switch (action.type) {
    case Permissions.PERMISSIONS_GET_SUCCESS:
    case Permissions.PERMISSIONS_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case Permissions.PERMISSIONS_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};
