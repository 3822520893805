import React from "react";
import { FormikContextType } from "formik";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import "../../CreateBriefForm/CreateBriefForm.scss";
import PopupIcon from "~/components/Toaster/PopupIcon";

interface IRoleInvolves {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}

const exampleTitle = "Here's an Example:";

const popupContent = (
  <span>
    {exampleTitle}
    <br />
    <br />
    <em>
      Leading a small team of 10 to develop and grow their capabilities
      <br />
      Pitching for new business
      <br />
      Driving innovative formats
    </em>
  </span>
);

const placeholder = `What will you actually be doing every day?

${exampleTitle}

Leading a small team of 10 to develop and grow their capabilities
Pitching for new business
Driving innovative formats`;

const multilinePlaceholderSafari = (
  <div>
    What will you actually be doing every day?
    <br />
    <br />
    {exampleTitle}
    <br />
    <br />
    Leading a small team of 10 to develop and grow their capabilities
    <br />
    Pitching for new business
    <br />
    Driving innovative formats
  </div>
);

export default function RoleInvolves(props: IRoleInvolves) {
  const { formik, isViewOnly = false } = props;

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon popup={{ content: popupContent, buttons: [{ text: "Got It 🦾" }] }} />
    </>
  );

  return (
    <div className="p-fluid field field-required">
      <label htmlFor="role_involves">
        What the Role Involves
        <InfoPopup />
      </label>
      <ApInputTextarea
        formik={formik}
        id="role_involves"
        noField
        readOnly={isViewOnly}
        placeholder={placeholder}
        maxChars={1200}
        multilinePlaceholderSafari={multilinePlaceholderSafari}
      />
    </div>
  );
}
