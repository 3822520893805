import Axios, { AxiosError, AxiosResponse } from "axios";
import { DataTableSortOrderType } from "primereact/datatable";
import { API_URL } from "~/config";
import { IUserPopup } from "~/interfaces/popups";

export class UserPopupService {
  static getUserPopup = async () => await Axios.get(`${API_URL}/user/current/popups`);

  static markAsViewed = async (id: number) => await Axios.post(`${API_URL}/user/current/popups/${id}/markAsViewed`);

  static getPopupsList = async (
    page = 0,
    per_page: number = 10,
    search?: string,
    sort?: { field: string; order: DataTableSortOrderType },
    controller: {
      signal: { aborted?: boolean; onabort?: () => void | null };
    }
  ): Promise<AxiosResponse | AxiosError> => {
    const query = new URLSearchParams({
      page: `${page + 1}`,
      per_page: String(per_page),
      ...(sort?.field && {
        order_by: sort.field,
        direction: sort.order === 1 ? "asc" : "desc",
      }),
      ...(search && { search }),
    }).toString();

    try {
      return await Axios.get(`${API_URL}/popups?${query}`, {
        signal: controller?.signal,
      });
    } catch (err) {
      throw err;
    }
  };

  static createPopup = async (popup: IUserPopup) => await Axios.post(`${API_URL}/popup`, popup);

  static updatePopup = async (popupId: number, popup: IUserPopup) =>
    await Axios.put(`${API_URL}/popups/${popupId}`, popup);
}
