.step-content {
  max-width: 720px;
  margin: 3em auto;

  .p-card-content {
    .p-card-title {
      p {
        margin: 5px 0 3px;
        padding: 0;
        font-size: 0.75em;
      }
    }
  }

  footer.p-field {
    text-align: right;

    > .p-button.align-left {
      float: left;
    }
  }
}

button[type="submit"] .pi-spinner {
  margin: 0 33px;
}

.role-description,
.work-description,
.work-setting,
.duration-types {
  width: 100%;
}

.textarea-placeholder-safari {
  position: relative;
  overflow: auto;

  textarea {
    background-color: transparent;

    &.data-edits {
      background-color: #f1f5f5;
    }
  }

  > div {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1f5f5;
    font-size: 15px;
    padding: 15px 20px;
    color: #999;
    font-style: italic;
  }
}
