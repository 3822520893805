import Axios from "axios";
import { API_URL, MSG_URL } from "~/config";

export class ConversationService {
  static async getConversations(page: number, search: string) {
    return await Axios.get(`${API_URL}/messenger/users?page=${page}&search=${search}`);
  }
  static async getConversation(ge_psid: string) {
    return await Axios.get(`${MSG_URL}/conversations/${ge_psid}`);
  }
}
