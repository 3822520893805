import React from "react";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { IBooking } from "~/store/constants/bookings";
import actions from "~/store/actions";
import { IBrief, IMatching } from "~/interfaces/brief";
import { useBriefContext } from "~/contexts/BriefContext";
import { usePermissionContext } from "~/contexts/PermissionContext";

export default function RowAmendBooking({
  rowData,
  onEditorValueChange,
}: {
  rowData: IBooking;
  onEditorValueChange?: Function;
}) {
  const dispatch = useDispatch();
  const { createBooking, setBrief, brief } = useBriefContext();

  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = checkIsAdmin();

  const handleAmendBooking = () => {
    dispatch(
      actions.modal.openModal("BOOKING_AMEND_MODAL", {
        onSubmit: handleSubmit,
        booking: {
          ...rowData,
          brief_id: rowData.brief_id ?? rowData.brief.id,
        },
      })
    );
  };

  const handleSubmit = async (data: any, callback: () => void) => {
    if (!data.match_id) {
      data.match_id = brief?.matches.find(({ bookings }) =>
        bookings.some(({ id }: { id: number }) => id === data.related_booking_id)
      )?.id;
    }

    const bookings = await createBooking(data, isAdmin);

    if (!!onEditorValueChange) {
      const updatedBrief = {
        ...brief,
        matches: brief?.matches?.map((match: IMatching) => {
          if (match.id === data.match_id) {
            return {
              ...match,
              bookings,
            };
          }
          return match;
        }),
      };

      !!bookings && onEditorValueChange({ id: data.match_id }, "bookings", bookings);
      setBrief(updatedBrief as IBrief);
    } else {
      dispatch(actions.bookings.listClientBookings());
    }

    callback && callback();
  };

  return (
    <Button type="button" icon="pi pi-file" className="p-button-text" onClick={handleAmendBooking}>
      Amend Booking
    </Button>
  );
}
