import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo } from "react";
import useUserPopup from "~/hooks/useUserPopup";
import { IUserPopup } from "~/interfaces/popups";
import { isValidUrl } from "~/utils";

interface IProps {
  popup?: IUserPopup;
  onClose?: () => void;
}

export default function UserPopup(props: IProps) {
  const { userPopup, getUserPopup, markAsViewed } = useUserPopup();
  const isPreview = useMemo(() => !!props.popup, [props]);

  useEffect(() => {
    !isPreview && getUserPopup();
  }, []);

  const handleAction = async (action?: string) => {
    if (isPreview) return null;

    !!userPopup && (await markAsViewed(userPopup.id));

    if (!!action && isValidUrl(action)) {
      window.location.href = action;
    }
  };

  const activePopup = isPreview ? props.popup : userPopup;
  if (!activePopup) return null;

  const { header, body, primary_button_text, primary_button_action, secondary_button_text, secondary_button_action } =
    activePopup;

  return (
    <Dialog
      modal={true}
      className="UserPopup"
      header={header}
      footer={
        (primary_button_text || secondary_button_text) && (
          <footer>
            {primary_button_text && (
              <Button label={primary_button_text} onClick={() => handleAction(primary_button_action)} />
            )}
            {secondary_button_text && (
              <Button
                className="p-button-outlined mr-2"
                label={secondary_button_text}
                onClick={() => handleAction(secondary_button_action)}
              />
            )}
          </footer>
        )
      }
      visible={!!activePopup}
      onHide={isPreview ? props.onClose : handleAction}
    >
      <p dangerouslySetInnerHTML={{ __html: body }}></p>
    </Dialog>
  );
}
