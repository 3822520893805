import {
  applyMiddleware,
  compose,
  createStore as createReduxStore,
  Store,
} from "redux";
import { routerMiddleware } from "react-router-redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import makeRootReducer from "./reducers";
import constants from "./constants";
import { History } from "history";
import interceptor from "./interceptor";

export default (initialState = {}, history: History) => {
  // ------------------------------------------------------
  // Middleware Configuration
  // ------------------------------------------------------
  const middleware = [routerMiddleware(history), thunk, logger];

  // ------------------------------------------------------
  // Store Enhancers
  // ------------------------------------------------------
  const enhancers: any[] = [];
  let composeEnhancers = compose;

  // ------------------------------------------------------
  // Store Instantiation and HMR Setup
  // ------------------------------------------------------
  const store: Store & { [key: string]: any } = createReduxStore(
    makeRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = history.listen(constants.location.updateLocation(store));

  // Catch auth errors
  interceptor.setup(store);

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }
  return store;
};
