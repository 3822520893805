import Quill from "quill";
import React from "react";
import { Value, Range } from "react-quill";

export const QuillToolbar = ({ toolbarId }: { toolbarId: string | number }) => (
  <div id={`${toolbarId}`}>
    <span className="ql-formats">
      <select className="ql-header" defaultValue="3">
        <option value="3">Normal</option>
        <option value="2">Subheading</option>
        <option value="1">Heading</option>
      </select>
    </span>
    <span className="ql-formats">
      <select className="ql-variables" defaultValue="">
        <option value="{user_first_name}">User First Name</option>
        <option value="{user_last_name}">User Last Name</option>
        <option value="{client_legal_name}">Client Legal Name</option>
        <option value="{client_trading_name}">Client Trading Name</option>
        <option value="{talent_name}">Talent Name</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <button className="ql-link" />
      <button className="ql-clean" />
    </span>
  </div>
);

export const modules = function (toolbarId: string | number) {
  return {
    toolbar: {
      container: `#${toolbarId}`,
      handlers: {
        variables: handleVariables,
      },
    },
  };
};

function handleVariables(this: { quill: Quill }, value: Value) {
  const { quill } = this;
  const range = quill.getSelection(true) as Range;
  const cursorPosition = quill.getSelection()?.index;

  range?.length && quill.deleteText(range.index, range.length);
  quill.insertText(cursorPosition, value);
  quill.setSelection(cursorPosition + value.length);
}

export default QuillToolbar;
