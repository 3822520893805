import { User } from "phosphor-react";
import { Menu } from "primereact/menu";
import React, { SyntheticEvent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { usePermissionContext } from "~/contexts/PermissionContext";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import {
  INVOICES,
  LOGOUT,
  NOTIFICATION_PREFERENCES,
  PERSONAL_DETAILS,
  SETTINGS,
  SUPERPOWERS,
  USER_MANAGEMENT,
  POPUP_MANAGEMENT,
} from "./constants";
import "./LayoutHeader.scss";

function UserMenu(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const { resetPermissions, checkIsAdmin, checkIsClientAdmin, canGetOwnInvoice } = usePermissionContext();

  const isAdmin = checkIsAdmin();
  const isClientAdmin = checkIsClientAdmin();
  let menu: Menu | null = null;

  const availableItems: string[] = [LOGOUT];
  canGetOwnInvoice && availableItems.unshift(INVOICES);
  isAdmin && availableItems.unshift(SUPERPOWERS, USER_MANAGEMENT, POPUP_MANAGEMENT, SETTINGS);
  isClientAdmin && availableItems.unshift(PERSONAL_DETAILS, NOTIFICATION_PREFERENCES);

  const allItems: { [key: string]: any } = useMemo(
    () => ({
      [PERSONAL_DETAILS]: {
        icon: "pi pi-fw pi-user",
        label: "Personal details",
        command: () => props.history.push("/profile"),
      },
      [NOTIFICATION_PREFERENCES]: {
        icon: "pi pi-fw pi-bell",
        label: "Notification preferences",
        command: () => props.history.push("/notification-preferences"),
      },
      [SUPERPOWERS]: {
        icon: "pi pi-fw pi-tags",
        label: "Superpowers",
        command: () => props.history.push("/superpowers"),
      },
      [USER_MANAGEMENT]: {
        icon: "pi pi-fw pi-lock",
        label: "User management",
        command: () => props.history.push("/permissions"),
      },
      [POPUP_MANAGEMENT]: {
        icon: "pi pi-fw pi-clone",
        label: "Pop-up management",
        command: () => props.history.push("/popups"),
      },
      [SETTINGS]: {
        icon: "pi pi-fw pi-sliders-v",
        label: "Settings",
        command: () => props.history.push("/settings"),
      },
      [LOGOUT]: { icon: "pi pi-fw pi-power-off", label: "Log out", command: () => logout() },
    }),
    []
  );

  const getItems = (items: any) =>
    items.reduce((specificItems: string[], key: string) => {
      if (key in allItems) {
        specificItems.push(allItems[key]);
      }
      return specificItems;
    }, []);

  const toggleMenu = (e: SyntheticEvent) => {
    if (menu) {
      menu.toggle(e);
    }
  };

  const logout = () => {
    dispatch(actions.user.logoutUser());
    resetPermissions();
    props.history.push("/");
  };

  return (
    <>
      <Menu model={getItems(availableItems)} popup={true} ref={(ref) => (menu = ref)} />
      <div className="user-button" onClick={toggleMenu}>
        <span className="user-name">{authenticatedUser?.full_name}</span>
        {authenticatedUser?.client?.work_profile?.image_url ? (
          <img
            alt={authenticatedUser?.full_name}
            className="user-avatar"
            src={authenticatedUser?.client?.work_profile?.image_url}
          />
        ) : (
          <div className="default-avatar">
            <User size={20} />
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(UserMenu);
