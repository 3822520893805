import Axios from "axios";
import { Dispatch } from "redux";
import Notifications from "../constants/notifications";
import { API_URL } from "~/config";
import { buildPaginatedQuery } from "~/utils";
import { IState } from "../reducers/index";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  getNotifications:
    (page = 0) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          dispatch(Notifications.getNotificationsRequest());
          Axios.get(`${API_URL}/notifications/paginated${buildPaginatedQuery(page)}`)
            .then((res) => {
              dispatch(Notifications.getNotificationsSuccess(res));
              resolve(res);
            })
            .catch((err) => {
              dispatch(Notifications.getNotificationsFailure(err));
              reject(err);
            });
        }
      });
    },
  getMainNotifications: () => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        dispatch(Notifications.getMainNotificationsRequest());
        Axios.get(`${API_URL}/notifications`)
          .then((res) => {
            dispatch(Notifications.getMainNotificationsSuccess(res));
            resolve(res);
          })
          .catch((err) => {
            dispatch(Notifications.getMainNotificationsFailure(err));
            reject(err);
          });
      }
    });
  },
  setCompleted:
    (id: number, type: string) =>
    (dispatch: Dispatch, getState: () => IState): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        const user_id = getState().user?.authenticatedUser?.id;
        if (token) {
          dispatch(Notifications.getNotificationsRequest());
          const data = {
            user_id,
            related_notification_id: id,
            type,
          };
          Axios.post(`${API_URL}/notification`, data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              dispatch(Notifications.getNotificationsFailure(err));
              reject(err);
            });
        }
      });
    },
};
