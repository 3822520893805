import Axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import Skills, { ISkill } from "../constants/skills";
import { IState } from "../reducers/index";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  saveSkill:
    (skill: ISkill) =>
    (dispatch: Dispatch, getState: () => IState): Promise<AxiosResponse | AxiosError> => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          try {
            dispatch(Skills.skillSaveRequest());
            const res =
              skill.id > 0
                ? await Axios.put(`${API_URL}/skill/${skill.id}`, skill)
                : await Axios.post(`${API_URL}/discipline/${skill.discipline}/skill`, skill);
            dispatch(Skills.skillSaveSuccess(res));
            resolve(res);
          } catch (err) {
            dispatch(Skills.skillSaveFailure(err));
            reject(err);
          }
        }
      });
    },
};
