import { Field, FormikProps, FormikValues } from "formik";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import { IClient, IClientUser } from "~/store/constants/client";
import { ITalent } from "~/store/constants/talent";
import { IUserForm } from "~/store/constants/user";
import "./UsersListForm.scss";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import { TabPanel, TabView } from "primereact/tabview";
import UsersForm from "../UsersForm";
import isUndefined from "lodash/isUndefined";
interface Props {
  data?: IClient | ITalent;
  selected?: number;
  onSelect?: (value: number) => void;
  type?: "client" | "talent";
  validate: any;
  updateUserList?: () => void;
  isNewUser?: boolean;
}

const isDisabledActiveTab = (users: IClientUser[] | IUserForm[]) => !users?.filter((user) => user.published).length;

const isDisabledInActiveTab = (users: IClientUser[] | IUserForm[]) => !users?.filter((user) => !user.published).length;

export default function UsersListForm(props: Props) {
  const dispatch = useDispatch();
  const { userAccess } = usePermissionContext();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  function openUserDialog(user?: IClientUser | IUserForm, userIndex?: number) {
    dispatch(
      actions.modal.openModal("ADD_USER_MODAL", {
        onClose: cancelUser,
        onSubmit: submitUser.bind(null, props.validate, userIndex),
        onRemove: removeUser.bind(null, props.validate, userIndex),
        user,
        type: props?.type,
      })
    );
  }

  async function submitUser(formik: FormikProps<FormikValues>, userIndex: number, user: IUserForm) {
    let newUsers;
    if (!isUndefined(userIndex)) {
      if (props.data) {
        const res = await dispatch(actions.user.updateUser({ ...user }));
        if (res?.status !== 200 || res?.statusText !== "OK") return;
      }
      newUsers = [...formik.values.users];
      newUsers[userIndex] = { ...user, full_name: `${user.first_name} ${user.last_name}` };
    } else {
      if (props.data) {
        const userData: IUserForm = { ...user };

        if (props?.type === "client") {
          userData.roles = [user.role, ...user.additional_roles];
          delete userData.role;
          delete userData.additional_roles;
        } else {
          delete userData.roles;
        }

        await dispatch(
          actions.user.createUser({
            user: { ...userData },
            type: props?.type,
            id: props?.data?.id,
          })
        );
        props.updateUserList && props.updateUserList();
      }

      newUsers = [...formik.values.users, { ...user }];
    }
    formik.setFieldValue("users", newUsers);
    cancelUser();
  }

  function removeUser(formik: any, userIndex: number) {
    const newUsers = [...formik.values.users];
    newUsers.splice(userIndex, 1);
    formik.setFieldValue("users", newUsers);
    cancelUser();
  }

  function cancelUser() {
    dispatch(actions.modal.closeModal());
  }

  return (
    <div className="UsersListForm">
      <Field name="users">
        {({
          form: {
            touched,
            errors,
            values: { users },
          },
        }: {
          form: { touched: { [key: string]: any }; errors: { [key: string]: any }; values: { [key: string]: any } };
        }) =>
          props?.isNewUser ? (
            <>
              <UsersForm users={users} {...props} />
              {touched.users && errors.users && <div className="col-12 ap-error">{errors.users}</div>}
            </>
          ) : (
            <>
              <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                <TabPanel header="Active users" disabled={isDisabledActiveTab(users)}>
                  <UsersForm users={users} isPublished {...props} changeTab={setActiveTabIndex} />
                  {touched.users && errors.users && <div className="col-12 ap-error">{errors.users}</div>}
                </TabPanel>
                <TabPanel header="Inactive users" disabled={isDisabledInActiveTab(users)}>
                  <UsersForm users={users} isPublished={false} {...props} changeTab={setActiveTabIndex} />
                  {touched.users && errors.users && <div className="col-12 ap-error">{errors.users}</div>}
                </TabPanel>
              </TabView>
            </>
          )
        }
      </Field>
      {userAccess(permissionType.postOwnClientUser, permissionType.postAnyUser) && (
        <div className="UsersListForm__addUser">
          <Button label="Add User" onClick={() => openUserDialog()} type="button" />
        </div>
      )}
    </div>
  );
}
