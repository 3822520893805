import { Dialog } from "primereact";
import React, { useMemo } from "react";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { IBooking } from "../../store/constants/bookings";
import "./BookingDetails.scss";

type IBookingDetailsProps = {
  booking: IBooking;
  onHide: any;
};

export default function BookingDetails({
  booking: { brief, brief_name, duration_id, duration_days, duration_weekends, duration_days_list },
  onHide,
}: IBookingDetailsProps) {
  const {
    global: { globalOptions },
  } = useGlobalContext();

  const durationType = useMemo(
    () => globalOptions?.duration_types.find(({ id }: { id: number }) => id === duration_id),
    [duration_id]
  );

  return (
    <Dialog
      header={brief_name || brief.name}
      className="BookingDetailsModal"
      visible={true}
      onHide={onHide}
      style={{ minWidth: "50vw", maxWidth: "50vw" }}
      baseZIndex={20000}
    >
      {!!durationType && (
        <p>
          <label>Duration Type:</label> {durationType.name}
        </p>
      )}
      {[1, 2].includes(duration_id) && (
        <>
          <p>
            <label>Weekdays:</label> {duration_days}
          </p>
          <p>
            <label>Weekend Days:</label> {duration_weekends}
          </p>
          <p>
            <label>No. Of Booked Days:</label> {duration_days + duration_weekends}
          </p>
        </>
      )}
      {duration_id === 3 && (
        <p>
          <label>Dates:</label> {duration_days_list}
        </p>
      )}
    </Dialog>
  );
}
