export const PERSONAL_DETAILS = "PERSONAL_DETAILS";
export const NOTIFICATION_PREFERENCES = "NOTIFICATION_PREFERENCES";
export const SUPERPOWERS = "SUPERPOWERS";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const POPUP_MANAGEMENT = "POPUP_MANAGEMENT";
export const SETTINGS = "SETTINGS";
export const INVOICES = "INVOICES";
export const LOGOUT = "LOGOUT";

export const TALENT = "TALENT";
export const CLIENTS = "CLIENTS";
export const BRIEFS = "BRIEFS";
export const CONVERSATIONS = "CONVERSATIONS";
export const MAKE_CONTACT = "MAKE_CONTACT";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const HELP_CENTRE = "HELP_CENTRE";

export const DASHBOARD = "DASHBOARD";
export const MY_TALENT_NETWORK = "MY_TALENT_NETWORK";
export const COMPANY_PROFILE = "COMPANY_PROFILE";
export const BOOKINGS = "BOOKINGS";
