import { Badge } from "primereact/badge";
import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import * as permissionType from "~/constants";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import GenieLogo from "../../assets/genie-logo.svg";
import { IState } from "../../store/reducers/index";
import "./LayoutHeader.scss";
import MobileMenu from "./MobileMenu";
import UserMenu from "./UserMenu";

function LayoutHeader() {
  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const isAuthenticated = useSelector((state: IState) => state.user.isAuthenticated);
  const { checkIsAdmin, checkIsClientAdmin, userAccess, canGetOwnInvoice } = usePermissionContext();
  const { pendingInvoicesTotal } = useInvoiceContext();
  const isAdmin = checkIsAdmin();
  const isClientAdmin = checkIsClientAdmin();
  const isClient = userAccess(permissionType.getOwnClientInfo, permissionType.getAnyClient);

  const badgeItem = (label: string | null, value: number | undefined, classNames: string) => {
    const badge =
      canGetOwnInvoice && pendingInvoicesTotal && pendingInvoicesTotal > 0 ? (
        <Badge className={classNames} value={value} severity="danger"></Badge>
      ) : null;
    return (
      <>
        <span>{label}</span>
        {badge}
      </>
    );
  };

  return (
    <header className="LayoutHeader">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="logo-wrapper">
          <img src={GenieLogo} id="LayoutHeader__Logo" alt="Genie Outside Logo" />
          <h4 className="logo-text">Outside</h4>
        </div>
      </Link>
      <div className="right-block">
        {isAuthenticated && (
          <>
            <nav className="desktop-menu">
              {isAdmin ? (
                <>
                  <NavLink to="/talents">Talent</NavLink>
                  <NavLink to="/clients">Clients</NavLink>
                  <NavLink to="/briefs">Briefs</NavLink>
                  <NavLink to="/invoices">Invoices</NavLink>
                  <NavLink to="/conversations">Conversations</NavLink>
                  <NavLink to="/contact">Make Contact</NavLink>
                  <NavLink to="/notifications">Notifications</NavLink>
                </>
              ) : (
                isClientAdmin && (
                  <>
                    <NavLink exact to={`/`}>
                      Dashboard
                    </NavLink>
                    <NavLink to="/talents">My Talent Network</NavLink>
                    {isClient && <NavLink to={"/clients/current"}>Company Profile</NavLink>}
                    <NavLink to={`/briefs`}>Briefs</NavLink>
                    <NavLink to="/bookings">Bookings</NavLink>
                    {canGetOwnInvoice && (
                      <NavLink to="/invoices">
                        {badgeItem("Invoices", pendingInvoicesTotal, "badge-menu-invoices")}
                      </NavLink>
                    )}
                  </>
                )
              )}
              <a href="https://meetgenie.co/help-centre" target="_blank">
                Help Centre
              </a>
            </nav>
            <MobileMenu />
          </>
        )}
        {isAuthenticated && authenticatedUser && <UserMenu />}
      </div>
    </header>
  );
}

export default withRouter(LayoutHeader);
