import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Message } from "primereact/message";
import React from "react";
import { IInvoice, LINE_ITEM_UNIT_TYPES } from "~/interfaces/invoices";
import { formatCurrency } from "~/utils";
import "./NewInvoiceDetails.scss";
import { InputTextarea } from "primereact/inputtextarea";
import { extractFileName } from "~/utils";

export default function NewInvoiceDetails({ invoice, isAdmin }: { invoice: IInvoice; isAdmin: boolean }) {
  const { subtotal, total_tax, total, line_items: lineItems, disputes = [], notes = "", attachments = [] } = invoice;

  return (
    <>
      {invoice.status === "DISPUTED" &&
        disputes.length > 0 &&
        disputes.reverse().map((dispute, i) => (
          <div key={i} className="InvoiceDetails__table__dispute">
            <Message
              severity="warn"
              content={
                <>
                  <label>{moment(dispute.created_at).format("MMM DD, YYYY")}</label>
                  <span>{dispute.description}</span>
                </>
              }
            />
          </div>
        ))}
      <DataTable autoLayout={true} className="InvoiceDetails__table" value={(lineItems ?? []) as any[]}>
        <Column field="description" header="Description" sortable={false} />
        <Column
          field="unit_type"
          header="Unit"
          sortable={false}
          body={(rowData: any) => (
            <span> {(LINE_ITEM_UNIT_TYPES.find(({ value }) => value === rowData.unit_type) || {}).label}</span>
          )}
        />
        <Column
          field="unit_amount"
          header="Amount"
          sortable={false}
          body={(rowData: any) => <span> £{rowData.unit_amount}</span>}
        />
        <Column field="quantity" header="Quantity" sortable={false} body={(rowData: any) => rowData.quantity} />
        <Column
          field="start_date"
          header="Start date"
          sortable={false}
          body={(rowData: any) => (
            <span> {rowData.start_date ? moment(rowData.start_date).format("MMM DD, YYYY") : "N/A"}</span>
          )}
        />
        <Column
          field="end_date"
          header="End date"
          sortable={false}
          body={(rowData: any) => (
            <span> {rowData.end_date ? moment(rowData.end_date).format("MMM DD, YYYY") : "N/A"}</span>
          )}
        />
        <Column
          field="line_amount"
          header="Total"
          sortable={false}
          body={(rowData: any) => <span> {formatCurrency(rowData.line_amount)}</span>}
        />
      </DataTable>
      <div className="grid">
        <div className="notes md:col-8">
          {!!notes && (
            <>
              <h4>Notes</h4>
              <InputTextarea disabled autoResize value={notes} />
            </>
          )}
          {isAdmin && attachments.length > 0 && (
            <>
              <h4>Attachments</h4>
              {attachments.map(({ url, id }) => (
                <div key={id} className="attachments">
                  <i className="pi pi-paperclip" />
                  <a href={url} target="_blank" className="attachments__name">
                    {extractFileName(url)}
                  </a>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="total-amount md:col-4">
          <ul>
            <li>
              <p>Subtotal:</p>
              <p>{formatCurrency(subtotal)}</p>
            </li>
            <li>
              <p>Total VAT, 20%:</p>
              <p>{formatCurrency(total_tax)}</p>
            </li>
            <li>
              <p>Total:</p>
              <p>{formatCurrency(total)}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
