import { Form, Formik, FormikProps, FormikValues } from "formik";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BookingDateConfirmSchema } from "~/schemas/BookingDateConfirmSchema";
import { IState } from "../../store/reducers/index";
import "./EditBookingRequestModal.scss";
import BriefDatesDaysCount from "~/components/BriefDatesDaysCount/BriefDatesDaysCount";
import toasts from "~/store/actions/toasts";
import { calculateDates, getDiffBetweenDays } from "~/utils";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { useBriefContext } from "~/contexts/BriefContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import PopupIcon from "~/components/Toaster/PopupIcon";
import DurationTypesPopup from "~/forms/BriefViewDetailsForm/DurationTypesPopup/DurationTypesPopup";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import CalendarBlock from "~/forms/BriefViewDetailsForm/Calendar/CalendarBlock";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";

const DEFAULT_DURATION_DAYS = 1;

export default function EditBookingRequestModal() {
  const dispatch = useDispatch();
  const { brief } = useBriefContext();
  const { userAccess } = usePermissionContext();
  const { modalProps } = useSelector((state: IState) => state.modal);

  const {
    booking,
    booking: {
      id,
      start_date,
      end_date,
      status,
      include_weekends,
      duration_days,
      duration_weekends,
      duration_id,
      talent_rate,
      contract_id,
    },
  } = modalProps || {};

  const {
    global: { optionsNormalization, globalOptions },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const [durationDays, setDurationDays] = useState(duration_days + duration_weekends || 1);
  const [maxDurationDays, setMaxDurationDays] = useState(null as any);
  const isExactWorkingDates = duration_id === DURATION_ID?.EXACT;

  const [dates, setDates] = useState(calculateDates.currentBrief(booking, isExactWorkingDates));
  const durationTypes = globalOptions?.duration_types;
  const durationTypeOptions = useMemo(
    () =>
      durationTypes
        ?.filter(({ is_enabled }: { is_enabled: boolean }) => is_enabled)
        ?.map(({ id: code, name, description }: { id: string; name: string; description: string }) => ({
          code,
          name,
          description,
        })),
    [durationTypes]
  );

  const changeDurationDays = (dates: Date[], day: number = DEFAULT_DURATION_DAYS) => {
    const endDate = dates[1] ? moment(dates[1]) : moment(dates[0]);
    const startDate = moment(dates[0]);
    const days = getDiffBetweenDays({ startDate, endDate });
    setMaxDurationDays(days + 1);
    setDurationDays(day);
  };
  useEffect(() => {
    changeDurationDays([start_date, end_date], duration_days + duration_weekends);
  }, []);

  function onSubmit(data: any) {
    dispatch(
      toasts.setPopup({
        content: (
          <BriefDatesDaysCount
            data={data}
            callback={(dates) => {
              data.duration_days = dates.duration_days;
              data.duration_weekends = dates.duration_weekends;
            }}
            bookingStatus={status}
          />
        ),
        buttons: [
          { text: "Dismiss" },
          {
            callback: () =>
              modalProps.onSubmit &&
              modalProps.onSubmit({
                id,
                status,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date ?? data.start_date).format("YYYY-MM-DD"),
                include_weekends: data.include_weekends,
                duration_id: data.duration_id,
                duration_days: data.duration_days,
                duration_weekends: data.duration_weekends,
                dates: data.dates,
                talent_rate: data.talent_rate,
              }),
            text: "Request",
          },
        ],
      })
    );
  }

  const changeDurationTypes = (value: number, formik: FormikProps<FormikValues>) => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
      duration_id: value,
      duration_days: DEFAULT_DURATION_DAYS,
    });
    setDurationDays(DEFAULT_DURATION_DAYS);
    setMaxDurationDays(null);
  };

  return (
    <Formik
      initialValues={{
        ...(userAccess(permissionType.accessAdmin) && { client_id: brief?.client_id }),
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        include_weekends: include_weekends || false,
        duration_id: duration_id,
        duration_days: durationDays,
        dates: calculateDates.formatAsObjectArr(dates || []),
        talent_rate,
        contract_id,
      }}
      validationSchema={BookingDateConfirmSchema}
      onSubmit={onSubmit}
    >
      {(props: FormikProps<FormikValues>) => (
        <Form>
          <Dialog
            className="EditBookingRequestModal"
            header="Edit your booking request"
            footer={
              <footer>
                <Button label="Edit request" type="submit" icon="pi pi-check" onClick={() => props.submitForm()} />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            Please select the start and end date and confirm the agreed day rate. <br />
            <label htmlFor="duration_id">
              Duration Types <PopupIcon content={<DurationTypesPopup />} />
            </label>
            <ApRadioGroup
              name="duration_id"
              options={durationTypeOptions}
              onChange={({ value }) => changeDurationTypes(value, props)}
            />
            <CalendarBlock
              formik={props}
              dates={dates}
              setDates={setDates}
              durationDays={durationDays}
              setDurationDays={setDurationDays}
              maxDurationDays={maxDurationDays}
              setMaxDurationDays={setMaxDurationDays}
              hideFlexibleDatesCheckbox
            />
            {props.touched?.start_date && props.errors?.start_date && (
              <div className="ap-error">{props.errors?.start_date}</div>
            )}
            <div className="field field-required">
              <label htmlFor="talent_rate">Agreed Day Rate</label>
              <ApInputNumber
                id="talent_rate"
                min={0}
                max={1000000}
                size={10}
                mode="currency"
                currency="GBP"
                locale="en-US"
                placeholder="Agreed Day Rate"
                useGrouping={false}
              />
            </div>
            {props.touched?.start_date && props.errors?.start_date && (
              <div className="ap-error">{props.errors?.start_date}</div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
