import classnames from "classnames";
import React from "react";
import { Field, FieldInputProps, FieldMetaProps } from "formik";
import "./ApMultiCheckbox.scss";
import { Checkbox } from "primereact/checkbox";

const ALL_ITEMS_SELECTED = "ALL_ITEMS_SELECTED";

export interface IOption {
  id?: number;
  name: string;
  code?: string;
  options_name?: string;
  description?: string;
}

interface IApMultiCheckbox {
  id: string;
  label?: string;
  options: IOption[];
  formik?: any;
  noField?: boolean;
  allItemsLabel?: string;
  className?: string;
  disabled?: boolean;
  selectedProp?: "id" | "code";
  onUpdate?: ({ value, checked }: { value: string | number; checked: boolean }) => void;
}

export default function ApMultiCheckbox(props: IApMultiCheckbox) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const selectedProp = props.selectedProp ?? "id";

  const handleToggle = (option: IOption, form = null) => {
    const value = option[selectedProp] as string;
    const formik = props.formik ?? form;
    const isAllItemsSelected = formik.values[props.id].length === props.options.length;

    let selected;

    if (value === ALL_ITEMS_SELECTED) {
      selected = isAllItemsSelected ? [] : props.options.map(({ [selectedProp as keyof IOption]: val }) => val);
    } else {
      selected = [...formik.values[props.id]];
    }

    const index = selected.indexOf(value);
    const isChecked = index === -1;

    if (value === ALL_ITEMS_SELECTED) {
    } else if (isChecked) {
      selected.push(value);
    } else {
      selected.splice(index, 1);
    }

    formik.setFieldValue(props.id, selected);
    props.onUpdate && props.onUpdate({ value, checked: isChecked });
  };

  const renderCheckboxes = (field: FieldInputProps<any>, meta: FieldMetaProps<any>, form = null) => (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      {[
        ...(!!props.allItemsLabel
          ? [
              {
                [selectedProp]: ALL_ITEMS_SELECTED,
                name: props.allItemsLabel,
              },
            ]
          : []),
        ...props.options,
      ].map((option: IOption, index: number) => {
        const checkboxId = `${props.id}-${index.toString()}`;
        const allItems = (props.formik ?? form).values[props.id].length === props.options.length;

        return (
          <div key={index} className="p-field-checkbox">
            <Checkbox
              inputId={checkboxId}
              {...field}
              onChange={() => handleToggle(option, form)}
              checked={allItems || field.value.includes(option[selectedProp])}
            />
            <label className="p-checkbox-label" htmlFor={checkboxId}>
              {option.options_name ?? option.name} <br />
              {option?.description && <span className="p-checkbox-label-description">{option?.description}</span>}
            </label>
          </div>
        );
      })}
      {meta.touched && meta.error && <sub className="ap-error">{meta.error}</sub>}
    </>
  );

  return props.noField && meta ? (
    <div
      className={classnames("ApMultiCheckbox", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {renderCheckboxes(field, meta)}
    </div>
  ) : (
    <div className="ApMultiCheckbox">
      <Field name={props.id}>{({ field, meta, form }: any) => renderCheckboxes(field, meta, form)}</Field>
    </div>
  );
}
