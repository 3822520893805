import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import React, { useState } from "react";
import "./ApCard.scss";
import cn from "classnames";
import TagsView from "../Tags/TagsView";
import { ITag } from "~/interfaces/common";

export interface ICardAccordionTab {
  id: number;
  title: string;
  content: React.ReactFragment;
  isHidden?: boolean;
}

interface IApCard {
  talentId?: number;
  image_url: string;
  renderCardText: () => JSX.Element;
  talentName?: string;
  accordionTabs: ICardAccordionTab[];
  tags?: ITag[];
  hideTags?: boolean;
  isTalent?: boolean;
  renderCardActions: () => JSX.Element;
  onCardClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function ApCard(props: IApCard) {
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  return (
    <Card
      className="ApCard"
      header={
        <div className="wrp-img">
          <img alt="Talent background image" src={props.image_url} />
          {!props.isTalent && !props.hideTags && (
            <TagsView talentName={props.talentName || ""} talentId={props.talentId} tags={props.tags || []} />
          )}
        </div>
      }
      onClick={props.onCardClick}
    >
      <div className="p-card-text">{props.renderCardText()}</div>
      <Accordion
        activeIndex={activeIndex}
        className="accordion-custom"
        onTabChange={(e: any) => setActiveIndex(e.index)}
      >
        {props.accordionTabs.map((tab: any) => (
          <AccordionTab
            tabIndex={activeIndex}
            key={tab.id}
            header={tab.title}
            className={cn({ ["visible-hidden"]: tab.isHidden })}
          >
            <div className="wrp-content">{tab.content}</div>
          </AccordionTab>
        ))}
      </Accordion>
      <div className="p-card-text grid card-actions">{props.renderCardActions()}</div>
    </Card>
  );
}
