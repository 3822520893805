import React from "react";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikValues } from "formik";
import { InputSwitch } from "primereact/inputswitch";

interface IMultiselect extends MultiSelectProps {
  id: string;
  formik?: FormikProps<FormikValues>;
  noField?: boolean;
  withSwitch?: boolean;
  onToggleSwitch?: (e: any) => void;
  switchValue?: string;
}
export default function ApMultiselect(props: IMultiselect) {
  let meta, field;
  const { noField, withSwitch, switchValue, onToggleSwitch, ...rest } = props;

  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const headerTemplate = (options: any) => {
    return (
      <>
        <div className="p-multiselect-header">
          {options.checkboxElement}
          {options.filterElement}
          {options.closeElement}
        </div>
        {withSwitch && (
          <div className="ap-multiselect-switch">
            <InputSwitch onChange={onToggleSwitch} checked={switchValue} trueValue={"AND"} falseValue={"OR"} />
            <span className="ap-multiselect-switch-label">
              {props.switchValue === "OR" ? "Require Any selected item(s)" : "Require All selected item(s)"}
            </span>
          </div>
        )}
      </>
    );
  };

  const renderMultiSelect = (field?: FieldInputProps<any>, meta?: FieldMetaProps<any>) => (
    <>
      <MultiSelect
        {...field}
        {...rest}
        filterPlaceholder={props.filterPlaceholder || "Filter..."}
        inputId={props.id}
        panelHeaderTemplate={headerTemplate}
      />
      {meta?.touched && meta?.error && <div className="ap-error">{meta.error}</div>}
    </>
  );

  return noField && !props.formik && withSwitch ? (
    renderMultiSelect()
  ) : noField && meta ? (
    renderMultiSelect(field as FieldInputProps<any>, meta)
  ) : (
    <Field name={props.id}>{({ field, meta }: any) => renderMultiSelect(field, meta)}</Field>
  );
}
