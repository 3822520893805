import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import ApDropdown from "../../components/common/ApDropdown/ApDropdown";
import useDebounce from "../../components/common/debounce";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import "./SelectTalentModal.scss";

const defaultSort: {
  field: string;
  order: DataTableSortOrderType;
} = {
  field: "name",
  order: 1,
};

const allTalentOption = { value: "all", label: "All talent" };

type ISpecialism = { id: number; code: string; name: string };

export default function SelectTalentModal() {
  const dispatch = useDispatch();
  const sortFunc = sortBackend();
  const talents = useSelector((state: IState) => state.talent.talentsPaginated);
  const { modalProps } = useSelector((state: IState) => state.modal);
  const loading = useSelector((state: IState) => state.talent.isFetching);
  const { checkIsAdmin, checkIsClientAdmin } = usePermissionContext();
  const isAdmin = useMemo(checkIsAdmin, []);
  const isClientAdmin = useMemo(checkIsClientAdmin, []);
  const {
    global: { globalOptions },
  } = useGlobalContext();

  const specialisms = (globalOptions?.disciplines || [])
    .reduce((acc: ISpecialism[], discipline: any) => [...acc, ...discipline.specialisms], [])
    .map(({ id: value, name: label }: { id: number; name: string }) => ({ label, value }))
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  const [sort, setSort] = useState(defaultSort);

  const [search, setSearch] = useState("");
  const debouncedSearch = isAdmin ? useDebounce(search, 1000) : undefined;

  useEffect(() => {
    getTalents({ page: 0 });
  }, [sort, debouncedSearch]);

  const { onSubmit } = modalProps || {};

  function getTalents({ page = 1 }: { page: number }) {
    dispatch(actions.talent.listTalentsPaginated(isClientAdmin, page + 1, undefined, search, 15, sort));
  }

  function sortBackend() {
    let sorting = false;
    function askSort(e: { sortField: any; sortOrder: any }) {
      if (!sorting) {
        sorting = true;
        setSort({
          field: e.sortField,
          order: e.sortOrder,
        });
      }
      return 0;
    }
    return askSort;
  }

  return (
    <Formik
      initialValues={{
        selectedItems: [],
        specialisms: 0,
      }}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Dialog
          header="Select Talent"
          className="AddMatchModal"
          onHide={() => {
            dispatch(actions.modal.closeModal());
          }}
          footer={
            (formik.values.selectedItems.length > 0 && !formik.values.specialisms) || formik.values.specialisms ? (
              <Button
                label="Select"
                onClick={() => {
                  onSubmit({ selectedItems: formik.values.selectedItems });
                  dispatch(actions.modal.closeModal());
                }}
              />
            ) : null
          }
          modal
          visible
        >
          <Form>
            <div className="formgrid grid">
              <div className="field col-8">
                <InputText
                  placeholder="Search for talent"
                  onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
                  value={search}
                />
              </div>
              <div className="field col-4">
                <ApDropdown
                  id="specialisms"
                  options={[allTalentOption, ...specialisms]}
                  noField
                  placeholder="Use preset"
                  onChange={(e) => {
                    onSubmit({
                      specialisms:
                        e.value === "all" ? allTalentOption : specialisms.find((item: any) => item.value === e.value),
                    });
                    dispatch(actions.modal.closeModal());
                  }}
                />
              </div>
            </div>
            {!formik.values.specialisms && (
              <>
                <DataTable
                  autoLayout={true}
                  className="AddMatch__talents"
                  emptyMessage={`No talent to add`}
                  value={talents?.data ?? []}
                  sortOrder={sort.order}
                  sortField={sort.field}
                  selection={formik.values.selectedItems}
                  selectionMode="multiple"
                  onSelectionChange={(e) => formik.setFieldValue("selectedItems", e.value)}
                  loading={loading}
                  onSort={sortFunc}
                >
                  <Column selectionMode="multiple" style={{ width: "3em" }} />
                  <Column field="name" header="Talent name / Team name" sortable={true} />
                  <Column field="specialism_name" header="Specialism" sortable={true} />
                </DataTable>
                {isAdmin && talents && talents.meta.total > talents.meta.per_page && (
                  <Paginator
                    rows={talents.meta.per_page}
                    totalRecords={talents.meta.total}
                    first={(talents.meta.current_page - 1) * talents.meta.per_page}
                    onPageChange={getTalents}
                  ></Paginator>
                )}
              </>
            )}
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
