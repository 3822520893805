import React, { createContext, useContext } from "react";
import useBrief from "~/hooks/useBrief";
import useBriefFilterSettings from "~/hooks/useBriefFilterSettings";
import { IBriefFilterSettings, IBriefState } from "~/interfaces/brief";

export const BriefContext = createContext(null as (IBriefState & IBriefFilterSettings) | null);

export const useBriefContext = () => {
  const context = useContext(BriefContext);
  if (!context) {
    throw new Error("useBriefContext must be used within a BriefContextProvider");
  }
  return context;
};

const BriefContextProvider = ({ children }: any) => {
  return (
    <BriefContext.Provider
      value={{
        ...useBrief(),
        ...useBriefFilterSettings(),
      }}
    >
      {children}
    </BriefContext.Provider>
  );
};

export default BriefContextProvider;
