import { useState } from "react";
import { DashboardService } from "~/API/DashboardService";
import { IDashboardState } from "~/contexts/DashboardContext";

const useDashboard = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(undefined as any);
  const token = localStorage.getItem("user");

  const loadDashboard = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await DashboardService.loadDashboard();
        res.status === 200 && setData(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const loadClientDashboard = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await DashboardService.loadClientDashboard();
        res.status === 200 && setData(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const resetDashboard = () => {
    setData(undefined);
  };

  return {
    isFetching,
    data,
    loadDashboard,
    loadClientDashboard,
    resetDashboard,
  } as IDashboardState;
};
export default useDashboard;
