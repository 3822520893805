import React from "react";
import "./PageNotFound.scss";

export default function PageNotFound() {
  return (
    <div className="page-not-found">
      <div className="error-block">
        <p>Oops...</p>
        <p className="error-code">404</p>
        <p>Not Found</p>
      </div>
    </div>
  );
}
