import Axios from "axios";
import { Dispatch } from "redux";
import { push } from "react-router-redux";
import Talent, { ITalent } from "../constants/talent";
import User from "../constants/user";
import { API_URL } from "~/config";
import { buildPaginatedQuery, formValuesExempt } from "~/utils";
import { IState } from "../reducers/index";
import { TOAST_SUCCESS_MESSAGE, TOAST_ERROR_MESSAGE, TOAST_IMAGE_SUCCESS } from "../constants/toasts";
import Toasts from "../constants/toasts";
import { DataTableSortOrderType } from "primereact/datatable";
import { FormikProps, FormikValues } from "formik";
import { ITag } from "~/interfaces/common";

let controller: any;
const createController = () => {
  controller = new AbortController();
};

// ------------------------------------
// Actions
// ------------------------------------
export default {
  addTalentGoto:
    (talents: number[] | number, origin: string = "talent", addTalentGotoSuccess: any, cb: any = null) =>
    async (dispatch: Dispatch, getState: () => IState) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        const formattedTalents = [talents].flat();
        try {
          dispatch(Talent.addTalentGotoRequest(formattedTalents));
          const res = await Axios.post(`${API_URL}/client_admin/goto`, { talents: formattedTalents });

          const data = (origin === "brief" && res.data.find((t: ITalent) => t.id === formattedTalents[0])) ?? null;

          origin === "talent" && dispatch(Talent.addTalentGotoSuccess(res));
          origin === "brief" && data && addTalentGotoSuccess({ data });

          cb && cb([]);

          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        } catch (err) {
          dispatch(Talent.addTalentGotoFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        }
      }
    },
  removeTalentGoto:
    (talents: number[] | number, cb?: Function) => async (dispatch: Dispatch, getState: () => IState) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const formattedTalents = [talents].flat();

          dispatch(Talent.removeTalentGotoRequest(formattedTalents));
          const res = await Axios.delete(`${API_URL}/client_admin/goto`, { data: { talents: formattedTalents } });
          dispatch(Talent.removeTalentGotoSuccess(res));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));

          cb && cb();
        } catch (err) {
          dispatch(Talent.removeTalentGotoFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        }
      }
    },
  listTalents: () => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        dispatch(Talent.listTalentsRequest());
        Axios.get(`${API_URL}/talents`)
          .then((res) => {
            dispatch(Talent.listTalentsSuccess(res));
            resolve(res);
          })
          .catch((err) => {
            dispatch(Talent.listTalentsFailure(err));
            reject(err);
          });
      }
    });
  },
  listTalentsPaginated:
    (
      isClientAdmin: boolean,
      page = 1,
      discipline?: string,
      search?: string,
      per_page?: number,
      sort?: { field: string; order: DataTableSortOrderType },
      other?: { [key: string]: any }
    ) =>
    async (dispatch: Dispatch, getState: () => IState) => {
      const query = buildPaginatedQuery(page, sort, { discipline, search, per_page, ...other });
      const token = getState().user?.authenticatedUser?.token;

      if (token) {
        try {
          createController();
          dispatch(Talent.listTalentsPaginatedRequest());
          const res = await Axios.get(`${API_URL}/${isClientAdmin ? "client_admin/" : ""}talents/paginated${query}`, {
            signal: controller?.signal,
          });
          dispatch(Talent.listTalentsPaginatedSuccess(res));
        } catch (err) {
          if (Axios.isCancel(err)) {
            console.error(err?.message);
          } else {
            dispatch(Talent.listTalentsPaginatedFailure(err));
          }
        }
      }
    },
  listTalentNetworkPaginated:
    (
      clientId: string,
      page = 0,
      discipline?: string,
      search?: string,
      per_page?: number,
      sort?: { field: string; order: DataTableSortOrderType },
      other?: { [key: string]: any }
    ) =>
    async (dispatch: Dispatch, getState: () => IState) => {
      const query = buildPaginatedQuery(page, sort, { discipline, search, per_page, ...other });
      const token = getState().user?.authenticatedUser?.token;

      if (token) {
        try {
          dispatch(Talent.listTalentsPaginatedRequest());
          const res = await Axios.get(`${API_URL}/client/${clientId}/talents/paginated${query}`);
          dispatch(Talent.listTalentsPaginatedSuccess(res));
        } catch (err) {
          dispatch(Talent.listTalentsPaginatedFailure(err));
        }
      }
    },
  createTalent: (talent: ITalent) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const data = new FormData();
          const fields = Object.keys(talent);
          fields.map((field) => {
            const fields = ["custom_skills", "sectors", "skills", "starred", "users"];
            if (fields.includes(field)) {
              data.append(field, JSON.stringify(talent[field]));
            } else {
              data.append(field, talent[field]);
            }
          });
          dispatch(Talent.createTalentRequest());
          const res = await Axios.post(`${API_URL}/talent`, data);
          dispatch(Talent.createTalentSuccess());
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          dispatch(push(`/talents/${res.data.id}`));
          resolve(res);
        } catch (err) {
          dispatch(Talent.createTalentFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
  getTalent: (talentId: number, isClientAdmin: boolean) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Talent.getTalentRequest());
          const res = await Axios.get(`${API_URL}/${isClientAdmin ? "client_admin/" : ""}talent/${talentId}`);
          dispatch(Talent.getTalentSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(Talent.getTalentFailure(err));
          reject(err);
        }
      }
    });
  },
  getTalentRebook:
    (userId: number, isAdmin: boolean, isClientAdmin: boolean) => (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        const url = isAdmin
          ? `${API_URL}/user/${userId}/direct_talents`
          : isClientAdmin
          ? `${API_URL}/client_admin/direct_talents`
          : "";
        if (token) {
          try {
            dispatch(Talent.getTalentRebookRequest());
            if (url) {
              const res = await Axios.get(url);
              dispatch(Talent.getTalentRebookSuccess(res));
              resolve(res);
            } else {
              throw new Error("no permission to the request");
            }
          } catch (err) {
            dispatch(Talent.getTalentRebookFailure(err));
            reject(err);
          }
        }
      });
    },
  saveTalentRebook: (rebook: FormikProps<FormikValues>) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      const isExempt = rebook.exempt || rebook.umbrella || rebook.ir35_compliant;
      const payload = isExempt ? formValuesExempt(rebook) : rebook;
      if (token) {
        try {
          dispatch(Talent.saveTalentRebookRequest());
          const res = await Axios.post(`${API_URL}/user/${rebook.author_id}/direct_book`, payload);
          dispatch(Talent.saveTalentRebookSuccess(res));
          dispatch(push(`/briefs/${res.data.id}`));
          resolve(res);
        } catch (err) {
          dispatch(Talent.saveTalentRebookFailure(err));
          reject(err);
        }
      }
    });
  },
  saveClientAdminTalentRebook: (rebook: any) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Talent.saveClientAdminTalentRebookRequest());
          const res = await Axios.post(`${API_URL}/client_admin/direct_book`, rebook);
          dispatch(Talent.saveClientAdminTalentRebookSuccess(res));
          dispatch(push(`/briefs/${res.data.id}`));
          resolve(res);
        } catch (err) {
          dispatch(Talent.saveClientAdminTalentRebookFailure(err));
          reject(err);
        }
      }
    });
  },
  updateTalent: (talent: any) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Talent.updateTalentRequest());

          delete talent.creative_partner_id;
          delete talent.matches;
          delete talent.users;

          const res = await Axios.put(`${API_URL}/talent/${talent.id}`, talent);
          dispatch(Talent.getTalentSuccess(res));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          resolve(res);
        } catch (err) {
          dispatch(Talent.updateTalentFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
  updateTalentWork: (talent: any) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Talent.updateTalentRequest());
          const res = await Axios.put(`${API_URL}/talent/${talent.id}/talent_work_profile`, talent.work_profile);
          dispatch(Talent.updateTalentWorkProfileSuccess(res));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          resolve(res);
        } catch (err) {
          dispatch(Talent.updateTalentFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
  saveProfileImage: (id: string, blob: Blob) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        const data: FormData = new FormData();
        data.append("image_url", blob);
        data.append("_method", "PUT");
        dispatch(Talent.saveTalentFileRequest());
        Axios.post(`${API_URL}/talent/${id}`, data)
          .then((res) => {
            dispatch(Talent.saveTalentFileSuccess(res));
            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_IMAGE_SUCCESS }]));
            resolve(res);
          })
          .catch((err) => {
            dispatch(Talent.saveTalentFileFailure(err));
            dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
            reject(err);
          });
      } else {
        reject();
      }
    });
  },
  updateTalentNotes:
    (talentNote: any, shouldUpdateTalentsPaginated = false) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const talent = getState().talent.talent;
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          try {
            dispatch(Talent.updateTalentNotesRequest());
            const res = await Axios.put(`${API_URL}/client_admin/talent/${talent?.id}/note`, talentNote);
            dispatch(Talent.updateTalentNotesSuccess(res));
            shouldUpdateTalentsPaginated &&
              dispatch(Talent.updateTalentPaginatedNotes({ id: talent?.id, note: res.data }));
            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
            resolve(res);
          } catch (err) {
            dispatch(Talent.updateTalentNotesFailure());
            dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
            reject(err);
          }
        }
      });
    },
  updateTalentLockStatus: (talent: any) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const { id: talent_id, isBlocked } = talent;
      const token = getState().user?.authenticatedUser?.token;
      let result;
      if (token) {
        try {
          dispatch(Talent.updateTalentLockStatusRequest());

          if (isBlocked) {
            result = await Axios.post(`${API_URL}/client_admin/block`, { talent_id });
          } else {
            result = await Axios.delete(`${API_URL}/client_admin/unblock`, { data: { talent_id } });
          }

          dispatch(Talent.updateTalentLockStatusSuccess(result));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));

          resolve(result);
        } catch (err) {
          dispatch(Talent.updateTalentLockStatusFailure());
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
  addTalentTag: (id: number, tag: ITag) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch(Talent.addTalentTagRequest());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const res = await Axios.post(`${API_URL}/talent/${id}/tag/${tag.id ? tag.id : ""}`, tag.id ? null : tag);
          const newTag = { color: res.data.color, name: res.data.name, id: res.data.id };

          dispatch(Talent.addTalentTagSuccess({ id, tag: newTag }));
          !tag.id && dispatch(User.addNewTag(newTag));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          resolve(newTag);
        } catch (err) {
          dispatch(Talent.addTalentTagFailure(err));
          dispatch(
            Toasts.setToasts([
              { severity: "error", summary: "", detail: err?.response?.data?.message || TOAST_ERROR_MESSAGE },
            ])
          );
          reject(err);
        }
      }
    });
  },
  removeTalentTag: (id: number, tagId: number) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch(Talent.removeTalentTagRequest());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const res = await Axios.delete(`${API_URL}/talent/${id}/tag/${tagId}`);

          dispatch(Talent.removeTalentTagSuccess({ id, tagId }));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          resolve(res);
        } catch (err) {
          dispatch(Talent.removeTalentTagFailure(err));
          dispatch(
            Toasts.setToasts([
              { severity: "error", summary: "", detail: err?.response?.data?.message || TOAST_ERROR_MESSAGE },
            ])
          );
          reject(err);
        }
      }
    });
  },
  reset: () => async (dispatch: Dispatch) => {
    controller?.abort();
  },
};
