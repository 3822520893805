@import "../../mixins.scss";

.tag-form {
  display: flex;
  flex-direction: column;
  .p-inputtext {
    width: 100%;
  }
  .tag-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid $lightGray;
    padding: 0.571rem 0;
    .p-button {
      &:only-child {
        margin-left: auto;
      }
    }
  }
}

.tag-preview {
  position: relative;
  display: flex;
  padding: 30px 20px;
  background: $lightGray;
  margin-bottom: 15px;
  &-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#ffffff, 0.4);
    .pi-spinner {
      color: #707070;
    }
  }
  .p-tag {
    width: 100%;
    min-height: 35px;
    text-transform: capitalize;
  }
}

.colorPanel {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  margin-bottom: 8px;
  > .p-tag {
    position: relative;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px transparent solid;
    height: 32px;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &.selected {
      border: 2px $primaryColor solid;
      &:after {
        content: "";
        position: absolute;
        inset: 0;
        border: 2px #ffffff solid;
        pointer-events: none;
      }
    }
  }
}
