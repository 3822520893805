import moment from "moment";
import { Badge } from "primereact/badge";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useGlobalContext } from "~/contexts/GlobalContext";
import Loader from "../Loader/Loader";
import "./BriefDatesDaysCount.scss";
import { useBriefContext } from "~/contexts/BriefContext";
import { IBriefValidate } from "~/interfaces/brief";
import BriefDays from "~/forms/BriefViewDetailsForm/BriefDays";

interface Props {
  data: IBriefValidate;
  callback?: (dates: { duration_days: number; duration_weekends: number }) => void;
  bookingStatus?: string;
}

export default function BriefDatesDaysCount(props: Props) {
  const { validateBriefDates, validatedDates: dates, isValidatingDates } = useBriefContext();
  const {
    global: { optionsNormalization },
  } = useGlobalContext();

  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const isTypeNumberDuration = props.data.duration_id === DURATION_ID?.NUMBER;
  const isExactWorkingDates = props.data.duration_id === DURATION_ID?.EXACT;
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  const isPermanentHiringBrief = props.data.contract_id === CONTRACT_TYPES.PERMANENT;

  let payload: IBriefValidate = {
    client_id: props.data.client_id,
    start_date: moment(props.data.start_date).format("YYYY-MM-DD"),
    end_date: moment(props.data.end_date ?? props.data.start_date).format("YYYY-MM-DD"),
    include_weekends: props.data.include_weekends,
    duration_id: props.data.duration_id,
  };
  if (isTypeNumberDuration) {
    payload.duration_days = props.data.duration_days;
  }
  if (isExactWorkingDates) {
    payload.dates = props.data.dates;
  }

  useEffect(() => {
    !isPermanentHiringBrief && validateBriefDates(payload);
  }, []);

  return (
    <>
      {isPermanentHiringBrief ? (
        <p className="BriefDatesDaysCount__permanent-brief-text">
          Ok, ready to go! Just confirming you are okay to approach talent for your{" "}
          <span className="BriefDatesDaysCount__job-title">{props.data.job_title}</span>, permanent brief.
        </p>
      ) : (
        <>
          {props.data?.talent_rate ? (
            <>
              <p>
                {props?.bookingStatus === "PENDING"
                  ? "Please review the new booking details below before submitting your request."
                  : "Ok, ready to go! Please review the final dates and agreed booking rate before submitting your booking request."}
              </p>
              <h3>
                {props.data?.contract_id === CONTRACT_TYPES?.FIXEDTERM && "Agreed Fixed Term Amount: "}
                {props.data?.contract_id === CONTRACT_TYPES?.FREELANCE && "Agreed Day Rate: "}£{props.data?.talent_rate}
              </h3>
            </>
          ) : (
            <p>
              Ok, ready to go! Just checking you are cool for me to approach our talent. Please, review brief dates.
            </p>
          )}
          <h2>
            <small>
              {moment(props.data.start_date).format("DD/MM/YYYY")} —{" "}
              {moment(props.data.end_date ?? props.data.start_date).format("DD/MM/YYYY")}
            </small>
          </h2>
          {!dates || isValidatingDates ? (
            <Loader />
          ) : (
            <>
              {isExactWorkingDates && <BriefDays dates={props.data.dates} />}
              <div className="BriefDatesDaysCount__stats">
                <div
                  className={classNames({
                    ["calendar-block"]: props.data.include_weekends || isTypeNumberDuration || isExactWorkingDates,
                  })}
                >
                  <i className="pi pi-calendar p-mr-4 p-text-secondary p-overlay-badge">
                    <Badge value={dates.duration_days} severity="danger" />
                  </i>
                  <span>
                    Weekdays
                    {!props.data.include_weekends && !isTypeNumberDuration && !isExactWorkingDates && (
                      <>
                        <br />
                        (weekends excluded)
                      </>
                    )}
                  </span>
                </div>
                {(props.data.include_weekends || isTypeNumberDuration || isExactWorkingDates) && (
                  <>
                    <div>
                      <i className="pi pi-plus" />
                    </div>
                    <div className="calendar-block">
                      <i className="pi pi-calendar p-mr-4 p-text-secondary p-overlay-badge">
                        <Badge value={dates.duration_weekends} severity="danger" />
                      </i>
                      <span>Weekend Days</span>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
