import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cn from "classnames";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import * as permissionType from "~/constants";
import { useBriefContext } from "~/contexts/BriefContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import TalentPreferences from "~/routes/Talent/TalentPreferences";
import { ITalent } from "~/store/constants/talent";
import { IState } from "../../store/reducers/index";
import useDebounce from "../../components/common/debounce";
import actions from "../../store/actions";
import "./AddMatchModal.scss";

const defaultSort: {
  field: string;
  order: DataTableSortOrderType;
} = {
  field: "name",
  order: 1,
};

const emptyTalents = {
  data: [],
  meta: {
    current_page: 0,
    per_page: 0,
    total: 0,
  },
};

export default function AddMatchModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { talents_to_add, onSubmit, isAdmin, briefId, excluded_talents } = modalProps || {};
  const { adminAddableTalents, listAdminAddableTalents, isFetchingTalents, setAdminAddableTalents } = useBriefContext();
  const { userAccess } = usePermissionContext();
  const invalidRef = useRef<(HTMLElement | null)[]>([]);

  const sortFunc = sortBackend();

  const talents = isAdmin
    ? adminAddableTalents || emptyTalents
    : useSelector((state: IState) => state.talent.talentsPaginated || emptyTalents);

  const loading = isAdmin ? isFetchingTalents : useSelector((state: IState) => state.talent.isFetching);

  const [sort, setSort] = useState(defaultSort);

  const [talent, setTalent] = useState<{ id: number | null; name: string | null }>({ id: null, name: null });
  const [search, setSearch] = useState("");
  const debouncedSearch = isAdmin ? useDebounce(search, 1000) : undefined;

  const excludedTalentsIds = excluded_talents.map((talent: ITalent) => talent.id);

  useEffect(() => {
    getTalents({ page: 0 });
  }, [sort, debouncedSearch]);

  function getTalents({ page = 0 }: { page: number }) {
    isAdmin
      ? listAdminAddableTalents({ briefId, page, search, sort })
      : dispatch(
          actions.talent.listTalentsPaginated(
            userAccess(permissionType.accessClientAdmin),
            page,
            undefined,
            search,
            15,
            sort
          )
        );
  }

  function handleCloseModal() {
    dispatch(actions.modal.closeModal());
    setAdminAddableTalents(undefined);
  }

  function confirmAddMatching() {
    onSubmit(talent.id);
    handleCloseModal();
  }

  function sortBackend() {
    let sorting = false;
    function askSort(e: { sortField: any; sortOrder: any }) {
      if (!sorting) {
        sorting = true;
        setSort({
          field: e.sortField,
          order: e.sortOrder,
        });
      }
      return 0;
    }
    return askSort;
  }
  const renderColumnName = (rowData: ITalent, { rowIndex }: { rowIndex: number }) => (
    <div className="flex">
      <Tooltip position="top" target={invalidRef.current[rowIndex]} />
      {isAdmin && rowData?.is_invalid && (
        <i
          ref={(el) => (invalidRef.current[rowIndex] = el)}
          className="pi pi-exclamation-circle invalid-talent"
          style={{ marginRight: "10px" }}
          data-pr-tooltip="Invalid talent"
        />
      )}
      <span className={cn(rowData?.is_invalid && "invalid-talent")}>{rowData.name}</span>
      {isAdmin && <TalentPreferences rowData={rowData} />}
    </div>
  );

  const rowClass = (rowData: any) => (isAdmin ? { "row-blocked": rowData.matchable !== "100%" } : {});

  function renderColumnAvailability(rowData: { id: number; is_available: boolean; start_from: string }) {
    let colorClass = "";
    let status = "";
    const isExcluded = excludedTalentsIds.includes(rowData.id);
    const diffInDays = moment(rowData.start_from).diff(moment().startOf("day"), "days");

    switch (true) {
      case isExcluded:
        colorClass = "declined";
        status = "Excluded";
        break;
      case !rowData.is_available:
        colorClass = "declined";
        status = "Unavailable";
        break;
      case diffInDays <= 0:
        colorClass = "accepted";
        status = "Available now";
        break;
      case diffInDays > 0 && diffInDays <= 30:
        colorClass = "requested";
        status = `From ${moment(rowData.start_from).format("DD/MM/YY")}`;
        break;
      case diffInDays > 0 && diffInDays > 30:
        colorClass = "pending";
        status = `From ${moment(rowData.start_from).format("DD/MM/YY")}`;
        break;
    }

    return (
      <span className={`matching-status ${colorClass}`} title={status}>
        {status}
      </span>
    );
  }

  function renderButton({
    id,
    name,
    is_available,
    is_invited,
  }: {
    id: number;
    name: string;
    is_available: boolean;
    is_invited: boolean;
  }) {
    const isExcluded = excludedTalentsIds.includes(id);
    if (!userAccess(permissionType.accessAdmin) && is_available && !isExcluded) {
      return <Button label="Add" onClick={() => setTalent({ id, name })} />;
    }
    if (userAccess(permissionType.accessAdmin)) {
      return (
        <Button label={is_invited ? "Invited" : "Add"} disabled={is_invited} onClick={() => setTalent({ id, name })} />
      );
    }
    return <Button label="Add" disabled />;
  }

  const talents_to_show = talents_to_add
    ? talents_to_add.filter(
        (item: { name: string; specialism_name: string }) =>
          search === "" ||
          item.name.toLowerCase().includes(search) ||
          item?.specialism_name?.toLowerCase().includes(search)
      )
    : talents?.data ?? [];

  return (
    <Dialog header="Select Talent" className="AddMatchModal" onHide={handleCloseModal} modal visible>
      {talent.id ? (
        <>
          <p>
            Are you sure you want to send the Brief to <strong>{talent?.name}</strong>?
          </p>
          <Button
            icon="pi pi-chevron-left"
            className="p-button-secondary"
            label="Back"
            onClick={() => setTalent({ id: null, name: null })}
          />
          &nbsp;
          <Button icon="pi pi-send" label="Send" onClick={confirmAddMatching} />
        </>
      ) : (
        <>
          <InputText
            placeholder="Search for talent"
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
            value={search}
          />
          <DataTable
            autoLayout={true}
            className="AddMatch__talents"
            emptyMessage={`No talent to add`}
            rowClassName={rowClass}
            value={talents_to_show}
            sortOrder={sort.order}
            sortField={sort.field}
            loading={loading}
            onSort={sortFunc}
          >
            <Column body={renderColumnName} field="name" header="Talent name / Team name" sortable={true} />
            <Column field="specialism_name" header="Specialism" sortable={true} />
            <Column body={renderColumnAvailability} field="start_from" header="Availability" sortable={true} />
            <Column header="" body={renderButton} />
          </DataTable>
          {isAdmin && talents && talents.meta.total > talents.meta.per_page && (
            <Paginator
              rows={talents.meta.per_page}
              totalRecords={talents.meta.total}
              first={(talents.meta.current_page - 1) * talents.meta.per_page}
              onPageChange={getTalents}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
