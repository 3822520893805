import React from "react";
import { FormikContextType } from "formik";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import "../../CreateBriefForm/CreateBriefForm.scss";

interface IWorkSettings {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}
export default function WorkSettings(props: IWorkSettings) {
  const { formik, isViewOnly = false } = props;

  const workSettingsOptions = [
    {
      id: 0,
      code: "WORK_REMOTE",
      name: "Remote Only (WFH)",
    },
    {
      id: 1,
      code: "WORK_ONSITE",
      name: "Office Only (On-site)",
    },
    {
      id: 2,
      code: "WORK_HYBRID",
      name: "Remote & Office (Hybrid)",
    },
  ];

  return (
    <div className="p-fluid field field-required work-setting">
      <label htmlFor="work_setting">Work Setting Expectations</label>
      <ApRadioGroup
        formik={formik}
        name="work_setting"
        noField
        options={workSettingsOptions || []}
        disabled={isViewOnly}
      />
    </div>
  );
}
