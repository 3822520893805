import { Form, Formik } from "formik";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useMemo, useState } from "react";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import useBrief from "~/hooks/useBrief";
import { MessageTalentSchema } from "~/schemas/BriefViewSchema";
import "./MessageTalent.scss";

const CUSTOM_MESSAGE_ID = 5;

type IMessageTalentItem = { talent_id: number; talent_name: string };

type Props = {
  briefId: number;
  talents: IMessageTalentItem[];
  onClose: () => void;
};

export default function MessageTalent({ briefId, talents, onClose }: Props) {
  const [screen, setScreen] = useState<number>(0);
  const [selected, setSelected] = useState<IMessageTalentItem[]>(talents);

  const { sendMessageFromBrief } = useBrief();

  const options = useMemo(
    () => [
      {
        id: 1,
        name: "The client is currently reviewing the talent matched to their brief. We’ll be in touch as soon as we have an update.",
      },
      {
        id: 2,
        name: "The client is still awaiting budget sign off for this brief. We’ll be in touch as soon as we have an update.",
      },
      {
        id: 3,
        name: "We appreciate that the start date for this brief is approaching, however we are still waiting to hear back from the client. We’ll be in touch as soon as we have an update.",
      },
      {
        id: 4,
        name: "The client needs a little bit more time on this one, but rest assured that the brief is still open. We'll get back to you as soon as we have an update.",
      },
      { id: CUSTOM_MESSAGE_ID, name: "Custom message" },
    ],
    []
  );

  function getModalButtons(
    { message, messageType }: { message: string; messageType: number },
    messageError: string | undefined
  ) {
    const modalButtons = [];

    if (screen === 0) {
      modalButtons.push({
        disabled: !selected.length,
        onClick: () => setScreen(1),
        text: `Next`,
      });
    }

    if (screen === 1) {
      modalButtons.push(
        {
          disabled: false,
          onClick: () => setScreen(0),
          text: `Back`,
        },
        {
          disabled: !!messageError,
          //TODO: replace with action when BE will ready
          onClick: () => {
            sendMessageFromBrief(
              briefId,
              selected.map(({ talent_id }) => ({ id: talent_id })),
              messageType === CUSTOM_MESSAGE_ID
                ? message
                : (options.find(({ id }) => id === messageType)?.name as string)
            ).then(onClose);
          },
          text: `Send`,
        }
      );
    }

    return modalButtons;
  }

  return (
    <Formik
      initialValues={{
        message: "",
        messageType: 1,
      }}
      validationSchema={MessageTalentSchema(CUSTOM_MESSAGE_ID)}
      onSubmit={() => {}}
    >
      {({ values, errors }) => (
        <Form>
          <Dialog
            className="MessageTalentModal"
            header={`Message Talent`}
            footer={
              <footer>
                {[...getModalButtons(values, errors.message)].map((button, i: number) => (
                  <Button disabled={!!button.disabled} type="button" key={i} onClick={button.onClick}>
                    {button.text}
                  </Button>
                ))}
              </footer>
            }
            visible={true}
            modal={true}
            onHide={onClose}
          >
            {screen === 0 && (
              <DataTable
                autoLayout={true}
                value={talents}
                selection={selected}
                onSelectionChange={({ value }) => setSelected(value)}
              >
                <Column selectionMode="multiple" style={{ width: "3em" }} />
                <Column field="talent_name" header="Talent name / Team name" sortable={true} />
              </DataTable>
            )}
            {screen === 1 && (
              <div className="MessageWrapper">
                <ApRadioGroup name="messageType" options={options} selectedProp="id" />
                {values.messageType === CUSTOM_MESSAGE_ID && <ApInputTextarea id="message" maxChars={300} />}
              </div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
