import { useState } from "react";
import { useDispatch } from "react-redux";
import { ConversationService } from "~/API/ConversationService";
import Toasts, { TOAST_ERROR_MESSAGE } from "~/store/constants/toasts";
import * as Conversation from "../interfaces/conversation";

const useConversation = () => {
  const dispatch = useDispatch();
  const [isFetchingConversation, setIsFetchingConversation] = useState(false);
  const [isFetchingConversations, setIsFetchingConversations] = useState(false);
  const [conversation, setConversation] = useState(undefined as Conversation.IConversation | undefined);
  const [conversations, setConversations] = useState(undefined as Conversation.IConversation[] | undefined);

  const getConversation = async (ge_psid: string) => {
    try {
      setIsFetchingConversation(true);
      setConversation(undefined);
      const { status, data } = await ConversationService.getConversation(ge_psid);
      status === 200 && setConversation(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetchingConversation(false);
    }
  };
  const getConversations = async (page: number, search: string) => {
    try {
      setIsFetchingConversations(true);
      setConversations(undefined);
      const { status, data } = await ConversationService.getConversations(page, search);
      status === 200 && setConversations(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetchingConversations(false);
    }
  };

  return {
    isFetchingConversation,
    isFetchingConversations,
    conversation,
    conversations,
    getConversation,
    getConversations,
  } as Conversation.IConversationState;
};
export default useConversation;
