import React from "react";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import { FormikContextType } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import cn from "classnames";

interface ILevels {
  formik: FormikContextType<any>;
  canShowInfoPopup?: boolean;
  isViewOnly?: boolean;
  levels: any;
  className?: string;
}
export default function Levels(props: ILevels) {
  const { formik, canShowInfoPopup = false, isViewOnly = false, levels, className } = props;

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon content="This is just an indicator of what you think you’re after. I’ll still look at others who could be great for your brief." />
    </>
  );

  return levels?.length > 1 ? (
    <div className={cn("p-fluid field field-required", className)}>
      <label htmlFor="level_id">
        Level
        {canShowInfoPopup && <InfoPopup />}
      </label>
      <ApDropdown
        disabled={!levels || isViewOnly}
        scrollHeight="320px"
        formik={formik}
        id="level_id"
        noField
        options={levels}
        optionLabel="name"
        optionValue="id"
        placeholder="Select level"
      />
    </div>
  ) : null;
}
