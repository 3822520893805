import { Field } from "formik";
import { Rating } from "primereact/rating";
import React from "react";

interface IApRating {
  noField?: boolean;
  formik?: any;
  id: string;
  label?: string;
  disabled?: boolean;
  stars?: boolean;
  validate?: Function;
  value?: number;
  showCancelBtn?: boolean;
}

export default function ApRating(props: IApRating) {
  let field;
  if (props.noField && props.formik) {
    field = props.formik.getFieldProps(props.id);
  }

  const RatingField = (field: any) => (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <Rating
        id={props.id}
        {...field}
        value={props.value || field.value || null}
        stars={props.stars}
        cancel={!!props.showCancelBtn}
        disabled={!!props.disabled}
      />
    </>
  );

  return props.noField ? <RatingField {...field} /> : (
    <Field name={props.id} validate={props.validate}>
      {({ field }) => <RatingField {...field} />}
    </Field>
  );
}
