import React, { MutableRefObject, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import "./Toaster.scss";

export default function Toaster() {
  const messagesRef = useRef() as MutableRefObject<Messages>;
  const toasts = useSelector((state: IState) => state.toasts.toasts);

  useEffect(() => {
    !!toasts.length && messagesRef.current.show(toasts);
    !toasts.length && messagesRef.current.clear();
  }, [toasts]);

  return <Messages className="Toaster" ref={messagesRef}></Messages>;
}
