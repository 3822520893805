import React, { memo, MutableRefObject } from "react";
import { Button } from "primereact/button";
import Loader from "~/components/Loader/Loader";

export const getSlides = ({
  activeStep,
  completedTime,
  callbackFunc,
  isErrorBriefCreation: isError,
}: {
  activeStep: number;
  completedTime: MutableRefObject<any>;
  callbackFunc: any;
  isErrorBriefCreation: boolean;
}) => {
  const time = activeStep === 3 && completedTime.current ? `${completedTime.current}` : "...";

  const EllipseCheckElement = memo(() => (
    <span className="ellipse-check">
      <span className="pi pi-fw pi-check"></span>
    </span>
  ));

  const StatusLoadingElement = memo(() => (
    <p className="loading-status">
      <EllipseCheckElement />
      <Loader className="carusel-loader" />
    </p>
  ));

  return [
    {
      key: 1,
      content: (
        <>
          <StatusLoadingElement />
          <p className="step">
            <span className="current"> Analyzing </span> Briefing Requirements
          </p>
        </>
      ),
    },
    {
      key: 2,
      content: (
        <>
          <StatusLoadingElement />
          <p className="step">
            <span className="current"> Matching </span> you with the best talent
          </p>
        </>
      ),
    },
    {
      key: 3,
      content: (
        <>
          <StatusLoadingElement />
          <p className="step">
            <span className="current"> Approaching </span> Talent
          </p>
        </>
      ),
    },
    {
      key: 4,
      content: (
        <div className="wrp-finish">
          {!isError ? (
            <>
              <p className="loading-status">
                <EllipseCheckElement />
              </p>
              <div className="Finish">
                <p className="header-finish">
                  <span className="current">Finished!</span>
                </p>
                <p className="content-finish">Hours of work completed in {time} seconds.</p>
                <Button className="p-button" label="Go to matches" disabled={activeStep !== 3} onClick={callbackFunc} />
              </div>
            </>
          ) : (
            <>
              <p className="loading-status"></p>
              <div className="Finish">
                <p className="header-finish">
                  <span className="current">ERROR!</span>
                </p>
                <p className="content-finish">An error has occurred</p>
                <Button className="p-button" label="Go to matches" disabled={activeStep !== 3} onClick={callbackFunc} />
              </div>
            </>
          )}
        </div>
      ),
    },
    // empty element necessary for the correct operation of the slider
    {
      key: 5,
      content: "",
    },
  ];
};
