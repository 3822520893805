import { useState } from "react";

export interface ILoadingAnimationState {
  timeBriefCreation: number;
  setIsShowStepperAnimation: (num: boolean) => void;
  isErrorBriefCreation: boolean;
  isShowStepperAnimation: boolean;
  withLoadingAnimation: (func: any, data: any) => void;
  callResult: any;
  setCallResult: (data: any) => void;
}

const useLoadingAnimation = () => {
  const [timeBriefCreation, setTimeBriefCreation] = useState(0);
  const [isShowStepperAnimation, setIsShowStepperAnimation] = useState(false);
  const [isErrorBriefCreation, setIsErrorBriefCreation] = useState(false);
  const [callResult, setCallResult] = useState(null);

  const withLoadingAnimation = async (fetchingFunction: any, data: any) => {
    try {
      setIsShowStepperAnimation(true);
      setIsErrorBriefCreation(false);
      // measure Time
      const start = new Date().getTime();
      const res = await fetchingFunction(data);
      const end = new Date().getTime();
      setTimeBriefCreation(end - start);
      setCallResult(res);
    } catch (err) {
      console.error(err);
      setIsErrorBriefCreation(true);
    }
  };

  return {
    timeBriefCreation,
    isShowStepperAnimation,
    isErrorBriefCreation,
    setIsShowStepperAnimation,
    withLoadingAnimation,
    callResult,
    setCallResult,
  } as ILoadingAnimationState;
};
export default useLoadingAnimation;
