import { AxiosError, AxiosResponse } from "axios";
import { IAttendee } from "~/components/common/Attendees/Attendees";
import { IBrief, IUmbrella } from "~/interfaces/brief";
import { IDiscipline } from "./global";

// ------------------------------------
// Constants
// ------------------------------------
export const CLIENTS_CREATE_FAILURE = "CLIENTS_CREATE_FAILURE";
export const CLIENTS_CREATE_REQUEST = "CLIENTS_CREATE_REQUEST";
export const CLIENTS_CREATE_SUCCESS = "CLIENTS_CREATE_SUCCESS";

export const CLIENTS_GET_FAILURE = "CLIENTS_GET_FAILURE";
export const CLIENTS_GET_REQUEST = "CLIENTS_GET_REQUEST";
export const CLIENTS_GET_SUCCESS = "CLIENTS_GET_SUCCESS";

export const CLIENTS_LIST_FAILURE = "CLIENTS_LIST_FAILURE";
export const CLIENTS_LIST_REQUEST = "CLIENTS_LIST_REQUEST";
export const CLIENTS_LIST_SUCCESS = "CLIENTS_LIST_SUCCESS";

export const CLIENTS_UPDATE_FAILURE = "CLIENTS_UPDATE_FAILURE";
export const CLIENTS_UPDATE_REQUEST = "CLIENTS_UPDATE_REQUEST";
export const CLIENTS_UPDATE_SUCCESS = "CLIENTS_UPDATE_SUCCESS";

export const CLIENTS_IR35_GET_FAILURE = "CLIENTS_IR35_GET_FAILURE";
export const CLIENTS_IR35_GET_REQUEST = "CLIENTS_IR35_GET_REQUEST";
export const CLIENTS_IR35_GET_SUCCESS = "CLIENTS_IR35_GET_SUCCESS";

export const CLIENTS_IR35_UPDATE_FAILURE = "CLIENTS_IR35_UPDATE_FAILURE";
export const CLIENTS_IR35_UPDATE_REQUEST = "CLIENTS_IR35_UPDATE_REQUEST";
export const CLIENTS_IR35_UPDATE_SUCCESS = "CLIENTS_IR35_UPDATE_SUCCESS";

export const CLIENTS_FILE_SAVE_FAILURE = "CLIENTS_FILE_SAVE_FAILURE";
export const CLIENTS_FILE_SAVE_REQUEST = "CLIENTS_FILE_SAVE_REQUEST";
export const CLIENTS_FILE_SAVE_SUCCESS = "CLIENTS_FILE_SAVE_SUCCESS";

// ------------------------------------
// Interfaces
// ------------------------------------

export interface IIR35 {
  id: number;
  client_id: number;
  allowed_annual_turnover: boolean | null;
  allowed_balance_sheet: boolean | null;
  allowed_perm_staff: boolean | null;
  freelancer_credits: boolean | null;
  freelancer_equipment: boolean | null;
  freelancer_introducing: boolean | null;
  freelancer_managerial: boolean | null;
  freelancer_staff: boolean | null;
  overseas: boolean | null;
  onboarded: boolean;
  ir35_compliant: boolean;
  [key: string]: number | string | boolean | IUmbrella[] | null;
  commission_rate: number;
  payment_handling_fee: number;
  po_number: boolean | null;
  invoice_auto_approve: boolean | null;
}

export interface IClientUser {
  id: number;
  fb_psid: string | null;
  ge_psid: string;
  auth_token: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  client_id: number;
  talent_id: number | null;
  reference_id: null;
  telephone: string;
  onboarded: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  must_change_password: number;
  reset_pass_token_exp: null;
  reset_pass_token: null;
  tos: number;
  nda: number;
  full_name: string;
  disciplines: { id: number; name: string }[];
  client_personal_profile: {
    completeness: string;
    exempt: boolean;
    ir35_flow: string;
    ir35_onboarded: boolean;
    umbrella_only: boolean;
    role: string;
  };
  published: boolean;
}

export interface IClient {
  address_1: string;
  address_2: string;
  created_at: string;
  description: string;
  headline: string;
  subhead: string;
  legal_name: string;
  name: string;
  postcode: string;
  published: boolean;
  city: string;
  profile_image: string;
  id: number;
  tandcsent: number;
  notes: string;
  country: string;
  closed_briefs: IBrief[];
  opened_briefs: IBrief[];
  resourced_briefs: IBrief[];
  updated_at: string;
  users: IClientUser[];
  payment_profile: IIR35;
  umbrella_companies?: IUmbrella[];
  custom_attendees?: IAttendee[];
  work_profile?: {
    display_rate: boolean;
    display_fee: boolean;
    image_url: string;
    vision_statement: string;
    company_benefits: string;
    genie_take: string;
  };
  disciplines: IDiscipline[];
}
export interface IInvoiceSettings {
  id: number;
  commission_rate: number;
  payment_handling_fee: number;
  po_number: boolean;
  invoice_auto_approve: boolean;
  custom_attendees?: IAttendee[];
}

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  createClientFailure: (err: AxiosError) => ({
    type: CLIENTS_CREATE_FAILURE,
    payload: err?.response?.data,
  }),
  createClientRequest: () => ({
    type: CLIENTS_CREATE_REQUEST,
  }),
  createClientSuccess: (res: AxiosResponse) => ({
    type: CLIENTS_CREATE_SUCCESS,
    payload: res.data,
  }),
  getClientFailure: (err: AxiosError) => ({
    type: CLIENTS_GET_FAILURE,
    payload: err?.response?.data,
  }),
  getClientRequest: () => ({
    type: CLIENTS_GET_REQUEST,
  }),
  getClientSuccess: (res: AxiosResponse) => ({
    type: CLIENTS_GET_SUCCESS,
    payload: res.data,
  }),
  listClientsFailure: (err: AxiosError) => ({
    type: CLIENTS_LIST_FAILURE,
    payload: err?.response?.data,
  }),
  listClientsRequest: () => ({
    type: CLIENTS_LIST_REQUEST,
  }),
  listClientsSuccess: (res: AxiosResponse) => ({
    type: CLIENTS_LIST_SUCCESS,
    payload: res.data,
  }),
  getClientIR35Failure: (err: AxiosError) => ({
    type: CLIENTS_IR35_GET_FAILURE,
    payload: err?.response?.data,
  }),
  getClientIR35Request: () => ({
    type: CLIENTS_IR35_GET_REQUEST,
  }),
  getClientIR35Success: (res: AxiosResponse) => ({
    type: CLIENTS_IR35_GET_SUCCESS,
    payload: res.data,
  }),
  updateClientIR35Failure: (err: AxiosError) => ({
    type: CLIENTS_UPDATE_FAILURE,
    payload: err?.response?.data,
  }),
  updateClientIR35Request: () => ({
    type: CLIENTS_IR35_UPDATE_REQUEST,
  }),
  updateClientIR35Success: (res: AxiosResponse) => ({
    type: CLIENTS_IR35_UPDATE_SUCCESS,
    payload: res.data,
  }),
  updateClientFailure: (err: AxiosError) => ({
    type: CLIENTS_IR35_UPDATE_FAILURE,
    payload: err?.response?.data,
  }),
  updateClientRequest: () => ({
    type: CLIENTS_UPDATE_REQUEST,
  }),
  updateClientSuccess: (res: AxiosResponse) => ({
    type: CLIENTS_UPDATE_SUCCESS,
    payload: res.data,
  }),
  saveClientFileFailure: (err: AxiosError) => ({
    type: CLIENTS_FILE_SAVE_FAILURE,
    payload: err?.response?.data,
  }),
  saveClientFileRequest: () => ({
    type: CLIENTS_FILE_SAVE_REQUEST,
  }),
  saveClientFileSuccess: (res: AxiosResponse) => ({
    type: CLIENTS_FILE_SAVE_SUCCESS,
    payload: res.data,
  }),
};
