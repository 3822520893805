import Axios from "axios";
import { API_URL } from "~/config";
import { IClientUser } from "~/store/constants/client";

export class ClientUsersService {
  static getClientUsers = async (clientId: number, isAdmin: boolean) =>
    await Axios.get(`${API_URL}${isAdmin ? `/client/${clientId}` : "/client_admin"}/users`);
  static attachUserRole = async (userId: number, roleId: string, isAdmin: boolean) => {
    return await Axios.put(`${API_URL}${isAdmin ? "" : "/client_admin"}/user/${userId}/roles/${roleId}/attach`);
  };
  static detachUserRole = async (userId: number, roleId: string, isAdmin: boolean) => {
    return await Axios.delete(`${API_URL}${isAdmin ? "" : "/client_admin"}/user/${userId}/roles/${roleId}/detach`);
  };
  static getRoles = async (isAdmin: boolean) => await Axios.get(`${API_URL}${isAdmin ? "" : "/client_admin"}/roles`);
  static resetCacheRoles = async () => await Axios.get(`${API_URL}/roles/flush`);
  static updateUserStatus = async (isAdmin: boolean, userId: string | number, published: string | boolean) =>
    await Axios.put(`${API_URL}/${isAdmin ? "" : "client_admin/"}user/${userId}`, { published });
  static getUserById = async (userId: number) => await Axios.get(`${API_URL}/user/${userId}`);
  static updateUserById = async (isAdmin: boolean, userId: number, data: IClientUser) =>
    await Axios.put(`${API_URL}${isAdmin ? "" : "/client_admin"}/user/${userId}`, data);
  static validateEmail = async (email: string) => await Axios.post(`${API_URL}/user/validate_email`, { email });
  static getClientPaymentProfile = async () => {
    const { status, data } = await Axios.get(`${API_URL}/client_admin`);
    if (status === 200) {
      return data;
    }
  };
}
