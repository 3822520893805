import { useLoadingAnimationContext } from "./../contexts/LoadingAnimationContext";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { BriefService } from "~/API/BriefService";
import { MutableRefObject, useMemo, useRef, useState } from "react";
import { IBrief, IBriefClientAdminData, IBriefState, IListAdminAddableTalentsData } from "~/interfaces/brief";
import { AxiosError, AxiosResponse } from "axios";
import * as Brief from "../interfaces/brief";
import { useHistory } from "react-router-dom";
import { ITalentsPaginated } from "~/store/reducers/talent";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";
import { DataTableSortOrderType } from "primereact/datatable";
import { PLATFORMS, SECTORS, SUPERPOWERS } from "~/forms/BriefViewDetailsForm/constants";
import { draftBriefValues, getSuccessMessageForBriefMatching, checkBriefForDraft } from "~/routes/Brief/helper";
import { jobUrlData } from "~/forms/BriefViewDetailsForm/helper";
import { IBooking } from "~/store/constants/bookings";
import { TalentBookingsService } from "~/API/TalentBookingsService";
import { orderBy } from "lodash";
import { arrayUnion } from "~/utils";

const useBrief = () => {
  const { withLoadingAnimation } = useLoadingAnimationContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingTalents, setIsFetchingTalents] = useState(false);
  const [isMatchAdding, setIsMatchAdding] = useState(false);
  const [isMatchUpdating, setIsMatchUpdating] = useState(false);
  const [isValidatingDates, setIsValidatingDates] = useState(false);
  const [brief, setBrief] = useState(undefined as Brief.IBrief | undefined);
  const jobUrlFormData: MutableRefObject<Object | null | undefined> = useRef<Object | number | undefined>(undefined);
  const setJobUrlFormData = (data: Object | undefined | null) => (jobUrlFormData.current = data);
  const [briefs, setBriefs] = useState<undefined | Object>(undefined);
  const [validatedDates, setValidatedDates] = useState(undefined);
  const [validatedDatesErrors, setValidatedDatesErrors] = useState(undefined);
  const [SDSBeingLoaded, setSDSBeingLoaded] = useState<number | undefined>(undefined);
  const token = useSelector((state: IState) => state.user.authenticatedUser?.token);
  const [adminAddableTalents, setAdminAddableTalents] = useState<undefined | ITalentsPaginated>(undefined);
  const { userAccess } = usePermissionContext();
  const isAdmin = userAccess(permissionType.accessAdmin);
  const clientIr35 = useSelector((state: IState) => state.client.client?.payment_profile);
  const ir35_compliant = useMemo(() => clientIr35?.ir35_compliant || false, [clientIr35]);
  const [preMatchedList, setPreMatchedList] = useState([] as Brief.IMatching[]);
  const customItems = (brief: IBrief) => [
    { name: SUPERPOWERS, items: brief?.custom_skills },
    { name: SECTORS, items: brief?.custom_sectors },
    { name: PLATFORMS, items: brief?.custom_platforms },
  ];
  const initialValues = {
    exempt: null,
    author_id: 0,
    client_id: 0,
    contract_id: null,
    ir35_compliant,
    discipline_id: null,
    specialism_id: null,
    level_id: null,
    team_id: null,
    skills: [],
    location_id: null,
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    include_weekends: false,
    dates_flexible: false,
    notes: "",
    freelancer_defines_location: null,
    freelancer_arrange_space: null,
    freelancer_flexibility: null,
    freelancer_type: "FREELANCER_TRUST",
    sds_type: "",
    freelancer_amend: "",
    freelancer_control: "",
    freelancer_other_projects: "",
    freelancer_substitute: "",
    freelancer_credits: null,
    freelancer_equipment: null,
    freelancer_introducing: null,
    freelancer_managerial: null,
    freelancer_staff: null,
    ir35_id: null,
    duration_days: 1,
    duration_id: 1,
    sds_ready: 0,
  };
  const additionalBriefValues = {
    regions: [],
    production_id: null,
    // budget: "",
    budget: null,
    budget_type: "TYPE_TOTAL",
    sectors: [],
    platforms: [],
    dates: [],
    goto_types: [],

    job_title: null,
    rate_min: null,
    rate_max: null,
    work_description: null,
    work_setting: null,
    right_requirements: null,
    permanent_roles: false,
    job_url_name: "",
    job_url: null,
    role_involves: null,
    country_of_residence: null,
    residence_city: null,
    company_benefits: null,
    excluded_talents: [],
  };
  const additionalRebookValues = {
    umbrella: null,
    talent_ids: [],
  };
  const getBriefFormValues = (additionalValues: any) => ({
    ...additionalValues,
    ...initialValues,
  });
  const requestPrefix: string = userAccess(permissionType.accessAdmin) ? "" : "/client_admin";

  const createBrief = async (data: IBrief) => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await BriefService.createBrief(data.client_id, data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          await BriefService.updateJobDescriptionForBrief(res.data.id, jobUrlData(res.data.job_url, jobUrlFormData));
          setJobUrlFormData(undefined);
        }
        return res.data;
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const createClientAdminBrief = async (data: IBrief) => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await BriefService.createClientAdminBrief(data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          await BriefService.updateJobDescriptionClientAdminBrief(
            res.data.id,
            jobUrlData(res.data.job_url, jobUrlFormData)
          );
          setJobUrlFormData(undefined);
        }
        return res.data;
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };
  const redirectAfterBriefCreated = (brief: IBrief) => {
    if (checkBriefForDraft(brief)) {
      return history.push({
        pathname: "/briefs",
        state: { briefsCategoryIndex: 4 },
      });
    }
    return history.push(`/briefs/${brief.id}`);
  };

  const saveOrConfirmCallback = (values: any, isAdmin: boolean) => {
    const createFunction = isAdmin ? createBrief : createClientAdminBrief;
    withLoadingAnimation(createFunction, values);
  };

  const getBrief = async (briefId: number) => {
    if (token) {
      try {
        setIsFetching(true);
        setBrief(undefined);
        const res = await BriefService.getBrief(briefId);
        if (checkBriefForDraft(res.data)) {
          setBrief(draftBriefValues(res.data));
        } else {
          setBrief(res.data);
        }
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getBriefMatchesByLimit = async (briefId: number, requestedMatches: number) => {
    if (token) {
      try {
        setIsFetching(true);
        setBrief((brief: IBrief) => {
          return {
            ...brief,
            matches: undefined,
          };
        });
        const res = await BriefService.getBriefMatchesByLimit(briefId, requestedMatches);

        if (res.status === 200) {
          const matchesFound = res.data?.new;
          setBrief((brief: IBrief) => {
            return {
              ...brief,
              matches: res.data.matches,
            };
          });

          const { msg, severity } = getSuccessMessageForBriefMatching(requestedMatches, matchesFound);
          dispatch(Toasts.setToasts([{ severity, summary: "", detail: msg }]));
        }
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getClientBrief = async (briefId: number) => {
    if (token) {
      try {
        setIsFetching(true);
        setBrief(undefined);
        const res = await BriefService.getClientBrief(briefId);
        if (checkBriefForDraft(res.data)) {
          setBrief(draftBriefValues(res.data));
        } else {
          setBrief(res.data);
        }
      } finally {
        setIsFetching(false);
      }
    }
  };

  const listBriefs = async (
    state: string,
    page: number,
    per_page?: number,
    search?: string,
    sort?: { field: string; order: DataTableSortOrderType },
    other?: { [key: string]: any }
  ) => {
    if (token) {
      try {
        setIsFetching(true);
        setBrief(undefined);
        const prefix: string = userAccess(permissionType.accessAdmin) ? "" : "/client_admin";
        const res = await BriefService.listBriefs(state, prefix, page, per_page, search, sort, { ...other });
        setBriefs(res.data);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const createMatch = async (talents: number | number[]) => {
    const briefId = brief?.id;
    if (token && briefId) {
      try {
        setIsMatchAdding(true);
        await BriefService.createMatch(briefId, talents);
        const res = await BriefService.getBriefMatches(briefId);
        const updatedMatchesList = orderBy(arrayUnion(brief.matches, res.data, "id"), ["score_total"], ["desc"]);

        setBrief({ ...brief, matches: updatedMatchesList });
        return res?.status === 200;
      } finally {
        setIsMatchAdding(false);
      }
    }
  };
  const createClientAdminMatch = async (talents: number | number[]) => {
    const briefId = brief?.id;
    if (token && briefId) {
      try {
        setIsMatchAdding(true);
        await BriefService.createClientAdminMatch(briefId, talents);
        // const res = await BriefService.getClientBriefMatches(briefId); TODO: uncomment once BE part done
        const res = await BriefService.getClientBrief(briefId);

        setBrief(res.data);
      } finally {
        setIsMatchAdding(false);
      }
    }
  };

  const updateBrief = async (data: IBrief, shouldBriefBePublished: boolean, isBriefDraft: boolean = false) => {
    if (token) {
      try {
        setIsFetching(true);
        let res = await BriefService.updateBrief(data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          res = await BriefService.updateJobDescriptionForBrief(
            res.data.id,
            jobUrlData(res.data.job_url, jobUrlFormData)
          );
          setJobUrlFormData(undefined);
        }

        if (shouldBriefBePublished && !isBriefDraft) {
          setBrief(undefined);
          return res.data;
        } else if (shouldBriefBePublished && isBriefDraft) {
          history.push({
            pathname: "/briefs",
            state: { briefsCategoryIndex: 4 },
          });
        } else {
          setBrief(res.data);
        }
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const updateClientAdminBrief = async (
    data: IBriefClientAdminData,
    shouldBriefBePublished: boolean,
    isBriefDraft: boolean = false
  ) => {
    if (token) {
      try {
        setIsFetching(true);
        let res = await BriefService.updateClientAdminBrief(data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          res = await BriefService.updateJobDescriptionClientAdminBrief(
            res.data.id,
            jobUrlData(res.data.job_url, jobUrlFormData)
          );
          setJobUrlFormData(undefined);
        }
        if (shouldBriefBePublished && !isBriefDraft) {
          setBrief(undefined);
          return res.data;
        } else if (shouldBriefBePublished && isBriefDraft) {
          history.push({
            pathname: "/briefs",
            state: { briefsCategoryIndex: 4 },
          });
        } else {
          setBrief(res.data);
        }
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const updateDraftBrief = (data: IBrief) => {
    return updateBrief(data, true, true);
  };

  const updateClientAdminDraftBrief = (data: IBrief) => {
    return updateClientAdminBrief(data, true, true);
  };

  const getBriefPreMatchedList = async (briefId: number) => {
    if (token) {
      try {
        setIsFetchingTalents(true);
        const res = await BriefService.getBriefPreMatchedList(briefId);

        setPreMatchedList(res.data);
      } finally {
        setIsFetchingTalents(false);
      }
    }
  };

  const resetBriefPreMatchedList = () => setPreMatchedList([]);

  const removeFromPreMatchedList = (id: number) =>
    setPreMatchedList((preMatches) => preMatches.filter(({ id: matchId }) => matchId !== id));

  const updateMatch = async (data: Brief.IMatching) => {
    if (token) {
      try {
        setIsMatchUpdating(true);
        const res = await BriefService.updateMatch(data);
        setBrief((brief: IBrief) => {
          return {
            ...brief,
            matches: [...(brief?.matches.map((match) => (match.id === res.data.id ? res.data : match)) || [])],
          };
        });
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      } finally {
        setIsMatchUpdating(false);
      }
    }
  };
  const updateClientAdminMatch = async (data: Brief.IMatching) => {
    if (token) {
      try {
        setIsMatchUpdating(true);
        const res = await BriefService.updateClientAdminMatch(data);
        setBrief((brief: IBrief) => {
          return {
            ...brief,
            matches: [...(brief?.matches.map((match) => (match.id === res.data.id ? res.data : match)) || [])],
          };
        });
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      } finally {
        setIsMatchUpdating(false);
      }
    }
  };
  const validateBrief = (data: IBrief, type: number, IR35_TYPES: any): Promise<number | AxiosError> => {
    return new Promise(async (resolve, reject) => {
      if (token) {
        try {
          const res = await BriefService.validateBrief(type, IR35_TYPES, data);
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      }
    });
  };
  const validateBriefDates = async (data: Brief.IBriefValidate) => {
    if (token) {
      try {
        setIsValidatingDates(true);
        const res = await BriefService.validateBriefDates(data);
        setValidatedDates(res.data);
      } catch (err: any) {
        setValidatedDatesErrors(err?.response?.data);
      } finally {
        setIsValidatingDates(false);
      }
    }
  };
  const addTalentGotoSuccess = (res: AxiosResponse) => {
    setBrief((brief: IBrief) => {
      return {
        ...brief,
        matches: brief.matches.map((item) =>
          item.talent.id === res.data.id ? { ...item, talent_type: res.data.type } : item
        ),
      };
    });
  };
  const cancelBrief = async (data: { briefId: number; reason_id: number; description?: string }) => {
    try {
      const res = await BriefService.cancelBrief(requestPrefix, data);
      res?.status === 200 &&
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const deleteBrief = async (briefId: number) => {
    try {
      const res = await BriefService.deleteBrief(requestPrefix, briefId);

      if (res?.status === 200 || res?.status === 204) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        briefs &&
          setBriefs({
            ...briefs,
            data: briefs?.data?.filter(({ id }) => id !== briefId),
          });
      }
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const regenerateSDS = async (data: { bookingId: number; matchId: number }) => {
    try {
      setSDSBeingLoaded(data.bookingId);
      const res = await BriefService.regenerateSDS(data.bookingId);
      if (res?.status === 200) {
        setBrief((brief: IBrief) => {
          const matchIndex = brief.matches?.findIndex((match) => match?.id === data?.matchId);
          const bookingIndex = brief.matches[matchIndex].bookings?.findIndex(
            (booking) => booking?.id === data.bookingId
          );
          matchIndex >= 0 && bookingIndex >= 0 && (brief.matches[matchIndex].bookings[bookingIndex] = res?.data);
          return { ...brief };
        });
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setSDSBeingLoaded(undefined);
    }
  };

  const updateBooking = async (
    data: { bookingId: number; matchId: number },
    payload: IBooking,
    isNeedUpdateBookingState: boolean
  ) => {
    try {
      setIsFetching(true);
      const res = await TalentBookingsService.UpdateBookingById(data.bookingId, payload, isAdmin);
      if (res?.status === 200 && isNeedUpdateBookingState) {
        setBrief((brief: IBrief) => {
          const matchIndex = brief.matches?.findIndex((match) => match?.id === data.matchId);
          const bookingIndex = brief.matches[matchIndex].bookings?.findIndex(
            (booking) => booking?.id === data.bookingId
          );
          matchIndex >= 0 && bookingIndex >= 0 && (brief.matches[matchIndex].bookings[bookingIndex] = res?.data);
          return { ...brief };
        });
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const createBooking = async (data: any, isAdmin: boolean = false) => {
    try {
      setIsFetching(true);

      const res = await TalentBookingsService.CreateBooking(data.brief_id, data, isAdmin);
      if (res?.status === 200) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        const matchToUpdate = brief?.matches.find(({ id }) => id === data.match_id);
        if (!!matchToUpdate) {
          return [...matchToUpdate.bookings, res.data];
        }
        return null;
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const trackPortfolioClick = (talentId: number, matchId: number) => {
    try {
      BriefService.trackPortfolioClick(talentId, matchId);
    } catch (err) {
      console.error(`Can't track portfolio click`, err);
    }
  };

  const trackDownloadCvClick = (talentId: number, matchId: number) => {
    try {
      BriefService.trackDownloadCvClick(talentId, matchId);
    } catch (err) {
      console.error(`Can't track download CV click`, err);
    }
  };

  const resetBrief = () => {
    setIsFetching(false);
    setIsMatchAdding(false);
    setIsMatchUpdating(false);
    setIsValidatingDates(false);
    setBrief(undefined);
    setBriefs(undefined);
    setValidatedDates(undefined);
    setValidatedDatesErrors(undefined);
    setAdminAddableTalents(undefined);
  };

  const listAdminAddableTalents = async (data: IListAdminAddableTalentsData) => {
    setIsFetchingTalents(true);
    try {
      const res = await BriefService.getAdminAddableTalents(data);
      res?.status === 200 && setAdminAddableTalents(res.data);
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetchingTalents(false);
    }
  };

  const sendMessageFromBrief = async (briefId: number, talents: { id: number }[], message: string) => {
    setIsFetching(true);
    try {
      const res = await BriefService.sendMessageFromBrief(briefId, talents, message);
      return res?.status === 200;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const checkTalentsWithSpecialismCount = async (specialism_id: number, prefix: string) => {
    try {
      return (await BriefService.checkTalentsWithSpecialismCount(specialism_id, prefix)).data;
    } catch (err) {}
  };

  const isSubmitDisabled = (
    form: { isValid?: boolean; dirty?: boolean; isSubmitting: boolean },
    initial = false
  ): boolean => {
    return initial ? !form.isValid || isFetching : !(form.dirty && form.isValid) || isFetching;
  };

  return {
    // brief state
    isFetching,
    isFetchingTalents,
    isMatchAdding,
    isMatchUpdating,
    isValidatingDates,
    SDSBeingLoaded,
    brief,
    briefs,
    validatedDates,
    validatedDatesErrors,
    adminAddableTalents,
    customItems,
    additionalBriefValues,
    additionalRebookValues,
    preMatchedList,
    // actions
    cancelBrief,
    deleteBrief,
    createBrief,
    createClientAdminBrief,
    getBrief,
    setBrief,
    getClientBrief,
    listBriefs,
    createMatch,
    createClientAdminMatch,
    updateBrief,
    updateClientAdminBrief,
    updateDraftBrief,
    updateClientAdminDraftBrief,
    updateMatch,
    updateClientAdminMatch,
    validateBrief,
    validateBriefDates,
    resetBrief,
    addTalentGotoSuccess,
    regenerateSDS,
    listAdminAddableTalents,
    setAdminAddableTalents,
    trackPortfolioClick,
    trackDownloadCvClick,
    isSubmitDisabled,
    getBriefFormValues,
    getBriefMatchesByLimit,
    jobUrlFormData,
    setJobUrlFormData,
    createBooking,
    updateBooking,
    saveOrConfirmCallback,
    redirectAfterBriefCreated,
    sendMessageFromBrief,
    getBriefPreMatchedList,
    resetBriefPreMatchedList,
    removeFromPreMatchedList,
    checkTalentsWithSpecialismCount,
  } as IBriefState;
};
export default useBrief;
