import React from "react";

export default function LineItemFormHeaders() {
  return (
    <div className="EditInvoiceModal__form__lineItems__item tb">
      <div className="delete no-icon"></div>
      <div className="index">Item</div>
      <div className="description">Description</div>
      <div className="unit">Unit</div>
      <div className="amount">Amount</div>
      <div className="qty">Qty</div>
      <div className="dates">Start Date - End Date</div>
      <div className="total">Total</div>
    </div>
  );
}
