import React from "react";
import { FormikContextType } from "formik";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";

interface IPermanentRoles {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}
export default function PermanentRoles(props: IPermanentRoles) {
  const { formik, isViewOnly = false } = props;

  return (
    <div className="col-12 field">
      <ApCheckbox formik={formik} label="Open to Temp to Perm?" name="permanent_roles" noField disabled={isViewOnly} />
    </div>
  );
}
