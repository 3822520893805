import broadcast, { IBroadcastState } from "./broadcast";
import client, { IClientState } from "./client";
import location, { ILocationState } from "./location";
import modal, { IModalState } from "./modal";
import talent, { ITalentState } from "./talent";
import toasts, { IToastsState } from "./toasts";
import user, { IUserState } from "./user";
import notifications, { INotificationsState } from "./notifications";
import skills, { ISkillsState } from "./skills";
import permissions, { IPermissionsState } from "./permissions";
import bookings, { IBookingsState } from "./bookings";
import { ITalent } from "../constants/talent";

export interface IState {
  broadcast: IBroadcastState;
  client: IClientState;
  location: ILocationState;
  modal: IModalState;
  talent: ITalentState;
  toasts: IToastsState;
  user: IUserState;
  notifications: INotificationsState;
  skills: ISkillsState;
  permissions: IPermissionsState;
  bookings: IBookingsState;
  preSelectedTalents?: ITalent[];
  preSelectedClient?: { client: number; clientUser: number };
}

export default {
  broadcast,
  client,
  location,
  modal,
  talent,
  toasts,
  user,
  notifications,
  skills,
  permissions,
  bookings,
};
