@import "../../mixins.scss";

.PersonalYouForm {
  .p-inputswitch {
    float: right;
    & + label {
      line-height: 24px !important;
    }
  }

  #uk_work_verification,
  #residence_work_verification {
    width: 100%;
  }

  .p-selectbutton.p-buttonset.p-component {
    width: 100%;
    display: flex;
    padding: 0 0.5rem;
    > .p-button {
      width: 50%;
    }
  }

  .field.grid > label {
    font-weight: bold;
  }

  &.readonly-form {
    .field {
      margin: 1.5rem auto;
      > label::after {
        content: "";
      }
    }
    .readonly-text {
      margin: 0;
    }
  }
  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
    padding: 0.725rem 0.429rem 0.225rem 0.429rem;
    .p-multiselect-token {
      margin-bottom: 0.5rem;
    }
  }
}

.languages-multiselect {
  .p-multiselect-items-wrapper {
    max-height: 350px !important;
    min-height: 350px !important;
  }
}
