import * as Yup from "yup";
import { IOptionsNormalization } from "~/hooks/useOptions";
import { yupString } from "~/utils";
import { BriefNewOverview, BriefPermanentHiringSchema, CommonBriefDetailsSchema, YNAOptions } from "./BriefNewSchema";

const dropdownMessage = "Please choose the ${label} from dropdown list";

export const BriefViewOverview = (optionsNormalization: IOptionsNormalization) => {
  const { ir35_id, ...BriefOverview } = BriefNewOverview(optionsNormalization);
  return Yup.object().shape(BriefOverview);
};

export const BriefViewDetails = (
  isSuperAdmin: boolean,
  isBriefRebook: boolean,
  optionsNormalization: IOptionsNormalization,
  skillsCount: number
) => {
  const DISCIPLINE_ID = optionsNormalization?.DISCIPLINE_ID;
  const IR35_TYPES = optionsNormalization?.IR35_TYPES;
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  return Yup.object().shape({
    ...CommonBriefDetailsSchema(optionsNormalization),
    ...BriefPermanentHiringSchema(optionsNormalization),
    include_weekends: Yup.boolean().nullable(),
    dates_flexible: Yup.boolean().nullable(),
    freelancer_defines_location: Yup.mixed<YNAOptions>()
      .nullable()
      .when(["ir35_id", "ir35_compliant"], {
        is: (ir35_id, ir35_compliant) => ir35_id === IR35_TYPES.OUTSIDE && ir35_compliant === false,
        then: Yup.mixed<YNAOptions>().oneOf(Object.values(YNAOptions)).required(),
      }),
    team_id: Yup.mixed().nullable(),
    discipline_id: isBriefRebook ? Yup.mixed().nullable() : Yup.mixed().label("discipline").required(dropdownMessage),
    specialism_id: isBriefRebook ? Yup.mixed().nullable() : Yup.mixed().label("specialism").required(dropdownMessage),
    level_id: isBriefRebook ? Yup.mixed().nullable() : Yup.mixed().label("level").required(dropdownMessage),
    production_id: Yup.number().nullable(),
    skills:
      isBriefRebook || isSuperAdmin
        ? Yup.array().nullable()
        : Yup.array()
            .of(Yup.object())
            .required("Gotta’add those powers please!")
            .min(1)
            .max(skillsCount, `Sorry, only ${skillsCount} items can be selected`),
    sectors: isSuperAdmin
      ? Yup.array()
          .nullable()
          .when("discipline_id", {
            is: DISCIPLINE_ID.SOCIAL,
            then: Yup.array().of(Yup.object()).required("Please, choose the sectors").min(1),
          })
      : Yup.array().nullable(),
    platforms: isSuperAdmin
      ? Yup.array()
          .nullable()
          .when("discipline_id", {
            is: DISCIPLINE_ID.SOCIAL,
            then: Yup.array().of(Yup.object()).required("Please, choose the platforms").min(1),
          })
      : Yup.array().nullable(),
  });
};

export const MessageTalentSchema = (CUSTOM_MESSAGE_ID: number) =>
  Yup.object().shape({
    message: Yup.string().when("messageType", {
      is: CUSTOM_MESSAGE_ID,
      then: yupString(true, 10, 500),
    }),
  });
