@import "../../../mixins.scss";

.card-tags {
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 2.1/1;
  overflow-y: auto;

  .p-tag {
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    align-self: flex-end;
    padding: 0 6px;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 4px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    max-width: calc(100% - (40% + 15px)); // width of the profile image + left margin
  }
  .p-button {
    margin-left: auto;
  }
}

.tags-settings {
  .p-sidebar-header {
    display: none;
  }
  .p-sidebar-content {
    display: flex;
    flex-direction: column;
  }
  .tags-header {
    position: relative;
    text-align: center;
    padding: 20px 0;
    border-bottom: 2px solid $lightGray;
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0 auto;
      text-align: center;
      width: calc(100% - 80px);
    }
    .p-button {
      position: absolute;
      &.icon-back {
        left: 0px;
      }
      &.icon-close {
        right: 0px;
      }
    }
  }
  .tags-body {
    flex: 1 1 100%;
  }
  .tags-table {
    height: 100%;
    .p-datatable-wrapper {
      flex: 1;
    }
  }
  .tag-form {
    height: 100%;
    .tag-form-body {
      flex: 1;
    }
  }
}
