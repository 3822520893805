import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Formik, Form, FormikProps } from "formik";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import { MostInfluentialManagementSchema } from "~/schemas/talent";
import isUndefined from "lodash/isUndefined";

interface IInfluentialEmployerModal {
  form: { client_name: string; start_year: string; end_year: string };
  index: number;
  onSubmit: ({
    client_name,
    start_year,
    end_year,
  }: {
    client_name: string;
    start_year: string;
    end_year: string;
  }) => void;
  onUpdate: (index: number, field: string, value: any) => void;
  onDelete: (index: number, field: string) => void;
  onClose: () => void;
}

export default function InfluentialEmployerModal(props: IInfluentialEmployerModal) {
  const { form, index, onSubmit, onUpdate, onDelete, onClose } = props;

  function getModalButtons(formik: FormikProps<{ client_name: string; start_year: string; end_year: string }>) {
    let modalButtons = [
      {
        disabled: !formik.dirty || !formik.isValid,
        onClick: () => {
          formik.validateForm().then((res) => {
            formik.handleSubmit();
            if (!Object.keys(res).length) {
              if (!isUndefined(index) && onUpdate) {
                onUpdate(index, "work_profile.most_influential", formik.values);
              } else {
                onSubmit(formik.values);
              }
            }
          });
        },
        text: `${form ? "Edit" : "Add"} Company`,
      },
    ];
    if (form) {
      modalButtons.unshift({
        disabled: false,
        onClick: () => {
          if (!isUndefined(index) && onDelete) {
            onDelete(index, "work_profile.most_influential");
          }
        },
        text: "Delete",
      });
    }
    return modalButtons;
  }

  return (
    <Formik
      initialValues={{
        client_name: form?.client_name || "",
        start_year: form?.start_year || "",
        end_year: form?.end_year || "",
      }}
      validationSchema={MostInfluentialManagementSchema}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Form>
          <Dialog
            header={`${form ? "Edit" : "Add"} Company`}
            footer={
              <footer>
                {[...getModalButtons(formik)].map((button: any, i: number) => (
                  <Button disabled={!!button.disabled} type="button" key={i} onClick={button.onClick}>
                    {button.text}
                  </Button>
                ))}
              </footer>
            }
            visible={true}
            modal={true}
            onHide={onClose}
          >
            <div className="p-fluid field field-required">
              <ApInputText id="client_name" label="Company Name" placeholder="Company Name" />
            </div>
            <div className="p-fluid grid">
              <div className="col-6 field field-required">
                <ApInputText
                  id="start_year"
                  label="Start Year"
                  keyfilter={/[0-9]+/}
                  maxLength={4}
                  placeholder="Start Year"
                  type="number"
                />
              </div>
              <div className="col-6 field field-required">
                <ApInputText
                  id="end_year"
                  label="End Year"
                  keyfilter={/[0-9]+/}
                  maxLength={4}
                  placeholder="Finish Year"
                  type="number"
                />
              </div>
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
