type FormItem = {
  type: string;
  name: string;
  text: string;
  popup?: string;
  options?: { name: string; value: boolean | string }[];
  value?: any;
  condition?: Function;
  section: "question" | "preset";
  isCheckOnlyAgree?: boolean;
};

export const defaultOptions = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

const insideOptions = [
  { name: "Yes", value: "YES" },
  { name: "No", value: "NO" },
];

const outsideOptions = [
  { name: "I Agree", value: "YES" },
  { name: "I Disagree", value: "NO" },
];

const flexibilityOptions = [
  { name: "I Agree", value: "FREELANCER_FREEHOURS" },
  { name: "I Disagree", value: "FREELANCER_FIXEDHOURS" },
];

export const OUTSIDE_IR35_VALIDATION_TEXT =
  "HOLD UP! To continue with this brief as OUTSIDE IR35 you must agree to this statement. Please update your answer or change the IR35 type of this brief";

export const briefIR35InsideForm: { getFields: () => FormItem[] } = {
  getFields: () =>
    [
      {
        type: "FormSelectButton",
        text: "Do you expect to have a similar level of control of the freelancer's working hours and projects as with your permanent staff?",
        name: "freelancer_control",
        options: insideOptions,
        section: "question",
      },
      {
        type: "FormSelectButton",
        text: "Are you happy for the freelancer(s) to nominate a substitute to do the work?",
        name: "freelancer_substitute",
        options: insideOptions,
        section: "question",
      },
      {
        type: "FormSelectButton",
        text: "Will you refuse the freelancer(s) the right to work on projects from other clients while working with you?",
        name: "freelancer_other_projects",
        options: insideOptions,
        section: "question",
      },
      {
        type: "FormSelectButton",
        text: "Will you want to amend or add further tasks for the freelancer without needing their consent during the booking?",
        name: "freelancer_amend",
        options: insideOptions,
        section: "question",
      },
      {
        type: "FormHeader",
        name: "FormHeader_0",
        text: "IR35 Pre Settings",
      },
      {
        type: "FormSelectButton",
        text: "The freelancer(s) will have a managerial position",
        name: "freelancer_managerial",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "The freelancer will not be integrated as a member of staff e.g feature on company organograms or be invited to staff parties",
        name: "freelancer_staff",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "Will the freelancer be asked to bring their own equipment to work? (Not including specialist equipment)",
        name: "freelancer_equipment",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "Will you be introducing the freelancer(s) as a freelancer to those they will work with?",
        name: "freelancer_introducing",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "Will the freelancer(s) be credited for work produced?",
        name: "freelancer_credits",
        section: "preset",
      },
      {
        type: "FormHidden",
        text: "",
        name: "sds_status",
        value: "CLIENT_CREATE",
      },
    ] as FormItem[],
};

export const briefIR35OutsideForm: { getFields: (LOCATION_ID: any) => FormItem[] } = {
  getFields: (LOCATION_ID) =>
    [
      {
        type: "FormSelectButton",
        text: "I do not expect to have a similar level of control of the freelancer's working hours as with my permanent staff",
        popup:
          "While you can’t enforce the specific working hours for the talent on a given brief, it is expected they will provide the necessary flexibility and commitment to successfully provide their services to you",
        name: "freelancer_control",
        options: outsideOptions,
        section: "question",
        isCheckOnlyAgree: true,
      },
      {
        type: "FormSelectButton",
        text: "The freelancer will determine how they go about doing the work",
        popup:
          "The freelancer providing their services are experts, as such they are best fit to determine the approach to achieve a successful outcome.",
        name: "freelancer_flexibility",
        options: flexibilityOptions,
        section: "question",
        isCheckOnlyAgree: true,
      },
      {
        type: "FormSelectButton",
        text: "The freelancer has the right to work on projects from other clients while working with you",
        popup:
          " The freelancer(s) is free to undertake other Contracts for Services for other parties at any time, either before, after, or concurrently with this Contract for Services. However, you can expect the necessary time and commitment from the freelancer to successfully deliver against your contract of Services",
        name: "freelancer_other_projects",
        options: outsideOptions,
        section: "question",
        isCheckOnlyAgree: true,
      },
      {
        type: "FormSelectButton",
        text: "The freelancer(s) can nominate a suitable substitute to do the work as long as they take responsibility for the final outcome and manage payments",
        popup:
          "The Company may utilize such persons as is necessary in order to undertake the services and make substitutions thereof provided always such persons possess the necessary skills, qualifications and experience to provide the Services. Our Clients may only reject such a replacement if in its reasonable opinion, the person to be utilized does not possess the security clearances as required by law, skills, qualifications or experience to carry out the Services.",
        name: "freelancer_substitute",
        options: outsideOptions,
        section: "question",
        isCheckOnlyAgree: true,
      },
      {
        type: "FormSelectButton",
        text: "I will require consent from the freelancer when making changes to the scope of work agreed once the booking has been confirmed",
        popup:
          "The freelancer is under no obligation to accept any changes to the agreed scope of work if added after the booking has been confirmed. Any such changes made without their consent could infringe the HMRC test for 'Mutuality of Obligation', thus causing the brief to no longer be considered as outside IR35.",
        name: "freelancer_amend",
        options: outsideOptions,
        section: "question",
        isCheckOnlyAgree: true,
      },
      {
        type: "FormSelectButton",
        text: "Will the freelancer decide the working location?",
        name: "freelancer_defines_location",
        options: insideOptions,
        section: "question",
      },
      {
        type: "FormSelectButton",
        text: "Where do you expect the talent to be working from?",
        name: "location_id",
        section: "question",
        options: [
          {
            name: "Office",
            value: LOCATION_ID.OFFICE_ONLY,
          },
          {
            name: "Remote",
            value: LOCATION_ID.REMOTE,
          },
          {
            name: "A mix",
            value: LOCATION_ID.FLEXIBLE,
          },
        ],
        condition: (values: { [key: string]: string }) => values.freelancer_defines_location === "NO",
      },
      {
        type: "FormHeader",
        name: "FormHeader_0",
        text: "IR35 Pre Settings",
      },
      {
        type: "FormSelectButton",
        text: "The freelancer(s) will have a managerial position",
        name: "freelancer_managerial",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "The freelancer will not be integrated as a member of staff e.g feature on company organograms or be invited to staff parties",
        name: "freelancer_staff",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "Will the freelancer be asked to bring their own equipment to work? (Not including specialist equipment)",
        name: "freelancer_equipment",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "Will you be introducing the freelancer(s) as a freelancer to those they will work with?",
        name: "freelancer_introducing",
        section: "preset",
      },
      {
        type: "FormSelectButton",
        text: "Will the freelancer(s) be credited for work produced?",
        name: "freelancer_credits",
        section: "preset",
      },
      {
        type: "FormHidden",
        text: "",
        name: "sds_status",
        value: "CLIENT_CREATE",
      },
    ] as FormItem[],
};
