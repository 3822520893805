import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import cn from "classnames";
import * as CSS from "csstype";

interface ITextArea {
  disabled?: boolean;
  readOnly?: boolean;
  formik?: FormikProps<FormikValues>;
  id: string;
  label?: string;
  maxChars?: number;
  noField?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  style?: CSS.Properties;
  multilinePlaceholderSafari?: React.ReactElement;
}

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification));

export default function ApInputTextarea(props: ITextArea) {
  let meta, field;
  const isShowPlaceholderSafari = isSafari && props?.multilinePlaceholderSafari;
  const safariPlaceholderStyles = { minHeight: "285px" };
  const styles = { ...(props.style ? props.style : {}), ...(isShowPlaceholderSafari ? safariPlaceholderStyles : {}) };

  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta && field ? (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <div className={cn({ ["textarea-placeholder-safari"]: isShowPlaceholderSafari })}>
        <InputTextarea
          {...field}
          disabled={props.disabled}
          readOnly={props.readOnly}
          type="text"
          value={field.value || ""}
          maxLength={props.maxChars}
          autoFocus={props.autoFocus || false}
          placeholder={!isShowPlaceholderSafari ? props.placeholder : ""}
          style={styles || undefined}
          className={cn(
            { ["data-edits"]: isShowPlaceholderSafari && field?.value?.length > 0 },
            { ["ap-inputtextarea-readonly"]: props.readOnly }
          )}
          onChange={(e) => {
            props.formik && props.formik.setFieldValue(props.id, e.target.value);
          }}
        />
        {isShowPlaceholderSafari && props.multilinePlaceholderSafari}
      </div>
      {props.maxChars && (
        <div className="charCount text-right">{props.maxChars - (field.value || "").length} characters left</div>
      )}
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.id}>
      {({ field, meta, form }: FieldProps) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <div className={cn({ ["textarea-placeholder-safari"]: isShowPlaceholderSafari })}>
            <InputTextarea
              {...field}
              disabled={props.disabled}
              readOnly={props.readOnly}
              type="text"
              value={field.value || ""}
              maxLength={props.maxChars}
              autoFocus={props.autoFocus || false}
              placeholder={!isShowPlaceholderSafari ? props.placeholder : ""}
              style={styles || undefined}
              className={cn(
                { ["data-edits"]: isShowPlaceholderSafari && field?.value?.length > 0 },
                { ["ap-inputtextarea-readonly"]: props.readOnly }
              )}
              onChange={(e) => {
                form.setFieldValue(props.id, e.target.value);
              }}
            />
            {isShowPlaceholderSafari && props.multilinePlaceholderSafari}
          </div>
          {props.maxChars && (
            <div className="charCount text-right">{props.maxChars - (field.value || "").length} characters left</div>
          )}
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
}
