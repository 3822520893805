@import "../../mixins.scss";

.BriefDetailsForm {
  .block-fields {
    display: flex;

    .p-fluid:not(:last-child),
    .field:not(:last-child) {
      margin-right: 20px;
    }
  }

  .block-specific-width > div {
    flex: 0 1 50%;
  }

  .p-picklist.p-component {
    .p-picklist-buttons {
      button:nth-child(even) {
        display: none;
      }
    }
  }

  .right-space {
    .p-picklist-list-wrapper.p-picklist-target-wrapper {
      padding-right: 54px;
    }
  }

  .p-fluid.field .p-picklist {
    width: calc(100% + 39px);
  }

  .p-picklist-list-wrapper {
    flex: 0 1 $briefWidthSm;
  }

  .flexibility {
    padding: 0;
  }

  .indent {
    margin-top: -2px;
  }

  .duration-types .p-radiobutton-label {
    display: inline;
  }

  .average-salary {
    border: 1px solid #d4272b;
    border-radius: 5px;
    padding: 8px;
  }

  .left-choose-entries {
    display: inline-block;
    margin: 0 0 10px 0;

    b {
      color: #d4272b;
    }
  }

  .disabled-source .p-picklist-source-wrapper {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(241, 245, 245, 0.7);
    }
  }

  .sectors,
  .skills,
  .languages,
  .platforms {
    > label {
      margin-right: 10px;
    }
  }

  .block-budget {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
}

.BriefViewDetailsForm {
  .separate-fields {
    display: flex;

    .p-fluid:not(:last-child),
    .field:not(:last-child) {
      margin-right: 20px;
    }
  }

  .block-specific-width > div {
    flex: 0 1 50%;
  }

  .block-specific-height {
    margin-bottom: 20px;
  }

  .left-choose-entries {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 18px;
    b {
      color: #d4272b;
    }
  }
}

.block-specific-width .job_description label {
  margin-bottom: 27px;
}

.p-fluid.field.skills,
.p-fluid.field.sectors,
.p-fluid.field.languages {
  margin-bottom: 1.5rem;
}

.p-fluid.field.languages-checkbox {
  > label {
    margin-bottom: 2px;
  }
}

.left-choose-entries.checkbox-info {
  margin: 0 0 0 27px;
  color: #999;
  font-size: 0.9em;
}

.salaryRange {
  display: block;
  flex-direction: column;

  .wrp-range-fields {
    display: flex;
  }
}

.preview_job_url {
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  > span {
    flex-grow: 10;
    font-size: 0.8em;
    padding: 11px 1rem 11px 0;
  }

  button[type="button"] {
    width: 40px;
    flex-grow: 1;
    padding: 10px;
  }
}
