import React from "react";
import { RadioButton } from "primereact/radiobutton";
import { FormikContextType, FormikHandlers, FormikProps, FormikValues, useFormikContext } from "formik";

interface IOption {
  name: string;
  selected: { display_fee?: boolean; display_rate: boolean };
}

export default function RateRadioGroup({ isAdmin }: { isAdmin: boolean }) {
  const {
    values: { display_rate, display_fee },
    setFieldValue,
  }: any = useFormikContext();

  const options: IOption[] = isAdmin
    ? [
        { name: "Day rate excluding Genie fee", selected: { display_rate: true, display_fee: false } },
        { name: "Day rate including Genie fee", selected: { display_rate: true, display_fee: true } },
        { name: "Total cost including Genie fee", selected: { display_rate: false, display_fee: true } },
        { name: "Total cost excluding Genie fee", selected: { display_rate: false, display_fee: false } },
      ]
    : [
        { name: "Display day rate", selected: { display_rate: true } },
        { name: "Display total cost", selected: { display_rate: false } },
      ];

  return (
    <>
      <label>Rate display options</label>
      {options.map(({ name, selected }, i: number) => {
        return (
          <div key={i} className="col-12">
            <RadioButton
              checked={
                isAdmin
                  ? display_fee === selected.display_fee && display_rate === selected.display_rate
                  : display_rate === selected.display_rate
              }
              name={name}
              onChange={() => Object.entries(selected).forEach(([key, value]) => setFieldValue(key, value))}
            />
            <label className="p-radiobutton-label">{name}</label>
          </div>
        );
      })}
    </>
  );
}
