import { useState } from "react";
import { useDispatch } from "react-redux";
import { FilterService } from "~/API/FilterService";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";

export interface IFiltersState {
  isFetching: boolean;
  filters: IFilter[] | null;
  getFilters: () => void;
  addFilter: (data: IFilter) => Promise<IFilter | undefined>;
  deleteFilter: (id: number) => void;
}

export type IFilter = {
  id?: number;
  name: string;
  params: {
    [key: string]: any;
  };
};

const useFilters = () => {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState<IFilter[] | null>(null);

  const getFilters = async () => {
    try {
      setIsFetching(true);
      const res = await FilterService.getFilters();
      res.status === 200 && setFilters(res.data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };
  const addFilter = async (data: IFilter) => {
    try {
      const res = await FilterService.addFilter(data);

      if (res.status === 201) {
        setFilters([...(filters ?? []), res.data]);
      }

      if (res.status === 200) {
        setFilters((filters || []).map((filter: IFilter) => (filter.id === res.data.id ? res.data : filter)));
      }

      return res.data;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const deleteFilter = async (id: number) => {
    try {
      const res = await FilterService.deleteFilter(id);
      if ([200, 204].includes(res.status)) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        setFilters(filters?.filter((filter: IFilter) => filter.id !== id));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  return {
    isFetching,
    filters,
    getFilters,
    addFilter,
    deleteFilter,
  };
};

export default useFilters;
