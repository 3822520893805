import { FormikProps, FormikValues } from "formik";
import { SelectButton } from "primereact/selectbutton";
import React from "react";
import { useDispatch } from "react-redux";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { useGlobalContext } from "~/contexts/GlobalContext";
import toasts from "~/store/actions/toasts";
import {
  briefIR35InsideForm,
  briefIR35OutsideForm,
  defaultOptions,
  OUTSIDE_IR35_VALIDATION_TEXT,
} from "./BriefQuestionnaireFields";
import isNull from "lodash/isNull";
import "./BriefQuestionnaireForm.scss";
interface Props {
  formik?: FormikProps<FormikValues>;
  callback?: () => void;
}

export default function BriefQuestionnaireForm(props: Props) {
  const dispatch = useDispatch();
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const IR35_TYPES = optionsNormalization?.IR35_TYPES;
  const formik = props.formik;
  const formFields =
    formik?.values.ir35_id === IR35_TYPES?.INSIDE
      ? briefIR35InsideForm.getFields()
      : briefIR35OutsideForm.getFields(optionsNormalization?.LOCATION_ID?.IR35_OUTSIDE);

  const checkShowValidationPopup = (value: string, isCheckOnlyAgree: boolean) => {
    if (isCheckOnlyAgree && (value === "NO" || value === "FREELANCER_FIXEDHOURS")) {
      dispatch(
        toasts.setPopup({
          content: OUTSIDE_IR35_VALIDATION_TEXT,
          buttons: [
            { text: "Review answers" },
            {
              text: "Change IR35 type",
              callback: () => props.callback && props.callback(),
            },
          ],
        })
      );
      return true;
    }
    return false;
  };
  return (
    <ul className="ir35_checker">
      {formFields
        .filter(({ condition }) => (condition ? condition(formik?.values) : true))
        .map(({ name, text, popup, options, value, type, isCheckOnlyAgree = false }, key: number) => {
          return type && type === "FormAlertText" ? (
            <div key={key} className="FormAlertText">
              {text}
            </div>
          ) : type === "FormSelectButton" ? (
            <li key={key}>
              <p>
                {text}
                {popup && (
                  <>
                    {` `}
                    <PopupIcon content={popup} />
                  </>
                )}
              </p>
              <SelectButton
                id={name}
                onChange={(e) => {
                  if (!isNull(e.value) && checkShowValidationPopup(e.value, isCheckOnlyAgree)) {
                    return false;
                  }
                  return !isNull(e.value) && formik?.setFieldValue(e.target.id, e.value);
                }}
                options={options || defaultOptions}
                optionLabel="name"
                value={formik?.values[name]}
              />
            </li>
          ) : type === "FormHidden" ? (
            <input key={key} type="hidden" name={name} value={value} />
          ) : type === "FormHeader" ? (
            <div key={key} className="p-card-title" style={{ marginLeft: "-40px", marginTop: "1em" }}>
              {text}
              {name === "FormHeader_0" && <p>Please review these answers and amend if necessary.</p>}
            </div>
          ) : null;
        })}
    </ul>
  );
}
