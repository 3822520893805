import React, { useState } from "react";
import { FormikContextType } from "formik";
import { InputNumber } from "primereact/inputnumber";
import PopupIcon from "~/components/Toaster/PopupIcon";

const popupContent = (
  <span>
    Undercutting market rates will negatively impact your chances of attracting the superstars you need.
    <br /> <br />
    If you have a ballpark salary range, put in a higher and lower amount and I will take that into account for
    matching, while also considering the superstars a little over(or under) your budget.
  </span>
);

export default function SalaryRange({
  formik,
  isViewOnly = false,
}: {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}) {
  const [rateMinCount, setRateMinCount] = useState<number>(formik.values.rate_min);
  const [rateMaxCount, setRateMaxCount] = useState<number>(formik.values.rate_max);
  const metaRateMin = formik.getFieldMeta("rate_min");
  const metaRateMax = formik.getFieldMeta("rate_max");

  const handleValueChangeMinRate = (e) => {
    formik.setTouched({ ...formik.touched, rate_min: true });
    formik.setFieldValue("rate_min", e.value);
    setRateMinCount(e.value);
  };
  const handleValueChangeMaxRate = (e) => {
    formik.setTouched({ ...formik.touched, rate_max: true });
    formik.setFieldValue("rate_max", e.value);
    setRateMaxCount(e.value);
  };
  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon popup={{ content: popupContent, buttons: [{ text: "Got It 🦾" }] }} />
    </>
  );

  return (
    <div className="field block-fields field-required salaryRange">
      <label htmlFor="salaryRange">
        Salary Range <InfoPopup />
      </label>
      <div className="wrp-range-fields">
        <div className="mr-3">
          <label htmlFor="rateMinCount" className="mr-2">
            Min
          </label>
          <InputNumber
            inputId="rateMinCount"
            min={0}
            max={10000000000}
            size={12}
            placeholder="75,000"
            prefix="£"
            locale="en-US"
            onValueChange={handleValueChangeMinRate}
            value={rateMinCount}
            useGrouping={true}
            disabled={isViewOnly}
          />
          {metaRateMin.touched && metaRateMin.error && <div className="ap-error">{metaRateMin.error}</div>}
        </div>
        <div>
          <label htmlFor="rateMaxCount" className="mr-2">
            Max
          </label>
          <InputNumber
            inputId="rateMaxCount"
            min={0}
            max={10000000000}
            size={12}
            locale="en-US"
            placeholder="85,000"
            prefix="£"
            onValueChange={handleValueChangeMaxRate}
            value={rateMaxCount}
            useGrouping={true}
            disabled={isViewOnly}
          />
          {metaRateMax.touched && metaRateMax.error && <div className="ap-error">{metaRateMax.error}</div>}
        </div>
      </div>
    </div>
  );
}
