import classnames from "classnames";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikValues } from "formik";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import React, { ReactNode } from "react";
import PopupIcon from "~/components/Toaster/PopupIcon";
import "./ApInputNumber.scss";

interface IApInputNumber extends InputNumberProps {
  noField?: boolean;
  formik?: any;
  sublabel?: string;
  type?: string;
  info?: string | ReactNode;
  label?: string;
  id: string;
}

export default function ApInputNumber(props: IApInputNumber) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta ? (
    <div
      className={classnames("ApInputNumber", props.className, {
        error: meta.touched && meta.error,
      })}
    >
      {props.label && (
        <label htmlFor={props.id} className="label">
          {props.label} {props.info && <PopupIcon content={props.info} />}
        </label>
      )}
      {props.sublabel && <span className="sublabel">{props.sublabel}</span>}
      <InputNumber
        disabled={props.disabled}
        locale="en-GB"
        id={props.id}
        mode={props.mode}
        currency={props.currency}
        placeholder={props.placeholder}
        type={props.type || "text"}
        required={props.required}
        max={props.max}
        min={props.min}
        minFractionDigits={props.minFractionDigits}
        maxFractionDigits={props.maxFractionDigits}
        name={field.name}
        onChange={(e) => props.formik.setFieldValue(props.id, e.value)}
        onBlur={field.onBlur}
        value={props.value || field.value || ""}
      />
      {props.disabled && <i className="icon-disabled" />}
      {meta.touched && meta.error && <sub>{meta.error}</sub>}
    </div>
  ) : (
    <Field name={props.id}>
      {({
        field,
        form,
        meta,
      }: {
        field: FieldInputProps<any>;
        meta: FieldMetaProps<any>;
        form: FormikProps<FormikValues>;
      }) => (
        <div
          className={classnames("ApInputNumber", props.className, {
            error: meta.touched && meta.error,
          })}
        >
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          {props.sublabel && <span className="sublabel">{props.sublabel}</span>}
          <InputNumber
            {...field}
            locale="en-GB"
            disabled={props.disabled}
            id={props.id}
            mode={props.mode}
            currency={props.currency}
            placeholder={props.placeholder}
            type={props.type || "text"}
            required={props.required}
            max={props.max}
            min={props.min}
            minFractionDigits={props.minFractionDigits}
            maxFractionDigits={props.maxFractionDigits}
            onChange={(e) => form.setFieldValue(props.id, e.value)}
            onBlur={field.onBlur}
            value={field.value ?? ""}
          />
          {meta.touched && meta.error && <sub>{meta.error}</sub>}
        </div>
      )}
    </Field>
  );
}
