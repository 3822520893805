import React from "react";
import RowAmount from "../rowCallbacks/RowAmount";
import RowInvoiceStatus from "../rowCallbacks/RowInvoiceStatus";
import RowDueDate from "../rowCallbacks/RowDueDate";
import RowInvoiceNumber from "../rowCallbacks/RowInvoiceNumber";
import RowBookingType from "../rowCallbacks/RowBookingType";

export const getInvoicesColumns = () => {
  const columns = [
    {
      name: "Number",
      sortable: false,
      field: "id",
      style: "",
      cellCallback: ({ id }: { id: number }) => <RowInvoiceNumber id={id} />,
    },
    {
      name: "Client Name",
      sortable: false,
      field: "client_name",
      style: "",
      cellCallback: (rowData: any) => <>{rowData.client.name}</>,
    },
    {
      name: "Talent Name",
      sortable: false,
      field: "talent_name",
      style: "",
      cellCallback: (rowData: any) => <>{rowData?.talent?.name}</>,
    },
    {
      name: "Ref",
      sortable: false,
      field: "reference",
      style: "",
    },
    {
      name: "Issue Date",
      sortable: false,
      field: "date",
      className: "",
      style: "fixed-column",
    },
    {
      name: "Due Date",
      sortable: false,
      field: "due_date",
      style: "fixed-column",
      cellCallback: (rowData: any) => <RowDueDate dueDate={rowData.due_date} status={rowData.status} />,
    },
    {
      name: "Booking type",
      sortable: false,
      field: "booking_type",
      style: "",
      cellCallback: (rowData: any) => <RowBookingType rowData={rowData} />,
    },
    {
      name: "Total",
      sortable: false,
      field: "total",
      style: "",
      cellCallback: (rowData: any) => <RowAmount total={rowData.total} />,
    },

    {
      name: "Status",
      sortable: false,
      field: "status",
      style: "",
      cellCallback: (rowData: any) => <RowInvoiceStatus rowData={rowData} />,
    },
  ];

  return columns;
};
