import { Dispatch } from "redux";
import Toasts, { MessageWithContent } from "../constants/toasts";
import { Message } from "primereact/messages";
import { Popup } from "../reducers/toasts";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  setAlerts: (alerts: MessageWithContent | MessageWithContent[]) => (dispatch: Dispatch) => {
    dispatch(Toasts.setAlerts(alerts));
  },
  setToasts: (toasts: Message | Message[]) => (dispatch: Dispatch) => {
    dispatch(Toasts.setToasts(toasts));
  },
  setPopup: (popup?: Popup) => (dispatch: Dispatch) => {
    dispatch(Toasts.setPopup(popup));
  },
};
