import * as Yup from "yup";
import { IOptionsNormalization } from "~/hooks/useOptions";
import { requiredFieldMessage, invalidNumberMessage } from "../utils";
import { errorMsgEntries } from "./helpers";

const dropdownMessage = "Please choose the ${label} from dropdown list";

const checkForOwnEntries: any = {
  name: "entries",
  message: errorMsgEntries,
  test: (value: { id: number }[]) => value.some((el: any) => el.id),
};

export enum YNAOptions {
  YES = "YES",
  NO = "NO",
  AGREE = "AGREE",
}

export enum FlexibilityCode {
  FLEXHOURS = "FREELANCER_FLEXHOURS",
  FREEHOURS = "FREELANCER_FREEHOURS",
  FIXEDHOURS = "FREELANCER_FIXEDHOURS",
}

export const BriefSchema = (
  step: number,
  isSuperAdmin: boolean,
  optionsNormalization: IOptionsNormalization,
  skillsCount: { [key: number]: number },
  ir35_compliant: boolean
) => {
  switch (step) {
    case 0:
      return Yup.object().shape(BriefNewOverview(optionsNormalization));
    case 1:
      return Yup.object().shape(BriefNewDetails(optionsNormalization, isSuperAdmin, skillsCount));
    case 2:
      return Yup.object().shape(BriefQuestionnaire(optionsNormalization, ir35_compliant));
  }
};

export const BriefRebookSchema = (
  step: number,
  optionsNormalization: IOptionsNormalization,
  ir35_compliant: boolean
) => {
  switch (step) {
    case 0:
      return Yup.object().shape(RebookNewOverview(optionsNormalization));
    case 1:
      return Yup.object().shape(RebookNewDetails(optionsNormalization));
    case 2:
      return Yup.object().shape(BriefQuestionnaire(optionsNormalization, ir35_compliant));
  }
};

export const BriefNewOverview = (optionsNormalization: IOptionsNormalization) => {
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  return {
    ...CommonBriefNewSchema(optionsNormalization),
    regions: Yup.array().of(Yup.mixed()).required(requiredFieldMessage),
    goto_types: Yup.array().of(Yup.number()).required(requiredFieldMessage),
    job_title: Yup.string()
      .nullable()
      .max(50, "Please, make it shorter than 50 symbols")
      .when("contract_id", {
        is: CONTRACT_TYPES?.PERMANENT,
        then: Yup.string().required(requiredFieldMessage),
      }),
  };
};

const RebookNewOverview = (optionsNormalization: IOptionsNormalization) => ({
  ...CommonBriefNewSchema(optionsNormalization),
  talent_ids: Yup.array().label("talent").min(1, dropdownMessage).required(dropdownMessage),
});

const BriefNewDetails = (
  optionsNormalization: IOptionsNormalization,
  isSuperAdmin: boolean,
  skillsCount: { [key: number]: number }
) => {
  const DISCIPLINE_ID = optionsNormalization?.DISCIPLINE_ID;
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  return {
    ...CommonBriefDetailsSchema(optionsNormalization),
    ...BriefPermanentHiringSchema(optionsNormalization),
    discipline_id: Yup.mixed().label("discipline").required(dropdownMessage),
    specialism_id: Yup.mixed().label("specialism").required(dropdownMessage),
    level_id: Yup.mixed().label("level").required(dropdownMessage),
    budget: Yup.number()
      .nullable()
      .when("contract_id", {
        is: (contract_id) => contract_id !== CONTRACT_TYPES?.PERMANENT,
        then: Yup.number().min(1, "Budget must be greater than 0").nullable(),
      }),
    production_id: Yup.number().nullable(),
    skills: (isSuperAdmin
      ? Yup.array().nullable()
      : Yup.array().of(Yup.object()).required("Gotta’add those powers please!").test(checkForOwnEntries)
    ).when("discipline_id", (discipline_id, schema) => {
      return schema.max(
        skillsCount[discipline_id],
        `Sorry, only ${skillsCount[discipline_id]} superpowers can be selected`
      );
    }),
    sectors: Yup.array()
      .nullable()
      .when("discipline_id", {
        is: DISCIPLINE_ID.SOCIAL,
        then: Yup.array().of(Yup.object()).required("Please, choose the sectors").test(checkForOwnEntries),
      })
      .max(4, "Sorry, only 4 sectors can be selected"),
    platforms: Yup.array()
      .nullable()
      .max(5, "Sorry, only 5 platforms can be selected")
      .when("discipline_id", {
        is: DISCIPLINE_ID.SOCIAL,
        then: Yup.array().of(Yup.object()).required("Please, choose the platforms").test(checkForOwnEntries),
      }),
    include_weekends: Yup.boolean(),
    team_id: Yup.mixed().nullable(),
    dates_flexible: Yup.boolean().nullable(),
  };
};

const RebookNewDetails = (optionsNormalization: IOptionsNormalization) => ({
  ...CommonBriefDetailsSchema(optionsNormalization),
});

const CommonBriefNewSchema = (optionsNormalization: IOptionsNormalization) => {
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  return {
    author_id: Yup.number().min(1, "Please, choose the client user").required("Please, choose the client user"),
    client_id: Yup.number().label("client").required(dropdownMessage),
    name: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(50, "Please, make it shorter than 50 symbols")
      .required(requiredFieldMessage),
    contract_id: Yup.number().required("Please, choose the contract type"),
    ir35_id: Yup.number()
      .nullable()
      .when(["contract_id", "exempt", "umbrella"], {
        is: (contract_id, exempt, umbrella) =>
          exempt === false && umbrella === false && contract_id === CONTRACT_TYPES?.FREELANCE,
        then: Yup.number().required("Please, choose the IR35 preference"),
      }),
  };
};
export const CommonBriefDetailsSchema = (optionsNormalization: IOptionsNormalization) => {
  const IR35_TYPES = optionsNormalization?.IR35_TYPES;
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  return {
    description: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .when("contract_id", {
        is: (contract_id) => contract_id === CONTRACT_TYPES?.PERMANENT,
        then: Yup.string().max(1200, "Please, make it shorter than 1200 symbols").required(requiredFieldMessage),
        otherwise: Yup.string().max(1000, "Please, make it shorter than 1000 symbols").required(requiredFieldMessage),
      }),
    start_date: Yup.date()
      .nullable()
      .when("contract_id", {
        is: (contract_id) => contract_id !== CONTRACT_TYPES?.PERMANENT,
        then: Yup.date().required("Please, choose the date"),
      }),
    end_date: Yup.date()
      .nullable()
      .when("contract_id", {
        is: (contract_id) => contract_id !== CONTRACT_TYPES?.PERMANENT,
        then: Yup.date().required("Please, choose the date"),
      }),
    notes: Yup.string().max(400),
    location_id: Yup.number()
      .nullable()
      .when(["ir35_id", "contract_id"], {
        is: (ir35_id, contract_id) => ir35_id !== IR35_TYPES.OUTSIDE && contract_id !== CONTRACT_TYPES?.PERMANENT,
        then: Yup.number().required("Please, choose the location type"),
      })
      .when("freelancer_defines_location", {
        is: "NO",
        then: Yup.number().required("Please, choose the location type"),
      }),
  };
};

const BriefQuestionnaire = (optionsNormalization: IOptionsNormalization, ir35_compliant: boolean) => {
  const IR35_TYPES = optionsNormalization?.IR35_TYPES;

  return ir35_compliant
    ? {}
    : {
        freelancer_defines_location: Yup.mixed<YNAOptions>()
          .nullable()
          .when(["ir35_id", "ir35_compliant"], {
            is: (ir35_id, ir35_compliant) => ir35_id === IR35_TYPES.OUTSIDE && ir35_compliant === false,
            then: Yup.mixed<YNAOptions>().oneOf(Object.values(YNAOptions)).required(),
          }),
        location_id: Yup.number()
          .nullable()
          .when(["ir35_id"], {
            is: (ir35_id) => ir35_id !== IR35_TYPES.OUTSIDE,
            then: Yup.number().required("Please, choose the location type"),
          })
          .when("freelancer_defines_location", {
            is: "NO",
            then: Yup.number().required("Please, choose the location type"),
          }),
        freelancer_control: Yup.mixed<YNAOptions>()
          .nullable()
          .when("ir35_id", {
            is: IR35_TYPES.INSIDE,
            then: Yup.mixed<YNAOptions>().oneOf([YNAOptions.YES, YNAOptions.NO]).required(),
          })
          .when("ir35_id", {
            is: IR35_TYPES.OUTSIDE,
            then: Yup.mixed<YNAOptions>().oneOf(Object.values(YNAOptions)).required(),
          }),
        freelancer_flexibility: Yup.mixed<YNAOptions>()
          .nullable()
          .when("ir35_id", {
            is: IR35_TYPES.OUTSIDE,
            then: Yup.mixed<FlexibilityCode>().oneOf(Object.values(FlexibilityCode)).required(),
          }),
        freelancer_substitute: Yup.mixed<YNAOptions>()
          .nullable()
          .when("ir35_id", {
            is: IR35_TYPES.INSIDE,
            then: Yup.mixed<YNAOptions>().oneOf([YNAOptions.YES, YNAOptions.NO]).required(),
          })
          .when("ir35_id", {
            is: IR35_TYPES.OUTSIDE,
            then: Yup.mixed<YNAOptions>().oneOf(Object.values(YNAOptions)).required(),
          }),
        freelancer_other_projects: Yup.mixed<YNAOptions>()
          .nullable()
          .when("ir35_id", {
            is: IR35_TYPES.INSIDE,
            then: Yup.mixed<YNAOptions>().oneOf([YNAOptions.YES, YNAOptions.NO]).required(),
          })
          .when("ir35_id", {
            is: IR35_TYPES.OUTSIDE,
            then: Yup.mixed<YNAOptions>().oneOf(Object.values(YNAOptions)).required(),
          }),
        freelancer_amend: Yup.mixed<YNAOptions>()
          .nullable()
          .when("ir35_id", {
            is: IR35_TYPES.INSIDE,
            then: Yup.mixed<YNAOptions>().oneOf([YNAOptions.YES, YNAOptions.NO]).required(),
          })
          .when("ir35_id", {
            is: IR35_TYPES.OUTSIDE,
            then: Yup.mixed<YNAOptions>().oneOf(Object.values(YNAOptions)).required(),
          }),
        freelancer_managerial: Yup.boolean()
          .nullable()
          .when("ir35_id", {
            is: (ir35_id) => ir35_id === IR35_TYPES.INSIDE || ir35_id === IR35_TYPES.OUTSIDE,
            then: Yup.boolean().required(),
          }),
        freelancer_staff: Yup.boolean()
          .nullable()
          .when("ir35_id", {
            is: (ir35_id) => ir35_id === IR35_TYPES.INSIDE || ir35_id === IR35_TYPES.OUTSIDE,
            then: Yup.boolean().required(),
          }),
        freelancer_equipment: Yup.boolean()
          .nullable()
          .when("ir35_id", {
            is: (ir35_id) => ir35_id === IR35_TYPES.INSIDE || ir35_id === IR35_TYPES.OUTSIDE,
            then: Yup.boolean().required(),
          }),
        freelancer_introducing: Yup.boolean()
          .nullable()
          .when("ir35_id", {
            is: (ir35_id) => ir35_id === IR35_TYPES.INSIDE || ir35_id === IR35_TYPES.OUTSIDE,
            then: Yup.boolean().required(),
          }),
        freelancer_credits: Yup.boolean()
          .nullable()
          .when("ir35_id", {
            is: (ir35_id) => ir35_id === IR35_TYPES.INSIDE || ir35_id === IR35_TYPES.OUTSIDE,
            then: Yup.boolean().required(),
          }),
      };
};

export const BriefPermanentHiringSchema = (optionsNormalization: IOptionsNormalization) => {
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;

  return {
    rate_min: Yup.number()
      .nullable()
      .when(["contract_id"], {
        is: (contract_id) => {
          return contract_id === CONTRACT_TYPES?.PERMANENT;
        },
        then: Yup.number()
          .min(1, "Please, choose the min salary value")
          .required("Please, choose the min salary value"),
      }),
    rate_max: Yup.number()
      .nullable()
      .when(["contract_id"], {
        is: (contract_id) => {
          return contract_id === CONTRACT_TYPES?.PERMANENT;
        },
        then: Yup.number()
          .min(1, "Please, choose the max salary value")
          .required("Please, choose the max salary value")
          .test("rate-max", "Max must be greater than Min", function (rateMax: number) {
            const rateMin = this.parent.rate_min;
            if (!rateMax || !rateMin) return true;
            return +rateMin < +rateMax;
          }),
      }),
    work_description: Yup.string().nullable().max(1200, "Please, make it shorter than 1200 symbols"),
    work_setting: Yup.string()
      .nullable()
      .when("contract_id", {
        is: CONTRACT_TYPES?.PERMANENT,
        then: Yup.string().required(requiredFieldMessage),
      }),
    right_requirements: Yup.string()
      .nullable()
      .when("contract_id", {
        is: CONTRACT_TYPES?.PERMANENT,
        then: Yup.string().required(requiredFieldMessage),
      }),
    role_involves: Yup.string()
      .nullable()
      .when("contract_id", {
        is: CONTRACT_TYPES?.PERMANENT,
        then: Yup.string().max(1200, "Please, make it shorter than 1200 symbols").required(requiredFieldMessage),
      }),
  };
};
