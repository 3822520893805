import Axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import Permissions from "../constants/permissions";
import { IState } from "../reducers/index";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  getPermissions: () => async (dispatch: Dispatch, getState: () => IState) => {
    const token = getState().user?.authenticatedUser?.token;
    if (token) {
      try {
        dispatch(Permissions.getRequest());
        const res = await Axios.get(`${API_URL}/roles`);
        dispatch(Permissions.getSuccess(res));
      } catch (err) {
        Axios.isAxiosError(err) && dispatch(Permissions.getFailure(err));
      }
    }
  },
};
