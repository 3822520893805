.ApMessage {
  background: #f7f7f7;
  border-left: 4px solid #888;
  padding: 1em;
  border-radius: 3px;
  position: relative;
  > span {
    margin-left: 30px;
    margin-right: 10px;
    line-height: 20px;
    display: block;
  }
  > .p-icon,
  svg {
    float: left;
  }
  .p-button-warning.p-button-raised {
    float: right;
    margin-top: -7px;
    box-shadow: none;
  }
  .send-message {
    margin: 8px 10px;
  }
  > * {
    vertical-align: middle;
  }
  &.type_callback_requested {
    background-color: #ffecb3;
    color: #6d5100;
    border-left-color: #d9a300;
  }
  &.type_callback_fulfilled {
    background-color: #c8e6c9;
    color: #224a23;
    border-left-color: #439446;
  }
  &.type_talent_blocked {
    background-color: #dfdfdf;
  }
  span.pi {
    cursor: pointer;
  }
}
.notification-container {
  max-width: 980px;
  margin: 0 auto !important;
  padding: 1em 0;
  .created_at {
    font-size: 0.75em;
    color: #888;
    margin-left: 30px;
    margin-top: 3px;
  }
  > div {
    margin-bottom: 0.5em !important;
  }
}
