import axios from "axios";
import { DataTableSortOrderType } from "primereact/datatable";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { UserPopupService } from "~/API/UserPopupService";
import { IUserPopup, IUserPopupList } from "~/interfaces/popups";
import Toasts from "~/store/actions/toasts";
import { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";

const useUserPopup = () => {
  const [userPopup, setUserPopup] = useState<IUserPopup | null>(null);
  const [popups, setPopups] = useState<IUserPopupList | {}>({});
  const [isFetching, setIsFetching] = useState<boolean>(false);
  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };
  const dispatch = useDispatch();

  const getUserPopup = async () => {
    try {
      const res = await UserPopupService.getUserPopup();
      res.status === 200 && !!res.data.length && setUserPopup(res.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  const markAsViewed = async (id: number) => {
    try {
      const res = await UserPopupService.markAsViewed(id);
      res.status === 204 && setUserPopup(null);
    } catch (err) {
      console.log(err);
    }
  };

  const getPopupsList = async (
    page: number,
    per_page?: number,
    search?: string,
    sort?: { field: string; order: DataTableSortOrderType }
  ) => {
    try {
      createController();
      setIsFetching(true);

      const res = await UserPopupService.getPopupsList(page, per_page, search, sort, controller);

      if (res?.status === 200) {
        setPopups(res?.data);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error(err?.message);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const createPopup = async (popup: IUserPopup) => {
    try {
      setIsFetching(true);

      const res = await UserPopupService.createPopup(popup);

      if (res?.status === 201) {
        setPopups({
          ...popups,
          data: [res.data, ...popups.data],
        });

        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const updatePopup = async (popupId: number, popup: IUserPopup) => {
    try {
      setIsFetching(true);

      const res = await UserPopupService.updatePopup(popupId, popup);

      if (res?.status === 200) {
        const resPopup = res.data;

        setPopups({
          ...popups,
          data: popups?.data.map((popup: IUserPopup) => (popup.id === resPopup.id ? resPopup : popup)),
        });

        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    userPopup,
    popups,
    getUserPopup,
    markAsViewed,
    getPopupsList,
    createPopup,
    updatePopup,
  };
};

export default useUserPopup;
