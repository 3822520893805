import React, { useMemo } from "react";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useGlobalContext } from "~/contexts/GlobalContext";
import cn from "classnames";

interface IBriefType {
  label: string;
  code: string;
}

interface IDisciplinesDropdown {
  briefType: IBriefType;
  setBriefType: any;
  className?: string;
}

export const ALL_BRIEF_TYPES = {
  label: "All brief types",
  code: "",
};

export default function BriefTypesDropdown(props: IDisciplinesDropdown) {
  const { briefType, setBriefType } = props;
  const {
    global: { globalOptions },
  } = useGlobalContext();

  const briefTypes = useMemo(
    () =>
      globalOptions?.contract_types
        .map(({ name: label, code }: { name: string; code: string }) => ({
          label,
          code,
        }))
        .concat(ALL_BRIEF_TYPES),
    [globalOptions?.contract_types]
  );

  function changeBriefType(e: DropdownChangeParams) {
    e.stopPropagation();
    setBriefType(e.value);
  }

  return (
    <Dropdown
      className={cn("filteringSelector", props.className)}
      value={briefType}
      options={briefTypes}
      onChange={changeBriefType}
      optionLabel="label"
      placeholder="Select brief type"
      scrollHeight="auto"
    />
  );
}
