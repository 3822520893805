import { AnyAction } from "redux";
import * as Notifications from "../constants/notifications";

// ------------------------------------
// Interface
// ------------------------------------
export interface INotificationsState {
  isFetching: boolean;
  main_notifications: INotification[] | undefined;
  notifications:
    | {
        count: number;
        current_page: number;
        last_page: number;
        per_page: number;
        total: number;
        data: INotification[] | undefined;
      }
    | undefined;
}

export type INotification = {
  id: number;
  related_notification_id: number | null;
  type: string;
  params: {
    [key: string]: string | number;
  };
  is_read: boolean;
  created_at: string;
  message: string;
};

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: INotificationsState = {
  isFetching: false,
  notifications: undefined,
  main_notifications: undefined,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): INotificationsState => {
  switch (action.type) {
    case Notifications.GET_NOTIFICATIONS_FAILURE:
    case Notifications.GET_MAIN_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case Notifications.GET_MAIN_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        main_notifications: undefined,
      };
    case Notifications.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        notifications: undefined,
      };
    case Notifications.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notifications: action.payload,
      };
    case Notifications.GET_MAIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        main_notifications: action.payload,
      };
    case Notifications.RESET_NOTIFICATIONS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
