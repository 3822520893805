import moment from "moment";

export interface IColumn {
  name?: string;
  field: string;
  header?: string;
  showStatement?: boolean;
  sortable?: boolean;
  cellCallback?: (rowData: any, columnProps?: any) => JSX.Element | null;
  className?: string;
  props?: {
    [key: string]: any;
  };
  style?: {
    [key: string]: any;
  };
}

const getColumns = (
  isAdmin: boolean,
  briefsCategory: string,
  vocabularies: { [key: string]: { [key: string]: string } }
) => {
  const renderColumnDiscipline = ({ discipline_code }: { discipline_code: string }) => {
    return vocabularies && discipline_code ? vocabularies.DISCIPLINES[discipline_code] : null;
  };

  const renderColumnBriefType = ({ contract_code }: { contract_code: string }) => {
    return vocabularies && contract_code ? vocabularies.CONTRACT_TYPES[contract_code] : null;
  };

  const renderColumnStartDate = ({ start_date }: { start_date: string }) => {
    return moment(start_date).format("DD/MM/YYYY");
  };

  const renderColumnEndDate = ({ end_date }: { end_date: string }) => {
    return moment(end_date).format("DD/MM/YYYY");
  };

  const columns: IColumn[] = [
    {
      field: "discipline_code",
      header: "Discipline",
      props: {
        sortable: true,
        body: renderColumnDiscipline,
      },
    },
    {
      field: "contract_code",
      header: "Brief Type",
      props: {
        sortable: true,
        body: renderColumnBriefType,
      },
    },
    {
      field: "start_date",
      header: "Start date",
      showStatement: briefsCategory !== "draft",
      props: {
        sortable: true,
        body: renderColumnStartDate,
      },
    },
    {
      field: "end_date",
      header: "End date",
      showStatement: briefsCategory !== "opened" && briefsCategory !== "draft",
      props: {
        sortable: true,
        body: renderColumnEndDate,
      },
    },
  ];

  return columns;
};

export default getColumns;
