import { DataTableSortOrderType } from "primereact/datatable";
import { AnyAction } from "redux";
import * as Client from "../constants/client";

// ------------------------------------
// Interface
// ------------------------------------
export interface IClientState {
  isFetching: boolean;
  client?: Client.IClient;
  clients?: Client.IClient[];
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IClientState = {
  isFetching: false,
  client: undefined,
  clients: undefined,
};

export interface IClientFilterSettings {
  resetFilterClientSettings: (currentPath: string | null) => void;
  search: string;
  setSearch: () => void;
  sort: { field: string; order: DataTableSortOrderType };
  setSort: () => void;
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IClientState => {
  switch (action.type) {
    case Client.CLIENTS_CREATE_FAILURE:
    case Client.CLIENTS_GET_FAILURE:
    case Client.CLIENTS_LIST_FAILURE:
    case Client.CLIENTS_UPDATE_FAILURE:
    case Client.CLIENTS_FILE_SAVE_FAILURE:
    case Client.CLIENTS_IR35_GET_FAILURE:
    case Client.CLIENTS_IR35_UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case Client.CLIENTS_CREATE_REQUEST:
    case Client.CLIENTS_GET_REQUEST:
    case Client.CLIENTS_LIST_REQUEST:
    case Client.CLIENTS_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Client.CLIENTS_IR35_UPDATE_REQUEST:
    case Client.CLIENTS_FILE_SAVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Client.CLIENTS_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case Client.CLIENTS_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        client: action.payload,
        clients: state.clients?.map((client) => (client.id === action.payload.id ? action.payload : client)),
      };
    case Client.CLIENTS_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        client: action.payload,
      };
    case Client.CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        clients: action.payload,
      };
    case Client.CLIENTS_IR35_UPDATE_SUCCESS:
    case Client.CLIENTS_IR35_GET_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          payment_profile: action.payload,
        },
        isFetching: false,
      };
    case Client.CLIENTS_FILE_SAVE_SUCCESS:
      return {
        ...state,
        client: {
          ...state.client,
          work_profile: action.payload.work_profile,
        },
        isFetching: false,
      };
    default:
      return state;
  }
};
