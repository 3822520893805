import { AxiosError, AxiosResponse } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";
export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";

export const GET_MAIN_NOTIFICATIONS_FAILURE = "GET_MAIN_NOTIFICATIONS_FAILURE";
export const GET_MAIN_NOTIFICATIONS_REQUEST = "GET_MAIN_NOTIFICATIONS_REQUEST";
export const GET_MAIN_NOTIFICATIONS_SUCCESS = "GET_MAIN_NOTIFICATIONS_SUCCESS";
export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

// ------------------------------------
// Interfaces
// ------------------------------------
export interface IGlobal {}

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  getMainNotificationsFailure: (err: AxiosError) => ({
    type: GET_MAIN_NOTIFICATIONS_FAILURE,
    payload: err?.response?.data,
  }),
  getMainNotificationsRequest: () => ({
    type: GET_MAIN_NOTIFICATIONS_REQUEST,
  }),
  getMainNotificationsSuccess: (res: AxiosResponse) => ({
    type: GET_MAIN_NOTIFICATIONS_SUCCESS,
    payload: res.data,
  }),
  getNotificationsFailure: (err: AxiosError) => ({
    type: GET_NOTIFICATIONS_FAILURE,
    payload: err?.response?.data,
  }),
  getNotificationsRequest: () => ({
    type: GET_NOTIFICATIONS_REQUEST,
  }),
  getNotificationsSuccess: (res: AxiosResponse) => ({
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: res.data,
  }),
  resetNotifications: () => ({
    type: RESET_NOTIFICATIONS,
  }),
};
