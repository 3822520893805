import cn from "classnames";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApAsyncSelect from "~/components/common/ApAsyncSelect/ApAsyncSelect";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import CustomBriefElement from "~/components/common/CustomBriefElement";
import { useBriefContext } from "~/contexts/BriefContext";
import { ILanguage, useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { getLevels } from "~/forms/BriefViewDetailsForm/helper";
import { checkBriefForPermanent } from "~/routes/Brief/helper";
import Toasts from "~/store/constants/toasts";
import { IState } from "~/store/reducers/index";
import { calculateDates, splitVocabulary } from "~/utils";
import PopupIcon from "../../components/Toaster/PopupIcon";
import ApRadioGroup from "../../components/common/ApRadioGroup/ApRadioGroup";
import Budget from "../BriefViewDetailsForm/BriefFields/Budget";
import CompanyBenefits from "../BriefViewDetailsForm/BriefFields/CompanyBenefits";
import Description from "../BriefViewDetailsForm/BriefFields/Description";
import Discipline from "../BriefViewDetailsForm/BriefFields/Discipline";
import Entry from "../BriefViewDetailsForm/BriefFields/Entry";
import JobDescription from "../BriefViewDetailsForm/BriefFields/JobDescription";
import Levels from "../BriefViewDetailsForm/BriefFields/Levels";
import Notes from "../BriefViewDetailsForm/BriefFields/Notes";
import PermanentRoles from "../BriefViewDetailsForm/BriefFields/PermanentRoles";
import ProductionSize from "../BriefViewDetailsForm/BriefFields/ProductionSize";
import RightRequirements from "../BriefViewDetailsForm/BriefFields/RightRequirements";
import RoleInvolves from "../BriefViewDetailsForm/BriefFields/RoleInvolves";
import SalaryRange from "../BriefViewDetailsForm/BriefFields/SalaryRange";
import Specialisms from "../BriefViewDetailsForm/BriefFields/Specialisms";
import TeamFlexibility from "../BriefViewDetailsForm/BriefFields/TeamFlexibility";
import WorkDescription from "../BriefViewDetailsForm/BriefFields/WorkDescription";
import WorkSettings from "../BriefViewDetailsForm/BriefFields/WorkSettings";
import WorkingLocation from "../BriefViewDetailsForm/BriefFields/WorkingLocation";
import Workplace from "../BriefViewDetailsForm/BriefFields/Workplace";
import "../BriefViewDetailsForm/BriefViewDetailsForm.scss";
import CalendarBlock from "../BriefViewDetailsForm/Calendar/CalendarBlock";
import DurationTypesPopup from "../BriefViewDetailsForm/DurationTypesPopup/DurationTypesPopup";
import {
  DEFAULT_DURATION_DAYS,
  LANGUAGES,
  PLATFORMS,
  SECTORS,
  SKILLS,
  languagesPopupContent,
  maxLanguagesCount,
  maxPlatformsCount,
  maxSectorsCount,
  skillPopupContent,
} from "../BriefViewDetailsForm/constants";

interface Props {
  formik?: FormikProps<FormikValues>;
}
export interface IMaxCount {
  skills: IEntry;
  sectors: IEntry;
  platforms: IEntry;
}

type IEntry = {
  limit: number | null;
  selected: any;
};

export default function BriefNewDetailsForm(props: Props) {
  const formik = props.formik || useFormikContext();
  const {
    global: {
      globalOptions,
      optionsNormalization,
      maxSkillsForDiscipline,
      getCountries,
      getCities,
      getLanguages,
      countries: countryOptions,
      languages: allLanguages,
      setCountry,
    },
  } = useGlobalContext();
  const { checkTalentsWithSpecialismCount } = useBriefContext();
  const clientDisciplines = useSelector((state: IState) => state?.client?.client?.disciplines);

  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  const isPermanentHiringBrief = useMemo(
    () => checkBriefForPermanent(formik.values, CONTRACT_TYPES),
    [formik.values.contract_id, CONTRACT_TYPES]
  );

  const dispatch = useDispatch();
  const pickListRefs = useRef({ skills: null, sectors: null, platforms: null, languages: null });
  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = useMemo(checkIsAdmin, []);
  const DURATION_ID = optionsNormalization?.DURATION_ID;

  const [durationDays, setDurationDays] = useState(formik?.values?.duration_days as number);
  const [maxDurationDays, setMaxDurationDays] = useState(null as any);
  const isExactWorkingDates = formik?.values.duration_id === DURATION_ID?.EXACT;

  const initialStateEntries = {
    skills: false,
    sectors: false,
    platforms: false,
    languages: false,
  };
  const [disabledEntries, setIsDisabledEntries] = useState(initialStateEntries);

  const getInitialDates = () => calculateDates.newBrief(formik, isExactWorkingDates);

  const [dates, setDates] = useState(getInitialDates());

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [languages, setLanguages] = useState([] as ILanguage[]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const pickListTypes: { [key: string]: any } = {
    skills: {
      source: skills,
      selected: selectedSkills,
      setEntries: setSkills,
      setSelectedEntries: setSelectedSkills,
    },
    languages: {
      source: languages,
      selected: selectedLanguages,
      setEntries: setLanguages,
      setSelectedEntries: setSelectedLanguages,
    },
    sectors: {
      source: sectors,
      selected: selectedSectors,
      setEntries: setSectors,
      setSelectedEntries: setSelectedSectors,
    },
    platforms: {
      source: platforms,
      selected: selectedPlatforms,
      setEntries: setPlatforms,
      setSelectedEntries: setSelectedPlatforms,
    },
  };

  const disciplines = globalOptions?.disciplines;
  const allPlatforms = globalOptions?.platforms;
  const durationTypes = globalOptions?.duration_types;
  const durationTypeOptions = useMemo(
    () =>
      durationTypes
        ?.filter(({ is_enabled }: { is_enabled: boolean }) => is_enabled)
        ?.map(({ id: code, name, description }: { id: string; name: string; description: string }) => ({
          code,
          name,
          description,
        })),
    [durationTypes]
  );

  const selectedDiscipline = disciplines?.find((el: any) => el.id === formik.values.discipline_id);

  const specialisms = selectedDiscipline?.specialisms;

  const isDisciplineProduction: boolean = selectedDiscipline?.code === "DISCIPLINE_PRODUCTION";
  const isDisciplineSocial: boolean = selectedDiscipline?.code === "DISCIPLINE_SOCIAL";
  const isDisciplineDesign: boolean = selectedDiscipline?.code === "DISCIPLINE_DESIGN";

  const showSectorPicker: boolean =
    selectedDiscipline && (isDisciplineProduction || isDisciplineSocial || isDisciplineDesign);
  const showPlatformPicker: boolean = selectedDiscipline && isDisciplineSocial;
  const selectedSpecialism = specialisms?.find((el: any) => el.id === formik.values.specialism_id);

  const levels = useMemo(
    () => selectedDiscipline && selectedSpecialism && [...getLevels(selectedDiscipline, selectedSpecialism)]?.reverse(),
    [selectedDiscipline, selectedSpecialism]
  );

  useEffect(() => {
    (!formik.values.languages?.length || selectedLanguages.length === 1) && formik.setFieldValue("fluent_in_all", true);
  }, [selectedLanguages]);

  useEffect(() => {
    !countryOptions.length && getCountries();
    !allLanguages.length && getLanguages();
  }, []);

  useEffect(() => {
    allLanguages.length &&
      splitVocabulary(allLanguages, formik.values.languages ?? selectedLanguages, setLanguages, setSelectedLanguages);
  }, [allLanguages]);

  useEffect(() => {
    if (levels?.length === 1 && formik.values.level_id !== levels[0].code) {
      formik.setFieldValue("level_id", levels[0].id);
    }
  }, [levels]);

  const teamwork = selectedSpecialism?.teamwork;

  useEffect(() => {
    if (teamwork?.length === 1) {
      formik.setFieldValue("team_id", teamwork[0].id);
    }
  }, [teamwork]);

  useEffect(() => {
    if (selectedDiscipline) {
      splitVocabulary(selectedDiscipline.skills, formik.values.skills ?? selectedSkills, setSkills, setSelectedSkills);
      splitVocabulary(
        selectedDiscipline.sectors,
        formik.values.sectors ?? selectedSectors,
        setSectors,
        setSelectedSectors
      );
      splitVocabulary(allPlatforms, formik.values.platforms ?? selectedPlatforms, setPlatforms, setSelectedPlatforms);
    }
  }, [formik.values.discipline_id]);

  useEffect(() => {
    if (specialisms?.length === 1) {
      formik.setFieldValue("specialism_id", specialisms[0].id);
      formik.setFieldValue("team_id", specialisms[0].teamwork[0]?.id || null);
    }
  }, [specialisms]);

  useEffect(() => {
    if (!!formik.values.specialism_id && specialisms.length > 1) {
      const prefix = isAdmin ? "" : "client_admin/";

      checkTalentsWithSpecialismCount(formik.values.specialism_id, prefix).then((enough) => {
        if (!enough) {
          dispatch(
            Toasts.setPopup({
              content: (
                <>
                  <h3>Important</h3>
                  <p>
                    We currently have a limited number of talent on the platform with your selected specialism. As a
                    result your matches may be slightly lower than expected for this brief.
                  </p>
                </>
              ),
            })
          );
        }
      });
    }
  }, [formik.values.specialism_id]);

  const maxCount: IMaxCount = useMemo(
    () => ({
      skills: {
        limit: (formik.values.discipline_id && maxSkillsForDiscipline[formik.values.discipline_id]) || null,
        selected: selectedSkills,
      },
      languages: {
        limit: maxLanguagesCount,
        selected: selectedLanguages,
      },
      sectors: {
        limit: maxSectorsCount,
        selected: selectedSectors,
      },
      platforms: {
        limit: maxPlatformsCount,
        selected: selectedPlatforms,
      },
    }),
    [formik.values.discipline_id, selectedSkills, selectedSectors, selectedPlatforms, selectedLanguages]
  );

  const canBeAddedEntry = (type: string, selectedItemsLength: number) => {
    let maxItems = maxCount[type as keyof IMaxCount].limit as number;
    const entry = isDisciplineDesign && type === "sectors" ? "tools" : type === "skills" ? "superpowers" : type;
    if (selectedItemsLength > maxItems) {
      dispatch(
        Toasts.setToasts([
          { severity: "error", summary: "", detail: `Sorry, only ${maxItems} ${entry} can be selected` },
        ])
      );
      return false;
    }
    return true;
  };
  const handleChangeEntries = (
    e: any,
    setEntries: Function,
    setSelectedEntries: Function,
    type: typeof SKILLS | typeof SECTORS | typeof PLATFORMS | typeof LANGUAGES,
    updatePickState = false
  ) => {
    formik.setTouched({ ...formik.touched, [type]: true });
    if (!canBeAddedEntry(type, e.target.length)) {
      return;
    }
    setEntries(e.source);
    setSelectedEntries(e.target);

    // TODO: Why we use there "setState"?
    // updatePickState &&
    //   pickListRefs.current[type]?.setState({
    //     targetSelection: [],
    //   });

    formik.setFieldValue(
      type,
      e.target
        .sort((a: any, b: any) => (a.id && !b.id ? -1 : !a.id && b.id ? 1 : 0))
        .map((entry: any, key: number) => ({
          ...entry,
          order_id: key + 1,
        }))
    );
  };

  const changeDurationTypes = (e: any) => {
    resetDates();
    formik.setFieldValue("duration_id", e.value);
    formik.setFieldValue("duration_days", DEFAULT_DURATION_DAYS);
    setDurationDays(DEFAULT_DURATION_DAYS);
    setMaxDurationDays(null);
  };

  const resetDates = () => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
    });
  };

  const defaultCity = formik.values.residence_city
    ? {
        label: formik.values.residence_city,
        value: formik.values.residence_city,
      }
    : null;

  const [selectedCity, setSelectedCity] = useState(defaultCity);
  const isMountedAsyncSelect = useRef(false);

  useEffect(() => {
    if (!!formik.values.country_of_residence) {
      setCountry(formik.values.country_of_residence);
      if (isMountedAsyncSelect.current) {
        formik.setFieldValue("residence_city", null);
        setSelectedCity(null);
      }
      isMountedAsyncSelect.current = true;
    }
  }, [formik.values.country_of_residence]);

  return (
    <div className="BriefDetailsForm">
      <div className="block-specific-width block-fields">
        <Discipline
          disciplines={clientDisciplines}
          formik={formik}
          setSelectedSkills={setSelectedSkills}
          setSelectedSectors={setSelectedSectors}
          setSelectedPlatforms={setSelectedPlatforms}
          initialStateEntries={initialStateEntries}
          setIsDisabledEntries={setIsDisabledEntries}
        />
        <Specialisms formik={formik} specialisms={specialisms} canShowInfoPopup />
        <Levels formik={formik} levels={levels} className="indent" canShowInfoPopup />
      </div>
      {!isPermanentHiringBrief && (
        <Budget
          formik={formik}
          selectedDiscipline={selectedDiscipline}
          selectedSpecialism={selectedSpecialism}
          levels={levels}
        />
      )}
      {isDisciplineProduction && !isPermanentHiringBrief && (
        <ProductionSize formik={formik} disciplines={disciplines} canShowInfoPopup />
      )}
      {isPermanentHiringBrief && (
        <div className="block-fields block-specific-width">
          <SalaryRange formik={formik} />
          <JobDescription formik={formik} />
        </div>
      )}
      <div className="block-fields">
        <Description formik={formik} canShowInfoPopup />
        {!isPermanentHiringBrief && <TeamFlexibility formik={formik} teamwork={teamwork} />}
      </div>
      {isPermanentHiringBrief && (
        <>
          <RoleInvolves formik={formik} />
          <WorkDescription formik={formik} canShowInfoPopup />
          <TeamFlexibility formik={formik} teamwork={teamwork} />
          <CompanyBenefits formik={formik} isCreationBriefProcess />
        </>
      )}
      {selectedDiscipline && (
        <>
          <Entry
            formik={formik}
            pickListTypes={pickListTypes}
            pickListRefs={pickListRefs}
            typeOfEntries={SKILLS}
            entries={skills}
            label={
              <>
                What Superpowers would be useful? Select & rank <PopupIcon content={skillPopupContent} />
              </>
            }
            selectedEntries={selectedSkills}
            disabledEntries={disabledEntries}
            setIsDisabledEntries={setIsDisabledEntries}
            handleChangeEntries={handleChangeEntries}
            sourceHeader="Select Superpower"
            maxCount={maxCount}
            className={cn("p-fluid field skills", !isAdmin && "field-required")}
          >
            <CustomBriefElement
              label="Enter Custom Superpower"
              placeholder="add your own"
              getElements={() => skills}
              setItems={setSkills}
            />
          </Entry>
          <div className="p-fluid field languages">
            <Entry
              formik={formik}
              pickListTypes={pickListTypes}
              pickListRefs={pickListRefs}
              typeOfEntries={LANGUAGES}
              entries={languages}
              label={
                <>
                  Select Languages <PopupIcon content={languagesPopupContent} />
                </>
              }
              selectedEntries={selectedLanguages}
              disabledEntries={disabledEntries}
              setIsDisabledEntries={setIsDisabledEntries}
              handleChangeEntries={handleChangeEntries}
              sourceHeader="All Languages"
              maxCount={maxCount}
              className={cn("p-fluid field")}
            />
            {formik.values.languages?.length > 1 && (
              <div className="p-fluid field languages-checkbox">
                <ApCheckbox
                  formik={formik}
                  noField
                  name="fluent_in_all"
                  label="Only include talent that are fluent in ALL of the selected languages."
                />
                <p className="left-choose-entries checkbox-info">
                  Unticking this box, will include talent that are fluent in at least one of the selected languages
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {showSectorPicker && (
        <Entry
          formik={formik}
          selectedDiscipline={selectedDiscipline}
          pickListTypes={pickListTypes}
          pickListRefs={pickListRefs}
          typeOfEntries={SECTORS}
          entries={sectors}
          label={
            <>
              {isDisciplineDesign
                ? `Tools/Applications Proficiencies. Select & rank up to ${maxSectorsCount} tools/applications (not mandatory, but helpful to know)`
                : "Sectors experience. Select & rank" +
                  (isDisciplineProduction ? " (not mandatory, but helpful to know)" : "")}
            </>
          }
          selectedEntries={selectedSectors}
          disabledEntries={disabledEntries}
          setIsDisabledEntries={setIsDisabledEntries}
          handleChangeEntries={handleChangeEntries}
          sourceHeader={`Select ${isDisciplineDesign ? "Tools" : "Sectors"}`}
          maxCount={maxCount}
          className={cn("p-fluid field sectors", isDisciplineSocial && "field-required")}
        >
          <CustomBriefElement
            label={`Enter Custom ${isDisciplineDesign ? "Tool" : "Sector"}`}
            placeholder={`add your own`}
            getElements={() => sectors}
            setItems={setSectors}
          />
        </Entry>
      )}
      {showPlatformPicker && (
        <Entry
          formik={formik}
          pickListTypes={pickListTypes}
          pickListRefs={pickListRefs}
          typeOfEntries={PLATFORMS}
          entries={platforms}
          label="Do you have specific expertise in a platform?"
          selectedEntries={selectedPlatforms}
          disabledEntries={disabledEntries}
          setIsDisabledEntries={setIsDisabledEntries}
          handleChangeEntries={handleChangeEntries}
          sourceHeader="Specific expertise"
          maxCount={maxCount}
          className={cn("p-fluid field field-required platforms")}
        >
          <CustomBriefElement
            label="Enter Custom Platform"
            placeholder="add your own"
            getElements={() => platforms}
            setItems={setPlatforms}
          />
        </Entry>
      )}
      <div className="block-fields">
        {isPermanentHiringBrief && <WorkSettings formik={formik} />}
        {!isPermanentHiringBrief && !!durationTypeOptions?.length && (
          <div className="p-fluid field field-required duration-types">
            <label htmlFor="duration_id">
              Duration Types <PopupIcon content={<DurationTypesPopup />} />
            </label>
            <ApRadioGroup
              formik={formik}
              name="duration_id"
              noField
              options={durationTypeOptions}
              onChange={changeDurationTypes}
            />
          </div>
        )}
      </div>
      {isPermanentHiringBrief ? (
        <>
          <RightRequirements formik={formik} />
          <PermanentRoles formik={formik} />
          {countryOptions.length > 0 && (
            <div className="p-fluid field">
              <label htmlFor="country_of_residence">Location</label>
              <ApDropdown
                formik={formik}
                id="country_of_residence"
                options={countryOptions}
                placeholder="Select a country"
                filter
                noField
              />
            </div>
          )}
          {!!formik.values.country_of_residence && (
            <div className="p-fluid field">
              <label htmlFor="residence_city">City</label>
              <ApAsyncSelect
                noField
                formik={formik}
                id="residence_city"
                selectedValue={selectedCity}
                setSelectedValue={setSelectedCity}
                fetchingData={getCities}
                placeholder="Select city"
                minCountToSearch={3}
                mapOptionsToValues={(options: any) =>
                  options.map(({ name, admin_division_1 }: { name: string; admin_division_1: string }) => ({
                    label: `${name} - ${admin_division_1}`,
                    value: `${name} - ${admin_division_1}`,
                  }))
                }
              />
            </div>
          )}
        </>
      ) : (
        <>
          <CalendarBlock
            formik={formik}
            dates={dates}
            setDates={setDates}
            durationDays={durationDays}
            setDurationDays={setDurationDays}
            maxDurationDays={maxDurationDays}
            setMaxDurationDays={setMaxDurationDays}
          />
          <WorkingLocation formik={formik} />
          <Workplace formik={formik} />
        </>
      )}
      {isAdmin && <Notes formik={formik} />}
    </div>
  );
}
