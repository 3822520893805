import React from "react";
import classNames from "classnames";
import "./ApMessage.scss";
import { INotification } from "../../../store/reducers/notifications";
import actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import moment from "moment";
import AppSendMessage from "./ApSendMessage";
import Toasts, { TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { CurrencyDollar } from "phosphor-react";
import RowInvoiceNumber from "~/routes/Invoices/InvoicesView/rowCallbacks/RowInvoiceNumber";
import { invoiceStatuses } from "~/routes/Invoices/InvoicesView/InvoicesList/InvoicesList";

interface IProps {
  key: number;
  item: INotification;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function ApMessage(props: IProps) {
  const { item } = props;
  let history = useHistory();

  const className: string =
    item.type === "TYPE_CALLBACK_REQUESTED"
      ? "pi-exclamation-triangle"
      : item.type === "TYPE_CALLBACK_FULFILLED"
      ? "pi-check"
      : "pi-info-circle";
  const dispatch = useDispatch();
  const isJobOfferMsg = ["TYPE_OFFER_SENT", "TYPE_OFFER_ACCEPTED", "TYPE_OFFER_REJECTED"].includes(item.type);

  function setComplete(id: number, type: string): void {
    dispatch(actions.notifications.setCompleted(id, type)).then(() => {
      const { pathname } = window.location;
      const shouldUpdateMainNotificationsState =
        pathname === "/" || pathname.startsWith("/talents/") || pathname.startsWith("/clients/");

      dispatch(
        shouldUpdateMainNotificationsState
          ? actions.notifications.getMainNotifications()
          : actions.notifications.getNotifications()
      );

      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    });
  }

  function handleRedirect(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, status: string) {
    event.preventDefault();
    history.push({
      pathname: "/invoices",
      state: { invoiceStatusIndex: invoiceStatuses.findIndex((el) => el.code === status) },
    });
  }

  function getMessageText(item: INotification) {
    if (!item.params) return item.message;
    switch (item.type) {
      case "TYPE_CLIENT_NEW":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} added new client{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link>
          </span>
        );
      case "TYPE_CLIENT_PUBLISHED":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} published client{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link>
          </span>
        );
      case "TYPE_TALENT_NEW":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} added new talent{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link>
          </span>
        );
      case "TYPE_TALENT_PUBLISHED":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} published new talent{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link>
          </span>
        );
      case "TYPE_TALENT_FROZEN":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} frozen new talent{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link>
          </span>
        );
      case "TYPE_INTERVIEW_ACCEPTED":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} set an interview for talent{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> with agency{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> regarding brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_INTERVIEW_FULFILLED":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} marked an interview request as fulfilled for{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link>
          </span>
        );
      case "TYPE_CALLBACK_FULFILLED":
        return (
          <span>
            {item.params?.admin_user_name} at {item.params?.admin_name} marked a call as fulfilled with{" "}
            {item.params?.user_name}
          </span>
        );
      case "TYPE_CLIENT_BRIEF":
        return (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> added new brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_BOOKING_REQUESTED":
        return (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> requested an booking
            talent <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> for brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_INTERVIEW_REQUESTED":
        return (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> requested an interview
            with <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> regarding brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_START_DATE_TO_TALENT":
        return (
          <span>
            {item.params?.talent_user_name} at{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> set themselves as
            available from {item.params?.date}
          </span>
        );
      case "TYPE_TALENT_AVAILABILITY":
        return (
          <span>
            {item.params?.talent_user_name} at{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> set themselves as not
            available for new briefs
          </span>
        );
      case "TYPE_BOOKING_ACCEPTED":
        return (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> has booked talent{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> for brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_BOOKING_DECLINED":
        return (
          <span>
            {item.params?.talent_user_name} at{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> has declined the booking
            offer from client <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> for
            brief <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_REBOOK_DECLINED":
        return (
          <span>
            {item.params?.talent_user_name} at{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> has declined the
            re-booking request <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link> from
            client <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link>
          </span>
        );
      case "TYPE_CALLBACK_REQUESTED":
        return item.params?.talent_user_name ? (
          <span>
            {item.params?.talent_user_name} ({item.params?.talent_discipline}) at{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> requested a callback
          </span>
        ) : (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> requested a callback
          </span>
        );
      case "TYPE_CALLBACK_CANCELLED":
        return item.params?.talent_user_name ? (
          <span>
            {item.params?.talent_user_name} at{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> cancelled a callback
          </span>
        ) : (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> cancelled a callback
          </span>
        );
      case "TYPE_TALENT_BLOCKED":
        return (
          <span>
            {item.params.client_user_name} at{" "}
            <Link to={`/clients/${item.params.client_id}`}>{item.params.client_name}</Link>
            {` `}has blocked talent <Link to={`/talents/${item.params.talent_id}`}>{item.params.talent_name}</Link> from
            receiving their briefs
          </span>
        );
      case "TYPE_OFFER_SENT":
        return (
          <span>
            {item.params.client_user_name} and{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> has sent a job offer to{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> for their brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_OFFER_ACCEPTED":
        return (
          <span>
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> has accepted the job offer
            from <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> for their brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_OFFER_REJECTED":
        return (
          <span>
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> has rejected the job offer
            from <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> for their brief{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>
          </span>
        );
      case "TYPE_INVOICE_DISPUTE":
        return (
          <span>
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> is disputing the{" "}
            <Link onClick={(e) => handleRedirect(e, "DISPUTED")} to="/invoices">
              <RowInvoiceNumber id={item.params?.invoice_id} />
            </Link>{" "}
            invoice they received from <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link>
            . They provided this reason: {item.params?.reason}
          </span>
        );
      case "TYPE_CLIENT_ADD_GOTO":
        return (
          <span>
            {item.params?.client_user_name} at{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> has just bookmarked{" "}
            <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> and added them to their My
            Talent Network.
          </span>
        );
      case "TYPE_MESSAGE_SENT_TO_BRIEF_TALENT":
        return (
          <span>
            {item.params?.admin_user_name} has sent an update to talent for{" "}
            <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link>'s brief,{" "}
            <Link to={`/briefs/${item.params?.brief_id}`}>{item.params?.brief_name}</Link>. Update Reason:{" "}
            {item.params?.message}
          </span>
        );
      case "TYPE_INVOICE_OVERDUE":
        return (
          <span>
            {item.params?.author_name}'s invoice{" "}
            <Link onClick={(e) => handleRedirect(e, "OVERDUE")} to="/invoices">
              <RowInvoiceNumber id={item.params?.invoice_id} />
            </Link>{" "}
            of <Link to={`/talents/${item.params?.talent_id}`}>{item.params?.talent_name}</Link> talent for their
            booking with <Link to={`/clients/${item.params?.client_id}`}>{item.params?.client_name}</Link> is now
            Overdue
          </span>
        );
      case "TALENTS_VALIDATION_FINISHED":
        return <span>{item?.message}</span>;
    }
    return "";
  }
  let sendMessageButton = undefined;
  if (item.type === "TYPE_CALLBACK_REQUESTED" && (item.params?.client_user_id || item.params?.talent_user_id)) {
    sendMessageButton = (
      <AppSendMessage
        opts={{
          user_id: (item.params.client_user_id || item.params.talent_user_id) as number,
          ...(item.params.client_id && { client_id: item.params.client_id as number }),
          ...(item.params.talent_id && { talent_id: item.params.talent_id as number }),
        }}
      />
    );
  }
  return (
    <div className={classNames("ApMessage", item.type.toLocaleLowerCase())}>
      {item.type === "TYPE_CALLBACK_REQUESTED" && (
        <Button
          className="p-button-warning p-button-raised"
          onClick={() => setComplete(item.id, "TYPE_CALLBACK_FULFILLED")}
          label="Mark as fulfilled"
        />
      )}
      {item.type === "TYPE_INTERVIEW_REQUESTED" && (
        <Button
          className="p-button-warning p-button-raised"
          onClick={() => setComplete(item.id, "TYPE_INTERVIEW_FULFILLED")}
          label="Mark as fulfilled"
        />
      )}
      {sendMessageButton}
      {isJobOfferMsg ? (
        <CurrencyDollar size={19} />
      ) : (
        <i className={classNames("p-icon p-message-icon pi", className)} />
      )}
      {getMessageText(item)}
      <div className="created_at">{moment(item.created_at).format("MMMM DD, YYYY [at] H:mm")}</div>
    </div>
  );
}
