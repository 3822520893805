import React from "react";
import PermissionContext from "~/contexts/PermissionContext";
import BriefContext from "~/contexts/BriefContext";
import InterviewContext from "~/contexts/InterviewContext";
import DashboardContext from "~/contexts/DashboardContext";
import GlobalContext from "~/contexts/GlobalContext";
import ConversationContext from "~/contexts/ConversationContext";
import TalentContext from "~/contexts/TalentContext";
import ClientContext from "~/contexts/ClientContext";
import InvoiceContext from "~/contexts/InvoiceContext";
import LoadingAnimationContext from "~/contexts/LoadingAnimationContext";
import FilterContext from "~/contexts/FilterContext";

const AppContext = ({ children }: any) => {
  return (
    <PermissionContext>
      <GlobalContext>
        <LoadingAnimationContext>
          <BriefContext>
            <TalentContext>
              <ClientContext>
                <DashboardContext>
                  <ConversationContext>
                    <InterviewContext>
                      <FilterContext>
                        <InvoiceContext>{children}</InvoiceContext>
                      </FilterContext>
                    </InterviewContext>
                  </ConversationContext>
                </DashboardContext>
              </ClientContext>
            </TalentContext>
          </BriefContext>
        </LoadingAnimationContext>
      </GlobalContext>
    </PermissionContext>
  );
};

export default AppContext;
