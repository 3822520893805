import { useMemo, useState } from "react";
import { OptionsService } from "~/API/OptionsService";
import { ICountry, IGlobalOptions, ILanguage } from "~/contexts/GlobalContext";
import { IPaymentCompany, IUmbrella } from "~/interfaces/brief";

export interface IOptionsNormalization {
  CONTRACT_TYPES: { [key: string]: number };
  IR35_TYPES: { [key: string]: number };
  DISCIPLINE_ID: { [key: string]: number };
  DURATION_ID: { [key: string]: number };
  LOCATION_ID: { [key: string]: { [key: string]: number } };
  SPECIALISMS: { id: number; label: string }[];
  LEVELS: { id: number; label: string }[];
  SKILLS: { id: number; label: string }[];
  SECTORS: { id: number; label: string }[];
}

const getFormatedOptions = (globalOptions: any) => {
  const formated = (types: any) =>
    types?.reduce((acc: { [key: string]: number }, item: any) => {
      const i = item.code.indexOf("_");
      acc[item.code.substring(i + 1)] = item.id;
      return acc;
    }, {});

  const formatedLocationId = (types: any) =>
    types?.reduce((acc: { [key: string]: { [key: string]: number } }, item: any) => {
      const i = item.code.indexOf("_");
      acc[item.ir35_code] = {
        ...acc[item.ir35_code],
        [item.code.substring(i + 1)]: item.id,
      };
      return acc;
    }, {});

  const SPECIALISMS: { [key: number]: string } = {};
  const LEVELS: { [key: number]: string } = {};
  const SKILLS: any = [];
  const SECTORS: { [key: number]: string } = {};

  const collect = (items: any, container: any) =>
    items.forEach(({ id, name }: { id: number; name: string }) => (container[id] = name));
  const formatAsOptions = (items: { [key: string | number]: string }, key: "id" | "code" = "id") =>
    Object.entries(items).map(([prop, label]) => ({ [key]: key === "id" ? +prop : prop, label }));

  globalOptions.disciplines.forEach(
    ({ name, sectors, skills, specialisms }: { sectors: any; skills: any; specialisms: any }) => {
      collect(sectors, SECTORS);
      // collect(skills, SKILLS);
      SKILLS.push({
        key: name,
        label: name,
        children: skills.map(({ id, name }) => ({
          key: id,
          label: name,
        })),
      });

      specialisms.forEach(({ id, name, levels }: { id: number; name: string; levels: any }) => {
        SPECIALISMS[id] = name;
        collect(levels, LEVELS);
        delete LEVELS[1];
      });
    }
  );

  return {
    IR35_TYPES: formated(globalOptions?.ir35_types),
    CONTRACT_TYPES: formated(globalOptions?.contract_types),
    DISCIPLINE_ID: formated(globalOptions?.disciplines),
    DURATION_ID: formated(globalOptions?.duration_types),
    LOCATION_ID: formatedLocationId(globalOptions?.locations),

    SPECIALISMS: formatAsOptions(SPECIALISMS),
    LEVELS: formatAsOptions(LEVELS),
    SKILLS, //: formatAsOptions(SKILLS),
    SECTORS: formatAsOptions(SECTORS),
  };
};

const useOptions = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [globalOptions, setGlobalOptions] = useState(undefined as any);
  const [countries, setCountries] = useState([] as ICountry[]);
  const [languages, setLanguages] = useState([] as ILanguage[]);
  const [countryResidence, setCountryResidence] = useState("" as string);
  const [umbrellaCompanies, setUmbrellaCompanies] = useState([] as IUmbrella[]);
  const [paymentCompanies, setPaymentCompanies] = useState([] as IPaymentCompany[]);

  const setCountry = (value: string) => setCountryResidence(value);

  const optionsNormalization: IOptionsNormalization = useMemo(
    () => (globalOptions && getFormatedOptions(globalOptions)) || {},
    [globalOptions]
  );

  const maxSkillsForDiscipline: { [key: string | number]: number } = useMemo(
    () =>
      globalOptions &&
      globalOptions.disciplines.reduce(
        (acc: { [key: string]: string }, item: { id: number; code: string; skills: any[] }) => {
          const maxSkill = Math.round(item.skills.length / 5);
          return {
            ...acc,
            [item.id]: maxSkill > 3 ? maxSkill : 4,
            [item.code]: maxSkill > 3 ? maxSkill : 4,
          };
        },
        {}
      ),
    [globalOptions]
  );

  const token = localStorage.getItem("user");

  const getGlobalOptions = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getGlobalOptions();
        res.status === 200 && setGlobalOptions(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getCountries = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getCountries();
        const countries = res.data.map((country: ICountry) => ({
          label: country.name,
          value: country.code,
        }));
        res.status === 200 && setCountries(countries);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getCities = (query: string) => OptionsService.getCities({ country: countryResidence, search: query });

  const getLanguages = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getLanguages();
        res.status === 200 && setLanguages(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getUmbrellaCompanies = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getUmbrellaCompanies();
        const companies = res.data.sort((a: IUmbrella, b: IUmbrella) => a.company_name.localeCompare(b.company_name));

        res.status === 200 && setUmbrellaCompanies(companies);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getPaymentCompanies = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getPaymentCompanies();
        const companies = res.data.sort((a: IPaymentCompany, b: IPaymentCompany) => a.name.localeCompare(b.name));

        res.status === 200 && setPaymentCompanies(companies);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  return {
    isFetching,
    optionsNormalization,
    maxSkillsForDiscipline,
    globalOptions,
    countries,
    languages,
    umbrellaCompanies,
    paymentCompanies,
    getGlobalOptions,
    getCountries,
    getCities,
    getLanguages,
    getUmbrellaCompanies,
    getPaymentCompanies,
    setCountry,
  } as IGlobalOptions;
};
export default useOptions;
