export interface IInvoice {
  id?: number;
  type: "DRAFT" | "XERO_ACCPAY" | "XERO_ACCREC";
  booking?: {
    id: number;
    ir35_code: string;
  };
  notes: string;
  attachments: {
    id: number;
    url: string;
  }[];
  currency_code: "GBP";
  currency_rate: 1;
  platform: "XERO";
  date: string;
  due_date: string;
  status: string;
  available_statuses?: InvoiceStatus[];
  line_items: ILineItem[];
  subtotal?: string;
  total_tax?: string;
  total?: string;
  disputes: {
    created_at: string;
    description: string;
  }[];
}

export interface ILineItems {
  description: string;
  quantity: number;
  tax_amount: number;
  line_amount: number;
}

export enum ILineItemUnit {
  TYPE_DAY_RATE = "TYPE_DAY_RATE",
  TYPE_FIXED_AMOUNT = "TYPE_FIXED_AMOUNT",
  TYPE_WEEKEND_RATE = "TYPE_WEEKEND_RATE",
  TYPE_OVERTIME = "TYPE_OVERTIME",
  EXPENSE = "EXPENSE",
  GENIE_FEE = "GENIE_FEE",
  PAYMENT_FEE = "PAYMENT_FEE",
}

export const LINE_ITEM_UNIT_TYPES = [
  { value: ILineItemUnit.TYPE_DAY_RATE, label: "Day Rate" },
  { value: ILineItemUnit.TYPE_FIXED_AMOUNT, label: "Fixed Amount" },
  { value: ILineItemUnit.TYPE_WEEKEND_RATE, label: "Weekend Rate" },
  { value: ILineItemUnit.TYPE_OVERTIME, label: "Overtime Hourly" },
  { value: ILineItemUnit.EXPENSE, label: "Expense" },
  { value: ILineItemUnit.GENIE_FEE, label: "Commission" },
  { value: ILineItemUnit.PAYMENT_FEE, label: "Commission" },
];

export type InvoiceStatus = "DRAFT" | "SUBMITTED" | "DISPUTED" | "AUTHORISED" | "PAID" | "CANCELLED";

export interface ILineItem {
  isNew?: boolean;
  id?: number;
  description: string;
  unit_type: ILineItemUnit;
  unit_amount: number;
  line_amount: number;
  quantity: number;
  start_date: string | null;
  end_date: string | null;
  attachments?: File[];
}

export type IInvoiceFormValues = {
  lineItems: ILineItem[];
  notes: string;
  agree: boolean;
  includeVAT: boolean;
  subtotal: number;
  subtotalVAT: number;
  total: number;
};

export interface IInvoiceDetails {
  currency_code: number;
  subtotal: number;
  total_tax: number;
  total: number;
  online_url: string;
  line_amount_types: string;
  line_items: ILineItems[];
}
