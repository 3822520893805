import React, { ChangeEvent, useState } from "react";
import cn from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import Loader from "~/components/Loader/Loader";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import { Checkbox } from "primereact/checkbox";
import { useBriefContext } from "~/contexts/BriefContext";
import "./ChangeBookingRateModal.scss";
import { InputNumber } from "primereact/inputnumber";

export default function ChangeBookingRateModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { onSubmit, talentRate, bookingId } = modalProps || {};
  const { SDSBeingLoaded, isFetching: isFetchingUpdateBooking } = useBriefContext();
  const isFetching = SDSBeingLoaded === bookingId || isFetchingUpdateBooking;

  const [rate, setRate] = useState<number>(talentRate);
  const [isNeedRegenerateSds, setIsNeedRegenerateSds] = useState<boolean>(false);

  function handleChangeSds(e: ChangeEvent) {
    e.stopPropagation();
    setIsNeedRegenerateSds(e.checked);
  }

  const Content = (
    <div className="block-fields">
      <div className="p-fluid field">
        <label htmlFor="booking_rate">Booking rate</label>
        <InputNumber
          inputId="booking_rate"
          min={0}
          max={1000000}
          size={10}
          mode="currency"
          currency="GBP"
          locale="en-US"
          placeholder="Booking rate"
          onChange={(e) => setRate(e.value as number)}
          onValueChange={(e) => setRate(e.value as number)}
          value={rate}
          useGrouping={false}
        />
      </div>
      <div className="col-12 field regenerate_sds">
        <Checkbox inputId="regenerate_sds" checked={isNeedRegenerateSds} onChange={handleChangeSds} />
        <label htmlFor="regenerate_sds">Regenerate SDS</label>
      </div>
    </div>
  );

  const footer = (
    <div className="footer-btn">
      <Button label="Go back" disabled={isFetching} onClick={() => dispatch(actions.modal.closeModal())} />
      <Button
        label="Yes, go for it"
        disabled={isFetching}
        onClick={() => {
          onSubmit(rate, isNeedRegenerateSds, () => dispatch(actions.modal.closeModal()));
        }}
      />
    </div>
  );

  return (
    <Dialog
      header="Edit Booking Rate"
      className={cn("EditBookingRateModal", { loading: isFetching })}
      onHide={() => {
        dispatch(actions.modal.closeModal());
      }}
      footer={footer}
      modal
      visible
    >
      {isFetching ? <Loader size={2} /> : Content}
    </Dialog>
  );
}
