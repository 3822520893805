import { AxiosError, AxiosResponse } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const PERMISSIONS_GET_REQUEST = "PERMISSIONS_GET_REQUEST";
export const PERMISSIONS_GET_SUCCESS = "PERMISSIONS_GET_SUCCESS";
export const PERMISSIONS_GET_FAILURE = "PERMISSIONS_GET_FAILURE";

// ------------------------------------
// Interfaces
// ------------------------------------
export type IPermission = {
  id: number;
  name: string;
  display_name: string;
  description: string;
};

export interface IGroupPermission extends IPermission {
  permissions: IPermission[];
}

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  getRequest: () => ({
    type: PERMISSIONS_GET_REQUEST,
  }),
  getSuccess: (res: AxiosResponse) => ({
    type: PERMISSIONS_GET_SUCCESS,
    payload: res.data,
  }),
  getFailure: (err: AxiosError) => ({
    type: PERMISSIONS_GET_FAILURE,
    payload: err?.response?.data,
  }),
};
