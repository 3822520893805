import React from "react";
import { getInvoiceStatusData } from "../helper";

export default function RowInvoiceStatus({
  rowData: { status, due_date },
  mode = "table",
  action = false,
  isXeroInvoice = false,
}: {
  rowData: { status: string; due_date: string };
  mode?: "table" | "circle";
  action?: boolean;
  isXeroInvoice?: boolean;
}) {
  const { colorClass, labelStatus } = getInvoiceStatusData(status, due_date, action, isXeroInvoice);

  switch (mode) {
    case "circle":
      return (
        <>
          <span className={`matching-status circle ${colorClass}`} title={status} />
          <span>{labelStatus}</span>
        </>
      );
    default:
      return (
        <span className={`matching-status badge ${colorClass}`} title={status}>
          {labelStatus}
        </span>
      );
  }
}
