import React, { createContext, useContext } from "react";
import usePermission from "~/hooks/usePermission";

export const CLIENT_ROLES_REG_EXP = /^client_/;
export const ADDITIONAL_ROLES: Array<string> = ["client_admin_invoices"];

export interface IPermissions {
  permissions: null | { [key: string]: string };
  roles: Array<IRole>;
  isFetchingRoles: boolean;
  savePermissions: (res: any) => void;
  userAccess: (...items: string[]) => boolean;
  resetPermissions: () => void;
  canEditMatches: () => boolean;
  checkIsAdmin: () => boolean;
  checkIsClientAdmin: () => boolean;
  getRoles: () => void;
  canOwnPaymentData: boolean;
  canGetOwnInvoice: boolean;
}

export interface IRole {
  description: string;
  display_name: string;
  id: number;
  name: string;
  permissions: null | { [key: string]: string };
  checkIsClientAdmin: () => boolean;
}

export const PermissionContext = createContext(null as IPermissions | null);

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermissionContext must be used within a PermissionContextProvider");
  }
  return context;
};

const PermissionContextProvider = ({ children }: any) => {
  return (
    <PermissionContext.Provider
      value={{
        ...usePermission(),
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContextProvider;
