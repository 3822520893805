import React from "react";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import { FormikContextType } from "formik";
import PopupIcon from "~/components/Toaster/PopupIcon";
import cn from "classnames";

interface ISpecialisms {
  formik: FormikContextType<any>;
  canShowInfoPopup?: boolean;
  isViewOnly?: boolean;
  specialisms: any;
}
export default function Specialisms(props: ISpecialisms) {
  const { formik, canShowInfoPopup = false, isViewOnly = false, specialisms } = props;

  const InfoPopup = () => (
    <>
      {" "}
      <PopupIcon
        content={
          <>
            I won’t exclude the others who look great for the brief,
            <br />
            this is just a key indicator for what you are after.
          </>
        }
      />
    </>
  );

  const handleChangeSpecialism = (e: { value: number }) => {
    formik.setFieldValue("level_id", null);
    formik.setFieldValue("specialism_id", e.value);
  };

  return specialisms?.length > 1 ? (
    <div className={cn("p-fluid field field-required indent")}>
      <label htmlFor="specialism_id">
        Specialism
        {canShowInfoPopup && <InfoPopup />}
      </label>
      <ApDropdown
        disabled={!specialisms || isViewOnly}
        scrollHeight="320px"
        formik={formik}
        id="specialism_id"
        noField
        options={specialisms}
        optionLabel="name"
        optionValue="id"
        placeholder="Select specialism"
        onChange={handleChangeSpecialism}
      />
    </div>
  ) : null;
}
