import React from "react";
import { Field, FormikContextType } from "formik";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { AxiosError, AxiosResponse } from "axios";
import cn from "classnames";
import "./ApAsyncSelect.scss";

interface IAsyncSelect {
  label?: string;
  isCachedOptions?: boolean;
  id: string;
  placeholder?: string;
  fetchingData: (search: string) => Promise<AxiosResponse | AxiosError>;
  selectedValue?: any;
  setSelectedValue?: any;
  formik: FormikContextType<any>;
  noField?: boolean;
  isDisabled?: boolean;
  minCountToSearch: number;
  mapOptionsToValues?: (options: any) => void;
}
export default function ApAsyncSelect(props: IAsyncSelect) {
  const {
    placeholder = "select...",
    formik,
    selectedValue,
    setSelectedValue,
    fetchingData,
    isCachedOptions = false,
    isDisabled = false,
    noField = false,
    minCountToSearch,
    mapOptionsToValues,
  } = props;

  let meta, field;

  if (noField && formik) {
    meta = formik.getFieldMeta(props.id);
    field = formik.getFieldProps(props.id);
  }
  const options = (query: string, callback: any) => {
    if (query.length >= minCountToSearch) {
      fetchingData(query)
        .then((resp) => {
          if (mapOptionsToValues) {
            return callback(mapOptionsToValues(resp.data));
          }
          return callback(resp.data);
        })
        .catch((err) => {
          return callback([]);
        });
    } else {
      callback([]);
    }
  };
  const debouncedLoadOptions = debounce(options, 2000);
  const handleChange = (city: any) => {
    formik.setFieldValue("residence_city", city.label);
    setSelectedValue(city);
  };

  return props.noField && meta ? (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <AsyncSelect
        {...field}
        {...props}
        inputId={props.id}
        cacheOptions={isCachedOptions}
        placeholder={placeholder}
        value={selectedValue}
        loadOptions={debouncedLoadOptions}
        isDisabled={isDisabled}
        onChange={handleChange}
        className={cn("async-select", isDisabled && "disabled")}
      />
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.id}>
      {({ field, meta }: any) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <AsyncSelect
            {...field}
            {...props}
            inputId={props.id}
            cacheOptions={isCachedOptions}
            placeholder={placeholder}
            value={selectedValue}
            loadOptions={debouncedLoadOptions}
            isDisabled={isDisabled}
            onChange={handleChange}
            className={cn("async-select", isDisabled && "disabled")}
          />
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
}
