import React from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import "./EditNotesModal.scss";
import TalentNotesForm from "~/forms/TalentNotesForm/TalentNotesForm";

export default function EditNotesModal() {
  const dispatch = useDispatch();

  return (
    <Dialog header="Notes" className="EditNotesModal" onHide={() => dispatch(actions.modal.closeModal())} modal visible>
      <TalentNotesForm isGridViewForm />
    </Dialog>
  );
}
