import { useState } from "react";
import { DataTableSortMeta, DataTableSortOrderType } from "primereact/datatable";
import { IClientFilterSettings } from "~/store/reducers/client";

const defaultSort: {
  field: string;
  order: DataTableSortOrderType;
} = {
  field: "name",
  order: 1,
};

const useClientFilterSettings = () => {
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);

  const resetFilterClientSettings = (currentPath: string) => {
    const isExistClientId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemClientPath = currentPath?.includes("/clients") && isExistClientId;

    if (currentPath && currentPath !== "/clients" && !isItemClientPath) {
      setSearch("");
      setSort(defaultSort);
    }
  };

  return {
    search,
    setSearch,
    sort,
    setSort,
    resetFilterClientSettings,
  } as IClientFilterSettings;
};
export default useClientFilterSettings;
