import { useState } from "react";
import { useDispatch } from "react-redux";
import { InterviewService } from "~/API/InterviewService";
import { usePermissionContext } from "~/contexts/PermissionContext";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import * as permissionType from "~/constants";
import { IAttachment, IInterview } from "~/interfaces/Interview";

const useInterview = () => {
  const dispatch = useDispatch();
  const { userAccess } = usePermissionContext();
  const isAdmin = userAccess(permissionType.accessAdmin);

  const [isLoading, setIsLoading] = useState(false);
  const [interview, setInterview] = useState<IInterview | null>(null);
  const [interviews, setInterviews] = useState<IInterview[] | []>([]);
  const [attachmentsError, setAttachmentsError] = useState<string[]>([]);

  const getInterviews = async (matchId: number) => {
    setIsLoading(true);
    try {
      setInterviews([]);
      const { status, data: newInterviews } = await InterviewService.getInterviews(matchId, isAdmin);

      status === 200 && setInterviews(newInterviews);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadAttachments = async (interviewId: number, attachments: File[] | IAttachment[]) => {
    const files = attachments?.filter((file: File[] | IAttachment[]) => file instanceof File);

    if (!files?.length) return;
    setAttachmentsError([]);

    const allPromises = await Promise.allSettled(
      files.map(async (attachment: File) => {
        try {
          const attachmentPromise = await InterviewService.addInterviewAttachment(interviewId, isAdmin, attachment);

          setInterview(attachmentPromise.data);
          return Promise.resolve(attachmentPromise);
        } catch (err) {
          return Promise.reject(err?.response.data.message);
        }
      })
    );

    return await allPromises;
  };

  const removeAttachments = async (interviewId, attachments) => {
    attachments?.map(async (attachment) => {
      try {
        await InterviewService.deleteInterviewAttachment(interviewId, isAdmin, attachment);
      } catch (err) {
        setAttachmentsError(err?.response.data.message);
      }
    });
  };

  const updateInterview = async (interviewId: number, data: IInterview, cb?: Function) => {
    setIsLoading(true);

    await removeAttachments(interviewId, data.attachments_to_remove);
    const attachmentsUpload = await uploadAttachments(interviewId, data.attachments);
    const attachmentsErrors = attachmentsUpload?.filter(({ status }: { status: string }) => status === "rejected");

    if (attachmentsErrors?.length) {
      setIsLoading(false);
      return setAttachmentsError(attachmentsErrors.map(({ reason }: { reason: string }) => reason));
    }

    try {
      const { data: newInterview } = await InterviewService.updateInterview(interviewId, data, isAdmin);
      setInterview(newInterview);
      const updatedInterviews = interviews.map((el) => (el.id === interviewId ? newInterview : el));

      setInterviews([...updatedInterviews]);
      cb && cb();
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsLoading(false);
      setAttachmentsError([]);
    }
  };

  const addInterview = async (matchId: number, data: IInterview, cb?: Function) => {
    setIsLoading(true);
    try {
      const res = await InterviewService.addInterview(matchId, data, isAdmin);

      if (res.status === 200) {
        const lastIndex = res.data.length - 1;
        const attachmentsUpload = await uploadAttachments(res.data[lastIndex].id, data.attachments);
        const attachmentsErrors = attachmentsUpload?.filter(({ status }: { status: string }) => status === "rejected");

        setInterviews([...res.data]);

        if (attachmentsErrors?.length) {
          setIsLoading(false);
          return setAttachmentsError(attachmentsErrors.map(({ reason }: { reason: string }) => reason));
        }
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        sendInterviewNotification(res.data[lastIndex].id);
        cb && cb([...res.data]);
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsLoading(false);
      setAttachmentsError([]);
    }
  };

  const sendInterviewNotification = async (interviewId: number) => {
    try {
      const res = await InterviewService.sendInterviewNotification(interviewId, isAdmin);

      res.status === 200 &&
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  return {
    isLoading,
    interview,
    interviews,
    attachmentsError,
    setInterview,
    getInterviews,
    updateInterview,
    addInterview,
    sendInterviewNotification,
  };
};
export default useInterview;
