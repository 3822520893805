import { AnyAction } from "redux";
import * as Bookings from "../constants/bookings";

// ------------------------------------
// Interface
// ------------------------------------
export interface IBookingsState {
  isFetching: boolean;
  bookings: Bookings.IBooking[] | undefined;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IBookingsState = {
  isFetching: false,
  bookings: undefined,
}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IBookingsState => {
  switch (action.type) {
    case Bookings.CLIENT_BOOKINGS_LIST_FAILURE:
    case Bookings.CLIENT_BOOKINGS_PUT_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case Bookings.CLIENT_BOOKINGS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        bookings: [],
      };
    case Bookings.CLIENT_BOOKINGS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        bookings: action.payload,
      };
    case Bookings.CLIENT_BOOKINGS_PUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};
