import React from "react";
import { useDispatch } from "react-redux";
import { Field, FormikContextType } from "formik";
import { EMAIL_REGEXP } from "~/config";
import { Chip } from "primereact/chip";
import { Chips } from "primereact/chips";
import Toasts from "~/store/constants/toasts";
import cn from "classnames";
import "./Attendees.scss";

export interface IAttendee {
  id?: number;
  name: string;
  email: string;
  active?: boolean;
  disabled?: boolean;
}

type Props = {
  label: string;
  placeholder?: string;
  formik: FormikContextType<any>;
  clientAttendees?: IAttendee[];
  setClientAttendees?: (newAttendiesList: IAttendee[]) => void;
};

export default function Attendees({ clientAttendees, setClientAttendees, formik, label, placeholder = "" }: Props) {
  const dispatch = useDispatch();
  const emailRegExp = new RegExp(EMAIL_REGEXP);
  const isShowClientAttendees = !!clientAttendees?.length;

  return (
    <div className="field">
      <label htmlFor="attendees">{label}</label> <br />
      <div className={cn("addAttendeesField", isShowClientAttendees && "withClientAttendees")}>
        <div className="customAttendees">
          <Field name="custom_attendees">
            {({ field, meta }: any) => (
              <>
                <Chips
                  {...field}
                  placeholder={placeholder}
                  separator=","
                  onChange={(e) => {
                    if (field.value.length > e.value.length) {
                      // if removing occurs
                      formik.setFieldValue("custom_attendees", e.value);
                    }
                  }}
                  onAdd={(e) => {
                    const isSameEmail = field.value.filter((elem: any) => elem.email === e.value).length > 0;

                    if (!emailRegExp.test(e.value)) {
                      dispatch(
                        Toasts.setToasts([{ severity: "error", summary: "", detail: "Incorrect email format" }])
                      );
                    } else if (isSameEmail) {
                      dispatch(
                        Toasts.setToasts([{ severity: "error", summary: "", detail: "This email already exists" }])
                      );
                    } else {
                      formik.setFieldValue("custom_attendees", [...field.value, { email: e.value }], true);
                    }
                  }}
                  itemTemplate={({ email }) => email}
                />
              </>
            )}
          </Field>
        </div>
        {isShowClientAttendees && (
          <div className="clientAttendees">
            {clientAttendees?.map(({ id, name, active, disabled }, index) => (
              <Chip
                key={id}
                icon={`pi pi-${active || id === formik?.values?.organizer_id ? "check" : "plus"}`}
                className={cn([active && "active", disabled && "disabled"])}
                label={name}
                onClick={() =>
                  setClientAttendees((clientAttendees) => {
                    const newAttendiesList = [...clientAttendees];
                    newAttendiesList[index].active = !newAttendiesList[index].active;
                    return newAttendiesList;
                  })
                }
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
