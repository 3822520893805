import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import cn from "classnames";
import { Button } from "primereact/button";
import "./ApproveInvoiceModal.scss";
import actions from "~/store/actions";
import Loader from "~/components/Loader/Loader";
import { InputText } from "primereact/inputtext";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { usePermissionContext } from "~/contexts/PermissionContext";

export default function ApproveInvoiceModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const [poNumber, setPoNumber] = useState(null as string | null);
  const { isFetching } = useInvoiceContext();
  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = checkIsAdmin();

  function onSubmit(poNumber: string | null, callback: () => void) {
    if (modalProps?.onSubmit) {
      modalProps.onSubmit(poNumber, callback);
    }
  }

  const footer = (
    <div className="footer-btn">
      <Button label="Go back" disabled={isFetching} onClick={() => dispatch(actions.modal.closeModal())} />
      <Button
        label="Approve"
        disabled={isFetching || (!isAdmin && !poNumber)}
        onClick={() => {
          onSubmit(poNumber, () => dispatch(actions.modal.closeModal()));
        }}
      />
    </div>
  );

  const Content = (
    <div className="block-fields">
      <div className="p-fluid">
        <div className={cn("field", !isAdmin && "field-required")}>
          <label htmlFor="po_number">
            Please add a PO number {isAdmin && "(leave blank if no PO number required)"}
          </label>
          <InputText value={poNumber} onChange={({ target: { value } }) => setPoNumber(value)} size={30} />
        </div>
      </div>
    </div>
  );
  return (
    <Dialog
      header="Approve Invoice"
      className={cn("ApproveInvoiceModal", { loading: isFetching })}
      onHide={() => {
        dispatch(actions.modal.closeModal());
      }}
      footer={footer}
      modal
      visible
    >
      {isFetching ? <Loader size={2} /> : Content}
    </Dialog>
  );
}
