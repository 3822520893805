import React from "react";
import { connect } from "react-redux";
import { IState } from "../store/reducers/index";
import AddMatchModal from "./AddMatchModal/AddMatchModal";
import AddUserModal from "./AddUserModal/AddUserModal";
import AmendBookingModal from "./AmendBookingModal/AmendBookingModal";
import BookingDatesModal from "./BookingDatesModal/BookingDatesModal";
import BookingExtendModal from "./BookingExtendModal/BookingExtendModal";
import BookingAmendModal from "./BookingAmendModal/BookingAmendModal";
import InterviewModal from "./InterviewModal/InterviewModal";
import InterviewNotes from "./InterviewNotes/InterviewNotes";
import RemoveInstanceModal from "./RemoveInstanceModal/RemoveInstanceModal";
import SelectTalentModal from "./SelectTalentModal/SelectTalentModal";
import InfluentialEmployerModal from "~/modals/InfluentialEmployerModal/InfluentialEmployerModal";
import ReferenceModal from "~/modals/ReferenceModal/ReferenceModal";
import ChangeRoleModal from "~/modals/ChangeRoleModal/ChangeRoleModal";
import RemoveTalentModal from "~/modals/RemoveTalentModal/RemoveTalentModal";
import GenerateMatchesModal from "~/modals/GenerateMatchesModal/GenerateMatchesModal";
import ChangeBookingRateModal from "./ChangeBookingRateModal/ChangeBookingRateModal";
import ApproveInvoiceModal from "./ApproveInvoiceModal/ApproveInvoiceModal";
import DisputeInvoiceModal from "./DisputeInvoiceModal/DisputeInvoiceModal";
import EditInvoiceModal from "./EditInvoiceModal/EditInvoiceModal";
import AddInvoiceAttachmentModal from "./AddInvoiceAttachmentModal/AddInvoiceAttachmentModal";
import MessageTalent from "./MessageTalent/MessageTalent";
import EditNotesModal from "~/modals/EditNotesModal/EditNotesModal";
import CreatePopupModal from "~/modals/CreatePopupModal/CreatePopupModal";
import EditBookingRequestModal from "~/modals/EditBookingRequestModal/EditBookingRequestModal";

const MODAL_COMPONENTS: any = {
  ADD_USER_MODAL: AddUserModal,
  INTERVIEW_MODAL: InterviewModal,
  INTERVIEW_NOTES: InterviewNotes,
  AMEND_BOOKING_DATES: AmendBookingModal,
  BOOKING_DATES_MODAL: BookingDatesModal,
  BOOKING_EXTEND_MODAL: BookingExtendModal,
  BOOKING_AMEND_MODAL: BookingAmendModal,
  EDIT_BOOKING_REQUEST_MODAL: EditBookingRequestModal,
  GENERATE_MATCHES_MODAL: GenerateMatchesModal,
  REMOVE_INSTANCE: RemoveInstanceModal,
  ADD_MATCHING: AddMatchModal,
  SELECT_TALENTS: SelectTalentModal,
  INFLUENTIAL_EMPLOYER_MODAL: InfluentialEmployerModal,
  REFERENCE_MODAL: ReferenceModal,
  CHANGE_ROLE_MODAL: ChangeRoleModal,
  REMOVE_TALENT_MODAL: RemoveTalentModal,
  BOOKING_RATE_MODAL: ChangeBookingRateModal,
  APPROVE_INVOICE_MODAL: ApproveInvoiceModal,
  DISPUTE_INVOICE_MODAL: DisputeInvoiceModal,
  EDIT_INVOICE_MODAL: EditInvoiceModal,
  ADD_INVOICE_ATTACHMENT_MODAL: AddInvoiceAttachmentModal,
  MESSAGE_TALENT: MessageTalent,
  EDIT_NOTES: EditNotesModal,
  CREATE_POPUP_MODAL: CreatePopupModal,
};

const ModalRoot = ({ modalType, modalProps, ...rest }: { modalType?: string; modalProps?: any }) => {
  if (!modalType || !MODAL_COMPONENTS[modalType]) return null;
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal side={modalProps.side} {...modalProps} {...rest} />;
};

export default connect((state: IState) => state.modal)(ModalRoot);
