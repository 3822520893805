import { AxiosError, AxiosResponse } from "axios";
import { ITag } from "~/interfaces/common";

// ------------------------------------
// Constants
// ------------------------------------
export const USERS_GET_PAYMENT_PROFILE_FAILURE = "USERS_GET_PAYMENT_PROFILE_FAILURE";
export const USERS_GET_PAYMENT_PROFILE_REQUEST = "USERS_GET_PAYMENT_PROFILE_REQUEST";
export const USERS_GET_PAYMENT_PROFILE_SUCCESS = "USERS_GET_PAYMENT_PROFILE_SUCCESS";

export const USERS_GET_PERSONAL_PROFILE_FAILURE = "USERS_GET_PERSONAL_PROFILE_FAILURE";
export const USERS_GET_PERSONAL_PROFILE_REQUEST = "USERS_GET_PERSONAL_PROFILE_REQUEST";
export const USERS_GET_PERSONAL_PROFILE_SUCCESS = "USERS_GET_PERSONAL_PROFILE_SUCCESS";

export const USERS_GET_BANK_PROFILE_FAILURE = "USERS_GET_BANK_PROFILE_FAILURE";
export const USERS_GET_BANK_PROFILE_REQUEST = "USERS_GET_BANK_PROFILE_REQUEST";
export const USERS_GET_BANK_PROFILE_SUCCESS = "USERS_GET_BANK_PROFILE_SUCCESS";

export const USERS_LOGIN_FAILURE = "USERS_LOGIN_FAILURE";
export const USERS_LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";

export const USERS_LOGOUT = "USERS_LOGOUT";

export const USERS_VERIFY_TOKEN_FAILURE = "USERS_VERIFY_TOKEN_FAILURE";
export const USERS_VERIFY_TOKEN_REQUEST = "USERS_VERIFY_TOKEN_REQUEST";
export const USERS_VERIFY_TOKEN_SUCCESS = "USERS_VERIFY_TOKEN_SUCCESS";

export const USERS_UPDATE_PAYMENT_PROFILE_FAILURE = "USERS_UPDATE_PAYMENT_PROFILE_FAILURE";
export const USERS_UPDATE_PAYMENT_PROFILE_REQUEST = "USERS_UPDATE_PAYMENT_PROFILE_REQUEST";
export const USERS_UPDATE_PAYMENT_PROFILE_SUCCESS = "USERS_UPDATE_PAYMENT_PROFILE_SUCCESS";

export const USERS_UPDATE_PROFILE_FAILURE = "USERS_UPDATE_PROFILE_FAILURE";
export const USERS_UPDATE_PROFILE_REQUEST = "USERS_UPDATE_PROFILE_REQUEST";
export const USERS_UPDATE_PROFILE_SUCCESS = "USERS_UPDATE_PROFILE_SUCCESS";

export const USERS_SET_NOT_AUTHENTICATED = "USERS_SET_NOT_AUTHENTICATED";
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";

export const AUTH_RESET_REQUEST_PASSWORD_FAILURE = "AUTH_RESET_REQUEST_PASSWORD_FAILURE";
export const AUTH_RESET_REQUEST_PASSWORD_REQUEST = "AUTH_RESET_REQUEST_PASSWORD_REQUEST";
export const AUTH_RESET_REQUEST_PASSWORD_SUCCESS = "AUTH_RESET_REQUEST_PASSWORD_SUCCESS";

export const USERS_SET_PASSWORD_FAILURE = "USERS_SET_PASSWORD_FAILURE";
export const USERS_SET_PASSWORD_REQUEST = "USERS_SET_PASSWORD_REQUEST";
export const USERS_SET_PASSWORD_SUCCESS = "USERS_SET_PASSWORD_SUCCESS";

export const AUTH_RESET_CHECK_PASSWORD_FAILURE = "AUTH_RESET_CHECK_PASSWORD_FAILURE";
export const AUTH_RESET_CHECK_PASSWORD_REQUEST = "AUTH_RESET_CHECK_PASSWORD_REQUEST";
export const AUTH_RESET_CHECK_PASSWORD_SUCCESS = "AUTH_RESET_CHECK_PASSWORD_SUCCESS";

export const GET_TAGS_FAILURE = "GET_TAGS_FAILURE";
export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";

export const UPDATE_TAG_REQUEST = "UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILURE = "UPDATE_TAG_FAILURE";

export const ADD_NEW_TAG = "ADD_NEW_TAG";

export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "DELETE_TAG_FAILURE";

// ------------------------------------
// Interfaces
// ------------------------------------
export interface ILoginForm {
  email: string;
  password: string;
}
export interface IUserForm {
  [k: string]: string | any[];
  first_name: string;
  last_name: string;
  email: string;
  telephone: string;
  roles: any[];
  role: string;
  additional_roles: string[];
}

export interface IResetPasswordForm {
  email: string;
}

export interface ISetPasswordForm {
  password: string;
}

export interface ICheckTokenForm {
  token: string;
}
// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  getPaymentProfileRequest: () => ({
    type: USERS_GET_PAYMENT_PROFILE_REQUEST,
  }),
  getPaymentProfileSuccess: (res: AxiosResponse) => ({
    type: USERS_GET_PAYMENT_PROFILE_SUCCESS,
    payload: res.data,
  }),
  getPaymentProfileFailure: (err: AxiosError) => ({
    type: USERS_GET_PAYMENT_PROFILE_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  getPersonalProfileRequest: () => ({
    type: USERS_GET_PERSONAL_PROFILE_REQUEST,
  }),
  getPersonalProfileSuccess: (res: AxiosResponse) => ({
    type: USERS_GET_PERSONAL_PROFILE_SUCCESS,
    payload: res.data,
  }),
  getPersonalProfileFailure: (err: AxiosError) => ({
    type: USERS_GET_PERSONAL_PROFILE_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  getBankProfileRequest: () => ({
    type: USERS_GET_BANK_PROFILE_REQUEST,
  }),
  getBankProfileSuccess: (res: AxiosResponse) => ({
    type: USERS_GET_BANK_PROFILE_SUCCESS,
    payload: res.data,
  }),
  getBankProfileFailure: (err: AxiosError) => ({
    type: USERS_GET_BANK_PROFILE_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  setNotAuthenticated: () => ({
    type: USERS_SET_NOT_AUTHENTICATED,
  }),
  loginUserFailure: (err: AxiosError) => ({
    type: USERS_LOGIN_FAILURE,
    payload: err?.response?.data,
  }),
  loginUserRequest: () => ({
    type: USERS_LOGIN_REQUEST,
  }),
  loginUserSuccess: (res: AxiosResponse) => ({
    type: USERS_LOGIN_SUCCESS,
    payload: { ...res.data },
  }),
  logoutUser: () => ({
    type: USERS_LOGOUT,
  }),
  updatePaymentProfileRequest: () => ({
    type: USERS_UPDATE_PAYMENT_PROFILE_REQUEST,
  }),
  updatePaymentProfileSuccess: (res: AxiosResponse) => ({
    type: USERS_UPDATE_PAYMENT_PROFILE_SUCCESS,
    payload: res.data,
  }),
  updatePaymentProfileFailure: (err: AxiosError) => ({
    type: USERS_UPDATE_PAYMENT_PROFILE_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  updateTalentProfilesRequest: () => ({
    type: USERS_UPDATE_PROFILE_REQUEST,
  }),
  updateTalentProfilesSuccess: (res: AxiosResponse) => ({
    type: USERS_UPDATE_PROFILE_SUCCESS,
    payload: res.data,
  }),
  updateTalentProfilesFailure: (err: AxiosError) => ({
    type: USERS_UPDATE_PROFILE_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  verifyTokenFailure: (err: AxiosError) => ({
    type: USERS_VERIFY_TOKEN_FAILURE,
    payload: err?.response?.data,
  }),
  verifyTokenRequest: () => ({
    type: USERS_VERIFY_TOKEN_REQUEST,
  }),
  verifyTokenSuccess: (res: AxiosResponse, token: string) => ({
    type: USERS_VERIFY_TOKEN_SUCCESS,
    payload: { ...res.data, token },
  }),
  resetUserRequest: () => ({ type: AUTH_RESET_REQUEST_PASSWORD_REQUEST }),
  resetUserSuccess: (res: AxiosResponse) => ({
    type: AUTH_RESET_REQUEST_PASSWORD_SUCCESS,
    payload: res.data,
  }),
  resetUserFailure: (err: AxiosError) => ({
    type: AUTH_RESET_REQUEST_PASSWORD_FAILURE,
    payload: err?.response?.data,
  }),
  setPasswordRequest: () => ({ type: USERS_SET_PASSWORD_REQUEST }),
  setPasswordSuccess: (res: AxiosResponse) => ({
    type: USERS_SET_PASSWORD_SUCCESS,
    payload: res.data,
  }),
  setPasswordFailure: (err: AxiosError) => ({
    type: USERS_SET_PASSWORD_FAILURE,
    payload: err?.response?.data,
  }),
  checkResetTokenRequest: () => ({
    type: AUTH_RESET_CHECK_PASSWORD_REQUEST,
  }),
  checkResetTokenSuccess: (res: AxiosResponse) => ({
    type: AUTH_RESET_CHECK_PASSWORD_SUCCESS,
    payload: res.data && res.data.response,
  }),
  checkResetTokenFailure: (err: AxiosError) => ({
    type: AUTH_RESET_CHECK_PASSWORD_FAILURE,
    payload: err?.response?.data,
  }),
  getTagsRequest: () => ({
    type: GET_TAGS_REQUEST,
  }),
  getTagsSuccess: (res: AxiosResponse) => ({
    type: GET_TAGS_SUCCESS,
    payload: res.data,
  }),
  getTagsFailure: (err: AxiosError) => ({
    type: GET_TAGS_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  updateTagRequest: () => ({
    type: UPDATE_TAG_REQUEST,
  }),
  updateTagSuccess: (res: ITag) => ({
    type: UPDATE_TAG_SUCCESS,
    payload: res,
  }),
  updateTagFailure: (err: AxiosError) => ({
    type: UPDATE_TAG_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  deleteTagRequest: () => ({
    type: DELETE_TAG_REQUEST,
  }),
  deleteTagSuccess: (tagId: number) => ({
    type: DELETE_TAG_SUCCESS,
    payload: tagId,
  }),
  deleteTagFailure: (err: AxiosError) => ({
    type: DELETE_TAG_FAILURE,
    payload: err && err.response && err.response.data,
  }),
  addNewTag: (tag: ITag) => ({
    type: ADD_NEW_TAG,
    payload: tag,
  }),
};
