import moment from "moment";
import { Star } from "phosphor-react";
import { Rating } from "primereact/rating";
import React from "react";
import { ITalent } from "~/store/constants/talent";

export interface IColumn {
  field: string;
  header: string;
  showStatement?: boolean;
  props?: {
    [key: string]: any;
  };
}

const getColumns = (isAdmin: boolean, globalOptions: any, isTalentNetworkTab: boolean = false) => {
  const renderColumnStarred = (rowData: ITalent) => {
    return rowData.starred ? <Star /> : null;
  };

  const renderColumnDayRate = (rowData: ITalent) => {
    return isAdmin && !isTalentNetworkTab ? (rowData.rate ? `£${rowData.rate}` : "") : rowData.rate_string;
  };

  const renderColumnRating = (rowData: ITalent) => {
    return rowData?.note?.rating ? <Rating cancel={false} readOnly stars={5} value={rowData.note.rating} /> : "—";
  };

  const renderColumnAvailability = (rowData: ITalent) => {
    return rowData.is_available
      ? rowData.start_from
        ? moment().isBefore(rowData.start_from, "day")
          ? moment(rowData.start_from).format("DD/MM/YYYY")
          : "Available now"
        : ""
      : "Unavailable";
  };

  const renderColumnCreatedAt = (rowData: ITalent) => {
    return <span>{moment(rowData.created_at).format("DD/MM/YYYY")}</span>;
  };

  const renderColumnWorkingStatus = (rowData: ITalent) => {
    const talentWorkingStatusId = rowData.working_status_id;
    return (
      <>
        {talentWorkingStatusId
          ? globalOptions?.working_statuses.find(({ id }: { id: number }) => id === talentWorkingStatusId).name
          : ""}
      </>
    );
  };

  const columns: IColumn[] = [
    {
      field: "rating",
      header: "Rating",
      showStatement: !isAdmin,
      props: {
        sortable: true,
        body: renderColumnRating,
      },
    },
    {
      field: "starred",
      header: "Starred",
      showStatement: isAdmin && !isTalentNetworkTab,
      props: {
        sortable: true,
        style: {
          width: "8em",
        },
        body: renderColumnStarred,
      },
    },
    {
      field: isAdmin ? "rate" : "rate_string",
      header: "Day rate",
      props: {
        sortable: true,
        style: {
          width: "8em",
        },
        body: renderColumnDayRate,
      },
    },
    {
      field: "start_from",
      header: "Availability",
      showStatement: !isTalentNetworkTab,
      props: {
        sortable: true,
        body: renderColumnAvailability,
      },
    },
    {
      field: "created_at",
      header: "Created at",
      props: {
        sortable: true,
        body: renderColumnCreatedAt,
      },
    },
    {
      field: "working_status_id",
      header: "Working status",
      showStatement: isAdmin && !isTalentNetworkTab,
      props: {
        sortable: true,
        style: {
          width: "15em",
        },
        body: renderColumnWorkingStatus,
      },
    },
    {
      field: "employer",
      header: "Current Employer",
      showStatement: isAdmin && !isTalentNetworkTab,
      props: {
        sortable: true,
        style: {
          width: "15em",
        },
      },
    },
  ];

  return columns;
};

export default getColumns;
