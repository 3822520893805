import React from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import EditorToolbar, { modules as defaultModules } from "./ApRichTextToolbar";
import "quill/dist/quill.snow.css";
import "./ApRichText.scss";

export default function ApRichText({ value = "", id = "ApRichText", onChange, ...rest }: ReactQuillProps) {
  return (
    <>
      <div className="ApRichText">
        <EditorToolbar toolbarId={id} />
        <ReactQuill modules={defaultModules(id)} value={value} onChange={onChange} {...rest} />
      </div>
    </>
  );
}
