import React, { useMemo } from "react";
import { FormikContextType } from "formik";
import { useGlobalContext } from "~/contexts/GlobalContext";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";

interface IWorkingLocation {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}
export default function WorkingLocation(props: IWorkingLocation) {
  const { formik, isViewOnly = false } = props;
  const {
    global: { optionsNormalization, globalOptions },
  } = useGlobalContext();
  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  const IR35_TYPES = optionsNormalization?.IR35_TYPES;
  let locations = globalOptions ? [...globalOptions?.locations].splice(0, 3) : [];

  const locationOptions = useMemo(
    () => locations?.map(({ id: code, name }: { id: number; name: string }) => ({ code, name })),
    [locations]
  );

  const isAvailableWorkingLocation =
    formik.values.exempt ||
    formik.values.ir35_compliant ||
    formik.values.contract_id === CONTRACT_TYPES?.FIXEDTERM ||
    (formik.values.contract_id === CONTRACT_TYPES?.FREELANCE && formik.values.ir35_id !== IR35_TYPES?.OUTSIDE);

  return isAvailableWorkingLocation ? (
    <div className="p-fluid field field-required">
      <label htmlFor="location_id">Location</label>
      <ApRadioGroup formik={formik} name="location_id" noField options={locationOptions || []} disabled={isViewOnly} />
    </div>
  ) : null;
}
