import { Form, Formik } from "formik";
import cn from "classnames";
import { Button } from "primereact/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApRating from "~/components/common/ApRating/ApRating";
import { talentNotesInputMaxLength } from "~/schemas/common";
import { TalentNotesSchema } from "~/schemas/notes";
import { IState } from "~/store/reducers/index";
import * as Talent from "~/store/constants/talent";
import actions from "~/store/actions";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants";

function TalentNotesForm({ isGridViewForm }: { isGridViewForm: boolean }) {
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.talent.isFetching);
  const talent = useSelector((state: IState) => state.talent.talent);
  const { userAccess } = usePermissionContext();

  const note = (talent as { note: Talent.ITalentNotes })?.note;

  const initialValues: Talent.ITalentNotes = {
    notes: note?.notes || "",
    rating: note?.rating || 0,
    ...(!isGridViewForm && { type: note?.type || "" }),
  };

  async function updateTalentNotes(values: Talent.ITalentNotes) {
    await dispatch(actions.talent.updateTalentNotes(values as Talent.ITalentNotes, isGridViewForm));
    isGridViewForm && dispatch(actions.modal.closeModal());
  }

  return (
    <>
      {!isGridViewForm && <h2>Notes</h2>}
      <div className="grid">
        <div className={cn("col-12", !isGridViewForm && "md:col-6")}>
          <Formik initialValues={initialValues} validationSchema={TalentNotesSchema} onSubmit={updateTalentNotes}>
            <Form className="TalentNotes">
              <div className="p-fluid field">
                <label htmlFor="rating">Rating</label>
                <ApRating id="rating" />
              </div>
              {!isGridViewForm && (
                <div className="p-fluid field">
                  <label htmlFor="type">Type</label>
                  <ApInputText id="type" maxChars={talentNotesInputMaxLength.type} />
                </div>
              )}
              <div className="p-fluid field">
                <label htmlFor="notes">Internal notes (not shared with freelancers)</label>
                <ApInputTextarea id="notes" maxChars={talentNotesInputMaxLength.notes} />
              </div>
              {userAccess(permissionType.accessClientAdmin) && <Button loading={loading} label="Save" type="submit" />}
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}

export default TalentNotesForm;
