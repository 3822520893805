import React from "react";
import { Dialog } from "primereact/dialog";
import UserManagementForm from "../../forms/UserManagementForm/UserManagementForm";
import { useSelector } from "react-redux";
import { IState } from "../../store/reducers";
import { Button } from "primereact/button";
import { Formik, Form } from "formik";
import { UserManagementSchema } from "../../schemas/user";
import "./AddUserModal.scss";
import { IUserForm } from "../../store/constants/user";

const DEFAULT_CLIENT_ROLE = "client_admin_briefing"; // briefing only role

export default function AddUserModal() {
  const { modalProps } = useSelector((state: IState) => state.modal);

  function onSubmit(user: IUserForm) {
    if (modalProps?.onSubmit) {
      modalProps.onSubmit(user);
    }
  }

  return (
    <Formik
      initialValues={
        modalProps.user || {
          first_name: "",
          last_name: "",
          email: "",
          telephone: "",
          ...(modalProps.type === "client" && {
            role: DEFAULT_CLIENT_ROLE,
            additional_roles: [],
          }),
        }
      }
      validationSchema={UserManagementSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {(formik) => (
        <Form>
          <Dialog
            className="AddUserModal"
            header={`${modalProps.user ? "Edit" : "Add a new"} User`}
            footer={
              <footer>
                {/*todo uncomment on BE ready*/}
                {/*{modalProps.user && (*/}
                {/*  <Button*/}
                {/*    className="p-button-secondary"*/}
                {/*    icon="pi pi-trash"*/}
                {/*    label="Remove"*/}
                {/*    onClick={modalProps.onRemove}*/}
                {/*  />*/}
                {/*)}*/}
                <Button label="Save" type="submit" onClick={() => formik.submitForm()} />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            <UserManagementForm edit={!!modalProps.user?.id} type={modalProps.type} />
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
