import React from "react";

export const SUPERPOWERS = "superpowers";
export const SKILLS = "skills";
export const LANGUAGES = "languages";
export const SECTORS = "sectors";
export const PLATFORMS = "platforms";

export const DEFAULT_DURATION_DAYS = 1;
export const maxSectorsCount = 4;
export const maxPlatformsCount = 5;
export const maxLanguagesCount = 50;

export const budgetOptions = [
  {
    code: "TYPE_TOTAL",
    name: "Total Budget for Period Worked",
  },
  {
    code: "TYPE_ANNUAL",
    name: "Pro Rata Annual Salary",
  },
];

export const skillPopupContent = (
  <span>
    🦸🏽‍♀️ Superpowers are my way of deconstructing a CV or portfolio of work to figure out what makes talent special.{" "}
    <br />
    <br />
    Don’t worry it’s not the only thing I look at - I’m smarter than that - but selecting what superpowers you think you
    need, then ranking them gives me a great head start on our journey to finding you the best person for your brief. 🦾
  </span>
);

export const languagesPopupContent = (
  <span>
    By adding a language to your brief requirements, only talent who are fluent in the selected language will be
    approached. When multiple languages are selected, you will be able to choose via the related checkbox if ALL the
    selected languages must be spoken fluently by the talent or if only one of the selected languages is required.
  </span>
);

export const durationTypes = [
  {
    title: "Consecutive Days",
    description: "The most common type of booking. Every day between start and finish date.",
    videoSrc: "https://www.loom.com/embed/d8115aef98b04f38a779f8d0c1200796",
  },
  {
    title: "Certain Number of Days",
    description:
      "When you only need a freelancer for a certain number of days in a period, but the freelancer decides what days.",
    videoSrc: "https://www.loom.com/embed/be47305cb2ca41fd8c68a09ea3fc347c",
  },
  {
    title: "Exact Working Dates",
    description:
      "When you want a freelancer to work on specific dates. Pls select each day you need the freelancer to work.",
    videoSrc: "https://www.loom.com/embed/6ccc5e6c421b474bb06392dad127cb3c",
  },
];
