import React, { useEffect, useState, useMemo } from "react";
import { Button } from "primereact/button";
import "./TagsView.scss";
import { Sidebar } from "primereact";
import { ITag } from "~/interfaces/common";
import TagForm from "../../../forms/TagForm/TagForm";
import TagsList from "./TagsList";
import ApTag from "~/components/common/ApTag/ApTag";
import { useDispatch, useSelector } from "react-redux";
import actions from "~/store/actions";
import { IUserState } from "~/store/reducers/user";

export default function TagsView({
  talentName,
  talentId,
  tags,
}: {
  talentName: string;
  talentId: number;
  tags: ITag[];
}) {
  const [selectedItems, setSelectedItems] = useState<ITag[]>([]);
  const [activeTag, setActiveTag] = useState<ITag | undefined>();
  const [editMode, setEditMode] = useState(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [userTags, setUserTags] = useState<ITag[]>([]);
  const dispatch = useDispatch();
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);
  const isFetchingTags = useSelector((state: any) => state.user.isFetchingTags);
  const isFetchingTalentTags = useSelector((state: any) => state.talent.isFetchingTags);
  const isFetching = useMemo(() => isFetchingTags || isFetchingTalentTags, [isFetchingTags, isFetchingTalentTags]);

  useEffect(() => {
    showSidebar && dispatch(actions.user.getTags());
  }, [showSidebar]);

  useEffect(() => {
    setUserTags(currentUser?.tags ?? []);
  }, [currentUser?.tags]);

  useEffect(() => {
    setSelectedItems(tags);
  }, [tags]);

  const showSidebarHandler = () => {
    setShowSidebar(true);
  };

  const resetEditMode = () => {
    setActiveTag(undefined);
    setEditMode(false);
  };

  const handleEditMode = (rowData?: ITag) => {
    setEditMode(true);
    setActiveTag(rowData);
  };

  const handleUpdate = (data: any) => {
    activeTag
      ? dispatch(actions.user.updateTag(data)).then(resetEditMode)
      : dispatch(actions.talent.addTalentTag(talentId, data)).then(resetEditMode);
  };

  const handleAttachTag = ({ data }: { data: ITag }) =>
    !isFetching && dispatch(actions.talent.addTalentTag(talentId, data));
  const handleDetachTag = ({ data }: { data: ITag }) =>
    !isFetching && dispatch(actions.talent.removeTalentTag(talentId, data.id));

  const handleDelete = (data: ITag) => {
    dispatch(actions.user.deleteTag(data)).then(resetEditMode);
  };

  return (
    <>
      <div className="card-tags">
        {selectedItems.length ? (
          selectedItems.map((tag) => (
            <ApTag key={tag.name} title={tag.name} fill={tag.color} onClick={showSidebarHandler} />
          ))
        ) : (
          <Button
            className="p-button-icon"
            icon="pi pi-tag"
            tooltip="Add Tag"
            tooltipOptions={{ position: "top" }}
            onClick={showSidebarHandler}
            type="button"
          />
        )}
      </div>
      <Sidebar
        visible={showSidebar}
        onHide={() => {
          setShowSidebar(false);
          resetEditMode();
        }}
        position="right"
        showCloseIcon={false}
        className="tags-settings"
      >
        <div className="tags-header">
          {editMode && (
            <Button
              type="button"
              icon="pi pi-arrow-left"
              className="p-button-rounded p-button-text icon-back"
              onClick={resetEditMode}
            />
          )}
          <h3>{editMode ? (activeTag ? "Edit tag" : "Create a new tag") : talentName}</h3>
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-rounded p-button-text icon-close"
            onClick={() => {
              setShowSidebar(false);
              setActiveTag(undefined);
              setEditMode(false);
            }}
          />
        </div>
        <div className="tags-body">
          {editMode ? (
            <TagForm tags={userTags} tag={activeTag} handleUpdate={handleUpdate} handleDelete={handleDelete} />
          ) : (
            <TagsList
              selectedItems={selectedItems}
              tags={userTags}
              handleAttachTag={handleAttachTag}
              handleDetachTag={handleDetachTag}
              handleEditMode={handleEditMode}
            />
          )}
        </div>
      </Sidebar>
    </>
  );
}
