import { Field } from "formik";
import { InputText } from "primereact/inputtext";
import React, { ChangeEvent, ReactNode } from "react";

interface IApInputText {
  autoFocus?: boolean;
  required?: boolean;
  noField?: boolean;
  formik?: any;
  id: string;
  label?: string;
  maxChars?: number;
  keyfilter?: RegExp | string;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  validate?: Function;
  price?: boolean;
  value?: string;
  type?: string;
  isError?: boolean;
  rightElement?: ReactNode;
}

export default function ApInputText(props: IApInputText) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const { value } = e.target;
    if (props.formik) {
      const val = props.price && value.charAt(0) === "£" ? value.substring(1) : value;
      props.formik.setFieldValue(props.id, val);
    }
  }

  return props.noField && meta ? (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <div>
        <InputText
          id={props.id}
          type={props.type || "text"}
          placeholder={props.placeholder}
          {...field}
          disabled={props.disabled}
          maxLength={props.maxChars || props.maxLength}
          value={((!!props.value || !!field.value) && !!props.price ? "£" : "") + (props.value || field.value || "")}
          keyfilter={props.keyfilter}
          onChange={props.price ? onChange : field.onChange}
          autoFocus={props.autoFocus || false}
          className={props.isError && "error"}
        />
        {props.maxChars && (
          <div className="charCount text-right">{props.maxChars - (field.value || "").length} characters left</div>
        )}
        {props.rightElement}
      </div>
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.id} validate={props.validate}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <InputText
            id={props.id}
            type="text"
            placeholder={props.placeholder}
            {...field}
            disabled={props.disabled}
            maxLength={props.maxChars || props.maxLength}
            value={props.value || field.value || ""}
            keyfilter={props.keyfilter}
            autoFocus={props.autoFocus || false}
          />
          {props.maxChars && (
            <div className="charCount text-right">{props.maxChars - (field.value || "").length} characters left</div>
          )}
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
}
