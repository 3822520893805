import React, { useState } from "react";
import { durationTypes } from "../constants";
import Loader from "~/components/Loader/Loader";
import "./durationTypesPopup.scss";

export default function DurationTypesPopup() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <div className="durationTypesPopup">
      {durationTypes.map(({ title, description, videoSrc }, index) => (
        <>
          <h3>{title}</h3>
          <p>
            {description}{" "}
            <span onClick={() => setActiveIndex(activeIndex === index ? null : index)}>
              {activeIndex === index ? "Hide video" : "Show video"}
            </span>
          </p>
          {activeIndex === index && (
            <div className="video">
              <Loader className="iframe-loader" size={1.5} />
              <iframe src={videoSrc} frameBorder="0" allowFullScreen />
            </div>
          )}
        </>
      ))}
    </div>
  );
}
