import cn from "classnames";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { InvoiceSchema } from "~/schemas/InvoiceSchema";
import toasts from "~/store/actions/toasts";
import actions from "~/store/actions";
import { formatCurrency, padWithZero } from "~/utils";
import LineItemForm from "./LineItemForm";
import LineItemFormHeaders from "./LineItemFormHeaders";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { IInvoice, IInvoiceFormValues, ILineItem, ILineItemUnit } from "~/interfaces/invoices";
import "./EditInvoiceModal.scss";

function InvoiceForm(props: { invoice?: IInvoice }) {
  const { invoice } = props;
  const { talent_rate, start_date, end_date } = {
    talent_rate: String(invoice?.line_items[0]?.unit_amount),
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };

  const dispatch = useDispatch();
  const [activeLineItem, setActiveLineItem] = useState<number | null>(0);
  const formikRef = useRef<FormikProps<IInvoiceFormValues> | null>(null);
  const { isFetching, putInvoice } = useInvoiceContext();

  const createDefaultLineItem = (): ILineItem => ({
    isNew: true,
    description: "",
    unit_type: ILineItemUnit.TYPE_DAY_RATE,
    unit_amount: parseInt(talent_rate || "0"),
    quantity: 1,
    dates: [
      moment().isAfter(start_date) ? start_date : moment().format("YYYY-MM-DD"),
      moment().isAfter(end_date) ? end_date : moment().format("YYYY-MM-DD"),
    ],
    line_amount: parseInt(talent_rate || "0"),
  });

  const initialValues: IInvoiceFormValues = {
    lineItems:
      invoice?.line_items.map(({ start_date, end_date, line_item_id, ...rest }) => ({
        ...rest,
        dates: [start_date, end_date],
      })) || [],
    notes: invoice?.notes || "",
    agree: false,
    includeVAT: invoice?.tax_type === "TAX_EXCLUSIVE",
    subtotal: 0,
    subtotalVAT: 0,
    total: 0,
  };

  useEffect(() => {
    !!invoice?.id && setActiveLineItem(null);
  }, [invoice]);

  const addRemoveItemConfirm = (addItem: boolean = true, callback: any) => {
    const content: string = addItem ? "Do you want to add a new Item?" : "Are you sure you want to remove Item?";

    dispatch(
      toasts.setPopup({
        content,
        buttons: [
          { text: "Close" },
          {
            text: addItem ? "Add" : "Remove",
            callback,
          },
        ],
      })
    );
  };

  const onAddItem = (values: IInvoiceFormValues, setValues: any) => {
    const lineItems = [...values.lineItems, createDefaultLineItem()];
    setValues({ ...values, lineItems });
  };

  const onRemoveLineItem = (values: IInvoiceFormValues, setValues: any, index: number) => {
    if (values.lineItems.length > 1) {
      const lineItems = [...values.lineItems];
      lineItems.splice(index, 1);
      setValues({ ...values, lineItems });
      calculateTotal({ ...values, lineItems }, setValues);
    }
  };

  const calculateTotal = (values: IInvoiceFormValues, setValues: any, index: number | false = false) => {
    const lineItems = [...values.lineItems];

    if (index !== false) {
      lineItems[index] = {
        ...lineItems[index],
        line_amount: lineItems[index].quantity * lineItems[index].unit_amount,
      };
    }

    const subtotal = lineItems.reduce((acc, item) => acc + item.line_amount, 0);

    setValues({
      ...values,
      lineItems,
      subtotal,
      subtotalVAT: subtotal * (values.includeVAT ? 0.2 : 0),
      total: subtotal * (values.includeVAT ? 1.2 : 1),
    });
  };

  const onChangeVAT = (values: IInvoiceFormValues, setValues: any) => {
    const includeVAT = !values.includeVAT;

    setValues({
      ...values,
      includeVAT,
      subtotalVAT: values.subtotal * (includeVAT ? 0.2 : 0),
      total: values.subtotal * (includeVAT ? 1.2 : 1),
    });
  };

  const onSubmit = async (values: IInvoiceFormValues & { status?: "SUBMITTED" }) => {
    const payload: IInvoice = {
      line_items: values.lineItems.map(({ dates, ...item }) => ({
        ...item,
        start_date: dates && dates[0],
        end_date: dates && dates[1],
      })),
      notes: values.notes,
      currency_code: "GBP",
      currency_rate: 1,
      platform: "XERO",
      date: invoice?.date || "",
      due_date: invoice?.due_date || "",
      tax_type: values.includeVAT ? "TAX_EXCLUSIVE" : "TAX_NONE",
    };
    if (invoice?.id) {
      payload.id = invoice.id;
      const status = await putInvoice(payload);

      status === 200 && dispatch(actions.modal.closeModal());
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={InvoiceSchema}
      onSubmit={onSubmit}
    >
      {({ values, setValues, isValid }) => {
        return (
          <Form>
            <Dialog
              className="EditInvoiceModal"
              header={`Edit Invoice GEN-${padWithZero(invoice.id, 5)}`}
              footer={
                <footer>
                  <Button
                    label="Update"
                    type="submit"
                    icon="pi pi-check"
                    disabled={!isValid || isFetching}
                    onClick={() => onSubmit({ ...values })}
                  />
                </footer>
              }
              visible={true}
              modal={true}
              onHide={() => dispatch(actions.modal.closeModal())}
            >
              <div className="EditInvoiceModal__form">
                <div className="EditInvoiceModal__form__lineItems">
                  <LineItemFormHeaders />
                  <FieldArray name="lineItems">
                    {() =>
                      values.lineItems.map((item, i) => (
                        <div
                          key={i}
                          className={cn("EditInvoiceModal__form__lineItems__item", {
                            "active-line-item": i === activeLineItem,
                          })}
                        >
                          <div
                            className={cn("delete", values.lineItems.length < 2 && "disabled")}
                            onClick={() =>
                              values.lineItems.length > 1 &&
                              addRemoveItemConfirm(false, () => onRemoveLineItem(values, setValues, i))
                            }
                          />
                          <LineItemForm key={i} item={item} calculateTotal={calculateTotal} index={i} />
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Field name="numberOfLineItems">
                    {() => (
                      <Button
                        label="+ Add item"
                        onClick={() =>
                          addRemoveItemConfirm(true, () => {
                            onAddItem(values, setValues);
                            setActiveLineItem(values.lineItems.length);
                          })
                        }
                        type="button"
                        name="lineItems"
                        className="inverse"
                      />
                    )}
                  </Field>
                </div>
                <div className="EditInvoiceModal__form__bottom grid">
                  <div className="col-12 flex justify-content-end align-items-end">
                    <table>
                      <tbody>
                        <tr>
                          <td>Subtotal:</td>
                          <td>{formatCurrency(values.subtotal)}</td>
                        </tr>
                        <tr>
                          <td>
                            <div className="InputSwitchWrapper">
                              <InputSwitch
                                id="includeVAT"
                                checked={values.includeVAT}
                                onChange={() => onChangeVAT(values, setValues)}
                              />
                              Include Total VAT, 20%:
                            </div>
                          </td>
                          <td>{formatCurrency(values.subtotalVAT)}</td>
                        </tr>
                        <tr>
                          <td>Total:</td>
                          <td>{formatCurrency(values.total)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
}

export default InvoiceForm;
