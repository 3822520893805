import { FormikProps, FormikValues, useFormik } from "formik";
import { Location } from "history";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Steps } from "primereact/steps";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Prompt, useHistory, RouterProps } from "react-router-dom";
import BriefDatesDaysCount from "~/components/BriefDatesDaysCount/BriefDatesDaysCount";
import { useBriefContext } from "~/contexts/BriefContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import useBeforeUnload from "~/hooks/useBeforeUnload";
import { CONFIRM_LEAVE_PAGE } from "../../config";
import toasts from "../../store/actions/toasts";
import { BriefStatuses, IBrief } from "../../interfaces/brief";
import { IState } from "../../store/reducers/index";
import { isBriefFormComplited, isBriefFormTouched, processBriefForm, processDraftBriefForm } from "../../utils";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { IR35_CLIENT_SETTINGS } from "./constants";
import { checkBriefForDraft } from "~/routes/Brief/helper";
import Loader from "~/components/Loader/Loader";
import "./CreateBriefForm.scss";
import { useLoadingAnimationContext } from "~/contexts/LoadingAnimationContext";
import actions from "~/store/actions";

const MIN_STEP = 0;

interface ICreateBriefFormProps {
  saveOrConfirmCallback: (values: any) => void;
  validationSchema: any;
  additionalValues: any;
  renderStepContent: (step: number, formik: FormikProps<FormikValues>, callback?: () => void) => void;
  title: string;
  handleClientChange: (formik: FormikProps<FormikValues>) => void;
  handlerLoadComponent?: (formik: FormikProps<FormikValues>) => void;
  handleChangeDurationType?: (formik: FormikProps<FormikValues>) => void;
  isSubmitButtonDisabled: (formik: FormikProps<FormikValues>) => boolean;
  updateDraftBrief?: (formik: FormikProps<FormikValues>) => void;
  cancelDraftBrief?: (briefId: number) => void;
  draftValues?: any;
  isAvailableSaveDraft?: boolean;
  loading: boolean;
}

function CreateBriefForm(props: ICreateBriefFormProps): JSX.Element {
  const {
    title,
    validationSchema,
    additionalValues,
    renderStepContent,
    saveOrConfirmCallback,
    handleClientChange,
    handlerLoadComponent = () => null,
    handleChangeDurationType = () => null,
    isSubmitButtonDisabled,
    draftValues,
    updateDraftBrief,
    isAvailableSaveDraft,
    loading,
    cancelDraftBrief = () => null,
  } = props;
  const { withLoadingAnimation } = useLoadingAnimationContext();
  const dispatch = useDispatch();
  const history = useHistory<RouterProps>();
  const {
    global: { globalOptions, optionsNormalization },
  } = useGlobalContext();
  const { checkIsClientAdmin, checkIsAdmin } = usePermissionContext();
  const isAdmin = useMemo(() => checkIsAdmin(), []);
  const { validateBrief, resetBrief, getBriefFormValues, createBrief, createClientAdminBrief } = useBriefContext();
  const { CONTRACT_TYPES, IR35_TYPES, DURATION_ID } = optionsNormalization;

  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const clientIr35 = useSelector((state: IState) => state.client.client?.payment_profile);

  const ir35_compliant = useMemo(() => clientIr35?.ir35_compliant || false, [clientIr35]);

  const isClientAdmin = useMemo(() => checkIsClientAdmin(), []);
  const [isTouched, setTouched] = useState(false);
  const [isSpecialismTouched, setSpecialismTouched] = useState(false);
  const [preset, setPreset] = useState(false);
  const [step, setStep] = useState(0);

  const redirectToFirstStep = () => {
    setStep(0);
    window.scrollTo(0, 0);
    formik.setSubmitting(false);
    setTimeout(() => {
      formik.setFieldError("ir35_id", "Please review IR35 type for this brief");
      formik.setFieldValue("ir35_id", null);
      formik.setTouched({ ...formik.touched, ir35_id: true });
    });
  };

  const handleOnSubmit = (form: any, actions) => {
    if (isLastStep) {
      processBriefForm(
        form,
        saveOrConfirm,
        () => optionsNormalization,
        { status: isClientAdmin ? BriefStatuses.PUBLISHED : BriefStatuses.UNPUBLISHED },
        dispatch,
        validateBrief,
        redirectToFirstStep,
        (error_fields) =>
          formik.setValues({
            ...formik.values,
            ...error_fields.reduce((a, v) => ({ ...a, [v]: null }), {}),
          })
      );
    } else {
      window.scrollTo(0, 0);
      setStep(step + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  const saveDraft = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, formik: any) => {
    e.preventDefault();
    setTouched(false);
    const isExistingDraftBrief = !!formik.values.id;
    const saveOrConfirmDraft = isAdmin ? createBrief : createClientAdminBrief;
    processDraftBriefForm(
      formik.values,
      isExistingDraftBrief ? updateDraftBrief : saveOrConfirmDraft,
      history,
      () => optionsNormalization
    );
  };

  const cancelDraft = ({ values: { id } }: any) => {
    dispatch(
      toasts.setPopup({
        content: (
          <>
            Are you sure you want to cancel this draft brief? Once cancelled it will be deleted permanently from your
            drafts.
          </>
        ),
        buttons: [
          {
            text: "Dismiss",
            callback: () => dispatch(actions.modal.closeModal()),
          },
          {
            text: "Delete Draft",
            callback: async () => {
              setTouched(false);
              await cancelDraftBrief(id);
              history.push({
                pathname: "/briefs",
                state: { briefsCategoryIndex: 4 },
              });
            },
          },
        ],
      })
    );
  };

  const currentValidationSchema = validationSchema(step);

  const formik = useFormik({
    initialValues: draftValues || getBriefFormValues(additionalValues),
    onSubmit: handleOnSubmit,
    validationSchema: currentValidationSchema,
  });

  const isBriefDraft = checkBriefForDraft(formik.values);
  // Change amount of steps depends on IR35 type of brief
  const steps = useMemo(
    () => [
      { label: "Step 1", formTitle: "Brief Details" },
      { label: "Step 2", formTitle: "Brief Details" },
      ...(!ir35_compliant &&
      (formik.values.ir35_id === IR35_TYPES?.INSIDE || formik.values.ir35_id === IR35_TYPES?.OUTSIDE)
        ? [{ label: "Step 3", formTitle: "IR35 Checker" }]
        : []),
    ],
    [formik.values.ir35_id, IR35_TYPES]
  );
  const isLastStep = step === steps.length - 1;

  useEffect(() => {
    if (!isBriefDraft) {
      handlerLoadComponent(formik);
      resetBrief();
    }
  }, []);

  useBeforeUnload(isTouched, CONFIRM_LEAVE_PAGE);

  // Set default value for team_id field for Creative Directors and Creative Teams
  useEffect(() => {
    const creativeSpecialisms = globalOptions?.disciplines?.find(
      ({ code }: { code: string }) => code === "DISCIPLINE_CREATIVE"
    )?.specialisms;
    const creativeDirectorSpecialism = creativeSpecialisms?.find(
      ({ code }: { code: string }) => code === "SPECIALISM_CREATIVE_DIRECTOR"
    );
    const teamFlexIndividualsOnlyId = creativeDirectorSpecialism?.teamwork?.find(
      ({ code }: { code: string }) => code === "TEAM_INDIVIDUAL"
    )?.id;
    const creativeTeamSpecialism = creativeSpecialisms?.find(
      ({ code }: { code: string }) => code === "SPECIALISM_CREATIVE_TEAM"
    );
    const teamFlexTeamTeamId = creativeTeamSpecialism?.teamwork?.find(
      ({ code }: { code: string }) => code === "TEAM_TEAM"
    )?.id;

    if (!isBriefDraft || isSpecialismTouched) {
      if (formik.values.specialism_id && formik.values.specialism_id === creativeDirectorSpecialism?.id) {
        formik.setFieldValue("team_id", teamFlexIndividualsOnlyId);
      }

      if (formik.values.specialism_id && formik.values.specialism_id === creativeTeamSpecialism?.id) {
        formik.setFieldValue("team_id", teamFlexTeamTeamId);
      }
    }
    setSpecialismTouched(true);
  }, [formik.values.specialism_id]);

  // Fill up IR35 client settings once appear on IR35 validation step
  useEffect(() => {
    if (!isBriefDraft && step === 2 && clientIr35 && !preset) {
      IR35_CLIENT_SETTINGS.map((field: string) => formik.setFieldValue(field, clientIr35[field]));
      setPreset(true);
    }
  }, [step]);

  useEffect(() => {
    !isBriefDraft && DURATION_ID && handleChangeDurationType(formik);
  }, [DURATION_ID]);

  // Compare form state with initial values to detect changes
  useEffect(() => {
    const isTouched = isBriefFormTouched({ ...formik.values }, { ...formik.initialValues }, isClientAdmin);
    setTouched(isTouched);
  }, [formik.values]);

  // Clear IR35 and payment fields when brief type changing to fixed term
  useEffect(() => {
    if (CONTRACT_TYPES && formik.values.contract_id !== CONTRACT_TYPES?.FREELANCE) {
      formik.setFieldValue("ir35_id", null);
    }
    if (CONTRACT_TYPES && formik.values.contract_id !== CONTRACT_TYPES?.PERMANENT) {
      formik.setFieldValue("job_title", null);
    }
  }, [formik.values.contract_id]);

  // Load client info on client change
  useEffect(() => {
    if (formik.values.client_id) {
      handleClientChange(formik);
      setPreset(false);
    }
  }, [formik.values.client_id]);

  useEffect(() => {
    if (authenticatedUser?.id && authenticatedUser?.client_id && isClientAdmin) {
      formik.setFieldValue("client_id", authenticatedUser?.client_id);
      formik.setFieldValue("author_id", authenticatedUser?.id);
    }
  }, [authenticatedUser]);

  function prevStep(e: MouseEvent) {
    e.stopPropagation();
    window.scrollTo(0, 0);
    if (step > MIN_STEP) {
      setStep(step - 1);
    }
  }

  function saveOrConfirm(values: IBrief) {
    const isBriefDraft = checkBriefForDraft(formik.values);
    const updateDraftBriefFunction = (values) => withLoadingAnimation(updateDraftBrief, values);
    const callback =
      isBriefDraft && updateDraftBrief ? () => updateDraftBriefFunction(values) : () => saveOrConfirmCallback(values);

    dispatch(
      toasts.setPopup({
        content: <BriefDatesDaysCount data={formik.values} />,
        buttons: [
          { text: "Go back" },
          {
            text: "Approach talent",
            callback,
          },
        ],
      })
    );
  }

  const submitLabel = () => {
    if (isLastStep && steps.length === 3) {
      return "Check IR35 Status";
    }
    return isLastStep ? "Create Brief" : "Next";
  };

  const isDisallowSaveDraft = (formik: FormikProps<FormikValues>) =>
    !formik.values.name || !formik.values.client_id || !formik.values.author_id;

  return (
    <div>
      <header>
        <div>
          <div>
            <Link to="/briefs" className="header-back">
              <i className="pi pi-arrow-left"></i> Back to Briefs
            </Link>
          </div>
          <h1>{title}</h1>
        </div>
        <Steps className="steps" activeIndex={step} model={steps} readOnly={true}></Steps>
      </header>
      <div>
        <form>
          <Card className="step-content" title={steps[step].formTitle}>
            {renderStepContent(step, formik, redirectToFirstStep)}
            <footer className="p-field">
              {step > 0 && step !== steps.length && (
                <Button
                  className="p-button p-button-secondary align-left mr-2"
                  label="Back"
                  onClick={prevStep}
                  type="button"
                />
              )}
              {draftValues?.status === BriefStatuses.DRAFT && (
                <Button
                  className="p-button align-left"
                  disabled={isDisallowSaveDraft(formik)}
                  label="Delete Draft"
                  type="button"
                  onClick={() => cancelDraft(formik)}
                />
              )}
              <div>
                {isAvailableSaveDraft && (
                  <Button
                    className="p-button mr-2"
                    disabled={isDisallowSaveDraft(formik)}
                    label="Save Draft"
                    type="button"
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => saveDraft(e, formik)}
                  />
                )}
                <Button
                  className="p-button"
                  disabled={isSubmitButtonDisabled(formik)}
                  type="button"
                  onClick={formik.handleSubmit}
                >
                  {loading ? <Loader /> : submitLabel()}
                </Button>
              </div>
            </footer>
          </Card>
          <Prompt message={(location: Location) => isBriefFormComplited(location, isTouched)} />
        </form>
      </div>
    </div>
  );
}

export default CreateBriefForm;
