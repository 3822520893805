import React, { useMemo } from "react";
import { FormikContextType } from "formik";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import "../../CreateBriefForm/CreateBriefForm.scss";

interface IDescription {
  formik: FormikContextType<any>;
  teamwork: any;
  isViewOnly?: boolean;
}

export default function TeamFlexibility(props: IDescription) {
  const { formik, teamwork, isViewOnly = false } = props;
  const teamworkOptions = useMemo(
    () => teamwork?.map(({ id: code, name }: { id: number; name: string }) => ({ code, name })),
    [teamwork]
  );

  return teamworkOptions?.length > 1 ? (
    <div className="p-fluid field field-required col-4 flexibility">
      <label htmlFor="team_flexibility">Team flexibility</label>
      <ApRadioGroup formik={formik} name="team_id" noField options={teamworkOptions} disabled={isViewOnly} />
    </div>
  ) : null;
}
