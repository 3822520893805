import * as Yup from "yup";
import { minSymbolsMessage, maxSymbolsMessage, requiredFieldMessage } from "~/utils";
import { ITag } from "~/interfaces/common";

export const TagSchema = (tagsList: ITag[]) =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, minSymbolsMessage)
      .max(20, maxSymbolsMessage)
      .test({
        name: "unique-tagname-test",
        test: function (name) {
          return tagsList.some((tag) => tag.name.toLowerCase() === name?.toLowerCase() && tag.id !== this.parent.id)
            ? this.createError({
                message: "Tag with this name already exists",
                path: "name",
              })
            : true;
        },
      })
      .required(requiredFieldMessage),
    color: Yup.string().required(requiredFieldMessage),
  });
