import * as Yup from "yup";
import { UserManagementSchema } from "./user";
import { genieDetailsMaxInputLength } from "./common";
import { requiredFieldMessage, invalidNumberMessage } from "~/utils";

export enum MaxMostInfluential {
  DISCIPLINE_CREATIVE = 3,
  DISCIPLINE_DESIGN = 3,
  DISCIPLINE_SOCIAL = 4,
  DISCIPLINE_STRATEGY = 4,
  DISCIPLINE_BUSINESS = 4,
  DISCIPLINE_PRODUCTION = 4,
}

export const yearMinValueTest: any = {
  name: "year-min-value",
  message: "${path} must not be earlier than 1980",
  test: (value: string) => !value || +value >= 1980,
};

export const yearMaxValueTest: any = {
  name: "year-max-value",
  message: "${path} must not be later than current year",
  test: (value: string) => !value || +value <= new Date().getFullYear(),
};

const invalidFieldTest: (invalid_fields: string[]) => {
  name: string;
  message: string;
  test: (value: unknown) => boolean;
} = (invalid_fields) => ({
  name: "invalid field",
  message: "This field is invalid",
  test: function (value: string) {
    return !invalid_fields.includes(this.path);
  },
});

const talentQuick = {
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  discipline_code: Yup.string().required(requiredFieldMessage),
};

const SkillsManagementSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required(requiredFieldMessage),
  order_id: Yup.number(),
});

const primaryDetails = (isSuperAdmin: boolean = true, invalidFields: string[] = []) => ({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  discipline_code: Yup.string().test(invalidFieldTest(invalidFields)).required(requiredFieldMessage),
  specialism_code: Yup.string().nullable().test(invalidFieldTest(invalidFields)).required(requiredFieldMessage),
  level_code: Yup.string().test(invalidFieldTest(invalidFields)).required(requiredFieldMessage),
  team_code: Yup.string()
    .nullable()
    .when(["specialism_code"], {
      is: (specialism_code) => specialism_code === "SPECIALISM_CREATIVE_TEAM",
      then: Yup.string().required(requiredFieldMessage),
    })
    .test(invalidFieldTest(invalidFields)),
  start_from: Yup.date().nullable().required(requiredFieldMessage),
  location_code: Yup.string().required(requiredFieldMessage),
  notes: Yup.string().nullable().max(640, "Please, make it shorter than 640 symbols"),
  skills: isSuperAdmin
    ? Yup.array()
        .test(invalidFieldTest(invalidFields))
        .of(SkillsManagementSchema)
        .min(1, "At least one Superpower should be assigned")
    : Yup.array().nullable(),
  sectors: isSuperAdmin ? Yup.array().test(invalidFieldTest(invalidFields)).nullable() : Yup.array().nullable(),
});

const users = {
  users: Yup.array()
    .of(UserManagementSchema)
    .min(1, "At least one user should be assigned")
    .required(requiredFieldMessage),
};

const genieDetails = {
  headline: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(
      genieDetailsMaxInputLength.headline,
      `Please, make it shorter than ${genieDetailsMaxInputLength.headline} symbols`
    )
    .nullable()
    .required(requiredFieldMessage),
  subhead: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(
      genieDetailsMaxInputLength.subhead,
      `Please, make it shorter than ${genieDetailsMaxInputLength.subhead} symbols`
    )
    .nullable()
    .required(requiredFieldMessage),
  description: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(
      genieDetailsMaxInputLength.description,
      `Please, make it shorter than ${genieDetailsMaxInputLength.description} symbols`
    )
    .nullable()
    .required(requiredFieldMessage),
  rate: Yup.number().min(0).nullable().typeError(invalidNumberMessage),
  annual_rate: Yup.number().min(0).nullable().typeError(invalidNumberMessage),
  portfolio_url: Yup.string()
    .nullable()
    .matches(
      /^((https?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?$/,
      "Please, enter URL in correct format"
    )
    .required(requiredFieldMessage),
  cv_url: Yup.string().nullable(),
};

const blockedBoosted = {
  freeze: Yup.string(),
};

export const TalentPrimaryDetailsSchema = (isSuperAdmin: boolean, invalidFields: string[]) =>
  Yup.object().shape(primaryDetails(isSuperAdmin, invalidFields));

export const TalentUsersSchema = Yup.object().shape(users);

export const TalentGenieDetailsSchema = Yup.object().shape({
  work_profile: Yup.object().shape(genieDetails),
  platforms: Yup.array().when("discipline_code", {
    is: "DISCIPLINE_SOCIAL",
    then: Yup.array().of(Yup.object()).max(3, "Max 3 Platforms should be assigned").nullable(),
  }),
  country: Yup.string().nullable(),
});

export const TalentBlockedBoostedSchema = Yup.object().shape(blockedBoosted);

export const TalentQuickSchema = Yup.object().shape({
  ...talentQuick,
  ...users,
});

export const TalentSchema = Yup.object().shape({
  ...primaryDetails(),
  ...users,
  ...genieDetails,
  ...blockedBoosted,
});

export const MostInfluentialManagementSchema = Yup.object().shape({
  client_name: Yup.string().required().label("Company Name").max(100),
  start_year: Yup.string()
    .required()
    .label("Start Year")
    .length(4, "Start Year must be exactly 4 characters")
    .test(yearMinValueTest),
  end_year: Yup.string()
    .required()
    .label("Finish Year")
    .length(4, "Finish Year must be exactly 4 characters")
    .test(
      "finish-year-later-or-equal-to-start-year",
      "Finish Year must be later than or equal to Start Year",
      function (endYear: string) {
        const startYear = this.parent.start_year;
        if (!endYear || !startYear) return true;
        return +startYear <= +endYear;
      }
    )
    .test(yearMaxValueTest),
});

export const ReferenceSchema = Yup.object().shape({
  full_name: Yup.string().required().label("Name").max(100),
  email: Yup.string().label("Email").email("Please, enter the correct email").max(100).required(),
});

// ------------------------------------
// Initial values
// ------------------------------------

export const initialValues = {
  name: "",
  description: "",
  headline: "",
  subhead: "",
  portfolio_url: "",
  cv_url: "",
  rate: "",
  annual_rate: "",
  freeze: false,
  freeze_reason: "",
  start_from: "",
  discipline_code: "",
  specialism_code: "",
  level_code: "",
  team_code: "",
  location_code: "",
  notes: "",
  starred: false,
  users: [],
  skills: [],
  sectors: [],
  blocked_clients: [],
  boosted_clients: [],
  work_profile: {
    image_url: "",
  },
  platforms: [],
  country: "",
  invalid_fields: [],
};
