import React, { ChangeEvent } from "react";
import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import "./ApCheckbox.scss";

interface ICheckbox {
  disabled?: boolean;
  checked?: boolean;
  formik?: FormikProps<FormikValues>;
  label: string;
  name: string;
  noField?: boolean;
  yesNo?: boolean;
}

export default function ApCheckbox(props: ICheckbox) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.name);
    field = props.formik.getFieldProps(props.name);
  }

  function onChange(e: CheckboxChangeParams) {
    e.stopPropagation();
    props.formik?.setFieldValue(props.name, props.yesNo ? (e.checked ? "YES" : "NO") : e.checked);
  }

  return props.noField && meta && field ? (
    <div className="checkbox-wrapper">
      <Checkbox
        disabled={!!props.disabled}
        inputId={props.name}
        {...field}
        checked={typeof field.value === "boolean" ? !!field.value : field.value === "YES" ? true : false}
        onChange={onChange}
      />
      <label htmlFor={props.name} className="p-checkbox-label">
        {props.label}
      </label>
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </div>
  ) : (
    <Field name={props.name}>
      {({ field, form, meta }: FieldProps) => (
        <div className="checkbox-wrapper">
          <Checkbox
            disabled={!!props.disabled}
            inputId={props.name}
            {...field}
            checked={typeof field.value === "boolean" ? !!field.value : field.value === "YES"}
            onChange={(e) => form.setFieldValue(props.name, props.yesNo ? (e.checked ? "YES" : "NO") : e.checked)}
          />
          <label htmlFor={props.name} className="p-checkbox-label">
            {props.label}
          </label>
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </div>
      )}
    </Field>
  );
}
