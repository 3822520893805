.display-rate {
  margin-top: 18px;
}
.ClientGenieDetailsForm {
  .p-checkbox-label,
  .p-radiobutton-label {
    width: auto;
    cursor: pointer;
  }
}
