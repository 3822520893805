import * as Yup from "yup";

export const BriefMatchingSchema = Yup.object().shape({
  status: Yup.string().oneOf([
    "PENDING",
    "BRIEF_ACCEPTED",
    "BRIEF_DECLINED",
    "BOOKING_REQUESTED",
    "BOOKING_ACCEPTED",
    "BOOKING_DECLINED",
    "INTERVIEW_REQUESTED",
    "INTERVIEW_ACCEPTED",
    "OFFER_SENT",
  ]),
  talent_feedback: Yup.string().nullable(),
  talent_location: Yup.string().nullable(),
  talent_rate: Yup.number().min(0).nullable(),
  talent_substitution: Yup.boolean().nullable(),
  start_date: Yup.string().nullable(),
});

export const BriefMatchingInterviewSchema = Yup.object().shape({
  date: Yup.date().required("Please, choose the date"),
  medium: Yup.string().required("Please, select interview type"),
  organizer_id: Yup.mixed().required("Please, choose Interview Organiser"),
  attendees: Yup.array(),
  custom_attendees: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email(({ value }) => `${value}: is not a valid email;`),
    })
  ),
  meeting_url: Yup.string().url().label("Meeting Link/URL"),
  information: Yup.string().max(600, "Please, make it shorter than 600 symbols"),
  attachments: Yup.array().max(5, "Please, add less than 5 files"),
});
