import React from "react";
import { FormikContextType } from "formik";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";
import { useGlobalContext } from "~/contexts/GlobalContext";

interface IWorkplace {
  formik: FormikContextType<any>;
  isViewOnly?: boolean;
}
export default function Workplace(props: IWorkplace) {
  const { formik, isViewOnly = false } = props;
  const {
    global: { optionsNormalization },
  } = useGlobalContext();

  const CONTRACT_TYPES = optionsNormalization?.CONTRACT_TYPES;
  const IR35_TYPES = optionsNormalization?.IR35_TYPES;

  const isAvailableWorkplace =
    formik.values.exempt ||
    formik.values.ir35_compliant ||
    formik.values.contract_id === CONTRACT_TYPES?.FIXEDTERM ||
    (formik.values.contract_id === CONTRACT_TYPES?.FREELANCE && formik.values.ir35_id === IR35_TYPES?.UMBRELLA);

  const workplaces = [
    {
      label: "Freelancer decides the working location",
      name: "freelancer_defines_location",
    },
    {
      label: "Client can arrange space in the office",
      name: "freelancer_arrange_space",
    },
  ];

  const renderWorkingLocation = ({ label, name }: { label: string; name: string }) => (
    <div key={name} className="col-12">
      <ApCheckbox formik={formik} label={label} name={name} noField yesNo disabled={isViewOnly} />
    </div>
  );

  return isAvailableWorkplace ? <>{workplaces.map(renderWorkingLocation)} </> : null;
}
