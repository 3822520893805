import React, { useEffect, useRef, useState } from "react";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getInvoicesColumns } from "./invoicesColumns";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useDispatch, useSelector } from "react-redux";
import actions from "~/store/actions";
import NewInvoiceDetails from "../../NewInvoiceDetails";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { isNull, isUndefined } from "lodash";
import { IState } from "~/store/reducers/index";
import { IUserState } from "~/store/reducers/user";
import "./InvoicesList.scss";
import { IInvoice } from "~/interfaces/invoices";
import { getInvoiceStatusData } from "../helper";
import { MenuItemCommandParams } from "primereact/menuitem";

export const invoiceStatuses = [
  { label: "All", code: "" },
  { label: "Submitted", code: "SUBMITTED" },
  { label: "Disputed", code: "DISPUTED" },
  { label: "Approved", code: "AUTHORISED" },
  { label: "Paid", code: "PAID" },
  { label: "Talent Paid", code: "TALENT_PAID" },
  { label: "Client Paid", code: "CLIENT_PAID" },
  { label: "Draft", code: "DRAFT" },
  { label: "Overdue", code: "OVERDUE" },
  { label: "Cancelled", code: "CANCELLED" },
];

export default function NewInvoices() {
  const menuRefs = useRef([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState([]);

  const {
    isFetching,
    getNewInvoices,
    resetInvoices,
    invoices,
    total: totalRecords,
    putInvoice,
    uploadAttachments,
  } = useInvoiceContext();
  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = checkIsAdmin();
  const [invoiceStatus, setInvoiceStatus] = useState(null as null | { label: string; code: string });
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedTalent, setSelectedTalent] = useState(null);
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);
  const clients = isAdmin
    ? useSelector((state: IState) => state.client.clients)
    : currentUser
    ? [currentUser.client]
    : undefined;
  const talents = useSelector((state: IState) => state.talent.talents);
  const isFetchingTalents = useSelector((state: IState) => state.talent.isFetching);
  const locationState = useSelector((state: IState) => state.location.state) as { invoiceStatusIndex: number };

  useEffect(() => {
    isNull(invoiceStatus) && setInvoiceStatus(invoiceStatuses[locationState?.invoiceStatusIndex ?? 0]);
  }, [locationState?.invoiceStatusIndex]);

  useEffect(() => {
    !isNull(invoiceStatus) &&
      getNewInvoices({
        page,
        ...(invoiceStatus.code && { status: invoiceStatus.code }),
        ...(!!selectedClient && { client: selectedClient }),
        ...(!!selectedTalent && { talent: selectedTalent }),
      });
    return () => resetInvoices();
  }, [page, invoiceStatus, selectedClient, selectedTalent]);

  const renderColumn = ({ name, sortable, cellCallback, field, style, isVisible = true }: any) =>
    isVisible && (
      <Column key={name} field={field} body={cellCallback} header={name} sortable={sortable} className={style} />
    );

  const handleRowClick = (e: { originalEvent: MouseEvent; data: IInvoice; index: number }) => {
    e.originalEvent.preventDefault();
    setExpandedRows((expRows: any) => {
      const currentRowId = e.data.id;
      const isExpandCurrentElement = !!~expRows.findIndex((row: IInvoice) => row.id === currentRowId);
      return isExpandCurrentElement ? expRows.filter((row: IInvoice) => row.id !== currentRowId) : [...expRows, e.data];
    });
  };

  const disputeInvoice = (id: number, status: string) => {
    dispatch(
      actions.modal.openModal("DISPUTE_INVOICE_MODAL", {
        onSubmit: async (reason: string, callback: () => void) => {
          await putInvoice({ id, status, dispute_description: reason });
          callback();
        },
      })
    );
  };

  const approveInvoice = (id: number, status: string) => {
    dispatch(
      actions.modal.openModal("APPROVE_INVOICE_MODAL", {
        onSubmit: async (poNumber: string, callback: () => void) => {
          await putInvoice({ id, status, ...(poNumber ? { po_number: poNumber } : {}) });
          callback();
        },
      })
    );
  };

  const getActionButtons = (rowData: IInvoice) => [
    ...(isAdmin && rowData.status !== "SUBMITTED"
      ? [
          {
            label: "Edit",
            command: () => {
              dispatch(
                actions.modal.openModal("EDIT_INVOICE_MODAL", {
                  invoice: rowData,
                })
              );
            },
          },
        ]
      : []),
    ...(isAdmin && rowData.status !== "CANCELLED"
      ? [
          {
            label: "Add attachment",
            command: () => {
              dispatch(
                actions.modal.openModal("ADD_INVOICE_ATTACHMENT_MODAL", {
                  invoice: rowData,
                  onSubmit: async (attachment: File, callback: () => void) => {
                    await uploadAttachments(rowData?.id, [attachment]);
                    callback();
                  },
                })
              );
            },
          },
        ]
      : []),
    ...(rowData.available_statuses || []).map((status) => ({
      label: getInvoiceStatusData(status, rowData.due_date, true).labelStatus,
      command: (e: MenuItemCommandParams) => {
        e.originalEvent.stopPropagation();
        switch (status) {
          case "DISPUTED":
            disputeInvoice(rowData.id, status);
            break;
          case "AUTHORISED":
            approveInvoice(rowData.id, status);
            break;
          default:
            putInvoice({ id: rowData.id, status });
        }
      },
    })),
  ];

  const renderColumnStatusChange = (rowData: IInvoice, { rowIndex }: { rowIndex: number }) =>
    rowData.available_statuses?.length &&
    !["AUTHORISED", "PAID", "CLIENT_PAID", "TALENT_PAID", "OVERDUE"].includes(rowData.status) ? (
      <>
        <Button icon="pi pi-ellipsis-h" onClick={(event) => menuRefs.current[rowIndex].toggle(event)} />
        <Menu
          model={getActionButtons(rowData)}
          popup
          ref={(el) => (menuRefs.current[rowIndex] = el)}
          className="invoice-action-buttons"
        />
      </>
    ) : null;

  function changeInvoiceStatus(e: DropdownChangeParams) {
    if (!isNull(e.value)) {
      setInvoiceStatus(e.value);
    }
  }

  useEffect(() => {
    if (isUndefined(clients) && isAdmin) {
      dispatch(actions.client.listClients());
    }
    if (isUndefined(talents) && isAdmin) {
      dispatch(actions.talent.listTalents());
    }
  }, []);

  function onChangeClient(e: DropdownChangeParams) {
    setSelectedClient(e.value);
  }

  function onChangeTalent(e: DropdownChangeParams) {
    setSelectedTalent(e.value);
  }

  return (
    <>
      {!!invoices && (
        <>
          {isAdmin && (
            <div className="invoicesTools">
              <Dropdown
                className="filteringSelector"
                value={invoiceStatus}
                options={invoiceStatuses}
                onChange={changeInvoiceStatus}
                optionLabel="label"
                scrollHeight="300"
              />
              <div className="filterDropdown ml-3">
                <Dropdown
                  id="client_id"
                  dataKey="id"
                  filter={true}
                  filterBy="name, legal_name"
                  options={clients && clients.sort((a, b) => a.name.localeCompare(b.name))}
                  optionLabel="name"
                  optionValue="id"
                  value={selectedClient}
                  onChange={onChangeClient}
                  placeholder="Select Client"
                />
                <i className="filterDropdownReset pi pi-times" onClick={() => setSelectedClient(null)} />
              </div>
              <div className="filterDropdown ml-3">
                <Dropdown
                  id="talent_id"
                  dataKey="id"
                  filter={true}
                  filterBy="name"
                  options={talents && talents.sort((a, b) => a.name.localeCompare(b.name))}
                  optionLabel="name"
                  optionValue="id"
                  disabled={isFetchingTalents}
                  value={selectedTalent}
                  onChange={onChangeTalent}
                  placeholder="Select Talent"
                />
                <i className="filterDropdownReset pi pi-times" onClick={() => setSelectedTalent(null)} />
              </div>
            </div>
          )}
          <div className="InvoicesList">
            <DataTable
              autoLayout={true}
              className="InvoicesList__Table"
              emptyMessage="Not found"
              loading={isFetching}
              expandedRows={expandedRows}
              onRowClick={handleRowClick}
              rowExpansionTemplate={(rowData: IInvoice) => <NewInvoiceDetails invoice={rowData} isAdmin={isAdmin} />}
              value={invoices}
              rowClassName={({ id: invoiceId }: { id: number }) => {
                const isExpanded = expandedRows.map(({ id }: { id: number }) => id).includes(invoiceId);
                return {
                  expanded: isExpanded,
                  collapsed: !isExpanded,
                };
              }}
            >
              <Column className="toggler" />
              {getInvoicesColumns().map(renderColumn)}
              <Column body={renderColumnStatusChange} />
            </DataTable>
            <Paginator
              rows={15}
              first={(page - 1) * 15}
              totalRecords={totalRecords}
              onPageChange={({ page }) => setPage(page + 1)}
            />
          </div>
        </>
      )}
    </>
  );
}
