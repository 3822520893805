import React from "react";
import { ITalent } from "~/store/constants/talent";
import "./TalentPreferences.scss";

interface gg {
  [key: string]: 0 | 1;
}
export default function TalentPreferences({ rowData }: { rowData: ITalent }) {
  const talentBriefPreferences =
    rowData?.preferences &&
    Object.entries(rowData?.preferences).reduce((acc, [key, value]): any => {
      if (value === 1) {
        switch (key) {
          case "DAY_RATE":
            return [...acc, "D"];
          case "FIXED_TERM":
            return [...acc, "F"];
          case "PERMANENT":
            return [...acc, "P"];
          default:
            return [...acc];
        }
      }
      return [...acc];
    }, []);

  if (!talentBriefPreferences?.length) {
    return null;
  }
  return (
    <span
      className="preferences"
      title="D = Day Rate;&#10;F = Fixed Term;&#10;P = Permanent Hiring;"
    >
      ({talentBriefPreferences.join(", ")})
    </span>
  );
}
