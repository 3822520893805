import { useFormikContext } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useEffect } from "react";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import { ILineItem, IInvoiceFormValues, ILineItemUnit, LINE_ITEM_UNIT_TYPES } from "~/interfaces/invoices";

export default function LineItemForm({
  item,
  index,
  calculateTotal,
}: {
  item: ILineItem;
  index: number;
  calculateTotal: any;
}) {
  const { setValues, values } = useFormikContext<IInvoiceFormValues>();

  const isFixTerm = item.unit_type === ILineItemUnit.TYPE_FIXED_AMOUNT;
  const isExpense = item.unit_type === ILineItemUnit.EXPENSE;

  useEffect(() => {
    const lineItems = [...values.lineItems];

    if (
      lineItems[index].unit_type === ILineItemUnit.TYPE_FIXED_AMOUNT ||
      lineItems[index].unit_type === ILineItemUnit.EXPENSE
    ) {
      lineItems[index].quantity = 1;
    }

    if (lineItems[index].unit_type === ILineItemUnit.EXPENSE) {
      lineItems[index] = {
        ...lineItems[index],
        dates: [],
      };
    }

    setValues({ ...values, lineItems });
  }, [values.lineItems[index].unit_type]);

  useEffect(() => {
    calculateTotal(values, setValues, index);
  }, [values.lineItems[index].quantity, values.lineItems[index].unit_amount]);

  return (
    <>
      <div className="index">{index + 1}.</div>
      <div className="description">
        <ApInputTextarea
          id={`lineItems.${index}.description`}
          label="Description"
          placeholder="Enter a description of the work you complete in this period…"
        />
      </div>
      <div className="unit">
        <ApDropdown id={`lineItems.${index}.unit_type`} label="Unit" options={LINE_ITEM_UNIT_TYPES} />
      </div>
      <div className="amount">
        <ApInputNumber
          required
          id={`lineItems.${index}.unit_amount`}
          label="Amount"
          mode="currency"
          currency="GBP"
          max={1000000}
          minFractionDigits={0}
          maxFractionDigits={2}
        />
      </div>
      <div className="qty">
        <ApInputNumber
          id={`lineItems.${index}.quantity`}
          label="Qty"
          mode="decimal"
          disabled={isFixTerm || isExpense}
          max={1000000}
          minFractionDigits={0}
          maxFractionDigits={2}
        />
      </div>
      <div className="dates">
        <Calendar
          id={`lineItems.${index}.start_date`}
          disabled={isExpense}
          placeholder="N/A"
          readOnlyInput
          maxDate={new Date()}
          label="Start Date - End Date"
          selectionMode="range"
          onChange={({ value }) => {
            if (!!value && Array.isArray(value)) {
              const newValues = { ...values };
              newValues.lineItems[index].dates = [
                !!value[0] ? moment(value[0]).format("YYYY-MM-DD") : value[0],
                !!value[1] ? moment(value[1]).format("YYYY-MM-DD") : value[1],
              ];
              setValues(newValues);
            }
          }}
          value={[new Date(item.dates[0]), !!item.dates[1] ? new Date(item.dates[1]) : null]}
        />
      </div>
      <div className="total">
        <ApInputText disabled id={`lineItems.${index}.line_amount`} label="Total" price />
      </div>
    </>
  );
}
