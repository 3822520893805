import { IState } from "../reducers/index";
import Axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import Broadcast from "../constants/broadcast";
import Toasts, { TOAST_ERROR_MESSAGE } from "../constants/toasts";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  sendMessage: (form: any) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Broadcast.sendMessageRequest());
          const res = await Axios.post(`${API_URL}/send_message`, form);
          dispatch(Broadcast.sendMessageSuccess(res));
          dispatch(Toasts.setToasts([{ severity: "success", detail: "Mesage sent 👌🏾" }]));
          resolve(res);
        } catch (err) {
          console.log(err);
          dispatch(Broadcast.sendMessageFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "Make a contact", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
};
