import React, { Dispatch, useMemo } from "react";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useBriefContext } from "~/contexts/BriefContext";
import { FormikContextType } from "formik";

interface IEntries {
  skills: boolean;
  sectors: boolean;
  platforms: boolean;
}

interface IDiscipline {
  formik: FormikContextType<any>;
  disciplines: any;
  isViewOnly?: boolean;
  setSelectedSkills: Dispatch<any>;
  setSelectedSectors: Dispatch<any>;
  setSelectedPlatforms: Dispatch<any>;
  setIsDisabledEntries: (initialStateEntries: IEntries) => void;
  initialStateEntries: IEntries;
}
export default function Discipline(props: IDiscipline) {
  const {
    formik,
    disciplines,
    isViewOnly = false,
    setSelectedSkills,
    setSelectedSectors,
    setSelectedPlatforms,
    setIsDisabledEntries,
    initialStateEntries,
  } = props;

  const { checkIsAdmin } = usePermissionContext();
  const isAdmin = useMemo(checkIsAdmin, []);
  const { brief } = useBriefContext();
  const isBriefRebook = useMemo(() => (isAdmin ? brief?.brief_type : brief?.type) === "REBOOK", [brief]);

  const handleChangeDiscipline = (e: { value: number }) => {
    formik.setValues({
      ...formik.values,
      discipline_id: e.value,
      specialism_id: null,
      level_id: null,
      skills: [],
      sectors: [],
      platforms: [],
    });
    setSelectedSkills([]);
    setSelectedSectors([]);
    setSelectedPlatforms([]);
    setIsDisabledEntries(initialStateEntries);
  };

  return !isBriefRebook ? (
    <div className="p-fluid field field-required">
      <label htmlFor="discipline_id">Discipline</label>
      <ApDropdown
        disabled={!disciplines || isViewOnly}
        scrollHeight="320px"
        formik={formik}
        id="discipline_id"
        noField
        options={disciplines}
        optionLabel="name"
        optionValue="id"
        placeholder="Select discipline"
        onChange={handleChangeDiscipline}
      />
    </div>
  ) : null;
}
