import moment from "moment";

export const checkBeforeDueDate = (date: string) => moment(date).isBefore();
export const checkIsCurrentDay = (date: string) => moment(date).isSame(moment(), "day");

export const getInvoiceStatusData = (status: string, due_date: string, action = false, isXeroInvoice = false) => {
  let colorClass = "";
  let labelStatus = status.split("_").join(" ") || "";

  const isBeforeDueDate = checkBeforeDueDate(due_date);
  const isCurrentDay = checkIsCurrentDay(due_date);
  const isOverdueStatus = isBeforeDueDate && !isCurrentDay;

  switch (status) {
    case "DRAFT":
      colorClass = "gray";
      labelStatus = action ? "Return to draft" : status;
      break;
    case "SUBMITTED":
      colorClass = "yellow";
      labelStatus = action ? "Submit for review" : "Awaiting approval";
      break;
    case "PAID":
    case "CLIENT_PAID":
    case "TALENT_PAID":
      colorClass = "green";
      break;
    case "DISPUTED":
      colorClass = "yellow";
      labelStatus = action ? "Dispute" : status;
      break;
    case "CANCELLED":
      colorClass = "red";
      labelStatus = action ? "Cancel Invoice" : status;
      break;
    case "AUTHORISED":
      colorClass = isOverdueStatus && isXeroInvoice ? "red" : "green";
      labelStatus = isOverdueStatus && isXeroInvoice ? "Overdue" : action ? "Approve" : "Awaiting payment";
      break;
    case "OVERDUE":
      colorClass = "red";
      break;
    default:
      colorClass = "gray";
      labelStatus;
  }

  labelStatus = labelStatus.charAt(0).toUpperCase() + labelStatus.slice(1).toLowerCase();

  return { colorClass, labelStatus };
};

export const getIr35Name = (code: string) => {
  let name = "";
  switch (code) {
    case "IR35_INSIDE":
      name = "Inside IR35";
      break;
    case "IR35_OUTSIDE":
      name = "Outside IR35";
      break;
    case "IR35_EXEMPT":
      name = "Exempt";
      break;
    case "IR35_UMBRELLA":
      name = "Umbrella";
      break;
    default:
      name = " – ";
      break;
  }
  return name;
};
