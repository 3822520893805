import React, { SyntheticEvent, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as permissionType from "~/constants";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { usePermissionContext } from "~/contexts/PermissionContext";
import "./LayoutHeader.scss";
import {
  TALENT,
  CLIENTS,
  BRIEFS,
  CONVERSATIONS,
  MAKE_CONTACT,
  NOTIFICATIONS,
  HELP_CENTRE,
  DASHBOARD,
  MY_TALENT_NETWORK,
  COMPANY_PROFILE,
  BOOKINGS,
  INVOICES,
} from "./constants";
import { useInvoiceContext } from "~/contexts/InvoiceContext";
import { Badge } from "primereact/badge";

function MobileMenu(props: RouteComponentProps) {
  const { checkIsAdmin, checkIsClientAdmin, userAccess, canGetOwnInvoice } = usePermissionContext();
  const { pendingInvoicesTotal } = useInvoiceContext();

  const isAdmin = checkIsAdmin();
  const isClientAdmin = checkIsClientAdmin();
  const isClient = userAccess(permissionType.getOwnClientInfo, permissionType.getAnyClient);
  let menu: Menu | null = null;

  const availableItems = [HELP_CENTRE];
  isAdmin && availableItems.unshift(TALENT, CLIENTS, BRIEFS, INVOICES, CONVERSATIONS, MAKE_CONTACT, NOTIFICATIONS);

  canGetOwnInvoice && availableItems.unshift(INVOICES);
  isClientAdmin && availableItems.unshift(BOOKINGS);
  isClientAdmin && availableItems.unshift(BRIEFS);

  isClientAdmin && isClient && availableItems.unshift(COMPANY_PROFILE);
  isClientAdmin && availableItems.unshift(DASHBOARD, MY_TALENT_NETWORK);

  const badgeItem = (label: string | null, value: number | undefined, classNames: string) => {
    const badge =
      canGetOwnInvoice && pendingInvoicesTotal && pendingInvoicesTotal > 0 ? (
        <Badge className={classNames} value={value} severity="danger"></Badge>
      ) : null;
    return (
      <>
        <span>{label}</span>
        {badge}
      </>
    );
  };

  const allItems = useMemo(
    () => ({
      [TALENT]: {
        label: "Talent",
        command: () => props.history.push("/talents"),
      },
      [CLIENTS]: {
        label: "Clients",
        command: () => props.history.push("/clients"),
      },
      [BRIEFS]: {
        label: "Briefs",
        command: () => props.history.push("/briefs"),
      },
      [CONVERSATIONS]: {
        label: "Conversations",
        command: () => props.history.push("/conversations"),
      },
      [MAKE_CONTACT]: {
        label: "Make Contact",
        command: () => props.history.push("/contact"),
      },
      [NOTIFICATIONS]: {
        label: "Notifications",
        command: () => props.history.push("/notifications"),
      },
      [HELP_CENTRE]: {
        label: "Help Centre",
        command: () => window.open("https://meetgenie.co/help-centre", "_blank"),
      },
      [DASHBOARD]: {
        label: "Dashboard",
        command: () => props.history.push("/"),
      },
      [MY_TALENT_NETWORK]: {
        label: "My Talent Network",
        command: () => props.history.push("/talents"),
      },
      [COMPANY_PROFILE]: {
        label: "Company Profile",
        command: () => props.history.push("/clients/current"),
      },
      [BOOKINGS]: {
        label: "Bookings",
        command: () => props.history.push("/bookings"),
      },
      [INVOICES]: {
        label: badgeItem("Invoices", pendingInvoicesTotal, "badge-menu-invoices"),
        command: () => props.history.push("/invoices"),
      },
    }),
    [pendingInvoicesTotal]
  );

  const getItems = (items: any) =>
    items.reduce((specificItems, key) => {
      if (key in allItems) {
        specificItems.push(allItems[key]);
      }
      return specificItems;
    }, []);

  const toggleMenu = (e: SyntheticEvent) => {
    if (menu) {
      menu.toggle(e);
    }
  };

  return (
    <>
      <Menu model={getItems(availableItems)} popup={true} ref={(ref) => (menu = ref)} />
      <Button className="mobile-menu" icon="pi pi-bars" onClick={toggleMenu} aria-controls="popup_menu" aria-haspopup />
    </>
  );
}

export default withRouter(MobileMenu);
